import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  FOMRS,
  apiConstants,
  successMsg,
  tableNamePaymentConstants,
} from "../../constants/Common/apiConstants";
import { ROUTE_PATH } from "../../constants/Routes";
import "../../pages/debitTransaction/DtMerge/DtMerge.scss";
import apiCall from "../../utils/apiFunction/apiCall";
import Button from "../common/Button/button";
import CustomTable from "../customtable/CustomTable";
import "./User.scss";

import { useDispatch } from "react-redux";
import { showBar } from "../../redux/reducer/sideBarStatus";
import SideBar from "../sideBar";
import AssignRole from "./assignRole";
import SystemXLoader from "../common/Loader/SystemXLoader";
import Export from "../../Images/export.svg";
import ChangePasswordSidebar from "./ChangePassword/ChangePasswordSideabar";
import SearchBar from "../common/Searchbar/searchbar";
import DeleteConfirmation from "../deleteModal/deleteModal";
import { toast } from "react-toastify";
const MyComponent = () => {
  const isRoleAssign = useRef(false);
  const isPasswordChange = useRef(false);

  const [sidebarType, setSidebarType] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [listdata, setListdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [datacount, setDatacount] = useState(0)
  const [searchValue, setSearchValue] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [downloadFlag, setDownloadFlag] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const searchValueChange = (value) => {
    setSearchValue(value);
  }

  const deleteFunc = () => {
    const query = {
      tablename: tableNamePaymentConstants?.User,
      body: { isBlock: 1, isActive: 0 },
      whereQuery: deleteItemId
    }

    console.log(query, '<<<<<<Q');
    apiCall.post(apiConstants?.updateUser, query).then((res) => {
      if (res?.data?.status === 200) {
        toast.success(successMsg.Deleted)
        setShowDeleteConfirmationModal(false);
        listApi(pageNumber)
      } else {
        toast.error(res?.data?.message)
      }
    })
  }

  const listApi = (value) => {
    let temp = 0;
    if ((value || pageNumber) > 0) {
      temp = value ? (value - 1) * 10 : (pageNumber - 1) * 10;
    }
    setLoading(true);
    apiCall
      .post(apiConstants?.fetchAllData, {
        // request body
        offset: temp,
        limit: 10,
        tablename: tableNamePaymentConstants?.User,
        whereQuery: { isBlock: 0 },
        order: [["id", "DESC"]],
        month: 0,
      })
      .then(async (res) => {
        if (res?.status === 200) {
          let result = res?.data?.response;
          setDatacount(res?.data?.count)
          result = result.map((item, index) => {
            let newItem = {
              no: temp + index + 1,
              user_name: item?.user_name,
              user_code: item?.user_code,
              user_type: item?.user_type,
              isBlock: item?.isBlock,
              change_password: (
                <div>
                  <img
                    className="icon"
                    src="/assests/images/reset-password.svg"
                    onClick={() => {
                      // navigate(ROUTE_PATH.ASSIGN_ROLE);
                      setSidebarType("password");
                      dispatch(showBar(true));
                      setSelectedRole(item);
                    }}
                    alt=""
                  />
                </div>
              ),
              assign_role: (
                <div>
                  <img
                    className="icon"
                    src="/assests/images/user.svg"
                    onClick={() => {
                      //   navigate(ROUTE_PATH.ASSIGN_ROLE);
                      setSidebarType("role");
                      dispatch(showBar(true));
                      setSelectedRole(item);
                    }}
                    alt=""
                  />
                </div>
              ),
              action: (
                <div>
                  <img
                    className="icon"
                    src="/assests/images/pencil.svg"
                    onClick={() => {
                      navigate(ROUTE_PATH.CREATE_USER, {
                        state: {
                          type: FOMRS.EDIT,
                          id: item,
                        },
                      });
                    }}
                    alt=""
                  />
                  <img
                    className="delete_icon"
                    src="/assests/images/trash-bin.svg"
                    onClick={() => {
                      setDeleteItemId({
                        id: item?.id,
                        user_name: item?.user_name,
                      })
                      setShowDeleteConfirmationModal(true)
                    }}
                    alt=""
                  />
                </div>
              )
            };
            return newItem;
          });
          if (result?.length > 0) {
            setListdata([...result]);
          }
        }
      })
      .catch((er) => { })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleSearch = (value) => {
    let temp = 0

    if ((value || pageNumber) > 0) {
      temp = value ? (value - 1) * 10 : (pageNumber - 1) * 10;
    }
    setIsSearch(true);
    setLoading(true);
    const params = {
      tableName: tableNamePaymentConstants?.User,
      columns: ['user_name', 'user_code', 'user_type', 'isBlock'],
      offset: temp,
      order: [['id', 'DESC']]
    };

    apiCall
      .post(apiConstants?.commonSearch + searchValue, params)
      .then((res) => {
        if (res.status === 200) {
          let result = res?.data?.response?.rows;
          setDatacount(res?.data?.count || 0)
          result = result.map((item, index) => {
            let newItem = {
              no: temp + index + 1,
              user_name: item?.user_name,
              user_code: item?.user_code,
              user_type: item?.user_type,
              isBlock: item?.isBlock,
              change_password: (
                <div>
                  <img
                    className="icon"
                    src="/assests/images/reset-password.svg"
                    onClick={() => {
                      // navigate(ROUTE_PATH.ASSIGN_ROLE);
                      setSidebarType("password");
                      dispatch(showBar(true));
                      setSelectedRole(item);
                    }}
                    alt=""
                  />
                </div>
              ),
              assign_role: (
                <div>
                  <img
                    className="icon"
                    src="/assests/images/user.svg"
                    onClick={() => {
                      //   navigate(ROUTE_PATH.ASSIGN_ROLE);
                      setSidebarType("role");
                      dispatch(showBar(true));
                      setSelectedRole(item);
                    }}
                    alt=""
                  />
                </div>
              ),
              action: (
                <div>
                  <img
                    className="icon"
                    src="/assests/images/pencil.svg"
                    onClick={() => {
                      navigate(ROUTE_PATH.CREATE_USER, {
                        state: {
                          type: FOMRS.EDIT,
                          id: item,
                        },
                      });
                    }}
                    alt=""
                  />
                  <img
                    className="delete_icon"
                    src="/assests/images/trash-bin.svg"
                    alt=""
                  />
                </div>
              )
            };
            return newItem;
          });
          if (result?.length > 0) {
            setListdata([...result]);
          }
        }

      }).catch((err) => {
        console.log("some error", err);
      }).finally(() => {
        setLoading(false);
      });
  }


  const userGetRecords = async () => {
    setDownloadFlag(true);
    setLoading(true);
    const params = {
      tablename: tableNamePaymentConstants.User,
      attributes: ["user_code", "user_name", "user_type", "isBlock"],
      month: "0",
      where: {},
    };
    try {
      const response = await apiCall.post(apiConstants?.getXLSXFile, params);
      const presignedUrl = response?.data?.response;
      const downloadLink = document.createElement("a");
      downloadLink.href = presignedUrl;
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("ERROR ::::::::::::::", error);
    } finally {
      setDownloadFlag(false);
      setLoading(false);
    }
  };

  const closeSideBar = () => {
    dispatch(showBar(false));
    setSelectedRole(null);
    setSidebarType("");
  };

  const changePage = (value) => {
    listApi(value);
    setPageNumber(value - 1);
  };
  useEffect(() => {
    closeSideBar();
    listApi();
  }, []);

  return (
    <>
      <div id="userpage">
        <div className="d-flex flex-wrap align-items-center justify-content-between mt-4 mb-3 mx-3">
          <div className="right-div-header">
            <div>
              <label className="labelPresentation">User</label>
            </div>
          </div>
          <div className="d-flex flex-wrap gap-3 left-div-header">
            {/* <div className="text-right input-container">
							<InputField className="SearchButton" placeholder={'Search'} />
							<span className="search-icon">
								<img
									src="/assests/images/Icon feather-search.svg"
									alt="Search icon"
								/>
							</span>
						</div>  */}
            <div className='mx-3 h-100'>
              <SearchBar value={searchValue} onChange={searchValueChange} onSearchClick={handleSearch} />
            </div>
            <div className="text-right">
              <button
                className={`exportBtn ${downloadFlag ? "disabled" : ""}`}
                type="button"
                value="Export"
                role="export"
                onClick={() => userGetRecords()}
                disabled={downloadFlag}
              >
                <span>
                  <img alt="export" src={Export}></img>
                </span>
                Export
              </button>
            </div>

            <div>
              <DeleteConfirmation
                showModal={showDeleteConfirmationModal}
                hideModal={() => setShowDeleteConfirmationModal(false)}
                confirmModal={deleteFunc}
                id={deleteItemId}
                type={FOMRS.DELETE}
                message="Are you sure you want to delete this item?"
              />
              <button
                className="createUserBtn"
                onClick={() => {
                  navigate(ROUTE_PATH.CREATE_USER, {
                    state: {
                      type: FOMRS.ADD,
                    },
                  });
                }}
              >
                Create User
              </button>
              {selectedRole && sidebarType === "role" && (
                <SideBar
                  data={
                    <AssignRole
                      selectedUser={selectedRole}
                      onClose={closeSideBar}
                    />
                  }
                />
              )}
              {selectedRole && sidebarType === "password" && (
                <SideBar
                  data={
                    <ChangePasswordSidebar
                      selectedUser={selectedRole}
                      onClose={closeSideBar}
                    />
                  }
                />
              )}
            </div>
          </div>
        </div>
        <div className="col dtMergeTable">
          {downloadFlag && (
            <div className="loaderDiv">
              <SystemXLoader />
            </div>
          )}
          <CustomTable
            cols={[
              {
                id: "no",
                title: "Sr No.",
              },
              {
                id: "user_code",
                title: "User ID",
              },
              {
                id: "user_name",
                title: "Name",
              },
              {
                id: "user_type",
                title: "User Type",
              },
              {
                id: "isBlock",
                title: "Is Block",
              },
              {
                id: "change_password",
                title: "Change Password",
              },
              {
                id: "assign_role",
                title: "Assign Role",
              },
              {
                id: "action",
                title: "Action",
              },
            ]}
            data={listdata}
            count={datacount}
            setPagination={(page) => changePage(page)}
            activePage={pageNumber}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
};

export default MyComponent;
