import React, { useEffect, useState } from "react";
import "./DisbursementTable.scss";
import DynamicForm from "../../../component/common/DynamicForm/dynamicForm";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import apiCall from "../../../utils/apiFunction/apiCall";
import moment from "moment";
import { errorMessages } from "../../../constants/Common/errorConstants";
import { toast } from "react-toastify";
import {
  apiConstants,
  errMessage,
  toastIds,
} from "../../../constants/Common/apiConstants";
import {
  commonVariables,
  dateFormats,
} from "../../../constants/Common/commonConstants";
import CustomTable from "../../../component/customtable/CustomTable";

const DisbursementTable = () => {
  const [formValues, setFormValues] = useState([
    {
      type: "dateinput",
      value: new Date(),
      label: "From Date",
      placeholder: "Select Date",
      required: true,
      width: 6,
    },
    {
      type: "dateinput",
      value: new Date(),
      label: "To Date",
      placeholder: "Select Date",
      required: true,
      width: 6,
    },
  ]);
  const [tableShowData, setTableShowData] = useState([]);
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [formData,setFormData]=useState();
  const onSubmit = (value,data) => {
    let temp = 0;
    if ((value || pageNumber) > 0) {
      temp = value ? (value - 1) * 10 : (pageNumber - 1) * 10;
    }
    if (data?.length) {
      let tempData = [...data];
      tempData = Object.assign({}, ...tempData);
      let apiBody = {
        FromDate:
          moment(tempData["From Date"]).format(dateFormats?.dmyupd) || "",
        ToDate: moment(tempData["To Date"]).format(dateFormats?.dmyupd) || "",
        offset: temp || 0,
        limit: apiConstants.limit || 10,
      };
      if (tempData["To Date"] >= tempData["From Date"]) {
        setLoading(true);
        apiCall
          .get(
            apiConstants.ReportsDisbursementDownloadFetchDisbursementReports,
            { params: apiBody }
          )
          .then(async (res) => {
            if (res.status === 200) {
              let result = res?.data?.response?.data;
              let resultShow = result?.map((item, index) => {
                let newItem = {
                  id: index + 1,
                  file_name: item?.file_name,
                  disbursement_data: item?.disbursement_data,
                  createdAt: moment(item?.createdAt).format(dateFormats?.dmy),
                  status: item?.status,
                  total_records: item?.total_records,
                };
                return newItem;
              });
              if (result?.length) {
                setTableShowData([...resultShow]);
                setListData([...result]);
                setCount(res?.data?.response?.totalCount);
              } else {
                toast.error(
                  res?.data?.message || errorMessages.SOMETHING_WRONG,
                  {
                    toastId: toastIds?.DataError,
                  }
                );
              }
            }
          })
          .catch((er) => {
            let resp = er?.message || "";
            toast.error(resp, {
              toastId: toastIds?.DataError,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        toast.error(errMessage?.ToDateMustBeGreaterThanFromDate, {
          toastId: toastIds?.SomethingWentWrong,
        });
      }
    }
  };

  const changePage = (value) => {
    setPageNumber(value);
  };

  const onDownload = () => {
    setLoading(true);
    apiCall
      .post(apiConstants?.downloadDisbursementReports, listData)
      .then((res) => {
        if (res?.data?.status === 200) {
          if (res?.data?.response) {
            const link = document.createElement("a");
            link.href = res?.data?.response;
            link.click();
            toast.success(commonVariables?.FileDownloadSuccess, {
              toastId: toastIds?.FileDownloadSucess,
            });
          } else {
            toast.error(res?.data?.message || errorMessages?.SOMETHING_WRONG, {
              toastId: toastIds?.DataError,
            });
          }
        } else {
          toast.error(res?.data?.message || errorMessages?.SOMETHING_WRONG, {
            toastId: toastIds?.DataError,
          });
        }
      })
      .catch((er) => {
        let resp = er?.message || "";
        toast.error(resp || errorMessages?.SOMETHING_WRONG, {
          toastId: toastIds?.SomethingWentWrong,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const Table = () => {
    return (
      <div className="mx-4">
        <button
          className="disbursementTable_downloadBtn m-3"
          onClick={() => onDownload()}
        >
          Download
        </button>
        <CustomTable
          cols={[
            {
              id: "id",
              title: "Sr No.",
            },
            {
              id: "file_name",
              title: "File Name",
            },
            {
              id: "createdAt",
              title: "Created At",
            },
            {
              id: "total_records",
              title: "Total Records",
            },
            {
              id: "disbursement_data",
              title: "Total Success Records",
            },
            {
              id: "status",
              title: "Status",
            },
          ]}
          data={tableShowData}
          count={count}
          activePage={pageNumber}
          loading={loading}
          setPagination={(page) => changePage(page)}
        />
      </div>
    );
  };

  useEffect(() => {
    if(pageNumber)
    {
      onSubmit(pageNumber,formData);
    }
  }, [pageNumber]);

  return (
    <div>
      {loading && (
        <div className="loaderDiv">
          <SystemXLoader />
        </div>
      )}
      <p className="disbursementTable_disbursementTableTxt px-4 mt-4 pt-2">
        <b>Disbursement Table</b>
      </p>
      <div className="disbursementTable_inputContainer mx-4 p-4">
        <DynamicForm onSubmit={(data)=>{setFormData(data); onSubmit(pageNumber,data);}} previewBtn formValues={formValues} />
      </div>
      {tableShowData.length > 0 && <Table />}
    </div>
  );
};

export default DisbursementTable;
