import React, { useEffect, useState } from "react";
import Datepicker from "../../../component/common/Daterange/daterange";
import Dropdown from "../../../component/common/Dropdown/dropdown";
import Button from "../../../component/common/Button/button";
import "./reports.scss";

function AccountReports() {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [reportType, setReportType] = useState("");
  const [reportOption, setReportOption] = useState([]);
  return (
    <>
      <div className="account_reports">
        <div className="">
          <h6>Reports</h6>
        </div>
        <div className="row report_details">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <Datepicker
              selected={startDate}
              onChange={(e) => setStartDate(e)}
              maxDate={new Date()}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <Datepicker
              selected={endDate}
              onChange={(e) => setEndDate(e)}
              maxDate={new Date()}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <Dropdown
              options={reportOption}
              placeholder={"Select System"}
              value={reportType}
              onChange={(e) => setReportType(e)}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6"></div>
          <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <Button value="Reset" role="secondary" className="set_top_margin" />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <Button
              value="Download"
              role="primary"
              className="set_top_margin"
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default AccountReports;
