import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { showBar } from "../../../../redux/reducer/sideBarStatus";
import Datepicker from "../../../../component/common/Daterange/daterange";
import Button from "../../../../component/common/Button/button";
import CloseBtn from "../../../../Images/close-cirle-btn.svg";
import "./CreateMerge.scss";
import apiCall from "../../../../utils/apiFunction/apiCall";
import { apiConstants, tableNamePaymentConstants } from "../../../../constants/Common/apiConstants";
import { Formik, Form, ErrorMessage } from "formik";
import { object, string } from "yup";
import FormLabel from "../../../../component/common/formLabel/formLabel";
import FormikSelect from "../../../../component/common/formikSelect/formikSelect";
import { PresentationOptions, YupMessages } from "../../../../constants/Common/commonConstants";

const CreateMerge = () => {
  const dispatch = useDispatch();
  const [mandatedata, setMandateData] = useState([]);
  const [companycode, setCompanyCode] = useState([]);
  const [sponserBank, setSponserBank] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [settlementValue, setSettlementValue] = useState("");

  let validSchema = object({
    manual_mandate: string().required(YupMessages?.ManualMandateRequired),
    sponsor_bank: string().required(YupMessages?.SponsorBankRequired),
    company_code: string().required(YupMessages?.CompanyCodeRequired),
    presentation_type: string().required(YupMessages?.PresentationTypeRequired),
    from_date: string().required(YupMessages?.FromDateRequired),
    to_date: string().required(YupMessages?.ToDateRequired),
    settlement: string().required(YupMessages?.SettlementDateRequired),
  });

  const MandateType = () => {
    let paramObj = {
      offset: 0,
      limit: 10,
      tablename: tableNamePaymentConstants?.mandateType,
    };
    apiCall
      .post(apiConstants?.findData, paramObj)
      .then(async (res) => {
        let result = res?.data?.response;
        result = result.map((item) => {
          let newItem = {
            key: item.mandate_type,
          };
          return newItem;
        });
        setMandateData([...result]);
      })
      .catch((er) => {
        console.log("errr =>>>>", er);
      });
  };

  const CompanyCode = () => {
    let paramObj = {
      offset: 0,
      limit: 10,
      tablename: tableNamePaymentConstants?.Company,
    };
    apiCall
      .post(apiConstants?.findData, paramObj)
      .then(async (res) => {
        let result = res?.data?.response;
        result = result.map((item) => {
          let newItem = {
            key: item.company_code,
          };

          return newItem;
        });

        setCompanyCode([...result]);
      })
      .catch((er) => {
        console.log("errr =>>>>", er);
      });
  };

  const SponserBank = () => {
    let paramObj = {
      offset: 0,
      limit: 10,
      tablename: tableNamePaymentConstants?.SponsorBank,
    };
    apiCall
      .post(apiConstants?.findData, paramObj)
      .then(async (res) => {
        let result = res?.data?.response;
        result = result.map((item) => {
          let newItem = {
            key: item.bank_name,
          };

          return newItem;
        });

        setSponserBank([...result]);
      })
      .catch((er) => {
        console.log("errr =>>>>", er);
      });
  };
  useEffect(() => {
    CompanyCode();
    MandateType();
    SponserBank();
  }, []);

  return (
    <>
      <div className="container createmanage ">
        <div className="d-flex justify-content-between ">
          <label className="heading mb-2 ps-3  ">Merge</label>
          <button
            type="button"
            className="cls-btn"
            onClick={() => {
              dispatch(showBar(false));
            }}
          >
            <img src={CloseBtn} alt="close button"></img>
          </button>
        </div>
      </div>
      <Formik
        initialValues={{
          manual_mandate: "",
          sponsor_bank: "",
          company_code: "",
          presentation_type: "",
        }}
        validationSchema={validSchema}
        // onSubmit={(values) => {
        //   console.log("regulsar users ::::", values);
        // }}
      >
        {({ values, setFieldValue }) => (
          <Form className="justify-content-center mx-3 createmanage">
            <div className=" mb-2 ps-2">
              <FormLabel
                value="Manual Mandate"
                className="sub-headings required"
              />
              <FormikSelect
                label="select a topic"
                name="manual_mandate"
                options={mandatedata}
                className="manage-inputs"
              />
              <div className="errorMessage">
                <ErrorMessage name="manual_mandate" />
              </div>
            </div>
            <div className="mb-2 ps-2">
              <FormLabel
                value="Sponsor Bank"
                className="sub-headings required"
              />
              <FormikSelect
                label="select a topic"
                name="sponsor_bank"
                options={sponserBank}
                className="manage-inputs"
              />
              <div className="errorMessage">
                <ErrorMessage name="sponsor_bank" />
              </div>
            </div>
            <div className="mb-2 ps-2">
              <FormLabel value="From  Date" className="sub-headings required" />
              <Datepicker
                className="manage-date"
                placeholder={"Please Select Date"}
                isClear={true}
                monthsShown={"2"}
                onChange={(e) => {
                  setFromDate(e);
                }}
                selected={fromDate}
              />
              <div className="errorMessage">
                <ErrorMessage name="from_date" />
              </div>
            </div>
            <div className="mb-2 ps-2">
              <FormLabel value="To Date" className="sub-headings required" />
              <Datepicker
                className="manage-date"
                placeholder={"Please Select  Date"}
                isClear={true}
                monthsShown={"2"}
                onChange={(e) => {
                  setToDate(e);
                }}
                selected={toDate}
              />
              <div className="errorMessage">
                <ErrorMessage name="to_date" />
              </div>
            </div>
            <div className="mb-2 ps-2">
              <FormLabel
                value="Presentation Type"
                className="sub-headings required"
              />
              <FormikSelect
                label="select a topic"
                name="presentation_type"
                options={PresentationOptions}
                className="manage-inputs"
              />
              <div className="errorMessage">
                <ErrorMessage name="presentation_type" />
              </div>
            </div>
            <div className="mb-2 ps-2">
              <FormLabel
                value="Company Code"
                className="sub-headings required"
              />
              <FormikSelect
                label="select a topic"
                name="company_code"
                options={companycode}
                className="manage-inputs"
              />
              <div className="errorMessage">
                <ErrorMessage name="company_code" />
              </div>
            </div>

            <div className="mb-2 ps-2">
              <FormLabel
                value="Settlement Date"
                className="sub-headings required"
              />
              <Datepicker
                className="manage-date"
                placeholder={"Please Select  Date"}
                isClear={true}
                monthsShown={"2"}
                onChange={(e) => {
                  setSettlementValue(e);
                }}
                selected={settlementValue}
              />
              <div className="errorMessage">
                <ErrorMessage name="settlement" />
              </div>
            </div>

            <div className="d-flex gap-4 ms-2 ">
              <Button
                role="secondary"
                value="Cancel"
                className="cancel-btn"
                onClick={() => {
                  dispatch(showBar(false));
                }}
              />
              <Button role="primary" value="Submit" />
            </div>
          </Form>
        )}
      </Formik>

      {/* <div className=" mb-2">
        <label htmlFor="l2" className="sub-headings">
          Sponser Bank
        </label>
        <Dropdown
          options={sponserBank}
          placeholder="Please Select Sponser Bank"
          id="l2"
          className="manage-inputs ms-1"
          value={sponserBankvalue}
          onChange={(e) => {
            setsponserBankvalue(e);
          }}
        />
      </div>
      <div className=" mb-2">
        <label htmlFor="l3" className="sub-headings">
          From Date
        </label>
        <Datepicker
          placeholder="Please Select Date"
          id="l3"
          className="manage-date p-2"
          selected={fromDate}
          onChange={(e) => {
            setFromDate(e);
          }}
        />
      </div>
      <div className=" mb-2">
        <label htmlFor="l4" className="sub-headings">
          To Date
        </label>
        <Datepicker
          placeholder="Please Select Date"
          id="l4"
          className="manage-date  p-2"
          selected={toDate}
          onChange={(e) => {
            setToDate(e);
          }}
        />
      </div>
      <div className=" mb-2">
        <label htmlFor="l5" className="sub-headings">
          Presentation Type
        </label>
        <Dropdown
          options={PresentationData}
          placeholder="Please Select Value"
          id="l5"
          className="manage-inputs"
          onChange={(e) => {
            setpresentation(e);
          }}
          value={presentation}
        />
      </div>
      <div className=" mb-2">
        <label htmlFor="l6" className="sub-headings">
          Company Code
        </label>
        <Dropdown
          options={companycode}
          placeholder="Please Select Company Code"
          id="l6"
          className="manage-inputs"
          onChange={(e) => {
            setCompanyCodeValue(e);
          }}
          value={companycodevalue}
        />
      </div>
      <div className=" mb-2">
        <label htmlFor="l7" className="sub-headings">
          Settlement Date
        </label>
        <Datepicker
          placeholder="Please Select Date"
          id="l7"
          className="manage-date  p-2"
          selected={settlementValue}
          onChange={(e) => {
            setSettlementValue(e);
          }}
        />
      </div>
      */}
    </>
  );
};
export default CreateMerge;
