import React, { useEffect, useState } from "react";
import CustomTable from "../../component/customtable/CustomTable";
import SystemXLoader from "../../component/common/Loader/SystemXLoader";
import { Link } from "react-router-dom";
import Button from "../../component/common/Button/button";
import InputField from "../../component/common/Inputfield/inputfield";
import apiCall from "../../utils/apiFunction/apiCall";
import Dropdown from "../../component/common/Dropdown/dropdown";
import {
  options,
  commonVariables,
  dateFormats,
} from "../../constants/Common/commonConstants";
import { toast } from "react-toastify";
import "../enach/Enach.scss";
import {
  apiConstants,
  enachStatusAttributes,
  errMessage,
  ByIdTableORder,
  tableNamePaymentConstants,
  toastIds,
} from "../../constants/Common/apiConstants";
import moment from "moment/moment";

const Statuspage = () => {
  const [values, setValues] = useState("");
  const [inputVal, setinputVal] = useState("");
  const [listdata, setListdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectMonth, setSelectMonth] = useState(1);
  const [count, setCount] = useState(0);
  const [defaultValue, setDefaultValue] = useState({
    label: "Select Category",
    value: "Select Category",
  });
  const [downloadFlag, setDownloadFlag] = useState(false);

  const fetchLogs = (mandate_request_id , key) => {
    return (
      <>
        <Link to="" onClick={() => downloadLogs(key)}>
          {mandate_request_id}
        </Link>
      </>
    );
  };

  const downloadLogs = (key) => {
    setDownloadFlag(true);
    apiCall
      .get(`${apiConstants?.downloadPresignedUrl}?key=${key}`)
      .then((response) => {
        if (response.status === 200) {
          const link = document.createElement("a");
          link.href = response?.data;
          link.click();
          toast.success(commonVariables?.FileDownloadSuccess, { toastId: toastIds?.FileDownloadSucess })

        }
      })
      .catch((error) => {
        toast.error(errMessage?.InternalServerError, {
          toastId: toastIds?.InternalServerError,
        });
      })
      .finally(() => {
        setDownloadFlag(false);
      });
  };



  const listDataByMonthApi = (value, field) => {
    setLoading(true);
    const whereQuery = value ? field === commonVariables?.OpportunityID ? {source_system_unique_number : value } : field === commonVariables?.WebTopNo ? {web_top : value} : field === commonVariables?.TransactionID ? {mandate_request_id : value} : {los_id : value} : {mandate_type : 'ENACH'}
    const params = {
      tablename: tableNamePaymentConstants?.ManualMandate,
      month: selectMonth,
      limit: value ? '' : apiConstants?.limit,
      order: ByIdTableORder,
      attributes: enachStatusAttributes,
      whereQuery: whereQuery
    };
    apiCall
      .post(apiConstants?.findData, params)
      .then(async (res) => {
        setLoading(true);
        if (res.status === 200) {
          let result = res?.data?.response;
          if (result.length === 0) return setListdata([]);
          result = result.map((item, i) => {
            let newItem = {
              sr_no: i + 1,
              Opportunity_ID: item?.source_system_unique_number ? item?.source_system_unique_number : '-',
              web_top: item?.web_top ? item?.web_top : '-',
              los_id: item?.los_id ? item?.los_id : '-',
              source_system: item?.source_system ? item?.source_system : '-',
              AC_holder: item?.account_holder_name ? item?.account_holder_name : '-',
              account_number: item?.account_number ? item?.account_number : '-',
              loan_no: item?.loan_no ? item?.loan_no : '-',
              rquest_id: item?.logs_value === '1' ? fetchLogs(item?.mandate_request_id , item?.enach_s3_logs_key) : item?.mandate_request_id,
              umrn_no: item?.umrn_no ? item?.umrn_no : '-',
              res_rej_reason: item?.response_rejection_reason ? item?.response_rejection_reason : '-',
              mandate_status: item?.mandate_status ? item?.mandate_status : '-' ,
              enach_registered_time : item?.createdAt ? item?.createdAt : '-',
              enach_initiated_time :  item?.enach_initiated_time?  item?.enach_initiated_time : '-',
              enach_response_time : item?.enach_response_time ? item?.enach_response_time : '-',
              Created_at: item?.createdAt ? moment(item?.createdAt)?.format(dateFormats?.dmyHms) : '-',
              updated_at: item?.updatedAt ? moment(item?.updatedAt)?.format(dateFormats?.dmyHms) : '-',
              vendor_type : item?.vendor_type ? item?.vendor_type : '-',
              product_type : item?.product_type ? item?.product_type : '-',
              auth_mode : (item?.auth_mode ? (item?.auth_mode === 'aadhar' ? 'AadharCard' : item?.auth_mode) : '-')
            };
            return newItem;
          });
          if (result?.length > 0) {
            setListdata([...result]);
          }
        }
      })
      .catch((er) => {
        setLoading(false);
        return setListdata([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClear = () => {
    setValues("");
    setinputVal("");
    listDataByMonthApi();
  };


  useEffect(() => {
    listDataByMonthApi();
  }, []);

  return (
    <div className="status_wrapper mx-3 mt-4">
      {downloadFlag && (
        <div className="className='row justify-content-center d-flex loaderDiv">
          <SystemXLoader />
        </div>
      )}

      <div>
        <div className="d-flex  justify-content-between mb-1">
          <div className="right-div-header">
            <div className="mt-2">
              <p className="common-header ">
                {commonVariables?.STATUS || ""}
              </p>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-3 statusui">
            <div className="d-flex gap-4  right-div-header">
              <div className="">
                <label htmlFor="select" className="text-style">
                  Select Category<span className="badge text-danger fs-7">*</span>
                </label>

                <Dropdown
                  onChange={(e) => setValues(e)}
                  className="Drop"
                  options={options}
                  value={values}
                  defaultValue={defaultValue}
                  placeholder={"select Category"}
                />
              </div>

              {values?.value === "Opportunity ID" ? (
                <div>
                  <label htmlFor="select" className="">
                    Opportunity ID{" "}
                    <span className="badge text-danger fs-7">*</span>
                  </label>

                  <InputField
                    value={inputVal}
                    onChange={(event) => setinputVal(event.target.value)}
                    placeholder="Please Enter Opportunity ID"
                  />
                </div>
              ) : null}
              {values?.value === "Web Top No" ? (
                <div>
                  <label htmlFor="select" className="">
                    Web Top No <span className="badge text-danger fs-7">*</span>
                  </label>
                  <InputField
                    value={inputVal}
                    onChange={(event) => setinputVal(event.target.value)}
                    placeholder="Please Enter Web Top No"
                    className="InputField"
                  />
                </div>
              ) : null}
              {values?.value === "Los ID" ? (
                <div>
                  <label htmlFor="select" className="">
                    Los ID <span className="badge text-danger fs-7">*</span>
                  </label>
                  <InputField
                    value={inputVal}
                    onChange={(event) => setinputVal(event.target.value)}
                    placeholder="Please Enter Los ID"
                  />
                </div>
              ) : null}
               {values?.value === "Transaction ID" ? (
                <div>
                  <label htmlFor="select" className="">
                    Transaction ID <span className="badge text-danger fs-7">*</span>
                  </label>
                  <InputField
                    value={inputVal}
                    onChange={(event) => setinputVal(event.target.value)}
                    placeholder="Please Enter Transaction ID"
                  />
                </div>
              ) : null}
              {values?.value === "Los ID" ||
                values?.value === "Web Top No" ||
                values?.value === 'Transaction ID' ||
                values?.value === "Opportunity ID" ? (
                <div className="d-flex gap-3 searchbtn">
                  <Button
                    value={"Search"}
                    onClick={() => listDataByMonthApi(inputVal, values.value)}
                    role="primary"
                  />
                  <Button
                    value={"Clear"}
                    onClick={() => onClear()}
                    role="primary"
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>


        <div className="statusTableWrapper">
          <CustomTable
            cols={[
              {
                id: "sr_no",
                title: "Sr.No.",
                classStyle: {},
              },
              {
                id: "Opportunity_ID",
                title: "Opportunity ID",
                classStyle: {},
              },
              {
                id: "web_top",
                title: "Web Top",
                classStyle: {},
              },
              {
                id: "los_id",
                title: "LOS ID",
                classStyle: {},
              },
              {
                id: "source_system",
                title: "Source System",
                classStyle: {},
              },
              {
                id: "product_type",
                title: "Product Type",
                classStyle: {},
              },
              {
                id: "AC_holder",
                title: "Account Holder Name",
                classStyle: {},
              },
              {
                id: "account_number",
                title: "Account Number",
                classStyle: {},
              },
              {
                id: "loan_no",
                title: "Loan Number",

                classStyle: {},
              },
              {
                id: "rquest_id",
                title: "Mandate Request ID",
                classStyle: {},
              },
              {
                id: "umrn_no",
                title: "UMRN Number",
                classStyle: {},
              },
              {
                id: "res_rej_reason",
                title: "Response Rejection Reason",
                classStyle: {},
              },
              {
                id: "mandate_status",
                title: "Mandate Status",
                classStyle: {},
              },
              {
                id : "vendor_type",
                title : "Vendor Type",
                classStyle : {}
              },
              {
                id : "auth_mode",
                title : "Authentication Mode",
                classStyle : {}
              },
              {
                id: "enach_registered_time",
                title: "Enach Registered Time",
                classStyle: {},
              },
              {
                id: "enach_initiated_time",
                title: "Enach Initiated Time",
                classStyle: {},
              },
              {
                id: "enach_response_time",
                title: "Enach Response Time",
                classStyle: {},
              },
            ]}
            data={listdata}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};
export default Statuspage;
