import React from 'react';
import { ROUTE_PATH } from '../../../../constants/Routes';
import Button from '../../../common/Button/button';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import '../NupayBankReports/NupayBankReport.scss';
import apiCall from '../../../../utils/apiFunction/apiCall'
import { apiConstants, tableNamePaymentConstants } from '../../../../constants/Common/apiConstants';


function NupayBankReports() {
	const navigate = useNavigate();
	const nuPayGetRecords = async () => {
		const params = {
			"tablename": tableNamePaymentConstants.NU_bank_list,
			"attributes": ["id", "nupay_bank_id", "bank_code", "bank_name", "auth_mode", "company_code"],
			"month": "0",
			"where": {
			}
		}
		try {
			const response = await apiCall.post(apiConstants?.getXLSXFile, params)
			const presignedUrl = response.data.response
			const downloadLink = document.createElement('a');
			downloadLink.href = presignedUrl;
			downloadLink.style.display = 'none';
			document.body.appendChild(downloadLink);
			downloadLink.click();
			document.body.removeChild(downloadLink);
		} catch (error) {
			console.error('ERROR ::::::::::::::', error);
		}
	}

	return (
		<>
			<div id="NupayBankReport">
				<div className="main-div">
					<Form className=".labler-wrapper">


						<div id="RegularForm">
							<div className="createroletext">
								<p id="pText">NUPAY Bank Report</p>
							</div>

							<div className="row paddingTop pb-4 ml-4 mr-4">
								<div className=" d-flex m-auto w-50">
									<div className="downlod_btn m-auto">
										<Button
											role="download"
											value="Download"
											type="button"
											onClick={() => nuPayGetRecords()}
										/>
									</div>
								</div>
							</div>
						</div>
					</Form>
				</div>
			</div>
		</>
	);
}

export default NupayBankReports;
