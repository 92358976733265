import React, { useState, useRef, useEffect } from "react";
import "./daterangepicker.scss";
import moment from "moment";
import DatePicker from "react-datepicker";
import calender_icon from "../../../Images/calendar.svg";
import "react-datepicker/dist/react-datepicker.css";

const DateRangePicker = (props) => {
  let startD = moment().startOf("month")._d;
  let endD = moment().endOf("month")._d;

  const datepicker_ref = useRef();

  const [dateRange, setDateRange] = useState([startD, endD]);

  const [startDate, endDate] = dateRange;
  const [clear, setclear] = useState(false);
  const openDatePicker = () => {
    datepicker_ref.current.onInputClick();
  };

  useEffect(() => {
    if (startDate && endDate) {
      props.handleDateChange(startDate, endDate);
    } else if (!startDate && !endDate) {
      console.log("error");
    }
  }, [startDate, endDate, clear]);

  return (
    <>
      <div className="date_range_Picker">
        <DatePicker
          ref={datepicker_ref}
          selectsRange={true}
          dateFormat="dd/MM/yyyy"
          startDate={startDate}
          endDate={endDate}
          onChange={(update) => {
            setDateRange(update);
          }}
          placeholderText= "DD-MM-YYYY DD-MM-YYYY"
          monthsShown={2}
        />
        <img src={calender_icon} alt="calender_icon" width={19} onClick={openDatePicker} />
      </div>
    </>
  );
};

export default DateRangePicker;
