import React, {useState, useEffect} from "react"
import "./enachMonths.scss"
import Button from "../../../component/common/Button/button"
import FormLabel from "../../../component/common/formLabel/formLabel"
import {Formik, Form, Field, ErrorMessage} from "formik"
import {object, string} from "yup"
import FormikSelect from "../../../component/common/formikSelect/formikSelect"
import apiCall from "../../../utils/apiFunction/apiCall"
import {
  apiConstants,
  FOMRS,
  isActiveDropdowns,
  STATUS,
  successMsg,
  tableNamePaymentConstants,
} from "../../../constants/Common/apiConstants"
import {useLocation, useNavigate} from "react-router-dom"
import {ROUTE_PATH} from "../../../constants/Routes"
import {YupMessages} from "../../../constants/Common/commonConstants"
import {toast} from "react-toastify"

let validSchema = object({
  months_in_number: string()
    .required("* Enach Months is Required.")
    .matches(/^(?!.*<script>).*$/, "Enach Months cannot contain scripts or HTML tags")
    .matches(/^(?!.*<\/?[a-z][\s\S]*>).*$/, "Enach Months cannot contain scripts or HTML tags")
    .matches(/^[^@#<>]+$/, "Enach Months cannot contain symbols like @ and #")
    .test("no-spaces", "Enach Months cannot be only spaces", (value) => value.trim() !== ""),
})

const EnachMonths = () => {
  const {state} = useLocation()
  const navigate = useNavigate()
  const formType = state?.type || FOMRS.ADD
  const headingText = "Enach Months"

  const [formData, setFormData] = useState({
    id: "",
    months_in_number: "",
  })
  const [loading, setLoading] = useState(false)

  const getMonths = () => {
    apiCall
      .post(apiConstants.fetchAllData, {
        offset: 0,
        limit: 10,
        tablename: "months_master",
        month: 0,
      })
      .then((res) => {
        // console.log(res, "<<<<<<<<<=========")
        if (res.status === 200) {
          let result = res?.data?.response
          setFormData({
            id: result[0].id,
            months_in_number: result[0].months_in_number,
          })
        }
        console.log(formData)
      })
  }

  const FormSubmit = (value) => {
    console.log(value)
    let query = {
      body: {
        months_in_number: value?.months_in_number ? value?.months_in_number : "-",
      },
      tablename: "months_master",
    }
    if (formData?.id > 0) {
      query.whereQuery = {
        id: formData?.id,
      }
      apiCall
        .post(apiConstants?.updateUser, query)

        .then((res) => {
          if (res?.status === STATUS.SUCCESS) {
            navigate(ROUTE_PATH.HOME)
            toast.success(successMsg?.UpdatedSuccessfully)
          }
        })
    }
  }

  useEffect(() => {
    getMonths()
  }, [])
  return (
    <>
      <Formik
        initialValues={{
          months_in_number: formData.months_in_number,
        }}
        validationSchema={validSchema}
        enableReinitialize={true}
        onSubmit={(values) => {
          FormSubmit(values)
        }}>
        {({values, setFieldValue}) => (
          <Form>
            <div id="enachMonths">
              <div className="row flexRow">
                <div className="col blueFonts large_f">{`ENACH MONTHS`}</div>
              </div>
              <div className="whiteBG">
                <div className="blueBG">
                  <span className="titleFont">{headingText}</span>
                </div>
                <div className="field">
                  <div className="row paddingTop">
                    <div className="col-lg-12 medium_f_bold directionColumn">
                      <div>
                        <FormLabel value="Enach Months" className="required" />
                      </div>
                      <Field
                        type="text"
                        className="InputField"
                        name="months_in_number"
                        placeholder="Months in Number"></Field>
                      <div className="errorMessage">
                        <ErrorMessage name="months_in_number" />
                      </div>
                    </div>
                  </div>

                  <div className="submitButton">
                    <div className="buttonSets">
                      <Button
                        role="secondary"
                        value="Cancel"
                        onClick={() => navigate(ROUTE_PATH?.HOME)}
                      />

                      <Button role="primary" value="Submit" onClick={() => {}} type="submit" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}
export default EnachMonths
