import React, { useEffect, useState } from "react";
import "./autoPaymentBankMaster.scss";
import TimeField from "react-simple-timefield";
import { useSelector } from "react-redux";
import Select from "react-select";
import apiCall from "../../../utils/apiFunction/apiCall";
import {
  Button as ButtonReact,
} from "react-bootstrap";
import {
  apiConstants,
  errMessage,
  tableNamePaymentConstants,
  toastIds,
} from "../../../constants/Common/apiConstants";
import ReactDatePicker from 'react-datepicker';
import { toast } from "react-toastify";
import moment from "moment";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import { YupMessages, commonVariables } from "../../../constants/Common/commonConstants";

let banks = [{ value: "HDFC DIGI CFAB", label: "HDFC Bank" }, { value: "ICICI BANK", label: "ICICI Bank" }];

const AutoPaymentBankMaster = () => {
  const [formValues, setFormValues] = useState({
    bank: { value: "", error: "", required: true },
    From_date: { value: "", error: "", required: true },
    From_time: { value: "", error: "", required: true },
    To_date: { value: "", error: "", required: true },
    To_time: { value: "", error: "", required: true }
  });
  const [initialFormValues, setInitialFormValues] = useState({});
  const [apiLoading, setApiLoading] = useState(false);

  const setFormValue = (updatedValues) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      ...Object.keys(updatedValues).reduce((acc, key) => {
        acc[key] = {
          ...prevValues[key],
          value: updatedValues[key],
        };
        return acc;
      }, {}),
    }));
  };

  const getBankwiseData = () => {
    if (formValues?.bank?.value) {
      apiCall
        .post(apiConstants?.findData, {
          tablename: tableNamePaymentConstants?.BANK_MASTER_AUTOPAY,
          whereQuery: {
            isActive: 1,
            bank_name: formValues.bank.value.value,
          },
          attributes: ['from_date', 'to_date', 'from_time', 'to_time'],
        })
        .then(async (res) => {
          if (res?.data?.status === 200) {
            const response = res?.data?.response[0];
            if (response) {
              const formattedFromDate = response?.from_date
                ? moment(response?.from_date, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY")
                : "";
              const formattedFromTime = response.from_time
                ? moment(response.from_time, "HH:mm:ss").format("HH:mm")
                : "";
              const formattedToDate = response.to_date
                ? moment(response?.to_date, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY")
                : "";
              const formattedToTime = response.to_time
                ? moment(response.to_time, "HH:mm:ss").format("HH:mm")
                : "";
              const initialValues = {
                From_date: formattedFromDate,
                From_time: formattedFromTime,
                To_date: formattedToDate,
                To_time: formattedToTime,
              };
              setInitialFormValues(initialValues);
              setFormValues((prevValues) => ({
                ...prevValues,
                From_date: { ...prevValues.From_date, value: formattedFromDate },
                From_time: { ...prevValues.From_time, value: formattedFromTime },
                To_date: { ...prevValues.To_date, value: formattedToDate },
                To_time: { ...prevValues.To_time, value: formattedToTime },
              }));
            }
          } else {
            toast.error(res?.data?.message, {
              toastId: toastIds?.DataError,
            });
          }
        })
        .catch((er) => {
          toast.error(er?.message, { toastId: toastIds?.DataError });
        });
    }
  };

  useEffect(() => {
    getBankwiseData();
  }, [formValues.bank.value]);

  const LoginState = useSelector((state) => state.login);

  const onSubmit = async () => {
    let tempData = { ...formValues };
    if (!validate()) {
      const FromDate = tempData.From_date.value
            ? moment(tempData.From_date.value, "DD/MM/YYYY").format("YYYY-MM-DD")
            : "";
          const ToDate = tempData.To_date.value
            ? moment(tempData.To_date.value, "DD/MM/YYYY").format("YYYY-MM-DD")
            : "";
            let compareDates=moment(ToDate).isSameOrAfter(moment(FromDate))
      if (compareDates) {
        if (tempData.To_time.value > tempData.From_time.value) {
          
          let userData = LoginState?.decodedData;
          const apiBody = {
            user_name: userData?.user_name || "",
            user_code: userData?.user_code || "",
            bank_name: formValues.bank.value.value,
            from_date: FromDate + " 00:00:00",
            to_date: ToDate + " 23:59:59",
            from_time: formValues.From_time.value + ":00",
            to_time: formValues.To_time.value + ":00",
          };
          setApiLoading(true);
          apiCall.post(apiConstants.CREATE_AUTOPAY_MASTER,apiBody)
            .then(async (res) => {
              if (res.data.status === 200) {
                toast.success(`Success`);
              }
            })
            .catch((er) => {
              let resp = er?.response?.data?.message || "";
              toast.error(resp || errMessage.somethingWentWrong, {
                toastId: toastIds?.DataError,
              });
            })
            .finally(() => {
              setApiLoading(false);
            });
        } else {
          toast.error(errMessage?.TO_TIME_MUST_BE_GREATER_THAN_FROM_TIME, {
            toastId: "timeCheck",
          });
        }
      } else {
        toast.error(errMessage?.ToDateMustBeGreaterThanFromDate, {
          toastId: "greateTime",
        });
      }
    }
  };

  const validate = () => {
    let error = false;
    let tempForm = { ...formValues };
    Object.entries(tempForm).forEach(([key, value]) => {
      if (value.required) {
        if (value.value) {
          tempForm[key].error = false;
        } else {
          tempForm[key].error = true;
          error = true;
        }
      }
    });
    setFormValues({ ...tempForm });
    return error;
  };

  const isFormUpdated = () => {
    return JSON.stringify({
      From_date: formValues.From_date.value,
      From_time: formValues.From_time.value,
      To_date: formValues.To_date.value,
      To_time: formValues.To_time.value,
    }) !== JSON.stringify(initialFormValues);
  };

  return (
    <>
      <div>
        {apiLoading ? (
          <div className="loaderDiv">
            <SystemXLoader />
          </div>
        ) : (
          <div className="main-container pt-3">
            <div className="manage-header ml-3 pl-3 rounded-top mt-3 d-flex align-items-center">
              <label className="sub-header">{commonVariables.AUTO_PAYMENT_BANK_MASTER}</label>
            </div>
            <div className="shadow-sm border border-1 rounded-1 ml-3">
              <div className="m-4">
                <div className="dynamicFormWrapper">
                  <div className="row mt-2 gap-1">
                    <div className="col-md-5">
                      <div className="inputDiv mt-1">
                        <p>
                          {commonVariables.BANK_NAME} <span className="textLabelRed">*</span>
                        </p>
                        <Select
                          options={banks}
                          placeholder={commonVariables.BANK_NAME}
                          value={formValues.bank.value}
                          onChange={(selectedOption) => {
                            setFormValues((prev) => ({
                              ...prev,
                              bank: { ...prev.bank, value: selectedOption },
                            }));
                          }}
                          classNamePrefix="react-select"
                          isSearchable
                        />
                        {formValues.bank.error &&
                          formValues.bank.required && (
                            <p className="textLabelRed m-0 mt-2">
                              {YupMessages.BANK_NAME_IS_REQUIRED}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                  {formValues.bank.value ?(
                    <>
                  <div className="row mt-2 gap-1">
                    <div className="col-md-5">
                      <div className="inputDiv mt-1">
                        <p>
                          From Date <span className="textLabelRed">*</span>
                        </p>
                        <ReactDatePicker
                          className="selectInput customDatepicker"
                          selected={formValues.From_date.value ? moment(formValues.From_date.value, "DD/MM/YYYY").toDate() : null}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Select From Date"
                          onChange={(date) => {
                            setFormValues((prev) => ({
                              ...prev,
                              From_date: {
                                ...prev.From_date,
                                value: date ? moment(date).format("DD/MM/YYYY") : "",
                              },
                            }));
                          }}
                        />
                        {formValues.From_date.error &&
                          formValues.From_date.required && (
                            <p className="textLabelRed m-0 mt-2">
                              From Date is required
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="inputDiv mt-1">
                        <p>
                          To Date <span className="textLabelRed">*</span>
                        </p>
                        <ReactDatePicker
                          className="selectInput customDatepicker"
                          selected={formValues.To_date.value ? moment(formValues.To_date.value, "DD/MM/YYYY").toDate() : null}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Select To Date"
                          onChange={(date) => {
                            setFormValues((prev) => ({
                              ...prev,
                              To_date: {
                                ...prev.To_date,
                                value: date ? moment(date).format("DD/MM/YYYY") : "",
                              },
                            }));
                          }}
                        />
                        {formValues.To_date.error &&
                          formValues.To_date.required && (
                            <p className="textLabelRed m-0 mt-2">
                              To Date is required
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 gap-1">
                    <div className="col-md-5">
                      <div className="inputDiv mt-1">
                        <p>
                          From Time <span className="textLabelRed">*</span>
                        </p>
                        <TimeField
                          className="selectInput customTimePicker"
                          format="HH:mm"
                          value={formValues.From_time.value}
                          onChange={(event, value) => {
                            setFormValues((prev) => ({
                              ...prev,
                              From_time: {
                                ...prev.From_time,
                                value: value,
                                error: !value && prev.From_time.required,
                              },
                            }));
                          }}
                        />
                        {formValues.From_time.error && formValues.From_time.required && (
                          <p className="textLabelRed m-0 mt-2">From Time is required</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="inputDiv mt-1">
                        <p>
                          To Time <span className="textLabelRed">*</span>
                        </p>
                        <TimeField
                          className="selectInput customTimePicker"
                          format="HH:mm"
                          value={formValues.To_time.value}
                          onChange={(event, value) => {
                            setFormValues((prev) => ({
                              ...prev,
                              To_time: {
                                ...prev.To_time,
                                value: value,
                                error: !value && prev.To_time.required,
                              },
                            }));
                          }}
                        />
                        {formValues.To_time.error && formValues.To_time.required && (
                          <p className="textLabelRed m-0 mt-2">To Time is required</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex gap-4 mt-3">
                    <ButtonReact
                      className="customButtonGreen"
                      onClick={onSubmit}
                      disabled={!isFormUpdated()}
                    >
                      Submit
                    </ButtonReact>
                  </div></>) : ""}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default AutoPaymentBankMaster;
