import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormLabel from "../../../common/formLabel/formLabel";
import { ROUTE_PATH } from "../../../../constants/Routes";
import FormikSelect from "../../../common/formikSelect/formikSelect";
import Button from "../../../common/Button/button";
import "./createCompany.scss";
import apiCall from "../../../../utils/apiFunction/apiCall";
import {
  apiConstants,
  FOMRS,
  isActiveDropdowns,
  STATUS, tableNamePaymentConstants, errMessage, successMsg, toastIds
} from "../../../../constants/Common/apiConstants";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { YupMessages } from "../../../../constants/Common/commonConstants";
import { toast } from "react-toastify";

let validationSchema = Yup.object({
  company: Yup.string()
    .required(YupMessages?.CompanyNameRequired)
    .matches(
      /^(?!.*<script>).*$/,
      "Company name cannot contain scripts or HTML tags"
    )
    .matches(
      /^(?!.*<\/?[a-z][\s\S]*>).*$/,
      "Company name cannot contain scripts or HTML tags"
    )
    .matches(/^[^!@#$%^&*]+$/, "Company name cannot contain symbols")
    .test(
      "no-spaces",
      "company cannot be only spaces",
      (value) => value.trim() !== ""
    ),
  name: Yup.string()
    .required(YupMessages?.NameRequired)
    .matches(/^(?!.*<script>).*$/, "Name cannot contain scripts or HTML tags")
    .matches(
      /^(?!.*<\/?[a-z][\s\S]*>).*$/,
      "Name cannot contain scripts or HTML tags"
    )
    .matches(/^[A-Za-z]/, "Name can only contain alphabets")
    .matches(/^[^!@#$%^&*]+$/, " Name cannot contain symbols")
    .test(
      "no-spaces",
      "Name cannot be only spaces",
      (value) => value.trim() !== ""
    ).matches(/^[^0-9]*$/, '* No Numbers Allowed'),
  e_mandate_amount: Yup.string()
    .required(YupMessages?.EMandateAmountRequired)
    .matches(/^[1-9][0-9]*$/, "E Mandate Amount should be greater than zero")
    .matches(/^[^!@#$%^&*]+$/, " E mandate Amount cannot contain symbols"),
  isActive: Yup.string().required(YupMessages?.isActiveRequired),
});

const Createcompany = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const formType = state?.type || FOMRS.ADD;
  const headingText = formType === FOMRS.ADD ? "Create Company" : "Update Company";

  const [formData, setFormData] = useState({
    company: "",
    name: "",
    e_mandate_amount: "",
    isActive: "",
  });

  const [loading, setLoading] = useState(false);


  const FormSubmit = (value) => {
    let query = {
      body: {
        company_code: value.company ? value.company : '-',
        company_name: value.name ? value.name : '-',
        emandate_amount: value.e_mandate_amount ? value.e_mandate_amount : '-',
        isActive: value.isActive ? value.isActive : '-',
      },
      tablename: tableNamePaymentConstants?.Company,
    };
    let url;
    let toastMessage;
    if (state?.type === FOMRS.EDIT) {
      url = apiConstants?.updateUser;
      query.whereQuery = {
        company_code: state?.user_type?.company_code,
      };
      toast.success(successMsg?.CompanyUpdatedSuccessfully, toastIds?.FileUploadSuccess)
    } else {
      url = apiConstants?.createUser;
      toast.success(successMsg?.CompanyCreatedSuccessfully, toastIds?.FileUploadSuccess)
    }
    apiCall.post(url, query).then(async (res) => {
      if (res?.status === STATUS.SUCCESS) {
        navigate(ROUTE_PATH.COMPANY);
      }
    });
  };
  useEffect(() => {
    if (formType === FOMRS.EDIT) {
      setFormData({
        company: state?.user_type?.company_code,
        name: state?.user_type?.company_name,
        e_mandate_amount: state?.user_type?.emandate_amount,
        isActive: state?.user_type?.isActive,
      });
    }
  }, [loading]);

  return (
    <Formik
      initialValues={{
        company: formData.company,
        name: formData.name,
        e_mandate_amount: formData.e_mandate_amount,
        isActive: formData.isActive,
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={(values) => {
        FormSubmit(values);
      }}
    >
      {({ values, setFieldValue }) => (
        <>
          <div id="createCompany">
            <div
              className="ml-3 blueFonts large_f"
              onClick={() => navigate(ROUTE_PATH.COMPANY)}
            >
              {`Company >> `}
              <span className="blackFonts">{headingText}</span>
            </div>
            <div className="main-div ml-3">
              <Form className=".labler-wrapper">
                <div id="RegularForm">
                  <div className="createroletext">
                    <p id="pText">{headingText}</p>
                  </div>

                  <div className="row paddingTop ml-4 mr-4">
                    <div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
                      <div>
                        <FormLabel value="Company" className="required" />
                      </div>
                      <Field
                        type="text"
                        className="InputField"
                        name="company"
                        id="company"
                        placeholder="Company"
                        disabled={state?.type === FOMRS.EDIT ? true : false}
                      ></Field>
                      <div className="errorMessage">
                        <ErrorMessage name="company" />
                      </div>
                    </div>
                  </div>

                  <div className="row paddingTop ml-4 mr-4">
                    <div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
                      <FormLabel value="Name" className="required" />
                      <Field
                        type="text"
                        className="InputField"
                        name="name"
                        id="name"
                        placeholder="Name"
                      ></Field>
                      <div className="errorMessage">
                        <ErrorMessage name="name" />
                      </div>
                    </div>
                  </div>

                  <div className="row paddingTop ml-4 mr-4">
                    <div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
                      <FormLabel
                        value="E-Mandate Amount"
                        className="required"
                      />
                      <Field
                        type="text"
                        className="InputField"
                        name="e_mandate_amount"
                        id="e_mandate_amount"
                        placeholder="E-Mandate Amount"
                      ></Field>
                      <div className="errorMessage">
                        <ErrorMessage name="e_mandate_amount" />
                      </div>
                    </div>
                  </div>

                  <div className="row paddingTop ml-4 mr-4">
                    <div className="col-md-12 col-lg-12 medium_f_bold directionColumn ">
                      <div className="formState">
                        <FormLabel value="Is Active" className="required" />
                        <FormikSelect
                          label="Please select a value"
                          name="isActive"
                          options={isActiveDropdowns}
                        />
                      </div>
                      <div className="errorMessage">
                        <ErrorMessage name="isActive" />
                      </div>
                    </div>
                  </div>

                  <div className=" d-flex py-4">
                    <div className="cancel_btn">
                      <Button
												className='mr-1'
                        role="secondary"
                        value="Cancel"
                        type="button"
                        onClick={() => navigate(ROUTE_PATH.COMPANY)}
                      />
                    </div>

                    <div className="submitButton">
                      <Button
												className='mx-1'
                        role="primary"
                        value="Submit"
                        type="submit"
                        onClick={() => { }}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

export default Createcompany;
