import React from 'react';
import ErrorImage from "../../Images/404page.jpg"
import "./errorPage.scss"
const ErrorPage = ({message}) => {
    return (
        <div className='d-flex justify-content-center align-items-center errorWrapper'>
        <img className="ErrorImage" src={ErrorImage} alt='ErrorImage'/>
        <h1>{message || "Page Not Found"}</h1>
        </div>
    )
}
export default ErrorPage    