import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import CustomTable from "../../../customtable/CustomTable";
import "../../../systemMaster/sponsorBank/BancsSponsorBank/BancsSponsorBank.scss";
import apiCall from "../../../../utils/apiFunction/apiCall";
import {
  apiConstants,
  FOMRS,
  tableNamePaymentConstants,
} from "../../../../constants/Common/apiConstants";
import { ROUTE_PATH } from "../../../../constants/Routes";
import Button from "../../../common/Button/button";
import {
  commonVariables,
  tableID,
} from "../../../../constants/Common/commonConstants";

function BancsSponsorBank() {
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [listdata, setListdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [datacount, setDatacount] = useState(0);
  useEffect(() => {
    listApi();
  }, []);
  const listApi = (value) => {
    let temp = 0;
    if ((value || pageNumber) > 0) {
      temp = value ? (value - 1) * 10 : (pageNumber - 1) * 10;
    }
    setLoading(true);
    apiCall
      .post(apiConstants?.fetchAllData, {
        offset: temp || 0,
        limit: 10,
        tablename: tableNamePaymentConstants?.BaNCS,
        month: 0,
      })
      .then(async (res) => {
        if (res?.status === 200) {
          let result = res?.data?.response;
          setDatacount(res?.data?.count || 0);
          result = result.map((item) => {
            let newItem = {
              no: item?.id,
              bankCode: item?.bank_code ? item?.bank_code : "-",
              bank_name: item?.bank_name ? item?.bank_name : "-",
              companyCode: item?.company_code ? item?.company_code : "-",
              bancs_bank_code: item?.bancs_bank_code
                ? item?.bancs_bank_code
                : "-",
              action: (
                <div>
                  <img
                    className="icon"
                    src="/assests/images/pencil.svg"
                    alt=""
                    onClick={() => {
                      navigate(ROUTE_PATH.CREATE_BANCS_SPONSOR_BANK, {
                        state: {
                          type: FOMRS.EDIT,
                          user_type: item,
                        },
                      });
                    }}
                  />
                  <img
                    className="delete_icon"
                    src="/assests/images/trash-bin.svg"
                    onClick={() => {}}
                    alt=""
                  />
                </div>
              ),
            };
            return newItem;
          });
          if (result?.length > 0) {
            setListdata([...result]);
          }
        }
      })
      .catch((er) => {
        console.log("err", er);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const changePage = (value) => {
    listApi(value);
    setPageNumber(value - 1);
  };

  return (
    <>
      <div id="BancsSponsor">
        <div className="alignHeader">
          <div className="larger_f_bold blueFont setMargin">
            BaNCS Sponsor Bank Master
          </div>
          <div className="setMargin">
            {" "}
            <Button
              value={"Create BaNCs"}
              role="primary"
              onClick={() => {
                navigate(ROUTE_PATH?.CREATE_BANCS_SPONSOR_BANK, {
                  state: {
                    type: FOMRS.ADD,
                  },
                });
              }}
            />
          </div>
        </div>
        <div className="col dtMergeTable">
          <CustomTable
            cols={[
              {
                id: tableID?.NO || "",
                title: commonVariables?.NO || "",
              },
              {
                id: tableID?.BANK_CODE || "",
                title: commonVariables?.BANK_CODE || "",
              },
              {
                id: tableID?.BANK_NAME || "",
                title: commonVariables?.BANK_NAME || "",
              },
              {
                id: tableID?.COMPANY_CODE || "",
                title: commonVariables?.COMPANY || "",
              },
              {
                id: tableID?.BANCS_BANK_CODE || "",
                title: commonVariables?.BANCS_BANK_CODE || "",
              },

              {
                id: tableID?.ACTION || "",
                title: commonVariables?.ACTION || "",
              },
            ]}
            data={listdata}
            count={datacount}
            setPagination={(page) => changePage(page)}
            activePage={pageNumber}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
}

export default BancsSponsorBank;
