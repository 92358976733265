import React, { useEffect, useState } from "react";
import "./openmandate.scss";
import apiCall from "../../../utils/apiFunction/apiCall"
import {
  Button as ButtonReact,
} from "react-bootstrap";
import {
  apiConstants,
  errMessage,
  toastIds,
} from "../../../constants/Common/apiConstants";
import ReactDatePicker from 'react-datepicker';
import { toast } from "react-toastify";
import moment from "moment";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import {
  commonVariables,
} from "../../../constants/Common/commonConstants";
import CustomTable from "../../../component/customtable/CustomTable";


const OpenMandateReports = () => {
  const [formValues, setFormValues] = useState({
    From_date: {
      value: '',
      error: '',
      required: true,
    }, 
    To_date: {
      value: '',
      error: '',
      required: true,
    },
    loan_no: {
        value: "",
        error: "",
        required: false,
      }
    }
  );
  const [listData, setListData] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [count, setCount] = useState(0);
  const onSubmit = async(value) => {
    if (!validate()) {
      let temp;
      if ((value || pageNumber) > 0) {
        temp = value ? (value - 1) * 10 : (pageNumber - 1) * 10;
      }
      let tempData = {...formValues};
      if (tempData.To_date.value >= tempData.From_date.value) {
        const FromDate = tempData.From_date.value
          ? moment(tempData.From_date.value).format("DD-MM-YYYY")
          : "";
        const ToDate = tempData.To_date.value
          ? moment(tempData.To_date.value).format("DD-MM-YYYY")
          : "";
        const loanNo = tempData.loan_no.value
        let apiBody = {
          createdFrom:FromDate || "",
          createdTo:ToDate || "",
          loanNo:loanNo || "",
          offset: temp || 0,
          limit: 10 || ""
        };
        setApiLoading(true);
        apiCall.get(apiConstants.OpenMandateReport,{params:apiBody})
        .then(async (res) => {
          if (res.data.status === 200) {
            let result = res?.data?.data;
            let resultShow = result.map((item, index) => {
              let newItem = {
                "Parent Loan Account Number": item['Parent Loan Account Number'],
                "Child Loan Account number": item['Child Loan Account number'],
                "Repayment Mode": item['Repayment Mode'],
                "Case Type": item['Case Type'],
                "Loan Status": item['Loan Status'],
                "Customer Name": item['Customer Name'],
                "Customer Bank": item['Customer Bank'],
                "Bank Account Number": item['Bank Account Number'],
                "Customer Bank IFSC": item['Customer Bank IFSC'],
                "Customer Bank Account Type": item['Customer Bank Account Type'],
                "UMRN Number": item['UMRN Number'],
                "Web Top": item["Web Top"],
                "Mandate Max Amount": item['Mandate Max Amount'],
                "Existing Limit": item['Existing Limit'],
                'Block Amount': item["Block Amount"],
                "Mandate Start Date": item['Mandate Start Date'],
                "Mandate End Date": item['Mandate End Date'],
                "Mandate Registration date": item['Mandate Registration date'],
                "Sponsor bank Name": item['Sponsor bank Name'],
                "Sponsor bank GL Code":item['Sponsor bank GL Code'],
                "CreatedAt": item['CreatedAt'],
                "Block Validity Date": item['Block Validity Date']
              };
              return newItem;
            });
            if (result?.length) {
              result?.length < 10 ? setCount(result?.length): setCount(100);
              setListData([...resultShow]);
            } else {
              toast.error(
                res?.data?.message || errMessage.somethingWentWrong,
                {
                  toastId: toastIds?.DataError,
                }
              );
            }
          }
        })
        .catch((er) => {
          let resp = er?.response?.data?.message || "";
          toast.error(resp || errMessage.somethingWentWrong, {
            toastId: toastIds?.DataError,
          });
        })
        .finally(() => {
          setApiLoading(false);
        });
       
      } else {
        toast.error(errMessage?.ToDateMustBeGreaterThanFromDate, {
          toastId: "saveMandateInfo",
        });
      }
    }
  };

  const validate = () => {
    let error = false;
    let tempForm = { ...formValues };
    console.log('form values',tempForm);
    Object.entries(tempForm).forEach((value) => {
      if (value[1].required) {
        if (value[1].value) {
          tempForm[value[0]].error = false;
        } else {
          tempForm[value[0]].error = true;
          error = true;
        }
      }
      return value;
    });
    setFormValues({ ...tempForm });
    return error;
  };

  const downloadFile = () => {
    let tempData = {...formValues};
    const FromDate = tempData.From_date.value
          ? moment(tempData.From_date.value).format("DD-MM-YYYY")
          : "";
        const ToDate = tempData.To_date.value
          ? moment(tempData.To_date.value).format("DD-MM-YYYY")
          : "";
        const loanNo = tempData.loan_no.value
        let apiBody = {
          createdFrom:FromDate || "",
          createdTo:ToDate || "",
          loanNo:loanNo || "",
        };
    setApiLoading(true);
    apiCall.get(apiConstants.OpenMandateReportDownload,{params:apiBody})
        .then(async (res) => {
        if (res.data.status === 200) {
          const presignUrl = res?.data?.data;
          console.log("Presign------------>",presignUrl)
          if (res?.data?.data) {
            const link = document.createElement("a");
            link.href = presignUrl;
            link.click();
            toast.success(commonVariables?.FileDownloadSuccess, { toastId: toastIds?.FileDownloadSucess })

          }
        }
      })
      .catch((er) => {
        console.log("err", er);
      })
      .finally(() => {
        setApiLoading(false);
      });
  };
  const onChange = (e) => {
    let tempData = { ...formValues };
    tempData[e.target.name].value = e.target.value;
    e.target.value
      ? (tempData[e.target.name].error = false)
      : (tempData[e.target.name].error = true);
    setFormValues({ ...tempData });
  };

  const changePage = (value) => {
    onSubmit(value);
    setPageNumber(value - 1);
  };



  // useEffect(() => {
  //   // tableAPI();
  // }, []);

  return (
    <>
    <div>
      {apiLoading ? (
        <div className="loaderDiv">
          <SystemXLoader />
        </div>
      ) : (
        <div className="main-container pt-3">
          <div className="d-flex flex-wrap gap-2 align-items-center justify-content-between  mt-3 mb-3 ">
            <div className="right-div-header">
              <div>
                <label className="common-header cursor-pointer ps-4">
                  Reports &gt; &gt;
                </label>
                <span className="text-nowrap">Open Mandate Reports </span>

              </div>
            </div>
          </div>
          <div className="container shadow-sm border border-1 rounded-2 ml-4 mt-2">
          <div className="m-4">
                    <div className="dynamicFormWrapper">
                      <div className="row mt-2 gap-1">
                          <div className="col-md-5">
                              <div className="inputDiv mt-1">
                                  <p>
                                    From Date <span className="textLabelRed">*</span>
                                  </p>
                                  <ReactDatePicker
                                    className="selectInput customDatepicker"
                                    selected={formValues.From_date.value}
                                    name="date"
                                    format="dd/MM/yyyy"
                                    placeholderText="Select From Date"
                                    onChange={(date) => {
                                      let tempData = { ...formValues };
                                      tempData.From_date.value = date;
                                      setFormValues({ ...tempData });
                                    }}
                                  />
                                  {formValues.From_date.error &&
                                    formValues.From_date.required && (
                                      <p className="textLabelRed m-0 mt-2">
                                        From Date is required
                                      </p>
                                    )}
                                </div>
                           </div>     
                           <div className="col-md-5">
                                <div className="inputDiv mt-1">
                                  <p>
                                    To Date <span className="textLabelRed">*</span>
                                  </p>
                                  <ReactDatePicker
                                    className="selectInput customDatepicker"
                                    selected={formValues.To_date.value}
                                    name="date"
                                    format="dd/MM/yyyy"
                                    placeholderText="Select To Date"
                                    onChange={(date) => {
                                      let tempData = { ...formValues };
                                      tempData.To_date.value = date;
                                      setFormValues({ ...tempData });
                                    }}
                                  />
                                   {formValues.To_date.error &&
                                    formValues.To_date.required && (
                                      <p className="textLabelRed m-0 mt-2">
                                        From Date is required
                                      </p>
                                    )}
                                </div>
                           </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                      </div>
                      <div className="row mt-2 gap-1">
                        <div className="col-md-5">
                          <div className="inputDiv mt-1">
                            <p>
                            Loan No 
                            </p>
                            <input
                              className="selectInput"
                              name="loan_no"
                              value={formValues.loan_no.value}
                              type="text"
                              placeholder="Please Enter Loan Number"
                              onChange={onChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex gap-4 mt-3">
                      <ButtonReact
                        className="customButtonGreen"
                        onClick={() => onSubmit()}
                        >
                        View
                      </ButtonReact>
                      </div>
                    </div>
                    </div>
                  </div>
          </div>
        </div>)}
            <div className="mx-4 pb-4">
            {listData.length > 0 && (
              <>
                <div className="pt-2 toggle-wrapper">
                <button
                    onClick={() => downloadFile()}
                    className="customRefreshButton mt-1"
                  >
                    Download
                  </button>
                </div>
                <CustomTable
                  cols={[
                    {
                      id: "Parent Loan Account Number",
                      title: "Parent Loan Account Number",
                      classStyle: {},
                    },
                    {
                      id: "Child Loan Account number",
                      title: "Child Loan Account number",
                      classStyle: {},
                    },
                    {
                      id: "Repayment Mode",
                      title: "Repayment Mode",
                      classStyle: {},
                    },
                    {
                      id: "Case Type",
                      title: "Case Type",
                      classStyle: {},
                    },
                    {
                      id: "Loan Status",
                      title: "Loan Status",
                      classStyle: {},
                    },
                    {
                      id: "Customer Name",
                      title: "Customer Name",
                      classStyle: {},
                    }, {
                      id: "Customer Bank",
                      title: "Customer Bank",
                      classStyle: {},
                    }, {
                      id: "Bank Account Number",
                      title: "Bank Account Number",
                      classStyle: {},
                    }, {
                      id: "Customer Bank IFSC",
                      title: "Customer Bank IFSC",
                      classStyle: {},
                    }, {
                      id: "Customer Bank Account Type",
                      title: "Customer Bank Account Type",
                      classStyle: {},
                    }, {
                      id: "UMRN Number",
                      title: "UMRN Number",
                      classStyle: {},
                    }, {
                      id: "Web Top",
                      title: "Web Top",
                      classStyle: {},
                    }, {
                      id: "Mandate Max Amount",
                      title: "Mandate Max Amount",
                      classStyle: {},
                    }, {
                      id: "Existing Limit",
                      title: "Existing Limit",
                      classStyle: {},
                    },{
                      id: "Block Amount",
                      title: "Block Amount",
                      classStyle: {},
                    },{
                      id: "Mandate Start Date",
                      title: "Mandate Start Date",
                      classStyle: {},
                    },{
                      id: "Mandate End Date",
                      title: "Mandate End Date",
                      classStyle: {},
                    },{
                      id: "Mandate Registration date",
                      title: "Mandate Registration date",
                      classStyle: {},
                    },{
                      id: "Sponsor bank Name",
                      title: "Sponsor bank Name",
                      classStyle: {},
                    },{
                      id: "Sponsor bank GL Code",
                      title: "Sponsor bank GL Code",
                      classStyle: {},
                    },{
                      id: "CreatedAt",
                      title: "CreatedAt",
                      classStyle: {},
                    },
                    {
                      id: "Block Validity Date",
                      title: "Block Validity Date",
                      classStyle: {},
                    }
                  ]}
                  data={listData}
                  loading={apiLoading}
                  count={count}
                  setPagination={(page) => changePage(page)}
                />
              </>
            )}
          </div>
        </div>
    </>
  );
};
export default OpenMandateReports;
