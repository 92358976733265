import React, { useState, useEffect, useLayoutEffect } from 'react';
import './openMandateDobUpdate.scss';
import ReactDatePicker from 'react-datepicker';
import apiCall from '../../../utils/apiFunction/apiCall';
import { apiConstants, STATUS, toastIds } from '../../../constants/Common/apiConstants';
import SystemXLoader from '../../../component/common/Loader/SystemXLoader';
import CustomTable from '../../../component/customtable/CustomTable';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ROUTE_PATH } from "../../../constants/Routes"

import {
  commonVariables,
  componentRole,
  tableID,
  selectoptions,
} from '../../../constants/Common/commonConstants';

const MandateDobUpdate = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [listdata, setListdata] = useState([]);
  const [formValues, setFormValues] = useState({
    pan_number: {
      value: '',
      error: '',
      required: true,
    },
    date: {
      value: '',
      error: '',
      required: true,
    },
  });
  const [showTable, setShowTable] = useState(false);
  const navigate = useNavigate();


  const viewData = (value) => {

    
    
    let temp = 0
    if ((pageNumber) > 1) {
      temp = pageNumber * 10 - 10;
    }
    

    setLoading(true);
    setShowTable(false);

    const params = {
      tablename: 'io_disbursement_tbl',
      month: 0,
      offset: temp,
      limit: 10,
      dataFetch: [
        {
          column: 'PAN_number',
          operator: 'eq',
          value: formValues.pan_number.value,
        },
      ],
    };

    

    
    apiCall
      .post(apiConstants?.fetchAllData, params)
      .then(async (res) => {
        if (res.status === 200) {
          let result = res?.data?.response;
          
          setCount(res?.data?.count);
          result = result.map((item) => {
            let newItem = {
              PAN_number: item?.PAN_number ? item?.PAN_number : '-',
              DOB: item?.DOB ? item?.DOB : '-',
              createdAt: item?.createdAt ? item?.createdAt : '-',
              updatedAt: item?.updatedAt ? item?.updatedAt : '-',
            };
            return newItem;
          });
          if (result?.length > 0) {
            setListdata([...result]);
            setShowTable(true);
          } else {
            toast.error("Data not found" , toastIds?.DataError)
          }
        }
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onChange = (e) => {
    let tempData = { ...formValues };
    tempData[e.target.name].value = e.target.value;
    e.target.value ? (tempData[e.target.name].error = false) : (tempData[e.target.name].error = true);
    setFormValues({ ...tempData });
  };

  const isUpdateButtonDisabled = !formValues.pan_number.value || (formValues.pan_number.value && !formValues.date.value);
  const isViewButtonDisabled = !formValues.pan_number.value


  const changePage = (value) => {
    setPageNumber(value);
  };



  

  useEffect(() => {
    if (pageNumber !== 0) {
      viewData(pageNumber + 1); // Fetch data for the selected page
    }
  }, [pageNumber]);


  const updateDob = () => {
    let temp = 0;
    if (pageNumber > 0) {
      temp = (pageNumber - 1) * 10;
    }

    const fetchDataParams = {
      tablename: 'io_disbursement_tbl',
      month: 0,
      offset: temp,
      limit: count,
      dataFetch: [
        {
          column: 'PAN_number',
          operator: 'eq',
          value: formValues.pan_number.value,
        },
      ],
    };

    apiCall
      .post(apiConstants.fetchAllData, fetchDataParams)
      .then((res) => {
        if (res.status === 200) {
          const result = res?.data?.response;

          if (result?.length > 0) {
            const updateParams = {
              body: {
                DOB: formValues.date.value
              },
              tablename: 'io_disbursement_tbl',
              whereQuery: {
                PAN_number: formValues.pan_number.value
              }
            };

            const updateUrl = apiConstants?.updateUser;
            const toastMessage = "DOB Updated Successfully";
            setLoading(true);

            apiCall.post(updateUrl, updateParams)
              .then((res) => {
                if (res?.status === STATUS.SUCCESS) {
                  toast.success(toastMessage, { toastId: 'dobSuccess' });
                  navigate(ROUTE_PATH.MANDATE_UPDATE);
                  setFormValues({
                    pan_number: { value: '', error: '', required: true },
                    date: { value: '', error: '', required: true },
                  }); // Resetting form values
                  setShowTable(false);
                } else {
                  toast.error("Failed to update DOB");
                }
              })
              .catch((err) => {
                toast.error("Failed to update DOB");
              })
              .finally(() => {
                setLoading(false);
              });
          } else {
            toast.error("Data not found");
          }
        }
      })
      .catch((error) => {
        toast.error("Error fetching data");
      })
      .finally(() => {
        setLoading(false);
      });
  };





  return (
    <>
      <div className="mt-4 mx-3">
        <p className="HoldPayment_holdPaymentTxt">
          <b>Mandate Dob Update</b>
        </p>
        <div className="container-fluid">
          <div className="row regular-payment-wrapper-row mb-3">
            <div className="col-12 col-sm-12 col-md-12 col-lg-3 p-0">
              <div className="Payment_DisbursmentCaseWrapper">
                <div>
                  <div className="Payment_DisbursmentCaseWrapper_Table-Head">
                    <p className="m-0">Select Type</p>
                  </div>
                  <div className="m-4">
                    <div className="dynamicFormWrapper">
                      <div className="g-3 gx-5 mb-5 row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <div className="inputDiv mt-1">
                            <input
                              className="selectInput"
                              name="pan_number"
                              value={formValues.pan_number.value}
                              type="text"
                              placeholder="PAN number"
                              onChange={onChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <div className="inputDiv mt-1">
                            <ReactDatePicker
                              className="selectInput customDatepicker"
                              selected={formValues.date.value}
                              name="date"
                              format="dd/MM/yyyy"
                              placeholderText="Select date"
                              onChange={(date) => {
                                let tempData = { ...formValues };
                                tempData.date.value = date;
                                setFormValues({ ...tempData });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex gap-4">
                        <button
                          // className="customButtonGreen"
                          className={`customButtonGreen ${isUpdateButtonDisabled ? 'disabled' : ''}`}
                          disabled={isUpdateButtonDisabled}
                          onClick={() => updateDob()}
                        >

                          Update
                        </button>
                        <button
                          className={`GreenButton ${isViewButtonDisabled ? 'disabled' : ''}`}
                          disabled={isViewButtonDisabled}
                          onClick={() => viewData()}
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-9 p-0">
              <div className="HoldPayment_max-widthTable RegularPayment_max-widthTable">
                {loading ? (
                  <div className="text-center">
                    <SystemXLoader />
                    <p>
                      <b>Loading...</b>
                    </p>
                  </div>
                ) : null}
                {showTable && !loading ? (
                  <div className="HoldPayment_max-widthTable RegularPayment_max-widthTable">
                    <CustomTable
                      cols={[
                        {
                          id: tableID?.PAN_NUMBER || '',
                          title: commonVariables?.PAN_NUMBER || '',
                        },
                        {
                          id: tableID?.DATE_OF_BIRTH || '',
                          title: commonVariables?.DATE_OF_BIRTH || '',
                        },
                        {
                          id: tableID?.CREATED_AT || '',
                          title: commonVariables?.CREATED_AT || '',
                        },
                        {
                          id: tableID?.UPDATED_AT || '',
                          title: commonVariables?.UPDATED_AT || '',
                        },
                      ]}
                      data={listdata}
                      count={count}
                      setPagination={(page) => changePage(page)}
                      activePage={pageNumber}
                      loading={loading}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MandateDobUpdate;