import React, { useEffect, useState } from "react";
import "../../enach/Enach.scss";
import downloadArrow from "../../../Images/downloadIcon.svg";
import apiCall from "../../../utils/apiFunction/apiCall";
import uploadPresigned from "../../../utils/presignedUpload";
import DynamicForm from "../../../component/common/DynamicForm/dynamicForm";

import { toast } from "react-toastify";
import "../../../pages/payment/datepicker.scss";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import { commonVariables, S3FILEPATH } from "../../../constants/Common/commonConstants";
import { useSelector } from "react-redux";
import {
  apiConstants,
  errMessage,
  reportConstants,
  toastIds,
} from "../../../constants/Common/apiConstants";

const ProvisionReportAnalysis = () => {
    const [uploadPercent, setUploadPercent] = useState(0);
    const [loading, setLoading] = useState(false);
    const [fileData, setFileData] = useState([]);
    const [downloadFlag, setdownloadFlag] = useState(false);
    const loginState = useSelector((state) => state.login);


  
    const [formValues] = useState([
      {
        type: "fileupload",
        width: 12,
        label: "Upload File",
        displaycomponent: <div className="uploadComponentLabel">Upload File</div>,
      },
    ]);
    const onFileUpload = (file) => {
      if (file.length > 0) {
        let extension = file[0].name.split(".")[1];
        if (extension != "xlsx")
          return toast.error(errMessage?.uploadFileWithXlsxFormat, {
            toastId: toastIds?.FileUploaderr,
          });
        getPresigned(file);
      }
    };
  
    const closeForm = () => {
      window.location.reload();
    };
  
    const onSubmit = () => {
      if (fileData.length === 0)
        return toast.error(errMessage?.invalidFile, {
          toastId: toastIds?.FileUploaderr,
        });
      toast.info("File is getting processed ! Please Wait", {
        toastId: "bulkUploadFileInfo",
      });
      setdownloadFlag(true);
      setdownloadFlag(true);
      toast.info("File is getting processed ! Please Wait", {
        toastId: "bulkUploadFileInfo",
      });
      const fileName = fileData[0].name;
      const key = `${loginState?.year}/${loginState?.month}/${loginState?.day}/${S3FILEPATH?.REPORTS?.REPORTS}/${S3FILEPATH?.COMMON?.UPLOADS}/${S3FILEPATH?.REPORTS?.PROVISION_REPORT}/${fileData[0].id}/${fileName}`    
      const params = {key : key, id : fileData[0].id};
      apiCall.post(reportConstants?.provisionAnalysisReport , params ).then((response) => {
        if (response?.data?.status === 500)
        return toast.error(errMessage?.fileDownloadFailed, {
          toastId: toastIds?.FileUploaderr,
        });
      if (response?.data?.status === 400)
        return toast.error(errMessage?.fileDownloadFailed, {
          toastId: toastIds?.FileUploaderr,
        });
      if (response?.data?.status === 404)
        return toast.info(errMessage?.NoRecordPresentWithinSelectedDate, {
          toastId: toastIds?.DataError,
        });

      window.location.href = response?.data?.response;
      toast.success('File Downloaded Successfully' , {toastId : 'success'})
        
    })
    .catch((error) => {
      toast.error(errMessage?.fileDownloadFailed, {
        toastId: toastIds?.FileUploaderr,
      });
    })
    .finally(() => {
      setdownloadFlag(false);
    });
 
    };
  
    const uploadLoder = (data) => {
      setUploadPercent(data);
    };
  
    const getPresigned = (value) => {
      var id = Math.floor(1000 + Math.random() * 9000);
      const key = `${loginState?.year}/${loginState?.month}/${loginState?.day}/${S3FILEPATH?.REPORTS?.REPORTS}/${S3FILEPATH?.COMMON?.UPLOADS}/${S3FILEPATH?.REPORTS?.PROVISION_REPORT}/${id}/${value[0].name}`    
      setLoading(true);
      apiCall
        .get(
          `${apiConstants?.uploadPreSignedUrl}?key=${key}`
        )
        .then(async (res) => {
          if (res.status === 200) {
            let result = res?.data;
            if (result) {
              value[0].id = id;
              value[0].file_type = `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`;
              uploadPresigned(value[0], result, uploadLoder)
                .then((res) => {
                  if (res.status == 200) {
                    setFileData(value);
                  }
                })
                .catch((e) => {
                  toast.error(e?.message||errMessage?.fileUploadFailed, {
                    toastId: toastIds?.FileUploaderr,
                  });
                });
            }
          }
        })
        .catch((er) => {
          toast.error(er?.message||errMessage?.fileUploadFailed, { toastId:toastIds?.FileUploaderr});
        })
        .finally(() => {
          setLoading(false);
        });
    };
  
  
    const downloadSampleFile = async () => {
      setdownloadFlag(true);
      const key = "sample_files/ProvisionSampleFile.xlsx";
  
      apiCall
        .get(`${apiConstants?.downloadPresignedUrl}?key=${key}`)
        .then((response) => {
          if (response.status === 200) {
            const link = document.createElement("a");
            link.href = response?.data;
            link.click();
            toast.success(commonVariables?.FileDownloadSuccess, { toastId: toastIds?.FileDownloadSucess })
     
          }
        })
        .catch((error) => {
          setdownloadFlag(false);
          return toast.error(errMessage?.InternalServerError, {
            toastId: toastIds?.InternalServerError,
          });
        })
        .finally(() => {
          setdownloadFlag(false);
        });
    };
  
    return (
      <div>
        {downloadFlag && (
          <div className="className='row justify-content-center d-flex loaderDiv">
            <SystemXLoader />
          </div>
        )}
        <div className="container ">
          <div className="row justify-content-center d-flex ">
            <div className="row ps-5 pt-5 pb-4 ">
              <div className="col-md-6">
                <label
                  className="common-header cursor-pointer"
               
                >
                  Provision Report Analysis 
                </label>
              </div>
            </div>
            <div className=" col-md-11 mycontainerstyle reportui">
              <div className="pt-4 ms-3">
                <div className="row">
                  <div className="col-md-9">
                    <div className="m-4">
                      <DynamicForm
                        onSubmit={onSubmit}
                        uploadPercent={uploadPercent}
                        onFileUpload={onFileUpload}
                        formValues={formValues}
                        closeForm={closeForm}
                      />
                    </div>
                    {/* <UploadComponent  name='upload_files'/> */}
                  </div>
                  <div className="col-md-2">
                    <div className="btnmargin">
                      <button
                        onClick={() => {
                          downloadSampleFile();
                        }}
                        className="download-btn text-nowrap"
                      >
                        <img
                          src={downloadArrow}
                          className="pe-2"
                          alt="downloadlogo"
                        ></img>
                        Download Sample File
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
export default ProvisionReportAnalysis;
