import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import Calendar from "../../../Images/calendar.svg";
import "./FormikDatepicker.scss";

export const FormikDatepicker = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <div className="d-flex datepicker">
      <DatePicker
        {...field}
        {...props}
        dateFormat="dd/MM/yyyy"
        selected={(field.value && new Date(field.value)) || null}
        onChange={(val) => {
          setFieldValue(field.name, val);
        }}
        placeholderText = {props?.placeholder}
        minDate={props.minDate}
      />
      <img src={Calendar} alt="calendar" className="CalendarImg" />
    </div>
  );
};

export default FormikDatepicker;
