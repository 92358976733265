import React, { useState } from "react";
import "./DtRepresentationForm.scss";
import {
  commonVariables,
  componentRole,
  dateFormats,
  S3FILEPATH
} from "../../../constants/Common/commonConstants";
import { useDispatch, useSelector } from "react-redux";
import { showBar } from "../../../redux/reducer/sideBarStatus";
import Button from "../../../component/common/Button/button";
import FormLabel from "../../../component/common/formLabel/formLabel";
import { ReactComponent as FileIcon } from "../../../Images/file.svg";
import Dropzone from "react-dropzone";
import uploadPresigned from "../../../utils/presignedUpload";
import { toast } from "react-toastify";
import apiCall from "../../../utils/apiFunction/apiCall";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import { admin, apiConstants, errMessage, successMsg, toastIds } from "../../../constants/Common/apiConstants";
import axios from "axios";
import moment from "moment";


const DebitTransactionRepresentaionForm = (props) => {
  const [fileData, setFileData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);
  const loginState = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [buttonClick, setButtonClick] = useState(false);

  const onDrop = (file) => {
    if (file.length > 0) {
      // if(file[0].name.includes(' ')){
      //   toast.error(
      //     "Please remove spaces from filename",
      //     { toastId: toastIds?.FileUploaderr }
      //   );
      // }else{
        setFileData(file);
      // }
    }
  };

  const uploadLoder = (data) => {
    setUploadPercent(data);
  };
  const getPresigned = (value,batch_id) => {
    setLoading(true);
    const s3key = `${loginState?.year}/${loginState?.month}/${loginState?.day}/${S3FILEPATH?.DT?.DT}/${S3FILEPATH?.COMMON?.UPLOADS}/${S3FILEPATH?.DT?.RPT_PROVISION_UPLOAD}/${batch_id}/${value[0].name}`
    apiCall
      .get(
        `${apiConstants.apicommonUtilityPresignedS3Upload}?key=${s3key}`
      )
      .then(async (res) => {
        if (res.status === 200) {
          let result = res?.data;
          if (result) {
            setLoading(true);
            const cancelToken = axios.CancelToken;
            const source = cancelToken.source();
            setCancelTokenSource(source);
            uploadPresigned(value[0], result, uploadLoder,source)
              .then((res) => {
                if (res.status == 200) {
                  console.log("Upload Response",res.status);
                  setFileData(value);
                  onSubmitApi(batch_id,s3key);
                }
              })
              .catch((e) => {
                toast.error(errMessage?.fileUploadFailed, { toastId: toastIds?.FileUploaderr });
              })
              .finally(() => {
                setLoading(false);
              });
          }
        }
      })
      .catch((er) => {
        toast.error(errMessage?.fileUploadFailed, { toastId: toastIds?.FileUploaderr });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const submitForm = () => {
    if (fileData.length) {
      setButtonClick(true);
      let userData = loginState?.decodedData;
      let apiBody = {
        file_name: fileData[0]?.name || "",
        user_name: userData?.user_name,
        upload_date_time: moment(new Date()).format(dateFormats.dateFormatInMDYWithTimeAndAm),
        upload_status: commonVariables?.PROCESSING, 
        module_name: commonVariables?.RPT_PROVISION
        };
         setLoading(true);
        apiCall
          .post(apiConstants?.createUser, {
            body: apiBody,
            tablename: "debit_tran_reg_file_upload_status",
          })
          .then(async (res) => {
            if (res?.data?.status === 200) {
              if (res?.data?.response?.data?.id) {
                onUpdate(res.data.response.data);
              } else {
                toast.error(
                  res?.data?.message || errMessage?.somethingWentWrong,
                  {
                    toastId: toastIds?.FileUploaderr,
                  }
                );
              }
            } else {
              toast.error(
                res?.data?.message || errMessage?.somethingWentWrong,
                {
                  toastId: toastIds?.FileUploaderr,
                }
              );
              setButtonClick(false);
            }
          })
          .catch((er) => {
            console.log("err", er);
            setLoading(false);
            let resp = er?.message || "";
            toast.error(resp, { toastId: toastIds?.FileUploaderr });
          })
          .finally(() => {
          });
    } else {
      toast.error(errMessage?.uploadFile, { toastId: toastIds?.FileUploaderr });
    }
  };


  const onUpdate = (data) => {
    if (fileData.length) {
      let apiBody = {
        batch_id: data.id || "",
      };
      setLoading(true);
      apiCall
        .post(apiConstants?.updateUser, {
          body: apiBody,
          tablename: "debit_tran_reg_file_upload_status",
          whereQuery: {
            id: data.id || "",
          },
        })
        .then(async (res) => {
          if (res?.data?.status === 200) {
            if (res.data.response[0] === 1) {
              getPresigned(fileData, data.id);
            } else {
              toast.error(
                res?.data?.message || errMessage?.somethingWentWrong,
                {
                  toastId: toastIds?.FileUploaderr,
                }
              );
            }
          } else {
            toast.error(res?.data?.message || errMessage?.somethingWentWrong, {
              toastId: toastIds?.FileUploaderr,
            });
          }
        })
        .catch((er) => {
          console.log("err", er);
          setLoading(false);
          let resp = er?.message || "";
          toast.error(resp, { toastId: toastIds?.FileUploaderr });
        })
        .finally(() => {
        });
    } else {
      toast.error(errMessage?.uploadFile, { toastId: toastIds?.FileUploaderr });
    }
  };

  const onSubmitApi = (batch_Id,s3key) => {
    // if (data.length) {
    let userData = loginState?.decodedData
    if (fileData.length) {
      setLoading(true)
      let apiBody = {
        file_name: fileData[0]?.name || "",
        key: fileData[0]?.name ? s3key : "",
        userName: userData?.user_name || "",
        roleData: admin,
        batchId: batch_Id
      }
      console.log("body",apiBody)
      apiCall
        .post(apiConstants?.rptFileUpload, apiBody)
        .then(async (res) => {
          if (res?.data?.status === 200) {
            toast.success("File Uploaded Successfully", "fileUploadSuccess")
            dispatch(showBar(false))
            handleCancel()
          } else {
            setLoading(false)
            let resp = res?.data?.message || errMessage?.somethingWentWrong
            toast.error(resp, {toastId: toastIds?.rptbillUpload})
          }
        })
        .catch((er) => {
          let resp = er?.response?.data?.message || errMessage?.somethingWentWrong
          toast.error(resp, {toastId: toastIds?.rptbillUpload})
        })
        .finally(() => {
          setButtonClick(false)
          setLoading(false)
        })
    } else {
      toast.error(errMessage?.uploadFile, {toastId: "uploaderror"})
      setButtonClick(false)
    }
    // }
  }
  const handleCancel = () =>{
    setButtonClick(false);
    props?.onCancel();
    setFileData([]);
    setLoading(false);
    setUploadPercent(0);
    if (cancelTokenSource) {
      cancelTokenSource.cancel('Upload canceled by user.');
    }
  }
  return (
    <>
      {loading ? (
        <div className="loaderDiv">
          <SystemXLoader />
        </div>) :
        (
          <>
      <div className="d-flex justify-content-end mr-3">
        <Button
          role={componentRole?.TRANSPARENT_CANCEL || ""}
          onClick={() => dispatch(showBar(false),handleCancel())}
        />
      </div>
      <div className="d-flex justify-content-start demergeLabel f-5 ms-4">
        <FormLabel value={commonVariables?.PROVISION_UPLOAD || ""} />
      </div>
      <div className="m-4">
        <p>Upload</p>
        <Dropzone
          onDrop={onDrop}
          multiple={false}
          accept={{
            "text/plain":['.csv'],
          }}
          disabled={(uploadPercent > 0 && uploadPercent < 100)}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps({
                className:
                  "dropWrapper d-flex align-items-center justify-content-center",
              })}
            >
              <input {...getInputProps()} />
              <div className="d-flex justify-content-center flex-wrap">
                <div className="d-flex gap-2 uploadContent">
                  {fileData[0]?.name && <FileIcon />}
                  {uploadPercent > 0 && uploadPercent < 100
                    ? `${uploadPercent}% Uploaded`
                    : fileData[0]?.name || "Upload File"}
                </div>
              </div>
            </div>
          )}
        </Dropzone>
        <div className="mt-4 d-flex gap-4">
          <button
            className="customButtonGreenInverted"
            onClick={() => dispatch(showBar(false),handleCancel())}
          >
            Cancel
          </button>
          <button
            className="customButtonGreen"
            disabled={((uploadPercent > 0 && uploadPercent < 100) || buttonClick)}
            onClick={() => submitForm()}
          >
            Submit
          </button>
        </div>
      </div>
      </>)}
    </>
  );
};

export default DebitTransactionRepresentaionForm;
