import React, { useEffect, useState } from "react";
import "./sftpTable.scss";
import CustomTable from "../../../component/customtable/CustomTable";
import Button from "../../../component/common/Button/button";
import apiCall from "../../../utils/apiFunction/apiCall";
import {
  componentRole,
  selectoptions,
} from "../../../constants/Common/commonConstants";
import "../../../styles/layout/common.scss";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import { toast } from "react-toastify";
import {
  apiConstants,
  enachApiConstants,
  bulkDownloadFlags,
  tableNamePaymentConstants,
  DtRepresentationTableOrder,
  errMessage,
  toastIds,
  mandateAttributes,
} from "../../../constants/Common/apiConstants";
import moment from "moment";

const SftpTable = () => {
  const [selectMonth, setSelectMonth] = useState(1);
  const [count, setCount] = useState(0);
  const [listdata, setListdata] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [loading, setLoading] = useState(false);
  const [downloadFlag, setdownloadFlag] = useState(false);

  const listDataByMonthApi = (value) => {
    setdownloadFlag(true);
    let temp = 0;
    if ((value || pageNumber) > 0) {
      temp = value ? (value - 1) * 10 : (pageNumber - 1) * 10;
    }
    setLoading(true);
    const params = {
        "tablename" : tableNamePaymentConstants?.fileUploadLog,
        "month" : 0,
        "offset" : temp,
        "order":[['id','desc']],
        "limit" : 50,
    };
    apiCall
      .post(apiConstants?.fetchAllData, params)
      .then((response) => {
        if (response.status === 200) {
          if (response?.data?.status === 500)
            return toast.error(errMessage?.InternalServerError, {
              toastId: toastIds?.InternalServerError,
            });
          if (response?.data?.status === 404) {
            setLoading(false);
            return setListdata([]);
          } else {
            setCount(response?.data?.count || 0);
            let data = response?.data?.response.map((item , index) => {
              return {
                no: temp + index +1,                
                bank_name: item?.bank_code,
                company_code: item?.company_code,
                mandate_type: item?.mandate_type,
                file_name: item?.file_name,
                folder: item?.folder === "mandateIn" ? "Registration" : "Transaction" ,
                user_name: item?.user_name,
                createdAt : item?.createdAt ,
                sftp_push_flag : item?.sftp_push_flag === 1 ? "Success" : "Pending",
              };
            });
            if (data?.length > 0) {
              setListdata([...data]);
            }
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        return setListdata([]);
      })
      .finally(() => {
        setdownloadFlag(false);
        setLoading(false);
      });
  };

  const changePage = (value) => {
    listDataByMonthApi(value);
    setPageNumber(value - 1);
  };



  useEffect(() => {
    listDataByMonthApi();
  }, [selectMonth]);

  return (
    <div className="mx-3 mt-4">
      {downloadFlag && (
        <div className="d-flex justify-content-center d-flex align-items-center loaderDiv">
          <SystemXLoader />
        </div>
      )}

      <div>
        <div>
          <div className="d-flex flex-wrap gap-2 align-items-center justify-content-between  mt-4 mb-3 ">
            <div className="right-div-header">
              <div className="mt-1">
                <label className="texsint-nowrap pl-0 common-header">
                Previously Uploaded Files 
                </label>
              </div>
            </div>
            {/* <div className="d-flex gap-3 left-div-header">
                <div>
                  <select
                    value={selectMonth}
                    onChange={(e) => {
                      setSelectMonth(e?.target?.value);
                    }}
                    className="form-select selectInput"
                  >
                    <option key="Select Month" hidden value>
                      Select Month
                    </option>
                    {selectoptions?.length &&
                      selectoptions.map((value) => {
                        return (
                          <option value={value.value}>{value.label}</option>
                        );
                      })}
                  </select>
                </div>
                <div>
                <div className="refreshBtn">
                    <Button
                 onClick={() => {
                 window.location.reload();
                }}
              value='Refresh'
              role={componentRole.PRIMARY}
            />
                </div>
                </div>
            </div> */}
          </div>
          <div className="mandateTableWrapper">
            <CustomTable
              cols={[
                {
                  id: "no",
                  title: "Sr.No",
                  classStyle: {},
                },
                {
                  id: "bank_name",
                  title: "Bank Name",
                  classStyle: {},
                },
                {
                  id: "company_code",
                  title: "Company Code",
                  classStyle: {},
                },
                {
                  id: "mandate_type",
                  title: "Mandate Type",
                  classStyle: {},
                },
                {
                  id: "file_name",
                  title: "File Name",
                  classStyle: {},
                },
                {
                  id: "folder",
                  title: "File Type",
                  classStyle: {},
                },
                {
                  id: "user_name",
                  title: "User Name",
                  classStyle: {},
                },
                {
                  id: "createdAt",
                  title: "Date",
                  classStyle: {},
                },
                {
                  id: "sftp_push_flag",
                  title: "SFTP Push",

                  classStyle: {},
                },
              ]}
              data={listdata}
              count={count}
              setPagination={(page) => changePage(page)}
              activePage={pageNumber}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default SftpTable;