import React, { useEffect, useState } from "react";
import "./Indusland.scss";
import InputField from "../../../../../component/common/Inputfield/inputfield";
import Button from "../../../../../component/common/Button/button";
import CustomTable from "../../../../../component/customtable/CustomTable";
import "../../../../../styles/layout/common.scss";
import { DtRepresentationTableOrder, apiConstants, errMessage, tableNamePaymentConstants, toastIds} from "../../../../../constants/Common/apiConstants";
import { commonVariables, componentRole, tableID,selectoptions, dateFormats} from "../../../../../constants/Common/commonConstants";
import apiCall from "../../../../../utils/apiFunction/apiCall";
import moment from "moment";
import { toast } from "react-toastify";
import SystemXLoader from "../../../../../component/common/Loader/SystemXLoader";

const IndusLand = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [listdata, setListdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [downloadFlag, setDownloadFlag] = useState(false);

  const listApi = (value) => {
    let temp = value ? (value - 1) * 10 : (pageNumber - 1) * 10;
    setLoading(true);
    setDownloadFlag(true);
    let paramObj = {
      offset: temp || 0,
      limit: apiConstants?.limit,
      tablename: tableNamePaymentConstants?.ScanRegRevFileLog,
      group: [commonVariables?.FILE_NAME],
      whereQuery: {
        bank_code: commonVariables?.INDUSIND,
      },
      month:0,
      order: DtRepresentationTableOrder,
    };
    apiCall
      .post(apiConstants?.fetchAllData, paramObj)
      .then(async (res) => {
        if (res.status === 200) {
          let result = res?.data?.response;
          result = result.map((item,i) => {
            let newItem = {
              srno:temp+i+1 ,
              bank:item?.bank_code,
              companyCode:item?.company_code,
              fileName: item?.file_name,
              sftpFolder: item?.folder,
              fileRecievedDate: moment(item.createdAt).format(dateFormats?.dateFormatInDMYWithTimeAndAm),
              download: (
                <Button value={commonVariables?.DOWNLOAD || ""} role={componentRole?.TRANSPARENT || ""} onClick={()=>{downloadReort(item?.file_name,item?.s3_key)}}  />
              ),
            };
            return newItem;
          });
          if (result?.length > 0) {
            setListdata([...result]);
          }
        }
      })
      .catch((er) => {
        console.log("err", er);
      })
      .finally(() => {
        setLoading(false);
        setDownloadFlag(false);
      });
  };

  const downloadReort=(fileName,s3_key)=>{
    apiCall
        .get(
          `${apiConstants.ReportsMandateRegistrationdownaloadMandateRegistrationRevSftpHdfc}?key=${s3_key}&file_name=${fileName}`
        )
        .then(async (res) => {
          if (res.status === 200) {
            const presignUrl = res.data.response
            if(res?.data?.status === 200){
              const link = document.createElement("a");
              link.href = presignUrl;
              link.click();  
              toast.success(commonVariables?.FileDownloadSuccess, { toastId: toastIds?.FileDownloadSucess })
     
            }
          }
          if (res?.data?.status === 404) {
            toast.error(res?.data?.message||errMessage?.somethingWentWrong, { toastId: toastIds?.FileUploadSuccess });
          }
        })
        .catch((er) => {
          console.log("err", er);
        })
        .finally(() => {
        
        });
  };
  const changePage = (value) => {
    listApi(value);
    setPageNumber(value - 1);
  };
  useEffect(() => {
    listApi();
  }, []);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mt-5 mb-4 mx-3 indusIndReportHeader">
          <div>
            <label className="labelPresentation">
              {commonVariables?.MANDATE_REG_REV_INDUS || ""}
            </label>
          </div>
          
          <div className="d-flex gap-3">
            {/* <div className="text-right">
            <InputField className="SearchButton" placeholder={"Search"} />
          </div> */}
            {/* <div>
            <select
                  className="form-select selectInput"
                >
                    <option key="Select Month" hidden value>
                      Select Month
                    </option>
                  {selectoptions?.length &&
                    selectoptions.map((value) => {
                      return <option value={value.value}>{value.label}</option>;
                    })}
                </select>
            </div> */}
            {/* <div>
              <Button value={commonVariables?.EXPORT || ""} role={componentRole?.EXPORT || ""} />
            </div> */}
          </div>
        </div>
      <div className="col mandate-indusland-table">
        {downloadFlag && (
            <div className="loaderDiv">
              <SystemXLoader />
            </div>
          )}
        <CustomTable
          cols={[
            {
              id: tableID.SRNO || "",
              title:commonVariables.SRNO || "",
            },
            {
              id: tableID.BANK || "",
              title: commonVariables.BANK || "",
            },
            {
              id: tableID.COMPANY_CODE || "",
              title: commonVariables.COMPANY_CODE || "",
            },
            {
              id: tableID.FILENAME || "",
              title: commonVariables.FILE_NAME || "",
            },
            {
              id: tableID.SFTP_FOLEDER  || "",
              title: commonVariables.SFTP_FOLEDER || "",
            },
            {
              id: tableID.FILE_REC_DATE || "",
              title: commonVariables.FILE_REC_DATE || "",
            },
            {
              id: tableID.DOWNLOAD || "",
              title: commonVariables.DOWNLOAD || "",
            },
          ]}
          data={listdata}
          count={apiConstants?.count}
          setPagination={(page) => changePage(page)}
          activePage={pageNumber}
          loading={loading}
        />
      </div>
      </>
  );
};
export default IndusLand;
