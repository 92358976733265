import React from 'react';
import { Form } from "react-bootstrap";
const Newdropdown=(props)=>{

    return(
        <div>
         <Form.Select className={props.className} >
         {props.placeholder && (<option key={props.placeholder} hidden value>
         {props.placeholder}
       </option>)}
         {
            props.options.map((i)=>
            <option key={props.key}>
                {i.value}
            </option>

            )
         }
         </Form.Select>
        </div>
    )
}
export default Newdropdown;