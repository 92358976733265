import CustomTable from '../../../customtable/CustomTable';
import { Form } from 'react-bootstrap';
import Button from '../../../common/Button/button';
import { useNavigate } from 'react-router-dom';
import InputField from '../../../common/Inputfield/inputfield';
import React, { useEffect, useState } from 'react';
import '../../sponsorBank/collectionSponsorBank/collectionSponsorBank.scss';
import { ROUTE_PATH } from '../../../../constants/Routes';
import apiCall from '../../../../utils/apiFunction/apiCall';
import { apiConstants, FOMRS, successMsg, tableNamePaymentConstants } from '../../../../constants/Common/apiConstants';
import { showBar } from '../../../../redux/reducer/sideBarStatus';
import { componentRole, commonVariables, tableID } from '../../../../constants/Common/commonConstants';
import SideBar from '../../../sideBar';
import { useDispatch } from 'react-redux';
import ExportCollectionSponsor from './exportCollectionSponsor/exportCollectionSponsor';
import SearchBar from '../../../common/Searchbar/searchbar';
import Pencil from "../../../../Images/assests/images/pencil.svg"
import Trash from "../../../../Images/assests/images/trash-bin.svg"
import { toast } from 'react-toastify';

function CollectionSponsorBank() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [pageNumber, setPageNumber] = useState(0);
	const [listdata, setListdata] = useState([]);
	const [loading, setLoading] = useState(false);
	const [datacount, setDatacount] = useState(0)
	const [searchValue, setSearchValue] = useState('');
	const [isSearch, setIsSearch] = useState(false);



	const searchValueChange = (value) => {
		setSearchValue(value);
	}

	const deleteFunc = (item) => {
		// console.log(item, '<>>>><<<<>');
		const query = {
			tablename: tableNamePaymentConstants?.SponsorBank,
			whereQuery: {
				id: item?.id,
				bank_code: item?.bank_code,
				bank_name: item?.bank_name,
				mandate_type: item?.mandate_type,
				company_code: item?.company_code,
			}
		}
		apiCall.post(apiConstants?.deleteRecord, query).then((res) => {
			if (res?.data?.status === 200) {
				toast.success(successMsg.Deleted)
				navigate(ROUTE_PATH?.COLLECTION_SPONSOR_BANK)
			} else {
				toast.error(res?.data?.message)
			}
		})
	}

	const listApi = (value) => {
		let temp = 0

		if ((value || pageNumber) > 0) {
			temp = value ? (value - 1) * 10 : (pageNumber - 1) * 10;
		}
		setLoading(true);
		apiCall
			.post(apiConstants?.fetchAllData, {
				offset: temp,
				limit: 10,
				tablename: tableNamePaymentConstants?.SponsorBank,
				whereQuery: {
					isActive: 1
				},
				order: [['id', 'DESC']],
				month: 0

			})
			.then(async (res) => {
				if (res?.status === 200) {
					let result = res?.data?.response;
					setDatacount(res?.data?.count || 0)
					result = result.map((item) => {
						let newItem = {
							no: item?.id,
							sponsorBank: item?.bank_code ? item?.bank_code : '-',
							name: item?.bank_name ? item?.bank_name : '-',
							mandate_type: item?.mandate_type ? item?.mandate_type : '-',
							isActive: item?.isActive ? (item?.isActive === 1 ? 'Yes' : 'No') : 'No',
							assign_sponsor_utility: item?.service_provider_utility_code ? item?.service_provider_utility_code : '-',
							action: (
								<div>
									<img
										className="icon"
										src={Pencil}
										alt=""
										onClick={() => {
											navigate(ROUTE_PATH.CREATE_SPONSOR, {
												state: {
													type: FOMRS.EDIT,
													user_type: item,
												},
											});
										}}
									/>
									<img
										className="delete_icon"
										src={Trash}
										onClick={() => { deleteFunc(item) }}
										alt=""
									/>
								</div>
							),
						};
						return newItem;
					});
					if (result?.length > 0) {
						setListdata([...result]);
					}
				}
			})
			.catch((er) => {
				console.log('err', er);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleSearch = (value) => {
		let temp = 0

		if ((value || pageNumber) > 0) {
			temp = value ? (value - 1) * 10 : (pageNumber - 1) * 10;
		}
		setIsSearch(true);
		setLoading(true);
		const params = {
			tableName: tableNamePaymentConstants?.SponsorBank,
			columns: ['bank_code', 'bank_name', 'mandate_type'],
			offset: temp,
			order: [['id', 'DESC']]
		};

		apiCall
			.post(apiConstants?.commonSearch + searchValue, params)
			.then((res) => {
				if (res.status === 200) {
					let result = res?.data?.response.rows;
					setDatacount(res.data.count || 0)
					result = result.map((item) => {
						let newItem = {
							no: item?.id,
							sponsorBank: item?.bank_code ? item?.bank_code : '-',
							name: item?.bank_name ? item?.bank_name : '-',
							mandate_type: item?.mandate_type ? item?.mandate_type : '-',
							isActive: item?.isActive ? (item?.isActive === 1 ? 'Yes' : 'No') : 'No',
							assign_sponsor_utility: item?.service_provider_utility_code ? item?.service_provider_utility_code : '-',
							action: (
								<div>
									<img
										className="icon"
										src={Pencil}
										alt=""
										onClick={() => {
											navigate(ROUTE_PATH.CREATE_SPONSOR, {
												state: {
													type: FOMRS.EDIT,
													user_type: item,
												},
											});
										}}
									/>
									<img
										className="delete_icon"
										src={Trash}
										onClick={() => { deleteFunc(item) }}
										alt=""
									/>
								</div>
							),
						};
						return newItem;
					});
					if (result?.length > 0) {
						setListdata([...result]);
					} else {
						setListdata([...result]);
					}
				}

			}).catch((err) => {
				console.log("some error", err);
			}).finally(() => {
				setLoading(false);
			});
	}

	const changePage = (value) => {
		isSearch ? handleSearch(value) : listApi(value);
		setPageNumber(value - 1);
	};

	useEffect(() => {
		isSearch ? handleSearch() : listApi();
	}, []);
	useEffect(() => {
		if (searchValue === '') {
			setIsSearch(false);
			listApi();
		}
	}, [searchValue]);

	return (
		<>
			<div id="SponsorBank">
				<div className="alignHeader ">
					<div className="larger_f_bold blueFont setMargin">Collection Sponsor Bank</div>
					<div className="setMargin d-flex">
						<div className='px-3'>
							<SearchBar value={searchValue} onChange={searchValueChange} onSearchClick={handleSearch} />
						</div>
						{' '}
						<div className='px-3'>
							<Button
								value={'Create Sponsor Bank'}
								role={componentRole?.PRIMARY}
								onClick={() => {
									navigate(ROUTE_PATH?.CREATE_SPONSOR, {
										state: {
											type: FOMRS.ADD,
										},
									});
								}}
							/>

						</div>
						<div className='px-3'>
							<Button
								value={'Sponsor Bank Status Export' || ""}
								role={componentRole?.PRIMARY || ""}
								onClick={() => {
									dispatch(showBar(true));
								}}
							/>
						</div>
						<SideBar data={<ExportCollectionSponsor />} />
					</div>
				</div>
				<div className="col dtMergeTable">
					<CustomTable
						cols={[
							{
								id: tableID?.NO || "",
								title: commonVariables?.NO || "",
							},
							{
								id: tableID?.SPONSOR_BANK || "",
								title: commonVariables?.SPONSOR_BANK || "",
							},
							{
								id: tableID?.NAME || "",
								title: commonVariables?.NAME || "",
							},
							{
								id: tableID?.MANDATE_TYPE || "",
								title: commonVariables?.MANDATE_TYPE || "",
							},
							{
								id: tableID?.IS_ACTIVE || "",
								title: commonVariables?.IS_ACTIVE || "",
							},
							{

								id: tableID?.ASSIGN_SPONSOR_UTILITY || "",
								title: commonVariables?.ASSIGN_SPONSOR_UTILITY || "",
							},

							{
								id: tableID?.ACTION || "",
								title: commonVariables?.ACTION || "",
							},
						]}
						data={listdata}
						count={datacount}
						setPagination={(page) => changePage(page)}
						activePage={pageNumber}
						loading={loading}
					/>
				</div>
			</div>
		</>
	);
}

export default CollectionSponsorBank;
