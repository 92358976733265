import clsx from "clsx";
import { Field } from "formik";
import "./formikSelect.scss";

function Select(props) {
  const { label, name, options, ...rest } = props;

  return (
    <div id="formDropDown">
      <div className="formSelect">
        <Field
          as="select"
          id={name}
          name={name}
          {...rest}
          className={clsx(props.className, "formSelect")}
        >
          <>
            {props?.showPlaceHolder && (
              <option value="" selected>
                Select an option
              </option>
            )}
          </>

          {options.map((option) => {
            return (
              <>
                <option key={option.value} value={option.value}>
                  {option?.key?.length > 55
                    ? option?.key?.slice(0, 55)?.padEnd(58, ".")
                    : option?.key}
                </option>
              </>
            );
          })}
        </Field>
      </div>
    </div>
  );
}

export default Select;
