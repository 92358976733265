import React from 'react';
import clsx from 'clsx';
import "./InputField.scss"

function InputField(props) {

 return (
 <>
    <input
        type={props?.type}
        value ={props?.value}
        className ={clsx(props?.className,"InputField")}
        placeholder ={props?.placeholder}
        onChange={props?.onChange}
        disabled = {props?.disabled}
        onBlur = {props?.onBlur}
        maxLength={props?.maxLength?props.maxLength:255}
        id={props?.id}
        required title={props?.rtitle}
    />
 </>
 );

}




export default InputField;