import { useState, useEffect } from "react";
import "./sftpUpload.scss";
import downloadArrow from "../../../Images/downloadIcon.svg";
import { useNavigate } from "react-router-dom";
import info from '../../../Images/info.svg';
import apiCall from "../../../utils/apiFunction/apiCall";
import uploadPresigned from "../../../utils/presignedUpload";
import DynamicForm from "../../../component/common/DynamicForm/dynamicForm";
import { toast } from "react-toastify";
import moment from "moment";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import { useSelector } from "react-redux";
import {
  apiConstants,
  enachApiConstants,
  bulkDownloadFlags,
  errMessage,
  toastIds,
  successMsg,
} from "../../../constants/Common/apiConstants";
import { commonVariables, dateFormats, toolTipMessages } from "../../../constants/Common/commonConstants";
import { tableNamePaymentConstants } from "../../../constants/Common/apiConstants";
import { ROUTE_PATH } from "../../../constants/Routes";
import CustomTooltip from "../../../component/Tooltip/Tooltip";
import { template } from "lodash";
import ResponseModal from "../../../component/responseModal/responseModal";

const SftpUpload = () => {
  const [uploadPercent, setUploadPercent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [downloadFlag, setdownloadFlag] = useState(false);
  const [modalData, setModalData] = useState({});
  const [showModal, setShowModal] = useState(false);

  const loginState = useSelector((state) => state.login);
  const [companyCode, setCompanyCode] = useState([]);
  const [repaymentMode , setRepaymentMode] = useState([]);
  const [sponsorBank , steSponsorBank] = useState([]);

  const CompanyCode = () => {
    let paramObj = {
      offset: 0,
      limit: 10,
      groupby: "company_code",
      tablename : tableNamePaymentConstants?.new_scan_reg_bank_list,
    };
    apiCall
      .post(apiConstants?.findData, paramObj)
      .then(async (res) => {
        let result = res?.data?.response;

        result = result.map((item) => {
          let label;

          switch (item?.company_code) {
            case "7000":
              label = "Tata Capital Ltd";
              break;
            case "9000":
              label = "Tata Capital Housing Finance Ltd";
              break;
            default:
              label = item?.company_code;
              break;
          }

          let newItem = {
            value: item?.company_code,
            label: label,
          };

          return newItem;
        });

        setCompanyCode([...result]);
      })
      .catch((er) => {
        console.log("errr =>>>>", er);
      });
  };



  const fetchMaster = () => {
    let paramObj = {
      offset: 0,
      limit: 10,
      groupby : "mandate_type",
      tablename: tableNamePaymentConstants?.new_scan_reg_bank_list,
    };
    apiCall
      .post(apiConstants?.findData, paramObj)
      .then(async (res) => {
        let result = res?.data?.response;

        result = result.map((item) => {

          let newItem = {
            value: item?.mandate_type,
            label: item?.mandate_type,
          };
          return newItem;
        });
        setRepaymentMode([...result]);
      })
      .catch((er) => {
        console.log("errr =>>>>", er);
      });
  };



  const fetchSponsorBank = () => {
    let paramObj = {
      groupby : "bank_code",
      dataFetch:[{
        column: "bank_code",
        operator: "in",
        value: ['HDFC', 'INDUSIND', 'FEDERAL'],
      }],
      tablename: tableNamePaymentConstants?.new_scan_reg_bank_list,
    };
    apiCall
      .post(apiConstants?.findData, paramObj)
      .then(async (res) => {
        let result = res?.data?.response;

        result = result.map((item) => {

          let newItem = {
            value: item?.bank_code,
            label: item?.bank_code,
          };
          return newItem;
        });
        steSponsorBank([...result]);
      })
      .catch((er) => {
        console.log("errr =>>>>", er);
      });
  };

  const FileType = {
    registration : "Registration",
    debitTransaction : "Debit Transaction"
  }

  const [formValues , setFormValues ] = useState();
  const onFileUpload = (file) => {
    if (file.length > 0) {
      // let extension = file[0].name.split(".")[1];
      // if (extension != "xlsx")
      //   return toast.error(errMessage?.uploadFileWithXlsxFormat, {
      //     toastId: toastIds?.FileUploaderr,
      //   });
        getPresigned(file);
    }
  };

  const closeForm = () => {
    window.location.reload();
  };


  const onSubmit = (data) => {
    if (data?.length) {
      let tempData = [...data];
      tempData = Object.assign({}, ...tempData);
    let userData = loginState?.decodedData;
    const params = {
      companyCode : tempData['Company Code'],
      mandateType : tempData['Repayment Mode'],
      bankCode : tempData['Sponsor Bank'],
      fileType : tempData['File Type'] === 'Registration' ? 'mandateIn' : tempData['File Type'] === 'Debit Transaction' ? 'TransactionIn' : null,
      userName : userData.user_name,
      key : `commonUpload/ScanRegistrationMergeFiles/${fileData[0].name}`
    }
    const headers = {
      "Content-Type": "application/json"
    }
    sftpUploadApi(params, headers)
    }
    // if (fileData.length === 0)
    //   return toast.error(errMessage?.invalidFile, {
    //     toastId: toastIds?.FileUploaderr,
    //   });
    // toast.info(errMessage?.ProcessingFile, {
    //   toastId:  toastIds?.FileUploadSuccess,
    // });
    // setdownloadFlag(true);
    // // navigate(ROUTE_PATH?.MANDATE);
    // const fileName = fileData[0].name;
    // const batch_id = `B${fileData[0].batch_id}`;
    // const key = `commonUpload/ScanRegistrationMergeFiles/${fileData[0].batch_id}/${fileName}`;
    // const params = {
    //   user_name: userData?.user_name,
    //   upload_date_time: moment().format(dateFormats?.dateFormatInDMYWithTime),
    //   file_name: fileName,
    //   user_code: userData?.user_code,
    //   upload_status: bulkDownloadFlags?.PROCCESSING,
    //   total_count: 0,
    //   success_count: 0,
    //   rejected_count: 0,
    //   key: key,
    //   batch_id: batch_id,
    // };
    // apiCall
    //   .post(apiConstants?.createUser, {
    //     body: params,
    //     tablename: tableNamePaymentConstants?.BulkMandateReg,
    //   })
    //   .then((response) => {
    //     if (response?.status === 200) {
    //       apiCall
    //         .get(
    //           `${enachApiConstants?.bulkMandateCreate}?key=${key}&batch_id=${batch_id}&user_id=${params.user_name}&file_name=${fileName}`
    //         )
    //         .then((response) => {
    //           if (response?.status === 200) {
    //             if (response?.data?.status !== 200)
    //               return toast.error(
    //                 response?.data?.message || errMessage?.fileUploadFailed,
    //                 {
    //                   toastId: toastIds?.FileUploaderr,
    //                 }
    //               );
    //             toast.success(successMsg?.EnachBulkUpload, {
    //               toastId: toastIds?.FileUploadSuccess,
    //             });
    //           }
    //         })
    //         .catch((error) => {
    //           return toast.error(
    //             error?.message || errMessage?.fileUploadFailed,
    //             {
    //               toastId: toastIds?.FileUploaderr,
    //             }
    //           );
    //         })
    //         .finally(() => {
    //           setdownloadFlag(false);
    //         });
    //     }
    //   })
    //   .catch((error) => {
    //     toast.error(error?.message || errMessage.fileUploadFailed, {
    //       toastId: toastIds?.FileUploaderr,
    //     });
    //   })
    //   .finally(() => {
    //     setdownloadFlag(false);
    //   });
  };

  const uploadLoder = (data) => {
    setUploadPercent(data);
  };
  const sftpUploadApi = (value, header) => {
    setLoading(true)
    apiCall.post(`${apiConstants?.sftpUploadApi}`,value, header).then(async (res) => {
      if (res.status === 200) {
        let response_msg = 'Sftp file uploaded successfully'
        setModalData(response_msg)
        setShowModal(true)
      }
    }).finally(() => {
      setLoading(false)
    }).catch((er) => {
      toast.error(er?.message || errMessage?.fileUploadFailed, {
        toastId: toastIds?.FileUploaderr,
      });
    })
  }

  const getPresigned = (value) => {
    var batch_id = Math.floor(1000 + Math.random() * 9000);
    setLoading(true);
    apiCall
      .get(
        `${apiConstants?.uploadPreSignedUrl}?key=commonUpload/ScanRegistrationMergeFiles/${value[0].name}`
      )
      .then(async (res) => {
        if (res.status === 200) {
          let result = res?.data;
          if (result) {
            value[0].batch_id = batch_id;
            value[0].file_type = `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`;
            uploadPresigned(value[0], result, uploadLoder)
              .then((res) => {
                if (res.status == 200) {
                  setFileData(value);
                }
              })
              .catch((e) => {
                toast.error(e?.message || errMessage?.fileUploadFailed, {
                  toastId: toastIds?.FileUploaderr,
                });
              });
          }
        }
      })
      .catch((er) => {
        toast.error(er?.message || errMessage?.fileUploadFailed, {
          toastId: toastIds?.FileUploaderr,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const navigate = useNavigate();

  const downloadSampleFile = async () => {
    setdownloadFlag(true);
    const key = "sample_files/mandateSampleFile.xlsx";

    apiCall
      .get(`${apiConstants?.downloadPresignedUrl}?key=${key}`)
      .then((response) => {
        if (response.status === 200) {
          const link = document.createElement("a");
          link.href = response?.data;
          link.click();
          toast.success(commonVariables?.FileDownloadSuccess, { toastId: toastIds?.FileDownloadSucess })

        }
      })
      .catch((error) => {
        setdownloadFlag(false);
        return toast.error(errMessage?.InternalServerError, {
          toastId: toastIds?.InternalServerError,
        });
      })
      .finally(() => {
        setdownloadFlag(false);
      });
  };


  const getValues = (data) => {
  }

  useEffect(() => {
    CompanyCode();
    fetchMaster();
    fetchSponsorBank();

    console.log("data" , companyCode , repaymentMode , sponsorBank);
  }, []);




  useEffect(() => {
    setFormValues([
      {
        type: "dropdown",
        value: "",
        label: "Company Code",
        placeholder: "Please select value",
        required : true,
        width: 6,
        options: companyCode,
      },
      {
        type: "dropdown",
        value: "",
        label: "Repayment Mode",
        placeholder: "Please select value",
        required : true,
        width: 6,
        options : repaymentMode,
      },
      {
        type: "dropdown",
        value: "",
        label: "Sponsor Bank",
        placeholder: "Please select Date",
        required : true,
        width: 6,
        options : sponsorBank,
      },
      {
        type: "dropdown",
        value: "",
        label: "File Type",
        placeholder: "Please select File Type",
        required : true,
        width: 6,
        options: Object.keys(FileType).map(key => ({
          value: FileType[key],
          label: FileType[key]
        })),
      },
      {
        type: "fileupload",
        width: 12,
        label: "Upload File",
        displaycomponent: <div className="uploadComponentLabel">Upload File</div>,
      }
    ])
  }, [companyCode]);

  return (
    <>
    <ResponseModal
          heading="Sftp file uploaded succesfully"
          data={modalData}
          show={showModal}
          onHide={() => setShowModal(false)}
          color='info'
    />
    <div>
      {downloadFlag && (
        <div className="className='row justify-content-center d-flex loaderDiv">
          <SystemXLoader />
        </div>
      )}
      <div className="mx-3 pt-4 ">
        <div className="row justify-content-center ">
          <div className="row pb-4 ">
            <div className="col-md-6 pl-0 mt-2">
              <label
                className="common-header cursor-pointer"
                onClick={() => {
                  navigate("/reports/sftptable");
                }}
              >
                Find Previous Status &gt; &gt;{" "}
                <span className="text-nowrap headername"> SFTP Upload Functionality </span>
              </label>
            </div>
          </div>
          <div>
            <div className="mycontainerstyle reportui pb-3">
              <div className="pt-4 ms-3 ">
                <div className="row">
                  <div className="col-xl-9 col-md-6 col-lg-8 col-sm-12">
                    <div className="mr-3">
                      <DynamicForm
                        uploadPercent={uploadPercent}
                        onFileUpload={onFileUpload}
                        formValues={formValues}          
                        onSubmit={(data)=>onSubmit(data)}
                        closeForm={()=>window.location.reload()}
                      />
                    </div>
                    {/* <UploadComponent  name='upload_files'/> */}
                  </div>
                  <div className="col-xl-3 col-md-6 col-lg-4 col-sm-12">
                  </div>
                  {/* <div className="d-flex gap-3 pt-3 flex-wrap">
                    <button className="sftpUpload-submitBtn" onClick={() => onSubmit()}>Submit</button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};
export default SftpUpload;