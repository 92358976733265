import React, { useEffect, useState } from "react";
import "./paymentLogs.scss";
import apiCall from "../../../utils/apiFunction/apiCall";
import DynamicForm from "../../../component/common/DynamicForm/dynamicForm";
import { toast } from "react-toastify";
import { Button as ButtonReact, Col, Row } from "react-bootstrap";
import { errMessage, toastIds } from "../../../constants/Common/apiConstants";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import { commonVariables } from "../../../constants/Common/commonConstants";

const PaymentLogs = () => {
  const [formValues, setFormValues] = useState({
    batch_id: {
      value: "",
      error: "",
      required: true,
    },
  });
  const [loading, setLoading] = useState(false);
  const [buttonClick, setButtonClick] = useState(false);

  const onChange = (e) => {
    let tempData = { ...formValues };
    setButtonClick(false);
    tempData[e.target.name].value = e.target.value;
    e.target.value
      ? (tempData[e.target.name].error = false)
      : (tempData[e.target.name].error = true);
    setFormValues({ ...tempData });
  };

  const onSubmit = () => {
    const batch_id = formValues.batch_id.value;
    let apiBody = {
      batch_id: batch_id,
    };
    setButtonClick(true);
    setLoading(true);
    apiCall
      .post("api/payment/accountNumberValidation/batch_id", apiBody)
      .then(async (res) => {
        if (res?.data?.status === 200) {
          const presignUrl = res.data.response;
          if (res?.data?.response) {
            const link = document.createElement("a");
            link.href = presignUrl;
            link.click();
            setButtonClick(true);
            toast.success(commonVariables?.FileDownloadSuccess, { toastId: toastIds?.FileDownloadSucess })
     
          }
        }
        if (res?.data?.status === 404) {
          toast.error(
            res?.data?.message || errMessage?.somethingWentWrong,
            toastIds?.SomethingWentWrong
          );
        }
      })
      .catch((er) => {
        let resp = er?.response?.data?.message || errMessage?.somethingWentWrong;
        toast.error(resp, toastIds?.SomethingWentWrong);
      })
      .finally(() => {
        setLoading(false);
      });
    // }
  };

  const submitForm = () => {
    if (
      formValues.batch_id.value.length === 0 ||
      formValues.batch_id.value === ""
    ) {
      let tempData = { ...formValues };
      tempData.batch_id.required = true;
      setFormValues({ ...tempData });
    } else {
      let tempData = { ...formValues };
      tempData.batch_id.required = false;
      setFormValues({ ...tempData });
      onSubmit();
    }
  };

  return (
    <>
      {loading ? (
        <div className="loaderDiv">
          <SystemXLoader />
        </div>
      ) : (
        <>
          <div className="main-container">
            <span className="text-nowrap ml-4 fs-4 ps-2">Payment Logs</span>
            <div className="container shadow-sm border border-1 rounded-2 ml-4 mt-2">
              <div className="m-4">
                <div className="dynamicFormWrapper">
                  <Row className="g-3 gx-5 mb-5">
                    <Col lg={12} md={12} sm={12}>
                      <div className="inputDiv mt-1">
                        <p>
                          Batch ID <span className="textLabelRed">*</span>
                        </p>
                        <input
                          type="number"
                          name="batch_id"
                          className="textInput"
                          onChange={(e) => onChange(e)}
                        />
                        {formValues.batch_id.error &&
                          formValues.batch_id.required && (
                            <p className="textLabelRed m-0 mt-2">
                              Batch ID is required
                            </p>
                          )}
                      </div>
                    </Col>
                    <div className="d-flex gap-4">
                      <ButtonReact
                        className="customButtonGreen"
                        disabled={
                          formValues.batch_id.value === "" ||
                          formValues.batch_id.value.length === 0 ||
                          buttonClick
                        }
                        onClick={() => submitForm()}
                      >
                        Download
                      </ButtonReact>
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default PaymentLogs;
