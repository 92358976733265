import React, {useEffect, useState} from "react"
import "./Indushdfc.scss"
import Dropdown from "../../../component/common/Dropdown/dropdown"
import Button from "../../../component/common/Button/button"
import Datepicker from "../../../component/common/Daterange/daterange"
import Label from "../../../component/label/label"
import "../../../styles/layout/common.scss"
import apiCall from "../../../utils/apiFunction/apiCall"
import {
  apiConstants,
  downloadIndusHdfcReports,
  errMessage,
  fetchIndusHdfcReports,
  tableNamePaymentConstants,
  toastIds,
} from "../../../constants/Common/apiConstants"
import DynamicForm from "../../../component/common/DynamicForm/dynamicForm"
import downloadIcon from "../../../Images/download.svg"
import moment from "moment"
import {toast} from "react-toastify"
import {errorMessages} from "../../../constants/Common/errorConstants"
import CustomTable from "../../../component/customtable/CustomTable"
import SystemXLoader from "../../../component/common/Loader/SystemXLoader"
import {commonVariables, dateFormats} from "../../../constants/Common/commonConstants"
import {useSelector} from "react-redux"
const IndusHdfcReport = () => {
  const [formValues, setFormValues] = useState()
  const [loading, setLoading] = useState(false)
  const [tableLoading, setTableLoading] = useState(false)
  const [sponserBank, setSponsorBank] = useState([])
  const [listData, setListdata] = useState([])
  const loginState = useSelector((state) => state.login)

  const SponserBank = () => {
    setLoading(true)
    let paramObj = {
      tablename: tableNamePaymentConstants?.sponsorBankPaymentTypeMaster,
    }
    apiCall
      .post(apiConstants?.findData, paramObj)
      .then(async (res) => {
        let result = res?.data?.response
        result = result.map((item) => {
          let newItem = {
            value: item.bank_GL_code,
            label: item.bank_name,
          }

          return newItem
        })

        setSponsorBank([...result])
      })
      .catch((er) => {
        console.log("errr =>>>>", er)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const downloadbtn = (fileName, bankName) => {
    return (
      <div>
        <button
          onClick={() => onDownload(fileName, bankName)}
          className="downloadbtn pt-1 pe-2 pe-2 d-flex align-items-center justify-content-center">
          <img src={downloadIcon} alt="download" />
          Download
        </button>
      </div>
    )
  }
  const onDownload = (fileName, bankName) => {
    setLoading(true)
    apiCall
      .get(downloadIndusHdfcReports(fileName, bankName))
      .then(async (res) => {
        if (res?.status === 200) {
          // setLoading(false);
          let result = res?.data
          if (result) {
            if (typeof res?.data?.response == "string") {
              const link = document.createElement("a")
              link.href = res?.data?.response
              link.click()
              toast.success(commonVariables?.FileDownloadSuccess, {
                toastId: toastIds?.FileDownloadSucess,
              })
            } else {
              toast.error(res?.data?.message || errMessage?.somethingWentWrong, {
                toastId: toastIds?.DataError,
              })
            }
          }
        } else {
          // setLoading(false);
          toast.error(res?.data?.message || errMessage?.somethingWentWrong, {
            toastId: toastIds?.DataError,
          })
        }
      })
      .catch((er) => {
        setLoading(false)
        toast.error(er?.message || errMessage?.somethingWentWrong, {
          toastId: toastIds?.DataError,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const onSubmit = (data) => {
    if (data?.length) {
      let tempData = [...data]
      tempData = Object.assign({}, ...tempData)
      if (tempData["To Date"] >= tempData["From Date"]) {
        setTableLoading(true)
        let apiBody = {
          FromDate: moment(tempData["From Date"]).format(dateFormats?.mdy) || "",
          ToDate: moment(tempData["To Date"]).format(dateFormats?.mdy) || "",
          Sponsor: tempData["Sponsor Bank"] || "",
          user_name: loginState?.decodedData?.user_name || "",
        }
        apiCall
          .get(
            fetchIndusHdfcReports(
              apiBody.FromDate,
              apiBody.ToDate,
              apiBody.Sponsor,
              apiBody.user_name,
            ),
          )
          .then(async (res) => {
            if (res.status === 200) {
              if (res?.data?.response?.length) {
                let result = res?.data?.response
                result = result.map((item, index) => {
                  let newItem = {
                    id: index + 1,
                    fileName: item.file_name,
                    status: item.sftp_file_status,
                    spBank: item.sponsor_bank,
                    totalCases: item.total_cases,
                    userName: item.file_creation_user,
                    date: moment(item.file_creation_date).format(dateFormats?.dmyHms),
                    action: downloadbtn(item.file_name, item.sponsor_bank),
                  }
                  return newItem
                })
                setListdata([...result])
              } else {
                setListdata([])
                toast.error(res.data.message || errorMessages.SOMETHING_WRONG, {
                  toastId: toastIds?.DataError,
                })
              }
            }
          })
          .catch((er) => {
            setListdata([])
            let resp = er?.message || ""
            toast.error(resp || errorMessages.SOMETHING_WRONG, {
              toastId: toastIds?.SomethingWentWrong,
            })
          })
          .finally(() => {
            setTableLoading(false)
          })
      } else {
        toast.error(errMessage?.ToDateMustBeGreaterThanFromDate, {
          toastId: toastIds?.DataError,
        })
      }
    }
  }
  useEffect(() => {
    setFormValues([
      {
        type: "dateinput",
        value: new Date(),
        label: "From Date",
        placeholder: "Select Date",
        required: true,
        width: 6,
      },
      {
        type: "dateinput",
        value: new Date(),
        label: "To Date",
        placeholder: "Select Date",
        required: true,
        width: 6,
      },
      {
        type: "dropdown",
        value: "",
        label: "Sponsor Bank",
        placeholder: "Please Select a value",
        required: true,
        width: 6,
        options: sponserBank,
      },
    ])
  }, [sponserBank])
  useEffect(() => {
    SponserBank()
  }, [])
  return (
    <>
      {loading ? (
        <div className="text-center mct-5">
          <SystemXLoader />
          <p>
            <b>Loading...</b>
          </p>
        </div>
      ) : (
        <div className="">
          <label className="common-header ms-3 ps-3 pb-1 mt-4 pt-2">Indus / HDFC Reports</label>
          <div className="paymentReport_inputContainer mx-4 p-4">
            <DynamicForm previewBtn onSubmit={(data) => onSubmit(data)} formValues={formValues} />
          </div>
          <div className="mx-4">
            {tableLoading && (
              <div className="loaderDiv h-50">
                <SystemXLoader />
              </div>
            )}
            {listData?.length > 0 && (
              <CustomTable
                cols={[
                  {
                    id: "fileName",
                    title: "File Name",
                    classStyle: {},
                  },
                  {
                    id: "spBank",
                    title: "Sponsor Bank",
                    classStyle: {},
                  },
                  {
                    id: "status",
                    title: "Status",
                    classStyle: {},
                  },
                  {
                    id: "totalCases",
                    title: "Total Cases",
                    classStyle: {},
                  },
                  {
                    id: "userName",
                    title: "User Name",
                    classStyle: {},
                  },
                  {
                    id: "date",
                    title: "Date",
                    classStyle: {},
                  },
                  {
                    id: "action",
                    title: "Action",
                    classStyle: {},
                  },
                ]}
                data={listData}
                loading={loading}
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}
export default IndusHdfcReport
