import React, { useEffect, useState } from "react";
import "./bulk.scss";
import Datepicker from "../../../component/common/Daterange/daterange";
import Button from "../../../component/common/Button/button";
import moment from "moment/moment";
import apiCall from "../../../utils/apiFunction/apiCall";
import { toast } from "react-toastify";
import {
  apiConstants,
  errMessage,
  getBankDetailsSingleAttributesIfsc,
  reportStatusAttributes,
  tableNamePaymentConstants,
  toastIds,
} from "../../../constants/Common/apiConstants";
import DynamicForm from "../../../component/common/DynamicForm/dynamicForm";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import { errorMessages } from "../../../constants/Common/errorConstants";
import {
  commonVariables,
  dateFormats,
} from "../../../constants/Common/commonConstants";
import downloadIcon from "../../../Images/download.svg";
import CustomTable from "../../../component/customtable/CustomTable";
import { useSelector } from "react-redux";

const Apistatusbulk = () => {
  const loginState = useSelector((state) => state.login);
  const [formValues, setFormValues] = useState([
    {
      type: "dateinput",
      value: "",
      label: "From Date",
      placeholder: "Select Date",
      required: true,
      width: 6,
    },
    {
      type: "dateinput",
      value: "",
      label: "To Date",
      placeholder: "Select Date",
      required: true,
      width: 6,
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [listData, setListData] = useState([]);
  let interval;

  const onSubmit = (data) => {
    if (data.length) {
      let tempData = [...data];
      tempData = Object.assign({}, ...tempData);
      if (tempData["To Date"] >= tempData["From Date"]) {
        setLoading(true);
        let apiBody = {
          FromDate:
            moment(tempData["From Date"]).format(dateFormats?.dmy) || "",
          ToDate: moment(tempData["To Date"]).format(dateFormats?.dmy) || "",
        };
        apiCall
          .get(
            `${apiConstants.ApipaymentApiStatusDownloadReports}?fromDate=${
              apiBody.FromDate
            }&toDate=${apiBody.ToDate}&user_name=${
              loginState?.decodedData?.user_name || ""
            }`
          )
          .then(async (res) => {
            if (res?.data?.status === 200) {
              tableAPI();
              if (res?.data?.response) {
                const link = document.createElement("a");
                link.href = res?.data?.response;
                link.click();
                toast.success(commonVariables?.FileDownloadSuccess, {
                  toastId: toastIds?.FileDownloadSucess,
                });
              } else {
                toast.success(
                  res?.data?.message || errorMessages.SOMETHING_WRONG,
                  {
                    toastId: toastIds?.SomethingWentWrong,
                  }
                );
              }
            } else {
              toast.error(res?.data?.message || errorMessages.SOMETHING_WRONG, {
                toastId: toastIds?.SomethingWentWrong,
              });
            }
          })
          .catch((er) => {
            let resp = er?.message || "";
            toast.error(resp || errorMessages.SOMETHING_WRONG, {
              toastId: toastIds?.SomethingWentWrong,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        toast.error(errMessage?.ToDateMustBeGreaterThanFromDate, {
          toastId: toastIds?.DataError,
        });
      }
    }
  };

  const downloadbtn = (data) => {
    return (
      <div>
        <button
          disabled={!data}
          onClick={() => fetchBlockFile(data)}
          className="downloadbtn pt-1 pe-2 pe-2 d-flex align-items-center justify-content-center"
        >
          <img src={downloadIcon} alt="download" />
          Download
        </button>
      </div>
    );
  };
  const fetchBlockFile = (key) => {
    apiCall
      .get(`${apiConstants?.downloadPresignedUrl}?key=${key}`)
      .then((response) => {
        if (response.status === 200) {
          const link = document.createElement("a");
          link.href = response?.data;
          link.click();
          toast.success(commonVariables?.FileDownloadSuccess, {
            toastId: toastIds?.FileDownloadSucess,
          });
        }
      })
      .catch((error) => {
        console.log("Error", error);
        return toast.error(errMessage?.InternalServerError, {
          toastId: toastIds?.InternalServerError,
        });
      });
  };
  const tableAPI = () => {
    setAutoRefresh(true)
    apiCall
      .post(apiConstants?.fetchAllData, {
        tablename: tableNamePaymentConstants?.reportStatus,
        whereQuery: {
          created_by: loginState?.decodedData?.user_name || "",
          module_type: "PAYMENT",
          sub_module_type: "PAY_API_Status_Bulk",
          metadata: {
            report_type: "API_Status_Bulk",
            reportName: "Bulk",
          },
        },
        attributes: reportStatusAttributes,
        order: [["id", "desc"]],
      })
      .then(async (res) => {
        if (res?.data?.status == 200) {
          let result = res?.data?.response;
          result = result.map((item, index) => {
            let newItem = {
              idData: index + 1,
              date: `${
                moment(item?.metadata?.StartDate, "YYYY-MM-DD").format(
                  "DD-MM-YYYY"
                ) || ""
              } to ${
                moment(item?.metadata?.EndDate, "YYYY-MM-DD").format(
                  "DD-MM-YYYY"
                ) || ""
              }`,
              moduletype: item?.module_type || "",
              submoduletype: item?.sub_module_type || "",
              rpname: item?.metadata?.reportName || "-",
              createdby: item?.created_by || "-",
              status: item?.status || "",
              action: downloadbtn(item?.s3_path),
            };
            return newItem;
          });
          let slice20 = result.slice(0,20)
          if (slice20?.length > 0) {
            setListData([...slice20]);
          }
        } else {
          setListData([]);
          toast.error(res?.data?.message, {
            toastId: toastIds?.DataError,
          });
        }
      })
      .catch((er) => {
        setListData([]);
        toast.error(er?.message, { toastId: toastIds?.DataError });
      }).finally(() => {
        setAutoRefresh(false)
      })
  };
  // useEffect(() => {
  //   if (autoRefresh) {
  //     // interval = setInterval(myFunction, 15000);
  //     tableAPI();
  //     interval = setInterval(() => {
  //       tableAPI();
  //     }, 15000);
  //   } else {
  //     clearInterval(interval);
  //   }

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [autoRefresh]);
  useEffect(() => {
    tableAPI();
  }, []);
  return (
    <div>
      {loading ? (
        <div className="loaderDiv">
          <SystemXLoader />
        </div>
      ) : (
        <>
          <p className="reversalStatus_reversalStatusTxt px-4 mt-4 pt-2">
            <b> API Status Bulk </b>
          </p>
          <div className="disbursementReport_inputContainer mx-4 p-4">
            <DynamicForm
              onSubmit={onSubmit}
              isDownload
              formValues={formValues}
            />
          </div>
        </>
      )}
      <div className="mx-4 pb-4">
        {listData.length > 0 && (
          <>
            <div className="pt-2 toggle-wrapper">
              <button
                onClick={() => tableAPI()}
                disabled={autoRefresh}
                className="customRefreshButton mt-1"
              >
                Refresh
              </button>
              {/* <div className="toggle-switch">
                <input
                  className="toggle-input"
                  id="toggle"
                  checked={autoRefresh}
                  type="checkbox"
                  onClick={() => setAutoRefresh(!autoRefresh)}
                />
                <label className="toggle-label" for="toggle"></label>
              </div>
              <label className="label-name">Auto Refresh</label> */}
            </div>
            <CustomTable
              cols={[
                {
                  id: "idData",
                  title: "ID",
                  classStyle: {},
                },
                {
                  id: "date",
                  title: "Date Range",
                  classStyle: {},
                },
                // {
                //   id: "moduletype",
                //   title: "Module Type",
                //   classStyle: {},
                // },
                // {
                //   id: "submoduletype",
                //   title: "Sub Module Type",
                //   classStyle: {},
                // },
                {
                  id: "rpname",
                  title: "Report Name",
                  classStyle: {},
                },
                {
                  id: "createdby",
                  title: "Created By",
                  classStyle: {},
                },
                {
                  id: "status",
                  title: "Status",
                  classStyle: {},
                },
                {
                  id: "action",
                  title: "Action",
                  classStyle: {},
                },
              ]}
              data={listData}
              loading={loading}
            />
          </>
        )}
      </div>
    </div>
  );
};
export default Apistatusbulk;
