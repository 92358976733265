import {Field, Form, Formik} from "formik"
import * as Yup from "yup"
import {commonVariables, componentRole} from "../../../constants/Common/commonConstants"
import Button from "../../common/Button/button"
import FormLabel from "../../common/formLabel/formLabel"
import "./changePassword.scss"
import {USER_LOGOUT} from "../../../redux/reducer/login"
import {useDispatch, useSelector} from "react-redux"
import {useNavigate} from "react-router-dom"
import apiCall from "../../../utils/apiFunction/apiCall"
import {
  apiConstants,
  tableNamePaymentConstants,
  toastIds,
} from "../../../constants/Common/apiConstants"
import {genSaltSync, hashSync} from "bcryptjs-react"
import {errorMessages} from "../../../constants/Common/errorConstants"
import {toast} from "react-toastify"
import {useRef} from "react"

const FormSchema = Yup.object({
  pass: Yup.string()
    .required("Password is Required")
    .min(8, "Password must be 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a symbol"),
  confirm: Yup.string()
    .oneOf([Yup.ref("pass"), null], 'Must match "password" field value')
    .required(),
})
const ChangePassword = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const loginState = useSelector((state) => state.login)

  const formRef = useRef(null)
  const handleSubmit = (value) => {
    let userData = loginState?.decodedData
    var salt = genSaltSync(10)
    if (value?.pass && value?.confirm && userData?.user_code) {
      console.log("value", value)
      let query = {
        body: {
          change_password: 1,
          password: hashSync(value?.pass, salt),
        },
        tablename: tableNamePaymentConstants?.User,
      }
      query.whereQuery = {
        user_code: userData?.user_code,
      }
      apiCall
        .post(apiConstants?.updateUser, query)
        .then(async (res) => {
          if (res?.data?.status === 200) {
            navigate("/index")
          } else {
            toast.error(res.data.message || errorMessages.SOMETHING_WRONG, {
              toastId: toastIds?.DataError,
            })
          }
        })
        .catch((err) => {
          toast.error(err || errorMessages.SOMETHING_WRONG, {
            toastId: toastIds?.DataError,
          })
        })
    }
  }
  return (
    <>
      <div className="changepwdwrapper">
        <div className="password-card">
          <Formik
            initialValues={{
              pass: "",
              confirm: "",
            }}
            innerRef={formRef}
            validationSchema={FormSchema}
            enableReinitialize={true}
            onSubmit={(value) => {
              handleSubmit(value)
            }}>
            {/* <div id="assignRole"> */}
            {({errors}) => (
              <Form>
                <div className="large_f px-4">
                  <div className="demergeLabel f-5 text-center w-100">
                    <h4>{commonVariables?.CHANGE_PASSWORD || ""}</h4>
                  </div>

                  <div className="data-container mt-4">
                    <div className="select-container medium_f_bold ">
                      {/* <FormLabel value="New Password" className=" usertext" />

                  <Field type="text" className="InputField" name="password" id="pasword"></Field>
                  <div className="errorMessage">
                    <ErrorMessage name="password" />
                  </div> */}
                      {/* <div>
                    <label>
                      Password
                      <Field type="password" name="pass" />
                    </label>
                    {errors.pass && <p>{errors.pass}</p>}
                  </div> */}

                      <div className="select-container medium_f_bold ">
                        <FormLabel value="Password" className="usertext" />

                        <Field
                          type="password"
                          name="pass"
                          className="InputField"
                          id="password"></Field>
                        <div className="errorMessage">{errors.pass && <p>{errors.pass}</p>}</div>
                      </div>

                      <div className="select-container medium_f_bold ">
                        <FormLabel value="Confirm Password" className="usertext" />
                        <Field
                          type="password"
                          name="confirm"
                          className="InputField"
                          id="confirmpassword"></Field>
                        <div className="errorMessage">
                          {errors.confirm && <p>{errors.confirm}</p>}
                        </div>
                      </div>
                      {/* <div>
                    <label>
                      Password Confirm
                      <Field type="password" name="confirm" />
                    </label>
                    {errors.confirm && <p>{errors.confirm}</p>}
                  </div> */}
                    </div>

                    <div className="d-flex mt-3">
                      <div className="cancel_btn mr-2">
                        <Button
                          role="secondary"
                          value="Cancel"
                          // disabled={isSubmitting}
                          onClick={() => {
                            dispatch(USER_LOGOUT())
                            navigate("/")
                          }}
                        />
                      </div>

                      <div className="submitButton">
                        <Button role="primary" value="Submit" type="submit" onClick={() => {}} />
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
            {/* </div> */}
          </Formik>
        </div>
      </div>
    </>
  )
}

export default ChangePassword
