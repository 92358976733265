import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../createnewSponsorPaymentBank/createnewSponsorPaymentBank.scss";
import Button from "../../../../../common/Button/button";
import FormLabel from "../../../../../common/formLabel/formLabel";
import Dropdown from "../../../../../common/Dropdown/dropdown";
import FormikSelect from "../../../../../common/formikSelect/formikSelect";
import { ROUTE_PATH } from "../../../../../../constants/Routes";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import apiCall from "../../../../../../utils/apiFunction/apiCall";
import {
  apiConstants,
  FOMRS,
  STATUS, tableNamePaymentConstants, errMessage, successMsg, toastIds
} from "../../../../../../constants/Common/apiConstants";
import { YupMessages } from "../../../../../../constants/Common/commonConstants";
import { toast } from 'react-toastify';


const validationSchema = Yup.object({
  client_code_api: Yup.string().required(YupMessages?.ClientCodeRequired)
    .matches(/^[^ ]/g, '* This field cannot contain only blankspaces').matches(/^[^!@#$%^&<>*()]+$/, '* No Special Characters or Script Tags'),

  bank_code: Yup.string().required(YupMessages?.BankCodeRequired)
    .matches(/^[^ ]/g, '* This field cannot contain only blankspaces').matches(/^[^!@#$%^&<>*()]+$/, '* No Special Characters or Script Tags'),
  bank_name: Yup.string().required(YupMessages?.BankNameIsRequired)
    .matches(/^(?!.*<script>).*$/, "Bank Name cannot contain scripts or HTML tags")
    .matches(/^[^ ]/g, '* This field cannot contain only blankspaces').matches(/^[^!@#$%^&<>*()]+$/, '* No Special Characters or Script Tags').matches(/^[^0-9]*$/, '* No Numbers Allowed'),

  bank_GL_code: Yup.string().required(YupMessages?.BankGlCodeRequired)
    .matches(/^(?!.*<script>).*$/, "Bank GL code api cannot contain scripts or HTML tags")
    .matches(/^(?!.*<\/?[a-z][\s\S]*>).*$/, "Bank GL code api cannot contain scripts or HTML tags")
    .test('no-spaces', 'Bank GL code api cannot be only spaces', (value) => value.trim() !== ''),

  bank_account_number: Yup.string().required(
    YupMessages?.BankAccountNoRequired)
    .matches(/^[0-9]+$/, "Bank account number must be a numeric value")
    .test('no-spaces', 'Bank account number cannot be only spaces', (value) => value.trim() !== ''),

  payment_max_cap: Yup.string().required(YupMessages?.PaymentMaxCapRequired)
    .matches(/^[^ ]/g, '* This field cannot contain only blankspaces').matches(/^[^!@#$%^&<>*()]+$/, '* No Special Characters or Script Tags'),

  payment_type: Yup.string().required(YupMessages?.paymentModeCodeRequired),
  product: Yup.string().required(YupMessages?.ProductRequired),
  sub_product: Yup.string(),
  system: Yup.string().required(YupMessages?.SystemRequired),
  company_name: Yup.string().required(YupMessages?.CompanyNameRequired),
  user_id_api: Yup.string().required(YupMessages?.UserIDRequired)
    .matches(/^[A-Za-z0-9]+$/, "User Id must contain only letters and numbers")
    .test('no-spaces', 'User Id  cannot be only spaces', (value) => value.trim() !== ''),

});

const CreatenewSponsorPaymentBank = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const formType = state?.type || FOMRS.ADD;
  const headingText =
    formType === FOMRS.ADD ? "Create Sponsor Payment Bank" : "Update Sponsor Payment Bank";

  const [formData, setFormData] = useState({
    client_code_api: "",
    bank_code: "",
    bank_name: "",
    bank_GL_code: "",
    bank_account_number: "",
    payment_max_cap: "",
    payment_type: "",
    payment_mode: "",
    product: "",
    sub_product: "",
    system: "",
    company_name: "",
    user_id_api: "",
  });

  const [loading, setLoading] = useState(false);
  const [subproducts, setSubproducts] = useState(false);

  const paymentType = [
    { key: "Please select a Value", value: "" },
    { key: "electronic", value: "electronic" },
    { key: "DD/Cheque", value: "DD/Cheque" }
  ];

  const [payMode, setPayMod] = useState([])

  const [product, setproduct] = useState([
    { key: "Please select a Value", value: "" },
  ]);

  const [subproduct, setsubproduct] = useState([]);

  const [system, setsystem] = useState([
    { key: "Please select a Value", value: "" },
  ]);

  const [company, setCompany] = useState([
    { key: "Please select a Value", value: "" },
  ]);

  const [paymentMode, setPaymentMode] = useState(false)
  const HandlePaymentType = (value) => {
    if (FOMRS.EDIT) {
      const data = state?.user_type?.payment_mode.split(',')
      // console.log(data);
      setPayMod(data)
    }
    if (value === 'electronic') {
      setPaymentMode(true)
    } else {
      setPaymentMode(false)
    }
  };

  const Product = () => {
    let paramObj = {
      offset: 0,
      tablename: "product",
    };
    apiCall
      .post(apiConstants?.findData, paramObj)
      .then(async (res) => {
        let result = res?.data?.response;
        result = result.map((item) => {
          let newItem = {
            key: item?.product_name,
            value: item?.product_code,
          };
          return newItem;
        });
        setproduct([...product, ...result]);
      })
      .catch((er) => {
        console.log("errr =>>>>", er);
      });
  };

  const Subproduct = (value) => {
    // console.log(value, '<<<<<');
    let paramObj = {
      offset: 0,
      tablename: "subProduct",
      whereQuery: {
        product_code: value,
      },
    };
    apiCall
      .post(apiConstants?.findData, paramObj)
      .then(async (res) => {
        let result = res?.data?.response;
        result = result?.map((item) => {
          let newItem = {
            key: item?.subProduct_name,
            value: item?.subProduct_code,
          };
          return newItem;
        });
        result ? setSubproducts(true) : setSubproducts(false)
        setsubproduct([{ key: "Please select a Value", value: "" }, ...result]);
      })
      .catch((er) => {
        toast.error('No Sub Product')
      });
  };

  const System = () => {
    let paramObj = {
      offset: 0,
      tablename: "system",
    };
    apiCall
      .post(apiConstants?.findData, paramObj)
      .then(async (res) => {
        let result = res?.data?.response;
        result = result.map((item) => {
          let newItem = {
            key: item?.system_name,
            value: item?.system_code,
          };
          return newItem;
        });
        setsystem([...system, ...result]);
      })
      .catch((er) => {
        console.log("errr =>>>>", er);
      });
  };

  const Company = () => {
    let paramObj = {
      offset: 0,
      tablename: "company",
    };
    apiCall
      .post(apiConstants?.findData, paramObj)
      .then(async (res) => {
        let result = res?.data?.response;
        result = result.map((item) => {
          let newItem = {
            key: item?.company_name,
            value: item?.company_code,
          };
          return newItem;
        });
        setCompany([...company, ...result]);
      })
      .catch((er) => {
        console.log("errr =>>>>", er);
      });
  };
  const FormSubmit = (values) => {
    const paymentModes = values.payment_mode
    const query = {
      body: {
        client_code_api: values.client_code_api,
        bank_code: values.bank_code,
        bank_name: values.bank_name,
        bank_GL_code: values.bank_GL_code,
        bank_account_number: values.bank_account_number,
        payment_max_cap: values.payment_max_cap,
        payment_type: values.payment_type,
        payment_mode: formType === FOMRS.EDIT ? `${payMode}` : `${paymentModes}`,
        product: values.product,
        sub_product: values.sub_product,
        system: values.system,
        company_name: values.company_name,
        user_id_api: values.user_id_api,
      },
      tablename: tableNamePaymentConstants?.sponsorBankPaymentTypeMaster,
    };


    if (formType === FOMRS.EDIT) {
      query.whereQuery = {
        id: state?.user_type.id,
      };
      apiCall
        .post(apiConstants?.updateUser, query)
        .then((res) => {
          if (res?.status === STATUS.SUCCESS) {
            navigate(ROUTE_PATH.SPONSOR_PAYMENT_BANK);
            toast.success(successMsg?.SourcePaymentUpdatedSuccessfully, toastIds?.FileUploadSuccess)
          }
        })
        .catch((error) => {
          console.error("Error updating record:", error);
        });
    }

    if (formType === FOMRS.ADD) {
      apiCall
        .post(apiConstants?.createUser, query)
        .then((res) => {
          if (res?.status === STATUS.SUCCESS) {
            navigate(ROUTE_PATH.SPONSOR_PAYMENT_BANK);
            toast.success(successMsg?.SourcePaymentCretaedSuccessfully, toastIds?.FileUploadSuccess)
          }
        })
        .catch((error) => {
          console.error("Error creating record:", error);
        });
    }
  };

  const handleCheckBox = (value) => {
    if (formType === FOMRS.EDIT) {
      if (payMode?.includes(value)) {
        const index = payMode.indexOf(value)
        if (index !== -1) {
          payMode.splice(index, 1)
        }
      } else {
        payMode.push(value)
      }
    }
  }

  const getFormData = async (id) => {
    let bodyParams = {
      tablename: tableNamePaymentConstants?.sponsorBankPaymentTypeMaster,
      whereQuery: {
        id,
      },
    };
  };

  useEffect(() => {
    if (formType === FOMRS.EDIT) {
      getFormData(state?.id);
    }
    // PaymentType();
    Product();
    // Subproduct();
    System();
    Company();
  }, []);

  useEffect(() => {
    if (formType === FOMRS.EDIT) {
      state?.user_type?.payment_type === 'electronic' ? HandlePaymentType(state?.user_type?.payment_type) : setPaymentMode(false)
      Subproduct(state?.user_type?.product);
      setFormData({
        client_code_api: state?.user_type?.client_code_api,
        bank_code: state?.user_type?.bank_code,
        bank_name: state?.user_type?.bank_name,
        bank_GL_code: state?.user_type?.bank_GL_code,
        bank_account_number: state?.user_type?.bank_account_number,
        payment_max_cap: state?.user_type?.payment_max_cap,
        payment_type: state?.user_type?.payment_type,
        payment_mode: state?.user_type?.payment_mode,
        product: state?.user_type?.product,
        sub_product: state?.user_type?.sub_product,
        system: state?.user_type?.system,
        company_name: state.user_type?.company_name,
        user_id_api: state?.user_type?.user_id_api,
      });

      // console.log('---->>', formData);
    }
  }, [loading]);

  return (
    <Formik
      initialValues={{
        client_code_api: formData?.client_code_api,
        bank_code: formData?.bank_code,
        bank_name: formData?.bank_name,
        bank_GL_code: formData?.bank_GL_code,
        bank_account_number: formData?.bank_account_number,
        payment_max_cap: formData?.payment_max_cap,
        payment_type: formData?.payment_type,
        payment_mode: formData?.payment_mode,
        product: formData?.product,
        sub_product: formData?.sub_product,
        system: formData?.system,
        company_name: formData?.company_name,
        user_id_api: formData?.user_id_api,
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={(values) => {
        FormSubmit(values);
      }}
    >
      {({ values, setFieldValue }) => (
        <>
          <div id="createnewSponsor">
            <div
              className="col blueFonts large_f"
              onClick={() => navigate(ROUTE_PATH.SPONSOR_PAYMENT_BANK)}
            >
              {`Sponsor Payment Bank>> `}
              <span className="blackFonts">{headingText}</span>
            </div>
            <div className="main-div">
              <Form className=".labler-wrapper">
                <div id="RegularUserForm">
                  <div className="createroletext">
                    <p id="pText">{headingText}</p>
                  </div>

                  <div className="row paddingTop ml-4 mr-4">
                    <div className="col-md-12 col-lg-6 medium_f_bold directionColumn">
                      <div>
                        <FormLabel
                          value="Client Code API"
                          className="required"
                        />
                      </div>
                      <Field
                        type="text"
                        className="InputField"
                        name="client_code_api"
                        id="client_code_api"
                        placeholder="Client Code"
                      ></Field>
                      <div className="errorMessage">
                        <ErrorMessage name="client_code_api" />
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6 medium_f_bold directionColumn">
                      <FormLabel
                        value="Bank/Client Code"
                        className="required"
                      />
                      <Field
                        type="text"
                        className="InputField"
                        name="bank_code"
                        id="bank_code"
                        placeholder="Bank Code"
                      ></Field>{" "}
                      <div className="errorMessage">
                        <ErrorMessage name="bank_code" />
                      </div>
                    </div>
                  </div>

                  <div className="row paddingTop ml-4 mr-4">
                    <div className="col-md-12 col-lg-6 medium_f_bold directionColumn">
                      <FormLabel value="Bank Name" className="required" />
                      {/* <Input /> */}
                      <Field
                        type="text"
                        className="InputField"
                        name="bank_name"
                        id="bank_name"
                        placeholder="Bank Name"
                      ></Field>
                      <div className="errorMessage">
                        <ErrorMessage name="bank_name" />
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6 medium_f_bold directionColumn">
                      <FormLabel value="Bank GL Code" className="required" />
                      <Field
                        type="text"
                        className="InputField"
                        name="bank_GL_code"
                        id="bank_GL_code"
                        placeholder="Bank GL Code"
                      ></Field>{" "}
                      <div className="errorMessage">
                        <ErrorMessage name="bank_GL_code" />
                      </div>
                    </div>
                  </div>

                  <div className="row paddingTop ml-4 mr-4">
                    <div className="col-md-12 col-lg-6 medium_f_bold directionColumn">
                      <FormLabel
                        value="Bank Account Number"
                        className="required"
                      />
                      <Field
                        type="text"
                        className="InputField"
                        name="bank_account_number"
                        id="bank_account_number"
                        placeholder="Bank Account Number"
                      ></Field>{" "}
                      <div className="errorMessage">
                        <ErrorMessage name="bank_account_number" />
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-6 medium_f_bold directionColumn">
                      <FormLabel value="Payment Max Cap" className="required" />
                      <Field
                        type="text"
                        className="InputField"
                        name="payment_max_cap"
                        id="payment_max_cap"
                        placeholder="Payment Max Cap"
                      ></Field>{" "}
                      <div className="errorMessage">
                        <ErrorMessage name="payment_max_cap" />
                      </div>
                    </div>
                  </div>
                  <div className="row paddingTop ml-4 mr-4">
                    <div className="col-md-12 col-lg-6 medium_f_bold directionColumn ">
                      <div className="formState">
                        <FormLabel
                          value="Payment Type & Mode"
                          className="required"
                        />
                        <FormikSelect
                          label="Please select a value"
                          name="payment_type"
                          options={paymentType}
                          onClick={(e) => {
                            HandlePaymentType(e.target.value)
                          }}
                        // value={values.payment_mode}
                        />
                      </div>
                      <div className="errorMessage">
                        <ErrorMessage name="payment_type" />
                      </div>
                    </div>
                    {paymentMode && <div className="col-md-12 col-lg-6 medium_f_bold directionColumn">
                      <FormLabel
                        value="Payment Mode"
                        className="required medium_f_bold"
                      />
                      <div className="form-check-inline medium_f_bold">
                        <label className="form-check-label">
                          <Field
                            type="checkbox"
                            name="payment_mode"
                            value="RTGS"
                            checked={FOMRS.EDIT && payMode?.includes('RTGS')}
                            onClick={(e) => {
                              handleCheckBox(e.target.value);
                            }}
                            className="form-check-input mt-1"
                          />
                          RTGS
                        </label>
                        <label className="form-check-label">
                          <Field
                            type="checkbox"
                            name="payment_mode"
                            value="NEFT"
                            checked={FOMRS.EDIT && payMode?.includes('NEFT')}
                            onClick={(e) => {
                              handleCheckBox(e.target.value);
                            }}
                            className="form-check-input mt-1"
                          />
                          NEFT
                        </label>
                        <label className="form-check-label">
                          <Field
                            type="checkbox"
                            name="payment_mode"
                            value="FT"
                            checked={FOMRS.EDIT && payMode?.includes('FT')}
                            onClick={(e) => {
                              handleCheckBox(e.target.value);
                            }}
                            className="form-check-input mt-1"
                          />
                          Fund transfer
                        </label>
                      </div>
                      <div className="errorMessage">
                        <ErrorMessage name="payment_mode" />
                      </div>
                    </div>}
                    <div className="col-md-12 col-lg-6 medium_f_bold directionColumn ">
                      <div className="formState">
                        <FormLabel value="Product" className="required" />
                        <FormikSelect
                          label="Please select a value"
                          name="product"
                          options={product}
                          onClick={(e) => {
                            Subproduct(e.target.value)
                            setFieldValue('product', e.target.value)
                          }}
                        />
                      </div>
                      <div className="errorMessage">
                        <ErrorMessage name="product" />
                      </div>
                    </div>

                  </div>

                  <div className="row paddingTop ml-4 mr-4">
                    {subproducts && (<div className="col-md-12 col-lg-6 medium_f_bold directionColumn ">
                      <div className="formState">
                        <FormLabel value="Sub product" className="required" />
                        <FormikSelect
                          label="Please select a value"
                          name="sub_product"
                          id='sub_product'
                          options={subproduct}
                        />
                      </div>
                      <div className="errorMessage">
                        <ErrorMessage name="sub_product" />
                      </div>
                    </div>)}
                    <div className="col-md-12 col-lg-6 medium_f_bold directionColumn ">
                      <div className="formState">
                        <FormLabel value="System" className="required" />
                        <FormikSelect
                          label="Please select a value"
                          name="system"
                          options={system}
                        />
                      </div>
                      <div className="errorMessage">
                        <ErrorMessage name="system" />
                      </div>
                    </div>
                  </div>

                  <div className="row paddingTop ml-4 mr-4">
                    <div className="col-md-12 col-lg-6 medium_f_bold directionColumn ">
                      <div className="formState">
                        <FormLabel value="Company Name" className="required" />
                        <FormikSelect
                          label="Please select a value"
                          name="company_name"
                          options={company}
                        />
                      </div>
                      <div className="errorMessage">
                        <ErrorMessage name="company_name" />
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6 medium_f_bold directionColumn">
                      <FormLabel value="User ID" className="required" />
                      <Field
                        type="text"
                        className="InputField"
                        name="user_id_api"
                        id="user_id_api"
                        placeholder="User ID"
                      ></Field>{" "}
                      <div className="errorMessage">
                        <ErrorMessage name="user_id_api" />
                      </div>
                    </div>
                  </div>

                  <div className=" d-flex py-4">
                    <div className="cancel_btn">
                      <Button
                        role="secondary"
                        value="Cancel"
                        type="button"
                        onClick={() =>
                          navigate(ROUTE_PATH.SPONSOR_PAYMENT_BANK)
                        }
                      />
                    </div>

                    <div className="submitButton">
                      <Button
                        role="primary"
                        value="Submit"
                        type="submit"
                        onClick={() => { }}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

export default CreatenewSponsorPaymentBank;
