import CustomTable from "../../customtable/CustomTable";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../frequency/Frequency.scss";
import { ROUTE_PATH } from "../../../constants/Routes";
import apiCall from "../../../utils/apiFunction/apiCall";
import {
  apiConstants,
  FOMRS,
  successMsg,
  tableNamePaymentConstants,
} from "../../../constants/Common/apiConstants";
import {
  commonVariables,
  tableID,
} from "../../../constants/Common/commonConstants";
import Button from "../../common/Button/button";
import SystemXLoader from "../../common/Loader/SystemXLoader";
import { toast } from "react-toastify";
import DeleteConfirmation from "../../deleteModal/deleteModal";

function Frequency() {
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [listdata, setListdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataloader, setDataloader] = useState(false);
  const [datacount, setDatacount] = useState(0);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState({});

  const deleteFunc = () => {
    const query = {
      tablename: tableNamePaymentConstants?.Frequency,
      whereQuery: deleteItemId
    }
    apiCall.post(apiConstants?.deleteRecord, query).then((res) => {
      if (res?.data?.status === 200) {
        toast.success(successMsg.Deleted)
        navigate(ROUTE_PATH?.FREQUENCY)
      } else {
        toast.error(res?.data?.message)
      }
    })
  }

  const listApi = (value) => {
    let temp = 0;
    if ((value || pageNumber) > 0) {
      temp = value ? (value - 1) * 10 : (pageNumber - 1) * 10;
    }
    setDataloader(true);
    setLoading(true);
    apiCall
      .post(apiConstants?.fetchAllData, {
        // request body
        offset: temp || 0,
        limit: 10,
        tablename: tableNamePaymentConstants?.Frequency,
        month: 0,
      })
      .then(async (res) => {
        if (res?.status === 200) {
          let result = res?.data?.response;
          setDatacount(res?.data?.count || 0);
          result = result.map((item, index) => {
            let newItem = {
              no: temp + index + 1,
              frequency: item?.frequency_code ? item.frequency_code : "-",
              name: item?.frequency_name ? item.frequency_name : "-",
              bancs_frequency_code: item?.bancs_frequency_code
                ? item.bancs_frequency_code
                : "-",
              isActive: item?.isActive
                ? item?.isActive === 1
                  ? "Yes"
                  : "No"
                : "No",

              action: (
                <div>
                  <img
                    className="icon"
                    src="/assests/images/pencil.svg"
                    alt=""
                    onClick={() => {
                      navigate(ROUTE_PATH.CREATE_FREQUENCY, {
                        state: {
                          type: FOMRS.EDIT,
                          user_type: item,
                        },
                      });
                    }}
                  />
                  <img
                    className="delete_icon"
                    src="/assests/images/trash-bin.svg"
                    onClick={() => {
                      setDeleteItemId({
                        frequency_code: item?.frequency_code,
                        frequency_name: item?.frequency_name,
                        bancs_frequency_code: item?.bancs_frequency_code,
                        isActive: item?.isActive,
                      })
                      setShowDeleteConfirmationModal(true)
                    }}
                    alt=""
                  />
                </div>
              ),
            };
            return newItem;
          });
          if (result?.length > 0) {
            setListdata([...result]);
          }
        }
      })
      .catch((er) => {
        console.log("err", er);
      })
      .finally(() => {
        setLoading(false);
        setDataloader(false);
      });
  };

  const changePage = (value) => {
    listApi(value);
    setPageNumber(value - 1);
  };
  useEffect(() => {
    listApi();
  }, []);
  return (
    <>
      <div id="Frequency">
        {/* <div className="alignHeader">
					<div className="larger_f_bold blueFont setMargin">Frequency</div>
					<div className="setMargin">
						{' '}
						<Button
							value={'Create Frequency'}
							role="primary"
							onClick={() => {
								navigate(ROUTE_PATH?.CREATE_FREQUENCY, {
									state: {
										type: FOMRS.ADD,
									},
								});
							}}
						/>
					</div>
				</div> */}
        <div className="d-flex flex-wrap align-items-center justify-content-between mt-4 mb-4 mx-3">
          <div className="right-div-header">
            <div>
              <label className="labelPresentation">Frequency</label>
            </div>
          </div>
          <div className="d-flex gap-3 left-div-header">
            <div>
              <DeleteConfirmation
                showModal={showDeleteConfirmationModal}
                hideModal={() => setShowDeleteConfirmationModal(false)}
                confirmModal={deleteFunc}
                id={deleteItemId}
                type={FOMRS.DELETE}
                message="Are you sure you want to delete this item?"
              />
              <Button
                value={"Create Frequency"}
                role="primary"
                onClick={() => {
                  navigate(ROUTE_PATH?.CREATE_FREQUENCY, {
                    state: {
                      type: FOMRS.ADD,
                    },
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="col dtMergeTable">
          {dataloader && (
            <div className="loaderDiv">
              <SystemXLoader />
            </div>
          )}
          <CustomTable
            cols={[
              {
                id: tableID?.NO || "",
                title: commonVariables?.NO || "",
              },
              {
                id: tableID?.FREQUENCY || "",
                title: commonVariables?.FREQUENCY || "",
              },
              {
                id: tableID?.NAME || "",
                title: commonVariables?.NAME || "",
              },
              {
                id: tableID?.BANCS_FREQUENCY_CODE || "",
                title: commonVariables?.BANCS_FREQUENCY_CODE || "",
              },
              {
                id: tableID?.IS_ACTIVE || "",
                title: commonVariables?.IS_ACTIVE || "",
              },

              {
                id: tableID?.ACTION || "",
                title: commonVariables?.ACTION || "",
              },
            ]}
            data={listdata}
            count={datacount}
            setPagination={(page) => changePage(page)}
            activePage={pageNumber}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
}

export default Frequency;
