import React, { useEffect, useState } from "react";
import Label from "../../../component/label/label";
import CustomTable from "../../../component/customtable/CustomTable";
import downloadArrow from "../../../Images/download_arrow.svg";
import "./Batch.scss";
import moment from "moment";
import downloadIcon from "../../../Images/download.svg";
import apiCall from "../../../utils/apiFunction/apiCall";
import {
  attributePaymentConstants,
  apiConstants,
  errMessage,
  paymentBatchTableAttributes,
  paymentBatchTableOrder,
  reportConstants,
  tableNamePaymentConstants,
  toastIds,
} from "../../../constants/Common/apiConstants";
import { toast } from "react-toastify";
import { commonVariables, dateFormats,paymentDropdownValues } from "../../../constants/Common/commonConstants";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import DynamicForm from "../../../component/common/DynamicForm/dynamicForm";
import { errorMessages } from "../../../constants/Common/errorConstants";
const FileBatch = () => {
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [apilogs, setApilogs] = useState([]);
  const [optionsValue,setOptionsValue] = useState([])
  const [formValues, setFormValues] = useState([
    {
      type: "dateinput",
      value: "",
      label: "From Date",
      placeholder: "Select Date",
      required: true,
      width: 6,
    },
    {
      type: "dateinput",
      value: "",
      label: "To Date",
      placeholder: "Select Date",
      required: true,
      width: 6,
    },
    {
      type: "dropdown",
      value: "",
      label: "Bank Name",
      placeholder: "Please Select a value",
      required: false,
      width: 6,

  }
  ]);

  useEffect(() => {
    getSponsorPayment();
    localStorage.removeItem(paymentDropdownValues);
  }, []);
 
  useEffect(() => {
    settingFormValues();
  }, [
    optionsValue,
  ]);

  const settingFormValues = () =>{
  		  
    setFormValues([
      {
        type: "dateinput",
        value: "",
        label: "From Date",
        placeholder: "Select Date",
        required: true,
        width: 6,
      },
      {
        type: "dateinput",
        value: "",
        label: "To Date",
        placeholder: "Select Date",
        required: true,
        width: 6,
      },
      {
        type: "dropdown",
          value: "",
          label: "Bank Name",
          placeholder: "Please Select a value",
          required: true,
          width: 6,
          options: optionsValue
 
      }
    ])
 
  }


  const downloadbtn = (batchId, fileName) => {
    return (
      <div>
        <button
          onClick={() => onDownload(batchId, fileName)}
          className="downloadbtn pt-1 pe-2 pe-2 d-flex justify-content-center align-items-center"
        >
          <img src={downloadIcon} alt="download" />
          Download
        </button>
      </div>
    );
  };

  const onDownload = (batchId, fileName) => {
    setLoading(true);
    apiCall
      .get(
        `${reportConstants.downloadBatchReport}reversal_batch_id=${batchId}&file_name=${fileName}`
      )
      .then(async (res) => {
        if (res?.status === 200) {
          setLoading(false);
          let result = res?.data;
          if (result) {
            if (typeof res?.data?.response == "string") {
              const link = document.createElement("a");
              link.href = res?.data?.response;
              link.click();
              toast.success(commonVariables?.FileDownloadSuccess, { toastId: toastIds?.FileDownloadSucess })
            } else {
              toast.error(res?.data?.message || errMessage?.noDataFound, { toastId: toastIds?.DataError });
            }
          }
        } else {
          setLoading(false);
          toast.error(res?.data?.message || errMessage?.noDataFound, { toastId: toastIds?.DataError });
        }
      })
      .catch((er) => {
        setLoading(false);
        toast.error(er?.message || errMessage?.somethingWentWrong, {
          toastId: toastIds?.DataError,
        });
      });
  };

  const listApi = (data) => {
   
    setLoading(true);
    let bodyParams = {
      tablename: tableNamePaymentConstants?.paymentResponseStatus,
      attributes: paymentBatchTableAttributes,
      whereQuery: {
        upload_status: commonVariables?.DOWNLOAD,
      },
      order: paymentBatchTableOrder,
    };
    if (data?.['From Date'] && data?.['To Date']) {
      bodyParams.whereQuery.createdAt = {
        "$gte": `${moment(data['From Date']).format('YYYY-MM-DD')} 00:00:00`,
        "$lte": `${moment(data['To Date']).format('YYYY-MM-DD')} 23:59:59`,
        "bank_name": data['Bank Name']
      }
    }
    apiCall
      .post(apiConstants.findData, bodyParams)
      .then(async (res) => {
        if (res.status === 200) {
          let result = res?.data?.response;
          result = result?.map((item) => {
            let newItem = {
              batchID: item?.batch_id,
              dateTime: moment(item?.createdAt).format(dateFormats?.dmyHms),
              file: item?.file_name,
              user: item?.user_name,
              total: item?.total_count,
              failed: item?.reject_count,
              success: item?.sucess_count,
              action: downloadbtn(item?.batch_id, item?.file_name),
            };
            return newItem;
          });
          if (result?.length > 0) {
            setApilogs([...result]);
          }
          else{
            setApilogs([]);

          }
        }
      })
      .catch((er) => {
        setApilogs([]);
      
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmit = (data) => {

    if (data.length) {
      let tempData = [...data];
      tempData = Object.assign({}, ...tempData);
      const ToDateGreaterThanFromDate =
        tempData["To Date"] >= tempData["From Date"];

      if (!ToDateGreaterThanFromDate) {
        toast.error(errorMessages.toDateMustBeGreaterThanToDate, {
          toastId: toastIds?.DataError,
        });
        
      } else {
        listApi(tempData);
      }
    }
    else {
    }
  }

  useEffect(() => {
    listApi();
  }, []);

  const changePage = (value) => {
    listApi(value);
    setPageNumber(value - 1);
  };


  const getSponsorPayment = () => {
    setLoading(true);
    let paramObj = {
      // offset: 0,
      // limit: 10,
      tablename: tableNamePaymentConstants?.sponsorBankPaymentTypeMaster,
      attributes: [attributePaymentConstants?.sponsorBankPaymentTypeMaster],
    };
    apiCall
      .post(apiConstants?.findData, paramObj)
      .then(async (res) => {
        let result = res?.data?.response;
        let resultData = result.filter( item => item.bank_name === "HDFC DIGI CFAB" || item.bank_name === "ICICI BANK");
        resultData = resultData.map((item) => {
          let newItem = {
            value: item.bank_name,
            label: item.bank_name,
          };
          return newItem;
        });
        setOptionsValue(resultData);
      })
      .catch((er) => {
        console.log("errr =>>>>", er);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="ml-3 mt-4 fileBasedBatch">
        <div className="row">
          <div className="col-md-6">
            <p className="reversalStatus_reversalStatusTxt mt-2">
              <b>Reversal Status - File Based - Batch</b>
            </p>
          </div>
        </div>

        <div className="disbursementReport_inputContainer mx-4 p-4">
          <DynamicForm
            onSubmit={onSubmit}
            formValues={formValues}
          />
        </div>
        {loading ? (
          <div className="loaderDivTable">
            <SystemXLoader />
          </div>
        ) :
          <div className="mx-4 fileBasedBatch_api-batch-table">
            <CustomTable
              cols={[
                {
                  id: "batchID",
                  title: "Batch ID",
                  classStyle: {},
                },
                {
                  id: "dateTime",
                  title: "Date & Time",
                  classStyle: {},
                },
                {
                  id: "file",
                  title: "File",
                  classStyle: {},
                },
                {
                  id: "total",
                  title: "Total",
                  classStyle: {},
                },
                {
                  id: "failed",
                  title: "failed",
                  classStyle: {},
                },
                {
                  id: "success",
                  title: "Success",
                  classStyle: {},
                },
                {
                  id: "user",
                  title: "User",
                  classStyle: {},
                },
                {
                  id: "action",
                  title: "Download",
                  classStyle: {},
                },
              ]}
              data={apilogs}
              count={10}
              setPagination={(page) => changePage(page)}
              activePage={pageNumber}
            />
          </div>
        }
      </div>
    </>
  );
};
export default FileBatch;
