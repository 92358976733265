import React, { useState, useEffect } from "react";
import CustomTable from "../../../component/customtable/CustomTable";
import Button from "../../../component/common/Button/button";
import apiCall from "../../../utils/apiFunction/apiCall";
import "./BilluploadStatus.scss";
import "../../../styles/layout/common.scss";
import {
  commonVariables,
  componentRole,
  selectoptions,
  tableID,
  dateFormats
} from "../../../constants/Common/commonConstants";
import BillUploadForm from "./BillUploadForm/BillUploadForm";
import { useDispatch } from "react-redux";
import { showBar } from "../../../redux/reducer/sideBarStatus";
import SideBar from "../../../component/sideBar";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import { apiConstants, toastIds,ModuleName } from "../../../constants/Common/apiConstants";
import { toast } from "react-toastify";
import moment from "moment";

const BillUploadStatus = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [count, setCount] = useState(0);
  const [selectMonth, setSelectMonth] = useState(1);
  const [listdata, setListdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [downloadFlag, setDownloadFlag] = useState(false);
  const dispatch = useDispatch();
  const listApi = (value) => {
    let temp = 0;
    if ((value || pageNumber) > 0) {
      temp = value ? (value - 1) * 10 : (pageNumber - 1) * 10;
    }
    const params = {
      tablename: "debit_tran_reg_file_upload_status",
      month: selectMonth,
      offset: temp,
      limit: 10,
      order: [["id", "desc"]],
      whereQuery: {
        module_name: ModuleName?.BILL_UPLOAD,
      },
    };
    setDownloadFlag(true);
    setLoading(true);
    apiCall
      .post(apiConstants?.fetchAllData, params)
      .then(async (res) => {
        if (res.status === 200) {
          let result = res?.data?.response;
          setCount(res?.data?.count || 0);
          result = result.map((item, i) => {
            let newresult = {
              batch_id: item?.batch_id ? item.batch_id : "-",
              user_name: item?.user_name ? item.user_name : "-",
              file_name: item?.file_name ? item.file_name : "-",
              upload_status: item?.upload_status ? item.upload_status : "-",
              total_count: item?.total_count ? item.total_count : "-",
              upload_date_time: item?.upload_date_time
                ? moment(item?.upload_date_time).format(dateFormats.dateFormatInMDYWithTimeAndAm)
                : "-",
              succuss_count: item?.succuss_count ? item.succuss_count : "-",
              rejected_count:
                item?.upload_status === "Success" &&
                item?.rejected_count !== "0"
                  ? addDownloadIcon(item?.rejected_count, item?.batch_id)
                  : item?.rejected_count
                  ? item?.rejected_count
                  : "-",
              pending_count: item?.pending_count ? item?.pending_count : "-",
            };
            return newresult;
          });
          if (result?.length > 0) {
            setListdata([...result]);
          }
        }
      })
      .catch((er) => {
        console.log("err", er);
      })
      .finally(() => {
        setDownloadFlag(false);
        setLoading(false);
      });
  };

  const addDownloadIcon = (rejected_data, batch_id) => {
    return (
      <>
        <div className="d-flex justify-content-between">
          <span className="text-primary">{rejected_data}</span>
          <Button
            onClick={() => {
              downloadRejectedFile(batch_id);
            }}
            role={componentRole?.TRANSPARENT_DOWNLOAD || ""}
          />
        </div>
      </>
    );
  };

  const downloadRejectedFile = (batch_id) => {
    setDownloadFlag(true);
    apiCall
      .get(
        `${apiConstants.DebitTransactionPicklistDonloadPicklistReject}?batch_id=${batch_id}`
      )
      .then(async (res) => {
        if (res.status === 200) {
          const presignUrl = res.data.response;
          if (res?.data?.response) {
            const link = document.createElement("a");
            link.href = presignUrl;
            link.click();
            toast.success(commonVariables?.FileDownloadSuccess, { toastId: toastIds?.FileDownloadSucess })
     
          }
        }
      })
      .catch((er) => {
        console.log("err", er);
      })
      .finally(() => {
        setDownloadFlag(false);
      });
  };

  const changePage = (value) => {
    listApi(value);
    setPageNumber(value - 1);
  };
  useEffect(() => {
    listApi();
  }, [selectMonth]);
  return (
    <>
      <div className="d-flex flex-wrap gap-2 align-items-center justify-content-between mt-4 mb-3 mx-3">
        <div className="right-div-header">
          <div className="mt-1">
            <label className="text-nowrap pl-0 common-header">
              {commonVariables.BILL_UPLOAD_STATUS}
            </label>
          </div>
        </div>
        <div className="d-flex flex-wrap gap-2 left-div-header">
          <div>
            <select
              disabled={loading || downloadFlag}
              value={selectMonth}
              onChange={(e) => {
                setSelectMonth(e?.target?.value);
              }}
              className="form-select selectInput cursor-pointer"
            >
              <option key="Select Month" hidden value>
                Select Month
              </option>
              {selectoptions?.length &&
                selectoptions.map((value) => {
                  return <option value={value.value}>{value.label}</option>;
                })}
            </select>
            {/* <Dropdown placeholder={"1 Month"} options={selectoptions}/> */}
          </div>
          <div>
            <button
            disabled={loading || downloadFlag}
              onClick={() => {
                dispatch(showBar(true));
              }}
              className="billuploadStatus_uploadBtn"
            >
              {commonVariables.UPLOAD}
            </button>
            <button
              onClick={() => {
                listApi()
              }}
              className="ml-2 presentationStatus_uploadBtn"
            >
              Refresh
            </button>
            <div>
              <SideBar data={<BillUploadForm onCancel={() => listApi()} />} />
            </div>
          </div>
        </div>
      </div>
      <div className="col billuploadStatus">
        {downloadFlag && (
          <div className="loaderDiv">
            <SystemXLoader />
          </div>
        )}
        <CustomTable
          cols={[
            {
              id: tableID?.BATCH_ID || "",
              title: commonVariables?.BATCH_ID || "",
            },
            {
              id: tableID?.UPLOAD_DATE_TIME || "",
              title: commonVariables?.DATE_TIME || "",
            },
            {
              id: tableID?.USER_NAME || "",
              title: commonVariables?.USER_NAME || "",
            },
            {
              id: tableID?.FILE_NAME || "",
              title: commonVariables?.FILE_NAME || "",
            },
            {
              id: tableID?.UPLOAD_STATUS || "",
              title: commonVariables?.FILE_UPLOAD_STATUS || "",
            },
            {
              id: tableID?.TOTAL_COUNT || "",
              title: commonVariables?.TOTAL_RECORDS || "",
            },
            {
              id: tableID?.SUCCESS_COUNT || "",
              title: commonVariables?.SUCCESSFUL || "",
            },
            {
              id: tableID?.REJECTED_COUNT || "",
              title: commonVariables?.REJECTED || "",
            },
            {
              id: tableID?.PENDING_COUNT || "",
              title: commonVariables?.PENDING || "",
            },
          ]}
          data={listdata}
          count={count}
          setPagination={(page) => changePage(page)}
          activePage={pageNumber}
          loading={loading}
        />
      </div>
    </>
  );
};
export default BillUploadStatus;
