import React from "react";
import DatePicker from "react-datepicker";
import Calendar from "../../../Images/calendar.svg";
import "./daterange.scss";
import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

function Datepicker(props) {
  return (
    <div>
      <div className="d-flex datepicker">
        <DatePicker
          className={props.className}
          selected={props.selected}
          dateFormat="dd/MM/yyyy"
          onChange={props.onChange}
          minDate={props.minDate}
          maxDate={props.maxDate}
          placeholderText={props.placeholder ? props.placeholder : "DD/MM/YYYY"}
          id={props.id}
          showTimeSelect={props.showTimeSelect ? props.showTimeSelect : false}
        />
        <img src={Calendar} alt="calendar" className="CalendarImg" />
      </div>
    </div>
  );
}
export default Datepicker;
