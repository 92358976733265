import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
// import parser from 'html-react-parser';
import React from 'react';
import LogoImage from '../../../../Images/Tata-Capital-Logo.png';
import moment from 'moment';
import { dateFormats } from '../../../../constants/Common/commonConstants';
// import Html from 'react-pdf-html';
// import arial from '../../assets/FONT/arial.ttf';
// import georgia from '../../assets/FONT/georgia.ttf';
// import helvetica from '../../assets/FONT/helvetica_neue.ttf';
// import impact from '../../assets/FONT/impact.ttf';
// import tahoma from '../../assets/FONT/tahoma.ttf';
// import timesNewRoman from '../../assets/FONT/timesNewRoman.ttf';
// import verdana from '../../assets/FONT/verdana.ttf';

// Font.register({ family: 'Arial', format: 'truetype', src: arial });
// Font.register({ family: 'Arial, Helvetica, sans-serif', format: 'truetype', src: arial });
// Font.register({ family: 'Georgia', format: 'truetype', src: georgia });
// Font.register({ family: 'Georgia, serif', format: 'truetype', src: georgia });
// Font.register({ family: 'Impact', format: 'truetype', src: impact });
// Font.register({ family: 'Impact, Charcoal, sans-serif', format: 'truetype', src: impact });
// Font.register({ family: 'Tahoma', format: 'truetype', src: tahoma });
// Font.register({ family: 'Tahoma, Geneva, sans-serif', format: 'truetype', src: tahoma });
// Font.register({ family: 'Times New Roman', format: 'truetype', src: timesNewRoman });
// Font.register({ family: 'Times New Roman, Times, serif', format: 'truetype', src: timesNewRoman });
// Font.register({ family: 'Verdana', format: 'truetype', src: verdana });
// Font.register({ family: 'Verdana, Geneva, sans-serif', format: 'truetype', src: verdana });
// Font.register({ family: 'Helvetica, sans-serif', format: 'truetype', src: helvetica });

const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    padding: 20,
  },
  section: {
    textAlign: 'center',
  },
  image: {
    height: '50px',
    width: '80px',
  },
  headingText: {
    textAlign: 'center',
  },

  table: {
    width: '70%',
    margin: '20px auto 0 auto',
    border: '1px solid #000',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #000000',
  },
  header: {
    borderTop: 'none',
  },

  col1: {
    width: '35%',
    fontSize: '10px',
    padding: '5px 5px',
    borderRight: '1px solid #000',
  },
  col2: {
    width: '100%',
    marginTop: 10,
    fontSize: '8px',
    paddingLeft: '20px',
  },
});

const GeneratePDF = ({ response, detail }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <Image style={styles.image} src={LogoImage} alt="Tata Capital"></Image>
        </View>
        <View style={styles.section}>
          <Text style={styles.headingText}>Account Validation Status</Text>
        </View>

        <View style={styles.table}>
          <View style={[styles.row, styles.bold, styles.header]}>
            <Text style={styles.col1}>Source System</Text>
            <Text style={styles.col2}>{response?.source_sytem}</Text>
          </View>

          <View style={styles.row}>
            <Text style={[styles.col1, styles.bold, styles.header]}>Company Code</Text>
            <Text style={[styles.col2]}>{response?.company_code}</Text>
          </View>
          <View style={[styles.row]}>
            <Text style={[styles.col1, styles.bold, styles.header]}>MICR Code</Text>
            <Text style={styles.col2}>{response?.micr_code}</Text>
          </View>
          <View style={[styles.row]}>
            <Text style={[styles.col1, styles.bold, styles.header]}>IFSC Code</Text>
            <Text style={styles.col2}>{response?.ifsc_code}</Text>
          </View>
          <View style={[styles.row]}>
            <Text style={[styles.col1, styles.bold, styles.header]}>Customer Name</Text>
            <Text style={styles.col2}>{response?.customer_name}</Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.col1, styles.bold, styles.header]}>Account Number</Text>
            <Text style={styles.col2}>{response?.account_number_as_per_mandate}</Text>
          </View>
          <View style={[styles.row]}>
            <Text style={[styles.col1, styles.bold, styles.header]}>Account Type</Text>
            <Text style={styles.col2}>{response?.account_type}</Text>
          </View>
          <View style={[styles.row]}>
            <Text style={[styles.col1, styles.bold, styles.header]}>Amount</Text>
            <Text style={styles.col2}>{response?.amount}</Text>
          </View>
          <View style={[styles.row]}>
            <Text style={[styles.col1, styles.bold, styles.header]}>Tnx Status Code</Text>
            <Text style={styles.col2}>{response?.txn_status_code}</Text>
          </View>
          <View style={[styles.row]}>
            <Text style={[styles.col1, styles.bold, styles.header]}>Tnx Status desc</Text>
            <Text style={styles.col2}>{response?.txn_status_desc}</Text>
          </View>
          <View style={[styles.row]}>
            <Text style={[styles.col1, styles.bold, styles.header]}>Tnx Status desc tc</Text>
            <Text style={styles.col2}>{response?.txn_status_desc_tc}</Text>
          </View>
          <View style={[styles.row]}>
            <Text style={[styles.col1, styles.bold, styles.header]}>UTR</Text>
            <Text style={styles.col2}>{response?.txn_utr}</Text>
          </View>
          <View style={[styles.row]}>
            <Text style={[styles.col1, styles.bold, styles.header]}>Tnx Bene Name</Text>
            <Text style={styles.col2}>{response?.txn_imps_bene_name}</Text>
          </View>
          <View style={[styles.row]}>
            <Text style={[styles.col1, styles.bold, styles.header]}>Transaction Date</Text>
            <Text style={styles.col2}>{moment(response?.createdAt).format(dateFormats?.dmyHms)}</Text>
          </View>

          {/* ))} */}
        </View>
      </Page>
    </Document>
  );
};

export default GeneratePDF;
