import axios from 'axios';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import BankDetails from '../../../component/accountVerification/bankDetails/BankDetails';
import CustomerDetails from '../../../component/accountVerification/customerDetails/CustomerDetails';
import SystemXLoader from '../../../component/common/Loader/SystemXLoader';
import { UserId, apiConstants, enachTechProcessConstants, singleAccountVerificationAPIMessage, toastIds, tokenConstants } from '../../../constants/Common/apiConstants';
import { ROUTE_PATH } from '../../../constants/Routes';
import apiCall from '../../../utils/apiFunction/apiCall';
import './SingleVerify.scss';
const SingleVerify = () => {
  const token = useRef(null);
  const batchId = useRef(null);

  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [customerDetails, setCustomerDetails] = useState();
  const navigate = useNavigate();

  // const [token, setToken] = useState(null);
  // const [batchId, setBatchId] = useState(null);

  const [counter, setCounter] = useState(null);
  const [pdfResponse, setPdfResponse] = useState({});

  const handleNext = (data) => {
    // setCustomerDetails(Object.assign(JSON.parse(data)));
    setCustomerDetails(JSON.parse(data));
    setActiveStep(activeStep + 1);
  };

  const onsubmit = (e) => {
    const bankDetailsData = JSON.parse(e);
    setLoading(true);

    let postData = {
      sourse_sytem: '',
      sponsor_bank: '',
      trans_type: '',
      sponsor_bank_account_number: '',
      company_code: customerDetails?.company_code || '',
      product_code: customerDetails?.product_type || '',
      sub_product_type: '',
      web_top: customerDetails?.lease_id || '',
      los_id: customerDetails?.application_id || '',
      micr_code: bankDetailsData?.micr_code || '',
      ifsc_code: bankDetailsData?.ifsc_code || '',
      bank_name: bankDetailsData?.bank_name || '',
      branch_code: bankDetailsData?.branch_code || '',
      city: bankDetailsData?.city || '',
      customer_name: customerDetails?.customer_name || '',
      mobile_number: customerDetails?.mobile_number || '',
      board_line_number: customerDetails?.board_line_no.toString() || '',
      personal_email: customerDetails?.personal_email || '',
      office_email: customerDetails?.office_email || '',
      account_holder_name: bankDetailsData?.account_holder_name || '',
      account_number_as_per_mandate: bankDetailsData?.account_number || '',
      account_type: bankDetailsData?.account_type || '',
      amount: 1,
      txn_id: enachTechProcessConstants?.txn_id,
      transaction_type: enachTechProcessConstants?.transaction_type,
      acct_validation_mode: enachTechProcessConstants?.acct_validation_mode,
      loan_number: customerDetails?.loan_no || '',
    };
    apiCall
      .post(apiConstants?.singleAccountVerificationAPI, postData)
      .then(async (res) => {
        if (res?.data?.status === 200) {
          let result = res?.data;
          if (result) {
            console.log('result', result);
            // console.log('result-', result.response.data.body.response.batch_id);
            batchId.current = result.response.batch_id;
            // setBatchId(result.response.batch_id);

            getToken();
            toast.success(res?.data?.message || singleAccountVerificationAPIMessage?.success, {
              toastId: toastIds?.SingleAccountVerificationPass,
            });
          }
        } else {
          batchId.current = null;
          // setBatchId(null);
          toast.error(res?.data?.message || singleAccountVerificationAPIMessage?.fail, {
            toastId: toastIds?.SingleAccountVerificationFailed,
          });
        }
      })
      .catch((er) => {
        toast.error(er?.message || singleAccountVerificationAPIMessage?.fail, {
          toastId: toastIds?.SingleAccountVerificationFailed,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getToken = async () => {
    try {
      const tokenResponse = await axios.post(apiConstants.ApiSingleValidationTokenGenerate, tokenConstants.enach_token_data);

      if (tokenResponse.data.status) {
        token.current = tokenResponse.data.response;
        // setToken(tokenResponse.data.response);
        setCounter(30);
      } else {
        token.current = null;

        // setToken(null);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set('onEdit', '1');
    window.history.pushState(null, '', newUrl.toString());
  };

  React.useEffect(() => {
    if (counter === 0 && token.current) {
      generatePdfResponse();
    }

    if (!counter) return;

    const intervalId = setInterval(() => {
      setCounter(counter - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [counter]);

  const generatePdfResponse = async () => {
    try {
      if (batchId.current !== null && batchId.current !== undefined) {
        const response = await axios.get(apiConstants.ApiFindByAllBatchId + batchId.current, {
          headers: {
            user_id: UserId,
            'x-access-token': token.current,
          },
        });

        if (response.status === 200) {
          const data = response.data.data[0];
          setPdfResponse({
            source_sytem: data?.source_sytem,
            company_code: data?.company_code,
            micr_code: data?.micr_code,
            ifsc_code: data?.ifsc_code,
            customer_name: data?.customer_name,
            account_number_as_per_mandate: data?.account_number_as_per_mandate,
            account_type: data?.account_type,
            amount: data?.amount,
            txn_status_code: data?.txn_status_code,
            txn_status_desc: data?.txn_status_desc,
            txn_status_desc_tc: data?.txn_status_desc_tc,
            txn_utr: data?.txn_utr,
            txn_imps_bene_name: data?.txn_imps_bene_name,
            createdAt: data?.createdAt,
          });
        } else {
          setPdfResponse({});
        }
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <>
      {loading && (
        <div className="loaderDiv">
          <SystemXLoader />
        </div>
      )}
      <div className="singleVerify mt-4 mx-3 ">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0 d-flex flex-wrap justify-content-between">
          <label className="common-header mt-2 cursor-pointer">
            Account Validation {'>>'}
            <span style={{ color: 'black' }}>Verify Account Single</span>
          </label>
          <div>
            {/* <button
              className="singleVerify_bulkMandateBtn"
              onClick={() => navigate(ROUTE_PATH?.VERIFY_ACCOUNT)}
            >
              Bulk Mandate
            </button> */}
            <button className="verifyAccountButtons_submitBtn mt-0" onClick={() => navigate(ROUTE_PATH?.VERIFY_ACCOUNT)}>
            Bulk Account Validation
            </button>
          </div>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0 mt-3">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
            <h6 className="create_manual_mandate">Single Verify</h6>
          </div>
          <div className="row col-xs-12 col-sm-12 col-md-12 col-lg-12 ml-0 single_verify_form">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 single_verify_step">
              <h6 className={activeStep === 0 ? 'activeStep' : 'non-activeStep'}>
                Customer Details
              </h6>
              <h6 className={activeStep === 1 ? 'activeStep' : 'non-activeStep'}>
                Bank Details
              </h6>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 right-side-div">
              {activeStep === 0 ? (
                <CustomerDetails isSingleVerify handleNext={(e) => handleNext(e)} />
              ) : (
                <BankDetails
                  isSingleVerify
                  handleNext={(e) => {
                    onsubmit(e);
                  }}
                  pdfResponse={pdfResponse}
                  handleBack={handleBack}
                  counter={counter}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleVerify;
