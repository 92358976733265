import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import apiCall from "../../../utils/apiFunction/apiCall";
import uploadPresigned from "../../../utils/presignedUpload";
import DynamicForm from "../../../component/common/DynamicForm/dynamicForm";
import { toast } from "react-toastify";
import moment from "moment";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import {
  apiConstants,
  enachApiConstants,
  bulkDownloadFlags,
} from "../../../constants/Common/apiConstants";
import jwt_decode from "jwt-decode";

const SwapReversalUpload = () => {
  const [uploadPercent, setUploadPercent] = useState(0);
  const [loading, setLoading] = useState(false);

  const [fileData, setFileData] = useState([]);
  const [downloadFlag, setdownloadFlag] = useState(false);
  const [accessData, setAccessData] = useState("");

  useEffect(() => {
    let roleData = jwt_decode(localStorage.getItem("loginRoleData"));
    if (roleData) return setAccessData(roleData);
  }, []);

  const [formValues] = useState([
    {
      type: "fileupload",
      width: 12,
      label: "Upload File",
      displaycomponent: <div className="uploadComponentLabel">Upload File</div>,
    },
  ]);
  const onFileUpload = (file) => {
    if (file.length > 0) {
      let extension = file[0].name.split(".")[1];
      if (extension != "csv")
        return toast.error("Please upload file with .csv format", {
          toastId: "bulkUploadFile",
        });
      getPresigned(file);
    }
  };

  const closeForm = () => {
    window.location.reload();
  };

  const onSubmit = () => {
    if (fileData.length === 0)
      return toast.error("Please Upload Valid File", {
        toastId: "bulkUploadFileError",
      });
    toast.info("File is getting processed ! Please Wait", {
      toastId: "bulkUploadFileInfo",
    });
    setdownloadFlag(true);
    const fileName = fileData[0].name;

    const key = `swap_reversal_file/${fileName}`;
    const params = {
      user_name: accessData?.user_name,
      upload_date_time: moment().format("YYYY-MM-DD HH:mm:ss"),
      file_name: fileName,
      user_code: accessData?.user_code,
      upload_status: bulkDownloadFlags?.PROCCESSING,
      total_count: 0,
      success_count: 0,
      rejected_count: 0,
      key: key,
    };
    apiCall
      .post(apiConstants?.createUser, {
        body: params,
        tablename: "swap_reversal_file",
      })
      .then((response) => {
        if (response?.status === 200) {
          const batch_id = response?.data?.response?.data?.batch_id;
          apiCall
            .get(
              `${enachApiConstants?.swapMandateCreate}?key=${key}&batch_id=${batch_id}&user_id=${params.user_name}&file_name=${fileName}`
            )
            .then((response) => {
              if (response?.status === 200) {
                if (response?.data?.status !== 200)
                  return toast.error("File Upload Failed", {
                    toastId: "bulkUploadFileError",
                  });
                toast.success("File Uploaded Successfully!", {
                  toastId: "bulkUploadFileSuccess",
                });
              }
            })
            .catch((error) => {
              return toast.error("File Upload Failed", {
                toastId: "bulkUploadFileError",
              });
            })
            .finally(() => {
              setdownloadFlag(false);
              navigate("/enach/swapReversalList");
            });
        }
      })
      .catch((error) => {
        toast.error("File Upload Failed", { toastId: "bulkUploadFileError" });
      })
      .finally(() => {
        setdownloadFlag(false);
      });
  };

  const uploadLoder = (data) => {
    setUploadPercent(data);
  };

  const getPresigned = (value) => {
    setLoading(true);
    apiCall
      .get(
        `${apiConstants?.uploadPreSignedUrl}?key=swap_reversal_file/${value[0].name}`
      )
      .then(async (res) => {
        if (res.status === 200) {
          let result = res?.data;
          if (result) {
            value[0].file_type = `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`;
            uploadPresigned(value[0], result, uploadLoder)
              .then((res) => {
                if (res.status == 200) {
                  setFileData(value);
                }
              })
              .catch((e) => {
                toast.error("File Upload Failed", {
                  toastId: "bulkUploadFileError",
                });
              });
          }
        }
      })
      .catch((er) => {
        toast.error("File Upload Failed", { toastId: "bulkUploadFileError" });
        console.log("err", er);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const navigate = useNavigate();

  return (
    <div className="mx-3 mt-4">
      {downloadFlag && (
        <div className="className='row justify-content-center d-flex loaderDiv">
          <SystemXLoader />
        </div>
      )}
      <div>
        <div className="row justify-content-center d-flex ">
          <div className=" pb-4 ">
            <div className="">
              <label
                className="common-header cursor-pointer"
                onClick={() => {
                  navigate("/enach/swapReversalList");
                }}
              >
                Swap Listing &gt; &gt;{" "}
                <span className="text-nowrap headername"> Swap Upload </span>
              </label>
            </div>
          </div>
                <div className="mx-3">
          <div className="mycontainerstyle reportui">
            <div className="pt-4 ms-3">
              <div className="row">
                  <div className="col-md-9">
                    <div className="m-4">
                      <DynamicForm
                        onSubmit={onSubmit}
                        uploadPercent={uploadPercent}
                        onFileUpload={onFileUpload}
                        formValues={formValues}
                        closeForm={closeForm}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SwapReversalUpload;
