import React, { useEffect, useState } from "react";
import "./DemergeFinnoneDownload.scss";
import CustomTable from "../../../component/customtable/CustomTable";
import Button from "../../../component/common/Button/button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import apiCall from "../../../utils/apiFunction/apiCall";
import {
  commonVariables,
  componentRole,
  route,
  tableID,
} from "../../../constants/Common/commonConstants";
import {
  apiConstants,
  errMessage,
  toastIds,
} from "../../../constants/Common/apiConstants";
import downloadIcon from "../../../Images/download.svg";
import { toast } from "react-toastify";

const DebitTransactionDemergeFinnoneDownload = () => {
  const { state } = useLocation();
  const [pageNumber, setPageNumber] = useState(0);
  const [listdata, setListdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const { batchId, system, mandate_type, bank_code } = state;

  const listApi = () => {
    setLoading(true);
    let newItem = {
      batch_id: batchId,
      download_id: "-",
      source_system: system,
      download_status: commonVariables?.DOWNLOAD_IS_BEING_READY || "",
      action: commonVariables?.INPROGRESS || "",
    };
    if (newItem) {
      setListdata([newItem]);
      updatedlistApi();
    }
  };

  const updatedlistApi = () => {
    setLoading(true);
    apiCall
      .get(
        `${
          apiConstants.DebitTransactionDeMergeDownloadDemerge
        }?batchId=${batchId}&system=${system.toLocaleUpperCase()}&mandate_type=${mandate_type}&bank_code=${bank_code}`
      )
      .then(async (res) => {
        if (res.status === 200) {
          let result = res?.data?.response;
          result = result.map((item) => {
            let newItem = {
              batch_id: batchId,
              download_id: item?.download_id,
              source_system: system,
              download_status: commonVariables?.DOWNLOAD_READY,
              action: addLink(item?.URL, item?.fName),
            };
            return newItem;
          });
          setListdata([...result]);
        }
      })
      .catch((er) => {
        console.log("err", er);
      })
      .finally(() => {
        // listApi()
      });
  };
  const fetchBlockFile = (key) => {
    apiCall
      .get(`${apiConstants?.downloadPresignedUrl}?key=${key}`)
      .then((response) => {
        if (response.status === 200) {
          const link = document.createElement("a");
          link.href = response?.data;
          link.click();
          toast.success(commonVariables?.FileDownloadSuccess, {
            toastId: toastIds?.FileDownloadSucess,
          });
        }
      })
      .catch((error) => {
        console.log("Error", error);
        return toast.error(errMessage?.InternalServerError, {
          toastId: toastIds?.InternalServerError,
        });
      });
  };
  const downloadbtn = (data) => {
    return (
      <div>
        <button
          disabled={!data}
          onClick={() => fetchBlockFile(data)}
          className="downloadbtn pt-1 pe-2 pe-2 d-flex align-items-center justify-content-center"
        >
          <img src={downloadIcon} alt="download" />
          Download
        </button>
      </div>
    );
  };
  const addLink = (url, file_name) => {
    return (
      <>
        <a
          className="link-primary cursor-pointer"
          onClick={() => fetchBlockFile(url)}
        >
          {file_name}
        </a>
      </>
    );
  };
  const previousNavigation = (databatchId) => {
    navigate(`${route?.DT_DEMERGE_SYSTEM}/${databatchId}` || "");
    // apiCall
    //   .get(`${apiConstants.debitTransactionDeMergeDemergeSourceSystem}?batchId=${databatchId}`)
    //   .then(async (res) => {
    //     if (res.status === 200) {
    //       let result = res?.data?.response
    //       let sourceData = {
    //         batch_id: result?.batchId,
    //         sap: result?.sap,
    //         bancs: result?.bancs,
    //         finnone: result?.finnone,
    //         sapecc6: result?.ecc6,
    //         manual: result?.manual,
    //         mandate_type: result?.mandate_type,
    //         bank_code: result?.bank_code,
    //       }
    //       if (sourceData) {
    //         navigate(route?.DT_DEMERGE_SYSTEM || "", {state: {data: sourceData}})
    //       }
    //     }
    //   })
    //   .catch((er) => {
    //     console.log("err", er)
    //   })
    //   .finally(() => {
    //     //   setLoading(false);
    //   })
  };

  const changePage = (value) => {
    listApi(value);
    setPageNumber(value - 1);
  };

  useEffect(() => {
    listApi();
  }, []);

  return (
    <>
      <div className="demergeSystemCountWrapper">
        <div className="d-flex align-items-center justify-content-between mt-5">
          <div className="right-div-header">
            <div>
            <label className="common-header cursor-pointer ps-4">
              {commonVariables?.DEMERGE || ""} &gt; &gt;
            </label>
            <label
              onClick={() => {
                previousNavigation(batchId);
              }}
              className="common-header cursor-pointer ps-2"
            >
              {commonVariables?.SYSTEM_COUNT_DATA || ""} &gt; &gt;
            </label>
            <span className="text-nowrap ps-2">
                {commonVariables?.FINNONE || ""}
              </span>
          </div>
          </div>
        </div>
        <div className="col">
          <CustomTable
            cols={[
              {
                id: tableID?.DOWNLOAD_ID || "",
                title: commonVariables?.DOWNLOAD_ID || "",
              },
              {
                id: tableID?.BATCH_ID || "",
                title: commonVariables?.BATCH_ID || "",
              },
              {
                id: tableID?.SOURCE_SYSTEM || "",
                title: commonVariables?.SOURCE_SYSTEM || "",
              },
              {
                id: tableID?.DOWNLOAD_STATUS || "",
                title: commonVariables?.DOWNLOAD_STATUS || "",
              },
              {
                id: tableID?.ACTION || "",
                title: commonVariables?.ACTION || "",
              },
            ]}
            data={listdata}
            setPagination={(page) => changePage(page)}
            activePage={pageNumber}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
};

export default DebitTransactionDemergeFinnoneDownload;
