import React from 'react';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { redirect, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import apiCall from '../../utils/apiFunction/apiCall';
import { apiConstants, toastIds } from '../../constants/Common/apiConstants';
import moment from "moment";


const initialState = {
	loading: false,
	loginResponse: [],
	userToken: '',
	userData: [],
	decodedData: '',
	error: '',
	role: '',
	validUser: false
};

export const reduxLogin = createAsyncThunk("login/reduxLogin", (payload) => {
  return apiCall
    .post(apiConstants?.login, payload)
    .then((response) => {
		if (response?.data?.status === 200) {
      toast.success("Login Successfully", {
        toastId: toastIds?.assignedRoleSuccess,
      });
      let result = response?.data?.response;
      if (response.data.message === "change password") {
        result["changePassword"] = true;
      }
      localStorage.setItem(
        "loginRoleData",
        JSON.stringify(response?.data?.response)
      );
      return response?.data;
	  }else{
		toast.error(
			response?.data?.message
			  ? response?.data?.message
			  : "Invalid Credentials",
			{
			  toastId: toastIds?.DataError,
			}
		  );
	  }
    })
    .catch((error) => {
      toast.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Invalid Credentials",
        {
          toastId: toastIds?.DataError,
        }
      );
      console.log("error", error?.response?.data?.message);
    });
});

const loginSlice = createSlice({
	name: 'login',
	initialState,
	reducers: {
		USER_LOGOUT: (state, action) => {
			console.log('logout');
			localStorage.setItem('loginRoleData', "")
			state.loginResponse = [];
		},
	},
	extraReducers: (builder) => {
		builder.addCase(reduxLogin.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(reduxLogin.fulfilled, (state, action) => {
			// return (
			// console.log('ACTION.Payload:::::::', action.payload?.response?.roleData);
			let roleArray = []
			if (action?.payload?.response?.roleData) {
				Object.entries(action?.payload?.response?.roleData).map((value) => {
					if (value[1]) {
						roleArray.push(value[0])
					}
				})
			}
			const currentDate = moment().format('YYYY_MM_DD')
			const [year , month , day] = currentDate.split('_')
			state.year = year;
			state.month = month;
			state.day = day;
			state.loading = false;
			state.loginResponse = action?.payload;
			state.userToken = action?.payload?.response?.token;
			state.decodedData = jwt_decode(action?.payload?.response?.token);
			state.userData = roleArray?.length ? roleArray : [];
			state.error = '';
			// );
		});
		builder.addCase(reduxLogin.rejected, (state, action) => {
			state.loading = false;
			state.loginResponse = [];
			state.userToken = '';
			state.userData = [];
			state.decodedData = '';
			state.error = action.error.message;
		});
	},
});

export const { USER_LOGOUT } = loginSlice.actions;

export default loginSlice.reducer;
