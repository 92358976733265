import React, { useEffect, useState } from "react";
import "../../pages/debitTransaction/DtMerge/DtMerge.scss";
import CustomTable from "../customtable/CustomTable";
import Button from "../common/Button/button";
import InputField from "../common/Inputfield/inputfield";
import apiCall from "../../utils/apiFunction/apiCall";
import { Form } from "react-bootstrap";
import "./UserType.scss";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../constants/Routes";
import {
  apiConstants,
  FOMRS,
  successMsg,
  tableNamePaymentConstants,
} from "../../constants/Common/apiConstants";
import {
  commonVariables,
  tableID,
} from "../../constants/Common/commonConstants";
import { toast } from "react-toastify";
import DeleteConfirmation from "../deleteModal/deleteModal";

const MyComponent = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [listdata, setListdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [datacount, setDatacount] = useState(0);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState({});


  const deleteFunc = (item) => {
    const query = {
      tablename: tableNamePaymentConstants?.UserType,
      whereQuery: deleteItemId
    }
    apiCall.post(apiConstants?.deleteRecord, query).then((res) => {
      if (res?.data?.status === 200) {
        toast.success(successMsg.Deleted)
        setShowDeleteConfirmationModal(false);
        listApi(pageNumber)
      } else {
        toast.error(res?.data?.message)
      }
    })
  }

  const listApi = (value) => {
    let temp = value ? (value - 1) * 10 : pageNumber * 10;
    setLoading(true);
    apiCall
      .post(apiConstants?.fetchAllData, {
        offset: temp || 0,
        limit: 10,
        tablename: tableNamePaymentConstants?.UserType,
        month: 0,
      })
      .then(async (res) => {
        if (res?.status === 200) {
          let result = res?.data?.response;
          setDatacount(res?.data?.count || 0);
          result = result.map((item, index) => {
            let newItem = {
              no: temp + index + 1,
              user_type: item?.user_type ? item?.user_type : "-",
              name: item?.user_name ? item?.user_name : "-",
              action: (
                <div>
                  <img
                    src="/assests/images/pencil.svg"
                    style={{ padding: "10px", cursor: "pointer" }}
                    onClick={() => {
                      navigate(ROUTE_PATH.CREATE_USER_TYPE, {
                        state: {
                          type: FOMRS.EDIT,
                          data: item,
                        },
                      });
                    }}
                    alt=""
                  />
                  <img
                    src="/assests/images/trash-bin.svg"
                    style={{ padding: "10px", cursor: "pointer" }}
                    onClick={() => {
                      setDeleteItemId({
                        user_type: item?.user_type,
                        user_name: item?.user_name,
                      })
                      setShowDeleteConfirmationModal(true)
                    }}
                    alt=""
                  />
                </div>
              ),
              download: (
                <Form.Select>
                  <option key="Download" hidden value>
                    Download
                  </option>
                  <option>Excel</option>
                  <option>TXT</option>
                </Form.Select>
              ),
            };
            return newItem;
          });
          if (result?.length > 0) {
            setListdata([...result]);
          }
        }
      })
      .catch((er) => { })
      .finally(() => {
        setLoading(false);
      });
  };

  const changePage = (value) => {
    listApi(value);
    setPageNumber(value - 1);
  };
  useEffect(() => {
    listApi();
  }, []);
  return (
    <>
      <div id="usertype">
        <div className="d-flex flex-wrap align-items-center justify-content-between mt-4 mb-4 mx-3">
          <div className="right-div-header">
            <div>
              <label className="labelPresentation">User Type</label>
            </div>
          </div>
          <div className="d-flex gap-3 left-div-header">
            <div>
              <DeleteConfirmation
                showModal={showDeleteConfirmationModal}
                hideModal={() => setShowDeleteConfirmationModal(false)}
                confirmModal={deleteFunc}
                id={deleteItemId}
                type={FOMRS.DELETE}
                message="Are you sure you want to delete this item?"
              />
              <button
                onClick={() => {
                  navigate(ROUTE_PATH.CREATE_USER_TYPE, {
                    state: {
                      type: FOMRS.ADD,
                    },
                  });
                }}
                className="createUserTypeButton"
              >
                Create User Type
              </button>
            </div>
          </div>
        </div>
        <div className="col dtMergeTable">
          <CustomTable
            cols={[
              {
                id: tableID?.NO || "",
                title: commonVariables?.NO || "",
              },
              {
                id: tableID?.USER_TYPE || "",
                title: commonVariables?.USER_TYPE || "",
              },
              {
                id: tableID?.NAME || "",
                title: commonVariables?.NAME || "",
              },

              {
                id: tableID?.ACTION || "",
                title: commonVariables?.ACTION || "",
              },
            ]}
            data={listdata}
            count={datacount}
            setPagination={(page) => changePage(page)}
            activePage={pageNumber}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
};

export default MyComponent;
