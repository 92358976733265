import { useState, useEffect } from "react";
import "./BulkMandate.scss";
import downloadArrow from "../../../Images/downloadIcon.svg";
import { useNavigate } from "react-router-dom";
import info from '../../../Images/info.svg';
import apiCall from "../../../utils/apiFunction/apiCall";
import uploadPresigned from "../../../utils/presignedUpload";
import DynamicForm from "../../../component/common/DynamicForm/dynamicForm";
import { toast } from "react-toastify";
import moment from "moment";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import { useSelector } from "react-redux";
import {
  apiConstants,
  enachApiConstants,
  bulkDownloadFlags,
  errMessage,
  toastIds,
  successMsg,
} from "../../../constants/Common/apiConstants";
import { commonVariables, dateFormats, S3FILEPATH, toolTipMessages } from "../../../constants/Common/commonConstants";
import { tableNamePaymentConstants } from "../../../constants/Common/apiConstants";
import { ROUTE_PATH } from "../../../constants/Routes";
import CustomTooltip from "../../../component/Tooltip/Tooltip";

const Bulkmandate = () => {
  const [uploadPercent, setUploadPercent] = useState(0);
  const [fileData, setFileData] = useState([]);
  const [downloadFlag, setdownloadFlag] = useState(false);
  const loginState = useSelector((state) => state.login);

  const [formValues] = useState([
    {
      type: "fileupload",
      width: 12,
      label: "Upload File",
      displaycomponent: <div className="uploadComponentLabel">Upload File</div>,
    },
  ]);
  const onFileUpload = (file) => {
    setdownloadFlag(true)
    if (file.length > 0) {
      let extension = file[0].name.split(".")[1];
      if (extension != "xlsx"){
        setdownloadFlag(false)
        file.length = 0
        return toast.error(errMessage?.uploadFileWithXlsxFormat, {
          toastId: toastIds?.FileUploaderr,
        });
      }
      getPresigned(file);
    }
  };



  const onSubmit = () => {
      const userData = loginState?.decodedData;
      if (fileData.length === 0) return toast.error(errMessage?.invalidFile, {
        toastId: toastIds?.FileUploaderr,
      });
      toast.info("File is getting processed ! Please Wait", {
        toastId: "bulkUploadFileInfo",
      });
  
      const params = {
        user_name : userData?.user_name,
        upload_date_time: moment().format(dateFormats?.dateFormatInDMYWithTime),
        file_name: fileData[0].name,
        key : fileData[0].key,
        user_code: userData?.user_code,
        upload_status: bulkDownloadFlags?.PROCCESSING,
        batch_id : fileData[0].batch_id,
        total_count: 0,
        success_count: 0,
        rejected_count : 0,
      }
      
      apiCall.post(apiConstants?.createUser , {
        body : params,
        tablename : tableNamePaymentConstants?.BulkMandateReg
      }).then((response) => {
        setdownloadFlag(true);
        navigate(ROUTE_PATH?.MANDATE);
        const key = `${loginState?.year}/${loginState?.month}/${loginState?.day}/${S3FILEPATH?.ENACH?.ENACH}/${S3FILEPATH?.COMMON?.UPLOADS}/${S3FILEPATH?.ENACH?.NOTBANKED}/${params?.batch_id}/${params?.file_name}`    
        apiCall.get(`${enachApiConstants?.bulkMandateCreate}?key=${key}&batch_id=${params?.batch_id}&user_id=${userData?.user_name}&file_name=${params?.file_name}`
        ).then((response) => {
          if (response?.status === 200) {
            if (response?.data?.status !== 200)
              return toast.error(
                response?.data?.message || errMessage?.fileUploadFailed,
                {
                  toastId: toastIds?.FileUploaderr,
                }
              );
            toast.success(successMsg?.EnachBulkUpload, {
              toastId: toastIds?.FileUploadSuccess,
            });
          }
        }).catch((error) => {
          return toast.error(
            error?.message || errMessage?.fileUploadFailed,
            {
              toastId: toastIds?.FileUploaderr,
            }
          );
        }).finally(() => {
          setdownloadFlag(false)
        })
      }).catch((error) => {
        toast.error(error?.message || errMessage?.fileUploadFailed, {
          toastId: toastIds?.FileUploaderr,
        });
      })
    

  };

  const uploadLoder = (data) => {
    setUploadPercent(data);
  };

  const getPresigned = (value) => {

    apiCall.post(enachApiConstants?.fetchBatch , {
      tablename : 'bulk_mandate_reg',
    }).then((response) => {
      if(response?.status === 200){
        const batchId = `B${response?.data?.result}`
        const key = `${loginState?.year}/${loginState?.month}/${loginState?.day}/${S3FILEPATH?.ENACH?.ENACH}/${S3FILEPATH?.COMMON.UPLOADS}/${S3FILEPATH?.ENACH?.NOTBANKED}/${batchId}/${value[0]?.name}`
        value[0].batch_id = batchId;
        value[0].file_type = `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`;
        value[0].key = key
        apiCall.get(`${apiConstants?.uploadPreSignedUrl}?key=${key}`).then((res) => {
          if(res?.status === 200){
              let result = res?.data;
                if (result) {
                  uploadPresigned(value[0], result, uploadLoder)
                    .then((res) => {
                      if (res.status == 200) {
                        setFileData(value);
                        setdownloadFlag(false)
                      }
                    })
                  .catch((e) => {
                    toast.error(e?.message || errMessage?.fileUploadFailed, {
                    toastId: toastIds?.FileUploaderr,
                  });
                 });
                  }
              }
            }).catch((error) => {
              toast.error(error?.message || errMessage?.fileUploadFailed, {
                toastId: toastIds?.FileUploaderr,
              });
            })

      }
    }).catch((error) => {
      toast.error(error?.message || errMessage?.fileUploadFailed, {
          toastId: toastIds?.FileUploaderr,
      });
    })
  };

  const navigate = useNavigate();

  const downloadSampleFile = async () => {
    setdownloadFlag(true);
    const fileName = 'mandateSampleFile.xlsx'
    const key = `${S3FILEPATH?.COMMON?.SAMPLEFILES}/${fileName}`;

    apiCall
      .get(`${apiConstants?.downloadPresignedUrl}?key=${key}`)
      .then((response) => {
        if (response.status === 200) {
          const link = document.createElement("a");
          link.href = response?.data;
          link.click();
          toast.success(commonVariables?.FileDownloadSuccess, { toastId: toastIds?.FileDownloadSucess })

        }
      })
      .catch((error) => {
        setdownloadFlag(false);
        return toast.error(errMessage?.InternalServerError, {
          toastId: toastIds?.InternalServerError,
        });
      })
      .finally(() => {
        setdownloadFlag(false);
      });
  };

  return (
    <div>
      {downloadFlag && (
        <div className="className='row justify-content-center d-flex loaderDiv">
          <SystemXLoader />
        </div>
      )}
      <div className="mx-3 pt-4">
        <div className="row justify-content-center">
          <div className="row pb-4 ">
            <div className="col-md-6 pl-0 mt-2">
              <label
                className="common-header cursor-pointer"
                onClick={() => {
                  navigate("/mandate");
                }}
              >
                Mandate Registration &gt; &gt;{" "}
                <span className="text-nowrap headername"> Bulk Mandate </span>
              </label>
            </div>
          </div>
          <div>
            <div className="mycontainerstyle reportui">
              <div className="pt-4 ms-3">
                <div className="row">
                  <div className="col-xl-9 col-md-6 col-lg-8 col-sm-12">
                    <div className="mr-3">
                      <DynamicForm
                        hideBtn
                        uploadPercent={uploadPercent}
                        onFileUpload={onFileUpload}
                        formValues={formValues}
                      />
                    </div>
                    {/* <UploadComponent  name='upload_files'/> */}
                  </div>
                  <div className="col-xl-3 col-md-6 col-lg-4 col-sm-12">
                    <div className="btnmargin">
                      <button
                        onClick={() => {
                          downloadSampleFile();
                        }}
                        className="download-btn text-nowrap"
                      >
                        <img
                          src={downloadArrow}
                          className="pe-2"
                          alt="downloadlogo"
                        ></img>
                        Download Sample File
                      </button>
                      <CustomTooltip
                        title={toolTipMessages?.downloadMandateSampleFile}
                        position="bottom"
                        id='info'
                      >
                        <img className="infoIcon" src={info} alt="info"></img>
                      </CustomTooltip>
                    </div>
                  </div>
                  <div className="d-flex gap-3 flex-wrap">
                    <button className="bulkMandate-submitBtn" onClick={()=>onSubmit()}>Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Bulkmandate;
