import React from "react";
import "../../styles/layout/common.scss";
import InputField from "../../component/common/Inputfield/inputfield";
import Newdropdown from "../../component/common/Dropdown/newDropdown";
import Button from "../../component/common/Button/button";
import CustomTable from "../../component/customtable/CustomTable";
import "./Accounts.scss";
import Export from "../../Images/export.svg"
import { monthOptions } from "../../constants/Common/commonConstants";

const Accounts = () => {
  return (
    <div className="container">
      <div className="container">
        <div className="d-flex justify-content-between mt-5 mb-4 ms-1">
          <label className="common-header">Accounts</label>
          <div className="d-flex gap-4">
            <div>
              <InputField placeholder="Search" className="accounts-inputs" />
            </div>
            <div>
              <Newdropdown
                placeholder="1 Month"
                options={monthOptions}
                className="accounts-dropdown"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <CustomTable
          cols={[
            {
              id: "no",
              title: "Batch ID",
            },
            {
              id: "batch_id",
              title: "File Name",
            },
            {
              id: "mandate",
              title: "Uploader Name",
            },
            {
              id: "bank",
              title: "Date & Time",
            },
            {
              id: "records",
              title: "File Upload Status",
            },
            {
              id: "date_time",
              title: "Total Records",
            },
            {
              id: "name",
              title: "Record Successful",
            },
            {
              id: "sftp_push",
              title: "Record Rejected",
            },
            {
              id: "",
              title: "Record Rejected",
            },
            {
              id: "",
              title: "SMS Delivered",
            },
            {
              id: "",
              title: "SMS Not Delivered",
            },
          ]}
        />
      </div>
    </div>
  );
};
export default Accounts;
