import React, { useEffect, useState } from "react";
import "../../enach/Enach.scss";
import moment from "moment/moment";
import Dropdown from "../../../component/common/Dropdown/dropdown";
import Button from "../../../component/common/Button/button";
import apiCall from "../../../utils/apiFunction/apiCall";
import {
  reportType,
  repaymentMode,
  YupMessages,
  systemCode,
  channels,
} from "../../../constants/Common/commonConstants.js";
import FormLabel from "../../../component/common/formLabel/formLabel";
import FormikSelect from "../../../component/common/formikSelect/formikSelect";
import FormikDatepicker from "../../../component/common/FormikDatepicker/FormikDatepicker";
import { toast } from "react-toastify";
import { Formik, Form, ErrorMessage } from "formik";
import "../../../pages/payment/datepicker.scss";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import { object, string } from "yup";
import {
  apiConstants,
  companyAttributes,
  errMessage,
  reportConstants,
  provisionReportType,
  tableNamePaymentConstants,
  toastIds,
} from "../../../constants/Common/apiConstants";

const Report = () => {
  const handleRefresh = () => {
    window.location.reload();
  };

  const [companycode, setCompanycode] = useState([]);

  const [sourcesystem, setSourcesystem] = useState([]);
  const [selectedReporttype, setSelectedReporttype] = useState("");
  const [downloadFlag, setdownloadFlag] = useState(false);

  const listCompanyCode = () => {
    apiCall
      .post(apiConstants?.findData, {
        tablename: tableNamePaymentConstants?.Company,
        attributes: companyAttributes,
      })
      .then((result) => {
        if (result.status === 200) {
          result = result?.data?.response.map((item) => {
            const new_item = {
              key: item?.company_name,
              value: item?.company_code,
            };
            return new_item;
          });
          result.splice(0, 0, {
            key: " Please Select Company Code",
            value: "",
          });
          setCompanycode([...result]);
        }
      })
      .catch((error) => {
        console.log(errMessage?.ErrorinCompanyCodeAPI, error);
      });
  };

  const listSourceSystem = () => {
    apiCall
      .post(apiConstants?.findData, {
        tablename: "source_system_master",
        attributes: ["source_system_name"],
        order: [["id", "DESC"]],
      })
      .then((result) => {
        if (result.status === 200) {
          result = result?.data?.response.map((item) => {
            const new_item = {
              key: item?.source_system_name,
              value: item?.source_system_name,
            };
            return new_item;
          });
          result.splice(0, 0, {
            key: "Please Select Source System",
            value: "",
          });
          result.splice(1, 1, { key: "ALL_SOURCE", value: "ALL_SOURCE" });
          setSourcesystem([...result]);
        }
      })
      .catch((error) => {
        console.log(errMessage?.ErrorinCompanyCodeAPI, error);
      });
  };

  const validSchema = object({
    from_date: string().required(YupMessages?.FromDateRequired),
    to_date: string().required(YupMessages?.ToDateRequired),
    company_code : string().required(YupMessages?.CompanyCodeRequired),
  });

  const onSubmit = (values) => {
    values.from_date = moment(values.from_date).format('YYYY-MM-DD')
    values.to_date = moment(values.to_date).format('YYYY-MM-DD')
    setdownloadFlag(true);

    apiCall
    .post(reportConstants?.provisionReport, values)
    .then((response) => {
      if (response?.status === 200) {
        
        if (response?.data?.status === 500)
          return toast.error(errMessage?.fileDownloadFailed, {
            toastId: toastIds?.FileUploaderr,
          });
        if (response?.data?.status === 400)
          return toast.error(errMessage?.fileDownloadFailed, {
            toastId: toastIds?.FileUploaderr,
          });
        if (response?.data?.status === 404)
          return toast.info(errMessage?.NoRecordPresentWithinSelectedDate, {
            toastId: toastIds?.DataError,
          });

        window.location.href = response?.data?.response;
      }
    })
    .catch((error) => {
      setdownloadFlag(false)
      toast.error(errMessage?.fileDownloadFailed, {
        toastId: toastIds?.FileUploaderr,
      });
    })
    .finally(() => {
      setdownloadFlag(false);
    });
  };



  

  useEffect(() => {
    listCompanyCode();
    listSourceSystem(selectedReporttype?.value);
  }, []);

  return (
    <>
      {downloadFlag && (
        <div className="className='row justify-content-center d-flex loaderDiv">
          <SystemXLoader />
        </div>
      )}
      <Formik
        initialValues={{
          from_date: "",
          to_date: "",
          mandate_type: "",
          company_code: "",
          system_code: "",
          channels: "",
          repayment_mode: ""

        }}
        validationSchema={validSchema}
        onSubmit={(values) => onSubmit(values)}
      >
        {({ values }) => (
          <Form>
            <div className="row justify-content-center d-flex container ">
              <div className="row ps-5 pt-5 pb-4 ">
                <div className="col-md-6">
                  <label className="common-header">Provision Report</label>
                </div>
              </div>
              <div className=" col-md-11 mycontainerstyle reportui">
                <div className="pt-4 ms-3">
                  <div>
                    <div className="row gap-3 ">
                      <div className="col-md-5">
                        <FormLabel
                          value="Select Report Type"
                          className="required"
                        />
                        <div>
                        <FormikSelect
                              label="Select Mandate Type"
                              name="mandate_type"
                              className="form-group form-control-lg pt-1 w-100 h-25"
                              options={provisionReportType}
                            />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    {values?.mandate_type !== ''
                       && (
                        <div className="row mt-2 gap-1">
                          <div className="col-md-5">
                            <FormLabel value="From Date" className="required" />
                            <FormikDatepicker
                              placeholder={" Please Select Date"}
                              name="from_date"
                            />
                            <div className="errorMessage">
                              <ErrorMessage name="from_date" />
                            </div>
                          </div>
                          <div className="col-md-5">
                            <FormLabel value="To Date" className="required" />
                            <FormikDatepicker
                              placeholder={"Please Select Date"}
                              name="to_date"
                            />
                            <div className="errorMessage">
                              <ErrorMessage name="to_date" />
                            </div>
                          </div>
                          <div className="col-md-5">
                            <FormLabel value="Company Code" className="required" />
                            <FormikSelect
                              label="Select Company Code"
                              name="company_code"
                              className="form-group form-control-lg pt-1 w-100 h-25"
                              options={companycode}
                            />
                            <div className="errorMessage">
                              <ErrorMessage name="company_code" />
                            </div>
                          </div>
                          <div className="col-md-5">
                            <FormLabel value="Repayment Mode" />
                            <FormikSelect
                              label="Select Repayment Mode"
                              name="repayment_mode"
                              className="form-group form-control-lg pt-1 w-100 h-25"
                              options={repaymentMode}
                            />
                          </div>
                          {
                            values?.mandate_type === 'EMANDATE_REVERSAL_FILE_FROM_OTHER_CHANNELS' &&
                            <div className="row mt-2 gap-3">
                              <div className="col-md-5">
                                <FormLabel value="System Code" />
                                <FormikSelect
                                  label="Select System Code"
                                  name="system_code"
                                  className="form-group form-control-lg pt-1 w-100 h-25"
                                  options={systemCode}
                                />
                              </div>
                              <div className="col-md-5">
                                <FormLabel value="Channels" />
                                <FormikSelect
                                  label="Select Channels"
                                  name="channels"
                                  className="form-group form-control-lg pt-1 w-100 h-25"
                                  options={channels}
                                />
                              </div>
                            </div>

                          }



                        </div>
                      )
                    }
                  </div>



                  {
                    values?.mandate_type !== '' ? (
                      <div>
                        <div className="d-flex pt-5 mb-5 gap-3">
                          <div className=" ">
                            <Button
                              value="Reset"
                              onClick={handleRefresh}
                              role="secondary"
                              className="cancel"
                            />
                            {/* onClick={() => resetfunction()} */}
                          </div>
                          <div className="">
                            <Button
                              type="submit"
                              onClick={() => { }}
                              value="Download"
                              role="primary"
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default Report;
