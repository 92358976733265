import React, { useEffect, useState, useRef } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import "./DropdownWithTooltip.scss";
import CustomTooltip from "../../Tooltip/Tooltip";

function DropdownWithTooltip(props) {
  const { options, label, disabled, defaultValue } = props;
  const [selectedValue, setSelectedValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [searchText, setSearchText] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const handleSelect = (eventKey, event) => {
    const selectedOption = options.find((option) => option.value === eventKey);
    setSelectedValue(selectedOption.key);
    setSearchText("");
    setShowDropdown(false);
    if (props?.onSelectCallback) {
      props?.onSelectCallback(selectedOption.value , selectedOption.customValue);
    }
  };

  useEffect(() => {
    setSelectedValue(defaultValue);
    setFilteredOptions(options);
  }, [defaultValue, options]);

  useEffect(() => {
    const filteredOptions = options.filter((data) =>
      data.key.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredOptions(filteredOptions);
  }, [searchText, options]);

  const handleKeyDown = (event) => {
    if (event.key === "ArrowDown") {
      setShowDropdown(true);
      event.preventDefault();
      dropdownRef.current.focus();
    } else if (event.key === "Enter" && showDropdown) {
      const focusedItem = document.activeElement;
      if (focusedItem && focusedItem.getAttribute("data-value")) {
        handleSelect(focusedItem.getAttribute("data-value"));
      }
    }
  };

  return (
    <div
      className="dropdownWrapper"
      onKeyDown={handleKeyDown}
      tabIndex="0"
      role="button"
    >
      <DropdownButton
        className="w-100 dropdownButton"
        show={showDropdown}
        title={
          <input
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e?.target?.value)}
            placeholder={selectedValue || label}
            className="w-100 dropdownInputBox"
          ></input>
        }
        onSelect={handleSelect}
        disabled={disabled || false}
        onClick={() => setShowDropdown(!showDropdown)}
        ref={dropdownRef}
      >
        <>
          {filteredOptions?.length > 0 ? (
            filteredOptions.map((option) => (
              <CustomTooltip
                title={option?.tooltipTitle}
                position="left"
                id={option?.value}
                key={option?.value}
              >
                <Dropdown.Item eventKey={option?.value} data-value={option?.value}>
                  <span className="spanText">
                    {option?.key.length > 55
                      ? option?.key.slice(0, 55).padEnd(58, ".")
                      : option?.key}
                  </span>
                </Dropdown.Item>
              </CustomTooltip>
            ))
          ) : (
            <p className="spanText mb-0">No data available</p>
          )}
        </>
      </DropdownButton>
    </div>
  );
}

export default DropdownWithTooltip;
