import React, { useEffect, useState } from "react";
import "../enach/Enach.scss"
import CustomTable from "../../component/customtable/CustomTable"
import Button from "../../component/common/Button/button";
import apiCall from "../../utils/apiFunction/apiCall"
import { componentRole, dateFormats, selectoptions } from '../../constants/Common/commonConstants';
import '../../styles/layout/common.scss'
import SystemXLoader from "../../component/common/Loader/SystemXLoader";
import { toast } from "react-toastify";
import { apiConstants , enachApiConstants , bulkDownloadFlags } from "../../constants/Common/apiConstants";
import moment from "moment/moment";


const SwapReversalList = () => {
    const [selectMonth, setSelectMonth] = useState(1);
    const [count, setCount] = useState(0);
    const [listdata, setListdata] = useState([]);
    const [pageNumber, setPageNumber] = useState(0)
    const [loading, setLoading] = useState(false);
    const [downloadFlag, setdownloadFlag] = useState(false)




    const listDataByMonthApi = (value) => {
        setdownloadFlag(true)
        let temp = 0
        if ((value || pageNumber) > 0) {
            temp = value ? (value - 1) * 10 : (pageNumber - 1) * 10;
        }
        setLoading(true);
        const params = {
            "tablename": "swap_reversal_file",
            "month": 0,
            "offset": temp || 0,
            "attributes": ['batch_id', 'upload_date_time', 'user_name', 'file_name' , 'upload_status' , 'total_count' , 'success_count' , 'rejected_count'],
            "limit": apiConstants?.limit,
            "order": [['batch_id', 'DESC']]

        }
        apiCall.post(apiConstants?.fetchAllData, params).then((response) => {
            if (response.status === 200) {
                if (response?.data?.status === 500) return toast.error('Internal Server Error', { toastId: 'BulkEnachListError' })
                if (response?.data?.status === 404) {
                    setLoading(false)
                    return setListdata([])
                }
                else {
                    setCount(response?.data?.count || 0)
                    let data = response?.data?.response.map((item , index) => {
                        return {
                            no: temp + index +1,                
                            batch_id : item?.batch_id,
                            upload_date_time : moment(item?.upload_date_time).format(dateFormats?.dmyHms),
                            user_name : item?.user_name,
                            file_name: item?.file_name,
                            upload_status : item?.upload_status,
                            total_count: item?.total_count,
                            success_count: item?.success_count,
                            rejected_count: item?.rejected_count,
                        };

                    })
                    if (data?.length > 0) {
                        setListdata([...data])
                    }
                }

            }
        }).catch((error) => {
            setLoading(false)
            return setListdata([])
        }).finally(() => {
            setdownloadFlag(false)
            setLoading(false);
        })


    }


    const changePage = (value) => {
        listDataByMonthApi(value)
        setPageNumber(value - 1);
    };



    useEffect(() => {
        listDataByMonthApi();
    }, [selectMonth]);

    return (

        <div>
            {downloadFlag && (
                <div className='d-flex justify-content-center d-flex align-items-center loaderDiv'>
                    <SystemXLoader />
                </div>
            )}

            <div className="mx-3 mt-4">
                <div >
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <div className="right-div-header">
                            <div className="mt-1">
                                <label className=" text-nowrap common-header">Swap Reversal File Log </label>
                            </div>
                        </div>
                        <div className="d-flex gap-4 right-div-header  mandateui">
                            <div>
                                <select
                                    value={selectMonth}
                                    onChange={(e) => {
                                        setSelectMonth(e?.target?.value);
                                    }}
                                    className="form-select selectInput"
                                >
                                    <option key="Select Month" hidden value>
                                        Select Month
                                    </option>
                                    {selectoptions?.length &&
                                        selectoptions.map((value) => {
                                            return <option value={value.value}>{value.label}</option>;
                                        })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="mandateTableWrapper">

                        <CustomTable
                            cols={[
                                {
                                    id: "no",
                                    title: "Sr No.",
                                    classStyle: {},
                                },
                                {
                                    id: "batch_id",
                                    title: "Batch Id",
                                    classStyle: {},
                                },
                                {
                                    id: "upload_date_time",
                                    title: "Upload Time",
                                    classStyle: {},
                                },
                                {
                                    id: "user_name",
                                    title: "Username",
                                    classStyle: {},
                                },
                                {
                                    id: "file_name",
                                    title: "File Name",

                                    classStyle: {},
                                },
                                {
                                    id: "upload_status",
                                    title: "Status",
                                    classStyle: {},
                                },
                                {
                                    id: "total_count",
                                    title: "Total",
                                    classStyle: {},
                                },
                                {
                                    id: "success_count",
                                    title: "Success",
                                    classStyle: {},
                                },
                                {
                                    id: "rejected_count",
                                    title: "Reject",
                                    classStyle: {},
                                },
                            ]}
                            data={listdata}
                            count={count}
                            setPagination={(page) => changePage(page)}
                            activePage={pageNumber}
                            loading={loading}

                        />
                    </div>
                </div>
            </div>

        </div>

    )
}
  export default SwapReversalList;