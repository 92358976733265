import React from 'react';
import './formLabel.scss'

function FormLabel(props) {
	return (
		<>
			<label className={props.className}>{props.value}</label>
		</>
	);
}

export default FormLabel;
