import React, { useEffect, useState } from "react";
import "../enach/Enach.scss";
import CustomTable from "../../component/customtable/CustomTable";
import Button from "../../component/common/Button/button";
import apiCall from "../../utils/apiFunction/apiCall";
import {
  componentRole,
  selectoptions,
} from "../../constants/Common/commonConstants";
import "../../styles/layout/common.scss";
import SystemXLoader from "../../component/common/Loader/SystemXLoader";
import { toast } from "react-toastify";
import {
  apiConstants,
  enachApiConstants,
  bulkDownloadFlags,
  tableNamePaymentConstants,
  DtRepresentationTableOrder,
  errMessage,
  toastIds,
  mandateAttributes,
} from "../../constants/Common/apiConstants";
import moment from "moment";

const Mandate = () => {
  const [selectMonth, setSelectMonth] = useState(1);
  const [count, setCount] = useState(0);
  const [listdata, setListdata] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [loading, setLoading] = useState(false);
  const [downloadFlag, setdownloadFlag] = useState(false);

  const listDataByMonthApi = (value) => {
    setdownloadFlag(true);
    let temp = 0;
    if ((value || pageNumber) > 0) {
      temp = value ? (value - 1) * 10 : (pageNumber - 1) * 10;
    }
    setLoading(true);
    const params = {
      tablename: tableNamePaymentConstants?.BulkMandateReg,
      month: selectMonth,
      offset: temp || 0,
      attributes: mandateAttributes,
      limit: apiConstants?.limit,
      order: DtRepresentationTableOrder,
    };
    apiCall
      .post(apiConstants?.fetchAllData, params)
      .then((response) => {
        if (response.status === 200) {
          if (response?.data?.status === 500)
            return toast.error(errMessage?.InternalServerError, {
              toastId: toastIds?.InternalServerError,
            });
          if (response?.data?.status === 404) {
            setLoading(false);
            return setListdata([]);
          } else {
            setCount(response?.data?.count || 0);
            let data = response?.data?.response.map((item , index) => {
              return {
                no: temp + index +1,                
                batch_id: item?.batch_id,
                file_name: item?.file_name,
                uploder_name: item?.user_name,
                date_time: moment(item?.upload_date_time).format('DD-MM-YYYY HH:mm:ss'),
                fupload_status: item?.upload_status,
                total_records: item?.total_count,
                record_success:
                  item?.success_count > 0
                    ? addDownloadIcon(
                        item?.success_count,
                        item?.batch_id,
                        bulkDownloadFlags.SUCCESS
                      )
                    : item?.success_count,
                record_reject:
                  item?.rejected_count > 0
                    ? addDownloadIcon(
                        item?.rejected_count,
                        item?.batch_id,
                        bulkDownloadFlags.REJECT
                      )
                    : item?.rejected_count,
                sms_yes:
                  item?.sms_yes > 0
                    ? addDownloadIcon(
                        item?.sms_yes,
                        item?.batch_id,
                        bulkDownloadFlags.SMS_YES
                      )
                    : item?.sms_yes,
                sms_no:
                  item?.sms_no > 0
                    ? addDownloadIcon(
                        item?.sms_no,
                        item?.batch_id,
                        bulkDownloadFlags.SMS_NO
                      )
                    : item?.sms_no,
              };
            });
            if (data?.length > 0) {
              setListdata([...data]);
            }
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        return setListdata([]);
      })
      .finally(() => {
        setdownloadFlag(false);
        setLoading(false);
      });
  };

  const changePage = (value) => {
    listDataByMonthApi(value);
    setPageNumber(value - 1);
  };

  const addDownloadIcon = (count, batch_id, flag) => {
    return (
      <>
        <div className="d-flex justify-content-between">
          <span className="text-primary">{count}</span>
          <Button
            onClick={() => {
              downloadFile(batch_id, flag);
            }}
            role={componentRole?.TRANSPARENT_DOWNLOAD || ""}
          />
        </div>
      </>
    );
  };



  const downloadFile = (batch_id, flag) => {
    let api_url;
    switch (flag) {
      case bulkDownloadFlags.REJECT:
        api_url = `${enachApiConstants?.bulkMandateRejectedRecords}/${batch_id}`;
        break;

      case bulkDownloadFlags.SUCCESS:
        api_url = `${enachApiConstants?.bulkMandateSuccessRecords}/${batch_id}`;
        break;

      case bulkDownloadFlags.SMS_NO:
        api_url = `${enachApiConstants?.bulkMandateSMSReports}/${batch_id}/${0}`;
        break;

      case bulkDownloadFlags.SMS_YES:
        api_url = `${enachApiConstants?.bulkMandateSMSReports}/${batch_id}/${1}`;
        break;

      default:
        window.location.reload();
    }
    setdownloadFlag(true);
    apiCall
      .get(api_url)
      .then((response) => {
        if (response.status === 200) {
          if (response?.data?.status === 500)
            return toast.error(errMessage?.InternalServerError, {
              toastId: toastIds?.InternalServerError,
            });
          if (response?.data?.status === 404)
            return toast.info(errMessage?.NoRecordPresent, {
              toastId:toastIds?.DataError,
            });
          if (response?.data?.status === 200)
            return (window.location.href = response?.data?.url);
        }
      })
      .catch((error) => {
        setdownloadFlag(false);
        toast.error(errMessage?.InternalServerError, {
          toastId: toastIds?.InternalServerError,
        });
      })
      .finally(() => {
        setdownloadFlag(false);
      });
  };

  useEffect(() => {
    listDataByMonthApi();
  }, [selectMonth]);

  return (
    <div className="mx-3 mt-4">
      {downloadFlag && (
        <div className="d-flex justify-content-center d-flex align-items-center loaderDiv">
          <SystemXLoader />
        </div>
      )}

      <div>
        <div>
          <div className="d-flex flex-wrap gap-2 align-items-center justify-content-between  mt-4 mb-3 ">
            <div className="right-div-header">
              <div className="mt-1">
                <label className="texsint-nowrap pl-0 common-header">
                  Mandate Registration 
                </label>
              </div>
            </div>
            <div className="d-flex gap-3 left-div-header">
                <div>
                  <select
                    value={selectMonth}
                    onChange={(e) => {
                      setSelectMonth(e?.target?.value);
                    }}
                    className="form-select selectInput cursor-pointer"
                  >
                    <option key="Select Month" hidden value>
                      Select Month
                    </option>
                    {selectoptions?.length &&
                      selectoptions.map((value) => {
                        return (
                          <option value={value.value}>{value.label}</option>
                        );
                      })}
                  </select>
                </div>
                <div>
                <div className="refreshBtn">
                    <Button
                 onClick={() => {
                 window.location.reload();
                }}
              value='Refresh'
              role={componentRole.PRIMARY}
            />
                </div>
                </div>
            </div>
          </div>
          <div className="mandateTableWrapper">
            <CustomTable
              cols={[
                {
                  id: "no",
                  title: "Sr.No",
                  classStyle: {},
                },
                {
                  id: "batch_id",
                  title: "Batch ID",
                  classStyle: {},
                },
                {
                  id: "file_name",
                  title: "File Name",
                  classStyle: {},
                },
                {
                  id: "uploder_name",
                  title: "Uploader Name",
                  classStyle: {},
                },
                {
                  id: "date_time",
                  title: "Date & Time",
                  classStyle: {},
                },
                {
                  id: "fupload_status",
                  title: "File Upload Status",
                  classStyle: {},
                },
                {
                  id: "total_records",
                  title: "Total Records",
                  classStyle: {},
                },
                {
                  id: "record_success",
                  title: "Record Successful",
                  classStyle: {},
                },
                {
                  id: "record_reject",
                  title: "Record Rejected",

                  classStyle: {},
                },
                {
                  id: "sms_yes",
                  title: "SMS Sent",
                  classStyle: {},
                },
                {
                  id: "sms_no",
                  title: "SMS Not Sent",
                  classStyle: {},
                },
              ]}
              data={listdata}
              count={count}
              setPagination={(page) => changePage(page)}
              activePage={pageNumber}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Mandate;
