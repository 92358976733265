import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./responseModal.scss"
const ResponseModal = ({ heading, data, show, onHide, color }) => {
  const handleClose = () => onHide();
  const handleShow = () => onHide(true);

  const modalBodyClassName = `alert alert-${color || 'info'}`; // Default to 'info' if color is not provided

  return (
    <>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header className='custom-modal-header' closeButton>
          <Modal.Title className='custom-modal-title'>{heading}</Modal.Title>
        </Modal.Header>
        <div className='bufferMargin'></div>
        
        <Modal.Body className={modalBodyClassName}>{data}</Modal.Body>
        <Modal.Footer>
          <Button className='custom-modal-button-danger' onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ResponseModal;
