import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const dateTimeReducer = createSlice({
  name: "dateTime",
  initialState,
  reducers: {
    setFromDateTime: (state, action) => {
      state.fromDateTime = action.payload;
    },
    setToDateTime: (state, action) => {
      state.toDateTime = action.payload;
    },
  },
});
export const { setFromDateTime, setToDateTime } = dateTimeReducer.actions;
export const selectArray = (state) => state.dateTime.enachTable;
export const dateTime = dateTimeReducer.reducer;