import React, { useEffect, useState } from "react";
import Dropdown from "../../common/Dropdown/dropdown";
import InputField from "../../common/Inputfield/inputfield";
import Button from "../../common/Button/button";
import FormLabel from "../../common/formLabel/formLabel";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { number, object, string } from "yup";
import FormikSelect from "../../common/formikSelect/formikSelect";
import apiCall from "../../../utils/apiFunction/apiCall";
import { toast } from "react-toastify";
import "./CustomerDetails.scss";
import { customerDetails } from "../../../redux/reducer/SingleMandateReducer";
import { useDispatch, useSelector } from "react-redux";
import { apiConstants, FOMRS } from "../../../constants/Common/apiConstants";
import { useLocation, useNavigate } from "react-router-dom";
import { YupMessages } from "../../../constants/Common/commonConstants";

function CustomerDetails(props) {
  const { state } = useLocation();

  const formType = state?.type || FOMRS.ADD;

  const fetchDataById = (id) => {
    const params = {
      tablename: "manualMandate",
      whereQuery: { id: id },
      attributes: [
        "customer_name",
        "mobile_number",
        "std_code",
        "boardline_no",
        "office_email",
        "personal_email",
      ],
    };
    apiCall
      .post(apiConstants?.findData, params)
      .then((response) => {
        if (response.status === 200) {
          const result = response?.data?.response[0];
          setFormData({
            customer_name: result?.customer_name,
            mobile_number: result?.mobile_number,
            std_code: result?.std_code,
            board_line_no: result?.board_line_no,
            office_email: result?.office_email,
            personal_email: result?.personal_email,
          });
        }
      })
      .catch((error) => {
        toast.error("Internal Server Error", { toastId: "FetchError" });
      });
  };

  useEffect(() => {
    if (formType === FOMRS.EDIT) {
      fetchDataById(state?.id);
    }
  }, []);

  const dispatch = useDispatch();
  const { handleNext, handleBack } = props;
  const [formData, setFormData] = useState({
    customer_name: "",
    mobile_number: "",
    std_code: "",
    board_line_no: "",
    office_email: "",
    personal_email: "",
  });
  const onContinue = (customerValues) => {
    dispatch(customerDetails({ customerValues }));
    return handleNext();
  };

  const onBack = (customerValues) => {
    dispatch(customerDetails({ customerValues }));
    return handleBack();
  };
  let validSchema = object({
    customer_name: string().test(YupMessages?.isValid , YupMessages?.InvalidCustomerName , (value) =>  /^[A-Za-z\s]*[A-Za-z][A-Za-z\s]*$/.test(value)).required(YupMessages?.CustomerNameIsRequired).matches(/^(?!.*<script>).*$/, YupMessages?.InvalidCustomerName),
    office_email : string().email(YupMessages?.InvalidEmail).matches(/^(?!.*<script>).*$/, YupMessages?.InvalidEmail),
    std_code : string().test(YupMessages?.isValid, YupMessages?.InvalidSTDCode , (value) => /^[a-zA-Z0-9]+$/.test(value)).matches(/^(?!.*<script>).*$/, YupMessages?.InvalidSTDCode),
    board_line_no : string().test(YupMessages?.isValid , YupMessages?.InvalidBoadLineNo , (value) => /^[a-zA-Z0-9]+$/.test(value)).matches(/^(?!.*<script>).*$/, YupMessages?.InvalidBoadLineNo),
    mobile_number: string()
      .test(
        YupMessages?.isNumber,
        YupMessages?.PleaseRnterValidMobileNumber,
        (value) => !isNaN(value)
      )
      .test(
        YupMessages?.IsTenDigits,
        YupMessages?.MobileNumberMustHave10Digits,
        (value) => value && value.length === 10
      )
      .required(YupMessages?.MobileNumberRequired)
      .matches(/^(?!.*<script>).*$/, YupMessages?.PleaseRnterValidMobileNumber),
    personal_email: string()
      .email(YupMessages?.InvalidEmail)
      .required(YupMessages?.PersonalEmailIsRequired)
      .matches(/^(?!.*<script>).*$/, YupMessages?.PersonalEmailIsRequired),
  });
  const customerFormDetails = useSelector((state) => {
    return state?.SingleMandate?.customerDetails;
  });
  useEffect(() => {
    setFormData(customerFormDetails?.customerValues);
  }, [customerFormDetails]);
  return (
    <>
      <Formik
        initialValues={{
          customer_name: formData?.customer_name ? formData?.customer_name : "",
          mobile_number: formData?.mobile_number ? formData?.mobile_number : "",
          std_code: formData?.std_code ? formData?.std_code : "",
          board_line_no: formData?.board_line_no ? formData?.board_line_no : "",
          office_email: formData?.office_email ? formData?.office_email : "",
          personal_email: formData?.personal_email
            ? formData?.personal_email
            : "",
        }}
        validationSchema={validSchema}
        enableReinitialize={true}
        onSubmit={(values) => onContinue(values)}
      >
        {(props) => {
          const { values, handleBlur } = props;
          return (
            <Form>
              <div className="row customer_details">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                  <div className="field_name">
                    <FormLabel value="Customer Name" className="required" />
                  </div>
                  <Field
                    type="text"
                    className="InputField"
                    name="customer_name"
                    disabled={state?.type === FOMRS.EDIT ? true : false}
                    id="customer_name"
                    placeholder="Customer Name"
                  ></Field>
                  <div className="errorMessage">
                    <ErrorMessage name="customer_name" />
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                  <div className="field_name">
                    <FormLabel value="Mobile Number" className="required" />
                  </div>
                  <Field
                    type="text"
                    className="InputField"
                    name="mobile_number"
                    id="mobile_number"
                    disabled={state?.type === FOMRS.EDIT ? true : false}
                    placeholder="Mobile Number"
                  ></Field>
                  <div className="errorMessage">
                    <ErrorMessage name="mobile_number" />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 p-2">
                  <div className="field_name">
                    <FormLabel value="STD Code" />
                  </div>
                  <Field
                    type="text"
                    className="InputField"
                    name="std_code"
                    disabled={state?.type === FOMRS.EDIT ? true : false}
                    id="std_code"
                    placeholder="STD Code"
                  ></Field>
                  <div className="errorMessage">
                    <ErrorMessage name="std_code" />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 p-2">
                  <div className="field_name">
                    <FormLabel value="Board Line No" />
                  </div>
                  <Field
                    type="text"
                    className="InputField"
                    name="board_line_no"
                    id="board_line_no"
                    disabled={state?.type === FOMRS.EDIT ? true : false}
                    placeholder="Board Line No"
                  ></Field>
                  <div className="errorMessage">
                    <ErrorMessage name="board_line_no" />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2">
                  <div className="field_name">
                    <FormLabel value="Office Email" />
                  </div>
                  <Field
                    type="text"
                    className="InputField"
                    name="office_email"
                    id="office_email"
                    disabled={state?.type === FOMRS.EDIT ? true : false}
                    placeholder="Office Email"
                  ></Field>
                  <div className="errorMessage">
                    <ErrorMessage name="office_email" />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2">
                  <div className="field_name">
                    <FormLabel value="Personal Email" className="required" />
                  </div>
                  <Field
                    type="text"
                    className="InputField"
                    name="personal_email"
                    id="personal_email"
                    disabled={state?.type === FOMRS.EDIT ? true : false}
                    placeholder="Personal Email"
                  ></Field>
                  <div className="errorMessage">
                    <ErrorMessage name="personal_email" />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2"></div>
                <div className="d-flex flex-wrap gap-3">
                  <div>
                    {/* <Button
                    role="primary"
                    value="Back"
                    type="submit"
                    onClick={() => onBack(values)}
                  />*/}
                    <button className="customer_details-submitBtn" type="submit" onClick={() => onBack(values)}>
                      Back
                    </button>
                  </div>

                  <div>
                    {/* <Button
                    role="primary"
                    value="Next"
                    type="submit"
                    onClick={() => ''}
                  /> */}
                    <button type="submit" className="customer_details-nextBtn">Next</button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
export default CustomerDetails;
