import { useState, useEffect } from "react";
import "./upiExecutionUpdate.scss"
import { useNavigate } from "react-router-dom";
import apiCall from "../../../utils/apiFunction/apiCall";
import uploadPresigned from "../../../utils/presignedUpload";
import DynamicForm from "../../../component/common/DynamicForm/dynamicForm";
import { toast } from "react-toastify";
import moment from "moment";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import { useSelector } from "react-redux";
import Papa from 'papaparse';
import {
  apiConstants,
  errMessage,
  toastIds,
} from "../../../constants/Common/apiConstants";
import { dateFormats, S3FILEPATH } from "../../../constants/Common/commonConstants";
import { tableNamePaymentConstants } from "../../../constants/Common/apiConstants";
import ResponseModal from "../../../component/responseModal/responseModal";
import CustomTable from "../../../component/customtable/CustomTable";

const UPIExecutionUpdate = () => {
  const [uploadPercent, setUploadPercent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [fileData, setFileData] = useState();
  const [count, setCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [downloadFlag, setdownloadFlag] = useState(false);
  const [modalData, setModalData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [companyCode, setCompanyCode] = useState([]);
  const [mandateOptions, setMandateOptions] = useState([{value:"UPI Mandate", label:"UPI Mandate"}]);
  const [listData, setListData] = useState([]);
  const [formValues , setFormValues ] = useState();
  const loginState = useSelector((state) => state.login);
  const CompanyCode = () => {
    let paramObj = {
      offset: 0,
      limit: 10,
      groupby: "company_code",
      tablename : tableNamePaymentConstants?.new_scan_reg_bank_list,
    };
    apiCall
      .post(apiConstants?.findData, paramObj)
      .then(async (res) => {
        let result = res?.data?.response;

        result = result.map((item) => {
          let label;

          switch (item?.company_code) {
            case "7000":
              label = "Tata Capital Ltd";
              break;
            case "9000":
              label = "Tata Capital Housing Finance Ltd";
              break;
            default:
              label = item?.company_code;
              break;
          }

          let newItem = {
            value: item?.company_code,
            label: label,
          };

          return newItem;
        });

        setCompanyCode([...result]);
      })
      .catch((er) => {
        console.log("errr =>>>>", er);
      });
  };
  const processFile = (file) => {
    const expectedHeaders = [
        'mandate_type',
        'company_code',
        'source_system',
        'loan_no',
        'mandate_date',
        'mandate_amount'
    ];

    Papa.parse(file, {
        header: true,
        complete: (results) => {
            const headers = results.meta.fields;
            console.log("heas-------------- ",headers)
            const headersMatch = expectedHeaders.every(header => headers.includes(header));
            if (!headersMatch) {
                toast.error("Invalid header", {
                    toastId: "fileUploadFail",
                });
                return;
            }
            toast.success("Headers match", {
                toastId: "fileUploadSuccess",
            });
        },
        error: (err) => {
            toast.error(`Error processing CSV: ${err.message}`);
        }
    });
};

const onFileUpload = async (file) => {
  if (file.length > 0) {
      const filePath = file[0]?.path;
      const params = {
          tablename: tableNamePaymentConstants.UPI_EXE_UPDATE_FILE_LOG,
          dataFetch: [
              {
                  column: "file_name",
                  operator: "eq",
                  value: filePath,
              }
          ],
      };

      try {
          const res = await apiCall.post(apiConstants.fetchAllData, params);
          if (res.status === 200) {
              const responseData = res.data;
              const fetchedFileName = responseData.response;
              const duplicateFileName = fetchedFileName.some(f => f.file_name === filePath);
              if (duplicateFileName) {
                  toast.error("Duplicate file name", {
                      toastId: "fileUploadFail",
                  });
              } else {
                setFileData(file)
                  processFile(file[0]);
              }
          }
      } catch (error) {
          toast.error("Error fetching data", {
              toastId: "fileUploadFail",
          });
      }
  }
};

const onSubmit = (data) => {
  if (data?.length) {
    getPresigned(fileData);
  } 
};

  const uploadLoder = (data) => {
    setUploadPercent(data);
  }

  const getPresigned = (value) => {
    let userData = loginState?.decodedData;
    setLoading(true);
    const currentDate = moment().format("YYYY_MM_DD");
    const [year, month, day] = currentDate.split("_");
    let key = `${year}/${month}/${day}/${S3FILEPATH.DT.DT}/Uploads/UPI_Update/${value[0]?.name}`
    apiCall
      .get(
        `${apiConstants?.uploadPreSignedUrl}?key=${key}`
      )
      .then(async (res) => {
        if (res.status === 200) {
          let apiBody = {
            file_name: value[0]?.name,
            s3_file_location: key,
            uploaded_by: userData.user_name,
            file_status: "Processing",
            mandate_date:moment(formValues[2].value).format(dateFormats.dmyHms),
            mandate_type:formValues[0]?.value,
            company_code:formValues[1]?.value,           
          }
          console.log("apibody----------",apiBody)
          let result = res?.data;
          if (result) {
            uploadPresigned(value[0], result, uploadLoder)
              .then((res) => {
                if (res.status == 200) {
                  apiCall.post(apiConstants?.createUser, {
                    body: apiBody,
                    tablename: "upi_exe_update_file_log",
                  })
                    .then(async (res) => {

                      if (res?.data?.status === 200) {
                        apiCall.post(`${apiConstants?.UPI_PROCESS_CSV_FROM_S3}?id=${res.data.response.data.id}`, {
                          s3Key:key
                        })
                        .then((res)=>{
                          console.log("res in --- ",res)
                          if(res.data.status==200){
                          toast.success(
                            res?.data?.message || "Success",
                            {
                              toastId: toastIds?.FileUploaderr,
                            }
                          );
                          tableAPI();
                        }
                          else{
                          toast.error(
                            res?.data?.message || errMessage?.somethingWentWrong,
                            {
                              toastId: toastIds?.FileUploaderr,
                            }
                          );}
                        })
                        .catch(()=>{

                        })
                        setFileData(res.data.response.data.id);
                      } else {
                        toast.error(
                          res?.data?.message || errMessage?.somethingWentWrong,
                          {
                            toastId: toastIds?.FileUploaderr,
                          }
                        );
                      }
                    })
                    .catch((er) => {
                      console.log("err", er);
                      let resp = er?.message || "";
                      toast.error(resp, { toastId: toastIds?.FileUploaderr });
                    })
                }
              })
              .catch((e) => {
                toast.error(e?.message || errMessage?.fileUploadFailed, {
                  toastId: toastIds?.FileUploaderr,
                });
              });
          }
        }
      })
      .catch((er) => {
        toast.error(er?.message || errMessage?.fileUploadFailed, {
          toastId: toastIds?.FileUploaderr,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
 
  const tableAPI = (value) => {
    let page=0;
    if ((value || pageNumber) > 0) {
      page = value ? (value - 1) * 10 : (pageNumber - 1) * 10;
    }
    setAutoRefresh(true)
    let fetchAttribute=["file_name","uploaded_by","mandate_date","file_status"]
    apiCall
      .post(apiConstants?.fetchAllData, {
        tablename: tableNamePaymentConstants.UPI_EXE_UPDATE_FILE_LOG,
        attributes: fetchAttribute,
        offset: page,
        limit: 10,
        order: [["id", "desc"]],
      })
      .then(async (res) => {
        if (res?.data?.status == 200) {
          setCount(res?.data?.count)
          let result = res?.data?.response;
          result = result.map((item, index) => {
            let newItem = {
              idData: index + 1,
              date: 
                item?.mandate_date,
              file_name: item?.file_name || "",
              file_status: item?.file_status || "",
              uploaded_by: item?.uploaded_by || ""
            };
            return newItem;
          });
          let slice20 = result.slice(0,20)
          if (slice20?.length > 0) {
            setListData([...slice20]);
          }
        } else {
          setListData([]);
          toast.error(res?.data?.message, {
            toastId: toastIds?.DataError,
          });
        }
      })
      .catch((er) => {
        setListData([]);
        toast.error(er?.message, { toastId: toastIds?.DataError });
      }).finally(() => {
        setAutoRefresh(false)
      })
  };
  useEffect(() => {
    CompanyCode();
    tableAPI();
  }, []);
  const changePage = (value) => {
    tableAPI(value);
    setPageNumber(value - 1);
  };
  useEffect(() => {
    setFormValues([
        {
            type: "dropdown",
            value: "",
            label: "Mandate Type",
            placeholder: "Please select value",
            required : true,
            width: 6,
            options : mandateOptions,
          },
      {
        type: "dropdown",
        value: "",
        label: "Company Code",
        placeholder: "Please select value",
        required : true,
        width: 6,
        options: companyCode,
      },
      
      {
        type: "dateinput",
        value: "",
        label: "Settlement Date",
        placeholder: "Select Date",
        id:"settlement_date",
        required: true,
        width: 6,
        minDate:moment().add(1, 'days').format("YYYY-MM-DD"),
      },{
        type: "fileupload",
        width: 12,
        label: "Upload File",
        displaycomponent: <div className="uploadComponentLabel">Upload File</div>,
      } 
    ])
  }, [companyCode]);

  return (
    <>
    <ResponseModal
          heading="Sftp file uploaded succesfully"
          data={modalData}
          show={showModal}
          onHide={() => setShowModal(false)}
          color='info'
    />
    <div>
      {downloadFlag && (
        <div className="className='row justify-content-center d-flex loaderDiv">
          <SystemXLoader />
        </div>
      )}
      <div className="mx-3 pt-4 ">
        <div className="row justify-content-center ">
          <div className="row pb-4 ">
            <div className="col-md-6 pl-0 mt-2">
                <span className="text-nowrap headername">UPI Representation Exclusive</span>
            </div>
          </div>
          <div>
            <div className="mycontainerstyle reportui pb-3">
              <div className="pt-4 ms-3 ">
                <div className="row">
                  <div className="col-xl-9 col-md-6 col-lg-8 col-sm-12">
                    <div className="mr-3">
                      <DynamicForm
                        uploadPercent={uploadPercent}
                        onFileUpload={onFileUpload}
                        formValues={formValues}          
                        onSubmit={(data)=>{
                          console.log("clieck daat==== ",data);
                          onSubmit(data)}}
                        closeForm={()=>window.location.reload()}
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6 col-lg-4 col-sm-12">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {listData.length > 0 && (
              <>
                <CustomTable
                  cols={[
                    {
                      id: "idData",
                      title: "ID",
                      classStyle: {},
                    },
                    {
                      id: "date",
                      title: "Mandate Date",
                      classStyle: {},
                    },
                    {
                      id: "file_name",
                      title: "File Name",
                      classStyle: {},
                    },
                    {
                      id: "file_status",
                      title: "File Status",
                      classStyle: {},
                    },
                    {
                      id: "uploaded_by",
                      title: "Uploaded By",
                      classStyle: {},
                    }
                  ]}
                  data={listData}
                  count={count}
                  setPagination={(page) => changePage(page)}
                  activePage={pageNumber}
                />
              </>
            )}
      </div>
      
    </div>
    </>
  );
};
export default UPIExecutionUpdate;