import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import FormLabel from '../../../common/formLabel/formLabel';
import { ROUTE_PATH } from '../../../../constants/Routes';
import React, { useState, useEffect } from 'react';
import FormikSelect from '../../../common/formikSelect/formikSelect';
import Button from '../../../common/Button/button';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../../systemMaster/mandateType/createMandateType/createMandateType.scss';
import apiCall from '../../../../utils/apiFunction/apiCall';
import { apiConstants, FOMRS, STATUS, tableNamePaymentConstants, errMessage, successMsg, toastIds, isActiveDropdowns } from '../../../../constants/Common/apiConstants';
import { YupMessages } from '../../../../constants/Common/commonConstants';
import { toast } from 'react-toastify';

const validationSchema = Yup.object({
	mandate_type: Yup.string().required(YupMessages?.MandateTypeRequired)
		.matches(/^(?!.*<script>).*$/, "Mandate Type cannot contain scripts or HTML tags")
		.matches(/^(?!.*<\/?[a-z][\s\S]*>).*$/, "Mandate Type cannot contain scripts or HTML tags")
		.matches(/^[A-Za-z]/, "Mandate Type can only contain alphabets")
		.test('no-spaces', 'Mandate Type cannot be only spaces', (value) => value.trim() !== '').matches(/^[^!@#$%^&*]+$/, " Name cannot contain symbols"),
	name: Yup.string().required(YupMessages?.NameRequired)
		.matches(/^(?!.*<script>).*$/, "Name cannot contain scripts or HTML tags")
		.matches(/^(?!.*<\/?[a-z][\s\S]*>).*$/, "Name cannot contain scripts or HTML tags")
		.matches(/^[A-Za-z]/, "Name can only contain alphabets")
		.matches(/^[^!@#$%^&*]+$/, " Name cannot contain symbols")
		.test(
			"no-spaces",
			"Name cannot be only spaces",
			(value) => value.trim() !== ""
		).matches(/^[^0-9]*$/, '* No Numbers Allowed'),
	isActive: Yup.string().required(YupMessages?.isActiveRequired),
});

const CreateMandate = () => {
	const navigate = useNavigate();
	const { state } = useLocation();

	const formType = state?.type || FOMRS.ADD;

	const headingText =
		formType === FOMRS.ADD ? "Create Mandate Type" : "Update Mandate Type";

	const [formData, setFormData] = useState({
		mandate_type: '',
		mandate_name: '',
		isActive: '',
	});

	const [loading, setLoading] = useState(false);


	const FormSubmit = (value) => {
		let query = {
			body: {
				mandate_type: value.mandate_type ? value.mandate_type : '-',
				mandate_name: value.name ? value.name : '-',
				isActive: value.isActive ? value.isActive : '-',
			},
			tablename: tableNamePaymentConstants?.MandateType,
		};
		let url;
		let toastMessage;
		if (state?.type === FOMRS.EDIT) {
			url = apiConstants?.updateUser
			query.whereQuery = {
				mandate_type: state?.user_type?.mandate_type,
			};
			toast.success(successMsg?.MandateTypeUpdatedSuccessfully, toastIds?.FileUploadSuccess)

		} else {
			url = apiConstants?.createUser
			toast.success(successMsg?.MandateTypeCreatedSuccessfully, toastIds?.FileUploadSuccess)

		}
		apiCall.post(url, query).then(async (res) => {
			if (res?.status === STATUS.SUCCESS) {
				navigate(ROUTE_PATH.E_MANDATE_TYPE);
			}
		});
	};

	const getFormData = async (id) => {
		let bodyParams = {
			tablename: tableNamePaymentConstants?.MandateType,
			whereQuery: {
				id,
			},
		};
	};

	useEffect(() => {
		if (formType === FOMRS.EDIT) {
			setFormData({
				mandate_type: state?.user_type?.mandate_type,
				name: state?.user_type?.mandate_name,
				isActive: state?.user_type?.isActive,
			});
		}
	}, [loading]);

	return (
		<Formik
			initialValues={{
				mandate_type: formData.mandate_type,
				name: formData.name,
				isActive: formData.isActive,
			}}
			validationSchema={validationSchema}
			enableReinitialize={true}
			onSubmit={(values) => {
				FormSubmit(values);
			}}
		>
			{({ values, setFieldValue }) => (
				<>
					<div id="createCompany">
						<div
							className="col blueFonts large_f"
							onClick={() => navigate(ROUTE_PATH.E_MANDATE_TYPE)}
						>
							{`Mandate Type >> `}
							<span className="blackFonts">{headingText}</span>
						</div>
						<div className="main-div">
							<Form className=".labler-wrapper">
								<div id="RegularForm" className='ml-3'>
									<div className="createroletext">
										<p id="pText">{headingText}</p>
									</div>

									<div className="row paddingTop ml-4 mr-4">
										<div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
											<div>
												<FormLabel value="Mandate Type" className="required" />
											</div>
											<Field
												type="text"
												className="InputField"
												name="mandate_type"
												id="mandate_type"
												placeholder="Mandate"
												disabled={state?.type === FOMRS.EDIT ? true : false}
											></Field>
											<div className="errorMessage">
												<ErrorMessage name="mandate_type" />
											</div>
										</div>
									</div>

									<div className="row paddingTop ml-4 mr-4">
										<div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
											<FormLabel value="Name" className="required" />
											{/* <Input /> */}
											<Field
												type="text"
												className="InputField"
												name="name"
												id="name"
												placeholder="Name"
											></Field>
											<div className="errorMessage">
												<ErrorMessage name="name" />
											</div>
										</div>
									</div>

									<div className="row paddingTop ml-4 mr-4">
										<div className="col-md-12 col-lg-12 medium_f_bold directionColumn ">
											<div className="formState">
												<FormLabel value="Is Active" className="required" />
												<FormikSelect
													label="Please select a value"
													name="isActive"
													options={isActiveDropdowns}
												/>
											</div>
											<div className="errorMessage">
												<ErrorMessage name="isActive" />
											</div>
										</div>
									</div>

									<div className=" d-flex py-3">
										<div className="cancel_btn">
											<Button
												className='mr-1'
												role="secondary"
												value="Cancel"
												type="button"
												onClick={() => navigate(ROUTE_PATH.E_MANDATE_TYPE)}
											/>
										</div>

										<div className="submitButton">
											<Button
												className='mx-1'
												role="primary"
												value="Submit"
												type="submit"
												onClick={() => { }}
											/>
										</div>
									</div>
								</div>
							</Form>
						</div>
					</div>
				</>
			)}
		</Formik>
	);
};

export default CreateMandate;
