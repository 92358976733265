import React, { useEffect, useState } from "react";
import "./DemergeSystemCountData.scss";
import CustomTable from "../../../component/customtable/CustomTable";
import Button from "../../../component/common/Button/button";
import {
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  commonVariables,
  componentRole,
  route,
  tableID,
} from "../../../constants/Common/commonConstants";
import { apiConstants } from "../../../constants/Common/apiConstants";
import apiCall from '../../../utils/apiFunction/apiCall'

const DebitTransactionDemergeSystemCount = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [listdata, setListdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const listApi = () => {
    setLoading(true);
    apiCall
      .get(
        `${apiConstants.debitTransactionDeMergeDemergeSourceSystem}?batchId=${id}`
      )
      .then(async (res) => {
        if (res.status === 200) {
          let result = res?.data?.response;
          let sourceData = {
            batch_id: result?.batchId,
            sap: result?.sap,
            bancs: result?.bancs,
            finnone: result?.finnone,
            sapecc6: result?.ecc6,
            manual: result?.manual,
            mandate_type: result?.mandate_type,
            bank_code: result?.bank_code,
          };
          if (sourceData) {
            let newItem = {
              batch_id: sourceData?.batch_id,
              sap:
                sourceData?.sap === 0
                  ? sourceData?.sap
                  : addLink(
                      sourceData?.sap || "",
                      sourceData?.batch_id || "",
                      sourceData,
                      commonVariables?.SAP || "",
                      commonVariables?.FINNONE
                    ),
              bancs:
                sourceData?.bancs === 0
                  ? sourceData?.bancs
                  : addLink(
                      sourceData?.bancs || "",
                      sourceData?.batch_id || "",
                      sourceData,
                      commonVariables?.BANCS || "",
                      commonVariables?.FINNONE || ""
                    ),
              finnone:
                sourceData?.finnone === 0
                  ? sourceData?.finnone
                  : addLink(
                      sourceData?.finnone || "",
                      sourceData?.batch_id || "",
                      sourceData,
                      commonVariables?.FINNONE || "",
                      commonVariables?.FINNONE || ""
                    ),
              sapecc6:
                sourceData?.sapecc6 === 0
                  ? sourceData?.sapecc6
                  : addLink(
                      sourceData?.sapecc6 || "",
                      sourceData?.batch_id || "",
                      sourceData,
                      commonVariables?.SAPECC6 || "",
                      commonVariables?.FINNONE || ""
                    ),
              manual:
                sourceData?.manual === 0
                  ? sourceData?.manual
                  : addLink(
                      sourceData?.manual,
                      sourceData?.batch_id || "",
                      commonVariables?.MANUAL
                    ),
            };
            if (newItem) {
              setListdata([newItem]);
            }
          }
        }
      })
      .catch((er) => {
        setListdata([])
        console.log("err", er);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addLink = (coldata, batchId, sourceData, system, type) => {
    return (
      <>
        <Button
          role={componentRole?.TRANSPARENT || ""}
          value={coldata}
          onClick={() => {
            downloadSourceSystemFile(batchId, sourceData, system, type);
          }}
        />
      </>
    );
  };

  const downloadSourceSystemFile = (batchId, sourceData, system, type) => {
    if (type == commonVariables?.FINNONE) {
      navigate(route?.DT_DEMERGE_FINNONE_DOWNLOAD || "", {
        state: {
          system: system,
          batchId: batchId,
          mandate_type: sourceData.mandate_type,
          bank_code: sourceData.bank_code,
        },
      });
    } else {
      navigate(route?.DEBIT_TRANSACTION_SOURCE_MANUAL_DOWNLOAD || "", {
        state: {
          batchId: batchId,
        },
      });
    }
  };

  const changePage = (value) => {
    listApi(value);
    setPageNumber(value - 1);
  };

  useEffect(() => {
    listApi();
  }, []);

  return (
    <>
    <div className="demergeSystemCountWrapper">
      <div className="d-flex align-items-center justify-content-between mt-5">
        <div className="right-div-header">
          <div>
            <label
              className="common-header cursor-pointer ps-4"
            >
              {commonVariables?.DEMERGE || ""} &gt; &gt;
            </label>
            <span className="text-nowrap ps-2">
              {commonVariables?.SYSTEM_COUNT_DATA || ""}
            </span>
          </div>
        </div>
        <div className="d-flex gap-3 left-div-header">
        </div>
      </div>
      <div className="col dtMergeTable">
        <CustomTable
          cols={[
            {
              id: tableID?.BATCH_ID || "",
              title: commonVariables?.BATCH_ID || "",
            },
            {
              id: tableID?.SAP || "",
              title: commonVariables?.SAP || "",
            },
            {
              id: tableID?.BANCS || "",
              title: commonVariables?.BANCS || "",  
            },
            {
              id: tableID?.FINNONE || "",
              title: commonVariables?.FINNONE || "",
            },
            {
              id: tableID?.SAPECC6 || "",
              title: commonVariables?.SAPECC6 || "",
            },
            {
              id: tableID?.MANUAL || "",
              title: commonVariables?.MANUAL || "",
            },
          ]}
          data={listdata}
          setPagination={(page) => changePage(page)}
          activePage={pageNumber}
          loading={loading}
        />
      </div>
      </div>
    </>
  );
};

export default DebitTransactionDemergeSystemCount;
