import React, { useEffect, useState } from "react";
import "./DtMerge.scss";
import CustomTable from "../../../component/customtable/CustomTable";
import Button from "../../../component/common/Button/button";
import InputField from "../../../component/common/Inputfield/inputfield";
import apiCall from "../../../utils/apiFunction/apiCall";

import { useDispatch, useSelector } from "react-redux";
import SideBar from "../../../component/sideBar";
import { showBar } from "../../../redux/reducer/sideBarStatus";
import DebitTransactionMergeForm from "./DtMergeForm/DtMergeForm";
import { toast } from "react-toastify";
import {
  commonVariables,
  componentRole,
  selectdownload,
  selectoptions,
  tableID,
  dateFormats
} from "../../../constants/Common/commonConstants";
import { apiConstants, errMessage, toastIds } from "../../../constants/Common/apiConstants";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import CustomTooltip from "../../../component/Tooltip/Tooltip";
import moment from "moment";

const DebitTransactionMerge = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [count, setCount] = useState(0);
  const [selectMonth, setSelectMonth] = useState(1);
  const [listdata, setListdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [downloadFlag, setDownloadFlag] = useState(false);
  const dispatch = useDispatch();
  let LoginState = useSelector((state) => state.login);


  const listApi = (value) => {
    let temp = 0;
    if ((value || pageNumber) > 0) {
      temp = value ? (value - 1) * 10 : (pageNumber - 1) * 10;
    }
    let paramObj = {
      offset: temp || 0,
      limit: apiConstants?.limit,
      month: selectMonth,
      tablename: "debit_transaction_reg_merge_status",
      order: [["batch_id", "desc"]],
      dataFetch: [
        {
          column: "total_count",
          operator: "ne",
          value: "0",
        },
      ],
    };
    setDownloadFlag(true);
    setLoading(true);
    apiCall
      .post(apiConstants?.fetchAllData, paramObj)
      .then(async (res) => {
        if (res.status === 200) {
          let result = res?.data?.response;
          setCount(res?.data?.count || 0);
          result = result.map((item, i) => {
            let newItem = {
              no: temp + i + 1,
              batch_id: item?.batch_id ? item.batch_id : "-",
              mandate: item?.mandate_type ? item.mandate_type : "-",
              bank: item?.bank_code ? item.bank_code : "-",
              records: item?.total_count ? item?.total_count : "-",
              date_time: item?.date_time ? moment(item?.date_time).format(dateFormats.dateFormatInMDYWithTimeAndAm) : "-",
              name: item?.user_name ? item?.user_name : "-",
              sftp_push: item?.sftp_push_flag ? item?.sftp_push : "-",
              download:
                item?.total_count !== "0" ? (
                  <select
                    onChange={(e) => {
                      downloadFiveField(
                        e.target.value,
                        item?.batch_id,
                        item?.mandate_type,
                        item?.bank_code
                      );
                    }}
                    className="form-select selectInput cursor-pointer"
                  >
                    <option key="Select File Type" hidden value>
                      {commonVariables.DOWNLOAD}
                    </option>
                    {selectdownload?.length &&
                      selectdownload.map((value) => {
                        if(item?.bank_code ==='HDFC'){ 
                          if(value.value !== 'Five_Field'){
                            return (
                              <option value={value.value}>{value.label}</option>
                            )}
                          // return (
                          //   <option value={value.value}>{value.label}</option>
                          // )
                        }
                        else{
                          
                          if(value.value !== 'Seven_Field'){
                          return (
                            <option value={value.value}>{value.label}</option>
                          )
                        }
                        }
                        //   
                          
                        
                      })}
                  </select>
                ) : (
                  "-"
                ),
            };

            return newItem;
          });
          if (result?.length > 0) {
            setListdata([...result]);
          }
        }
      })
      .catch((er) => {
        setListdata([]);
        console.log("err", er);
      })
      .finally(() => {
        setDownloadFlag(false);
        setLoading(false);
      });
  };
  const downloadFiveField = (selection, batch_id, mandate_type, bank_code) => {
    let userData = LoginState?.decodedData;
    let params = {
      user_name: userData?.user_name || "",
      user_code: userData?.user_code || "",
    };
    setDownloadFlag(true);
    if (selection === "Five_Field" || selection === 'Seven_Field') {
      apiCall
        .get(
          `${apiConstants.DebitTransactionMergeDownloadFiveField}?batchId=${batch_id}&BankCode=${bank_code}`
        )
        .then(async (res) => {
          if (res?.data?.status === 200) {
            const presignUrl = res?.data?.response;
            if (res?.data?.response !== "") {
              const link = document.createElement('a');
              link.href = presignUrl;
              link.click();
              toast.success(commonVariables?.FileDownloadSuccess, { toastId: toastIds?.FileDownloadSucess })
            }
            else {
              toast.error(res?.data?.message || errMessage?.somethingWentWrong, { toastId: toastIds?.SomethingWentWrong });
            }
          } else {
            toast.error(res?.data?.message || errMessage?.somethingWentWrong, { toastId: toastIds?.SomethingWentWrong });
          }
        })
        .catch((er) => {
          console.log("err", er);
        })
        .finally(() => {
          setDownloadFlag(false);
          setTimeout(function () {
            window.location.reload();
          }, 8000);
        });
    } else {
      apiCall
        .get(
          `${apiConstants.DebitTransactionMergeDownloadMergeData}?batchId=${batch_id}&mandateType=${mandate_type}&bankCode=${bank_code}`,
          { params }
        )
        .then(async (res) => {
          if (res?.data?.status === 200) {
            const presignUrl = res.data.response;
            if (res?.data?.response !== '') {
              const link = document.createElement('a');
              link.href = presignUrl;
              link.click();
              toast.success(commonVariables?.FileDownloadSuccess, { toastId: toastIds?.FileDownloadSucess })
            }
            else {
              toast.error(res?.data?.message || errMessage?.somethingWentWrong, { toastId: toastIds?.SomethingWentWrong });
            }
          }
          else {
            toast.error(res?.data?.message || errMessage?.somethingWentWrong, { toastId: toastIds?.SomethingWentWrong });
          }
        })
        .catch((er) => {
          console.log("err", er);
        })
        .finally(() => {
          setDownloadFlag(false);
          setTimeout(function () {
            window.location.reload();
          }, 8000);

        });
    }
  };

  const changePage = (value) => {
    listApi(value);
    setPageNumber(value - 1);
  };

  useEffect(() => {
    listApi();
  }, [selectMonth]);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mt-4 mb-3 mx-3 flex-wrap gap-2">
        <div className="right-div-header">
          <div className="mt-1">
            <label className="text-nowrap pl-0 common-header">Merge</label>
          </div>
        </div>
        <div className="d-flex flex-wrap gap-2 left-div-header">
          <div>
            <select
              value={selectMonth}
              disabled={loading || downloadFlag}
              onChange={(e) => {
                setSelectMonth(e?.target?.value);
              }}
              className="form-select selectInput cursor-pointer"
            >
              <option key="Select Month" hidden value>
                Select Month
              </option>
              {selectoptions?.length &&
                selectoptions.map((value) => {
                  return (
                    <option value={value.value}>{value.label}</option>);
                })}
            </select>
            {/* <Dropdown placeholder={"1 Month"} options={selectoptions}/> */}
          </div>
          <div>
            {/* <Button
              onClick={() => {
                dispatch(showBar(true));
              }}
              value={commonVariables.MERGE}
              role={componentRole.PRIMARY}
            /> */}

            <button
              disabled={loading || downloadFlag}
              onClick={() => {
                dispatch(showBar(true));
              }}
              className="mergeBtn"
            >
              Merge
            </button>

            <div>
              <SideBar
                data={<DebitTransactionMergeForm onCancel={() => listApi()} />}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col dtMergeTable">
        {downloadFlag && (
          <div className="loaderDiv">
            <SystemXLoader />
          </div>
        )}
        <CustomTable
          cols={[
            {
              id: tableID.NO || "",
              title: commonVariables.NO || "",
            },
            {
              id: tableID.BATCH_ID,
              title: commonVariables.BATCH_ID,
            },
            {
              id: tableID.MANDATE || "",
              title: commonVariables.MANDATE || "",
            },
            {
              id: tableID.BANK || "",
              title: commonVariables.BANK || "",
            },
            {
              id: tableID.RECORDS || "",
              title: commonVariables.TOTAL_RECORDS || "",
            },
            {
              id: tableID.DATE_TIME || "",
              title: commonVariables.DATE_TIME || "",
            },
            {
              id: tableID.NAME || "",
              title: commonVariables.USER_NAME || "",
            },
            {
              id: tableID.SFTP_PUSH || "",
              title: commonVariables.SFTP_PUSH || "",
            },
            {
              id: tableID.DOWNLOAD || "",
              title: commonVariables.DOWNLOAD || "",
            },
          ]}
          data={listdata}
          count={count}
          setPagination={(page) => changePage(page)}
          activePage={pageNumber}
          loading={loading}
        />
      </div>
    </>
  );
};

export default DebitTransactionMerge;