import React, { useRef } from "react";
import Select from "react-select";

function Dropdown(props) {
  const selectInputRef = useRef();

  const onClear = () => {
    selectInputRef.current.clearValue();
  };

  let customStyles = {
    option: (styles) => {
      return {
        ...styles,
        // backgroundColor: "#232135",
        color: "#000",
        borderColor: "#FFFFFF",
        // backgroundColor:'red',
      };
    },

    valueContainer: (styles) => {
      return { ...styles, backgroundColor: "#FFFFFF", color: "#000" };
    },

    control: (styles) => {
      return {
        ...styles,
        backgroundColor: "#FFFFFF;",
        borderColor: "#dcdcdc",
        overflowY: "auto",
        height: "28px",
        borderColor: "#dcdcdc",
        // marginTop: "15px",
      };
    },

    singleValue: (styles) => {
      return {
        ...styles,
        color: "#000",
        fontSize: "12px",
      };
    },

    dropdownIndicator: (styles) => {
      return { ...styles, backgroundColor: "#FFFFFF;", borderColor: "#FFFFFF" };
    },

    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#FFFFFF;",
        borderColor: "#FFFFFF;",
        color: "#000",
        fontSize: "12px",
        fontFamily: "Arial",
        fontWeight: "100",
      };
    },

    multiValueRemove: (styles) => {
      return { ...styles, backgroundColor: "#FFFFFF", color: "#000" };
    },

    multiValueLabel: (styles) => {
      return {
        ...styles,
        color: "#000",
        fontFamily: "Arial",
        fontSize: "13",
        height: "25",
      };
    },

    indicatorsContainer: (styles) => {
      return {
        ...styles,
        backgroundColor: "#FFFFFF;",
        borderColor: "#FFFFFF",
        position: " absolute",
        right: "0px",
        fontSize: "12px",
        fontFamily: "Arial",
      };
    },

    indicatorSeparator: (styles) => {
      return { ...styles, display: "none" };
    },

    clearIndicator: (styles) => {
      return { ...styles, backgroundColor: "#FFFFFF", borderColor: "#FFFFFF" };
    },

    placeholder: (styles) => {
      return {
        ...styles,
        color: "#A7A7A7",
        fontSize: "12px",
        fontFamily: "Arial",  
      };
    },

    menu: (styles) => {
      //    if(props.id==='reptype'){
      //     return {

      //         ...styles,
      //         margin: 0,
      //         background: "#FFFFFF;",
      //         fontSize: "12px",
      //         fontFamily: "Arial",
      //         maxHeight:'150px',
      //         overflowY:'hidden',

      //     };
      //    }else{

      return {
        ...styles,
        margin: 0,
        background: "#FFFFFF",
        fontSize: "12px",
        fontFamily: "Arial",
        zIndex:900,
      };

      //    }
    },

    SelectContainer: (styles) => {
      return {
        ...styles,
        margin: 0,
        background: "#000",
        fontSize: "12px",
        fontFamily: "Arial",
      };
    },

    selectOption: (styles) => {
      return {
        ...styles,
        margin: 0,
        background: "#fff",
        fontSize: "12px",
        fontFamily: "Arial",
        color: "#000", 
      };
    },

    input: (styles) => {
      return {
        ...styles,
        color: "black",
        fontFamily: "Arial",
        fontSize: "13px",
        height: "25",
      };
    },
  };

  return (
    <>
      <Select
        ref={selectInputRef}
        options={props.options}
        placeholder={props.placeholder}
        value={props.value}
        theme={(theme) => ({
          ...theme,
          borderRadius: 4,
          opacity: 2,
          colors: {
            ...theme.colors,
            primary25: "#00000029",
            primary: "#A7A7A7",
          },
        })}
        defaultValue={props.defaultValue}
        closeMenuOnSelect={props.closeMenuOnSelect === false ? false : true}
        hideSelectedOptions={false}
        components={props.components}
        styles={customStyles}
        onChange={props.onChange}
        allowSelectAll={true}
        isSearchable={props.isSearchable === false ? false : true}
        isMulti={props.isMulti === true ? true : false}
        isDisabled={props?.isDisabled === true ? true : false}
        className={props.className}
        id={props.id}
      />
    </>
  );
}
export default Dropdown;
