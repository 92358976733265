import React from "react"
import clsx from "clsx"
import "./button.scss"
import Export from "../../../Images/export.svg"
import Download from "../../../Images/download.svg"
import Cancel from "../../../Images/cancel.svg"

function Button(props) {
  return (
    <>
      <div class="btn ">
        <button
          type={props.type || "button"}
          className={
            props.role === "primary"
              ? clsx(props.className, "primaryBtn")
              : props.role === "secondary"
              ? clsx(props.className, "secondaryBtn")
              : props.role === "export"
              ? clsx(props.className, "exportBtn")
              : props.role === "reject"
              ? clsx(props.className, "rejectBtn")
              : props.role === "approve"
              ? clsx(props.className, "approveBtn")
              : props.role === "download"
              ? clsx(props.className, "downloadBtn")
              : props.role === "transparent"
              ? clsx(props.className, "transaparentBtn")
              : props.role === "transparentdownload"
              ? clsx(props.className, "transaparentBtn")
              : props.role === "transparentcancel"
              ? clsx(props.className, "transaparentBtn")
              : props.role === "cancel"
              ? clsx(props.className, "cancelBtn")
              : clsx(props.className, "btn btn-outline-secondary")
          }
          onClick={() => props?.onClick()}>
          {props.role === "export" ? (
            <>
              <img src={Export} alt={props?.role}></img>
              {props.value}
            </>
          ) : props.role === "download" ? (
            <>
              <img src={Download} alt={props?.role}></img> {props.value}
            </>
          ) : props.role === "transparentdownload" ? (
            <>
              <img src={Download} alt={props?.role}></img> {props.value}
            </>
          ) : props.role === "transparentcancel" ? (
            <>
              <img src={Cancel}  alt={props?.role}></img> {props.value}
            </>
          ) : (
            props.value
          )}
        </button>
      </div>
    </>
  )
}

export default Button
