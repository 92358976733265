
export const tpslConfig = (return_url, values , data , card) => {

    return {
        'tarCall': false,
        'features': {
            'showPGResponseMsg': true,
            'enableNewWindowFlow': false,    //for hybrid applications please disable this by passing false
            'payDetailsAtMerchantEnd': true,
            'enableExpressPay': true,
            'siDetailsAtMerchantEnd': true,
            'hideSIConfirmation': true,
            'enableSI': true,
            'hideSIConfirmation': true
        },
        'consumerData': {
            'deviceId': 'WEBMD5',   //possible values 'WEBSH1', 'WEBSH2' and 'WEBMD5'
            'token': data.token, //'<%=data.data.token%>',
            // 'returnUrl': 'https://www.tekprocess.co.in/MerchantIntegrationClient/MerchantResponsePage.jsp',
            'returnUrl': return_url, //localhost 4000 response
            'paymentMode': card === 'DebitCard' ? 'all' : 'netBanking',//$('#payment_mode').val(), //netBanking
            'merchantLogoUrl': 'https://upload.wikimedia.org/wikipedia/en/e/ef/Tata_Capital_Official_Logo.jpg',  //provided merchant logo will be displayed
            // 'merchantId': 'T3239', //demo
            'merchantId': data.merchant_id, //
            'currency': 'INR',// 'INR',
            'consumerId': data.consumer_id,//,  //Your unique consumer identifier to register a eMandate/eSign
            'consumerMobileNo': values.mobile_number, //consumer_mobile_number_value,//'<%=data.data.consumer_mobile_number%>',//'9876543210',
            'consumerEmailId': values.email_id, //email_id_value, //'<%=data.data.email_id%>',//'test@test.com',
            'txnId': data.txn_id,   //Unique merchant transaction ID
            'items': [{
                'itemId': "TEST", //Value is FIRST
                'amount': data.total_amt, //default value is 5
                'comAmt': '0'
            }],
            'cartDescription': data.loan_no,
            'bankCode': card === 'DebitCard' ? data.debit_card_emandate_id : card === 'NetBanking' ? data.bank_id : card === 'aadhar' ? data.aadhar_bank_code : '',//'<%=data.data.debit_card_emandate_id%>', // Value will be provided by Tech Process
            'accountNo': values.account_number,//'<%=data.data.account_number%>',

            'customStyle': {
                'PRIMARY_COLOR_CODE': '#3977b7',   //merchant primary color code
                'SECONDARY_COLOR_CO02DE': '#FFFFFF',   //provide merchant's suitable color code/+]01-10-2018]\
                'BUTTON_COLOR_CODE_2': '#FFFFFF'   //provide merchant's suitable color code for button text
            },
            // 'accountNo': $("#accountNo").val(),    //Pass this if accountNo is captured at merchant side for eMandate/eSign                -
            'accountType': values.account_type === 'SavingAccount' ? 'Saving' : values.account_type === 'CurrentAccount' ? 'Current' : '',    //  Available options Saving, Current and CC for Cash Credit, only for eSign
            // 'accountHolderName': 'Name',  //Pass this if accountHolderName is captured at merchant side for eSign only(Note: For ICICI eMandate registration this is mandatory field, if not passed from merchant Customer need to enter in Checkout UI)
            //'aadharNo': '123456789012',   //Pass this if aadharNo is captured at merchant side for eSign only
            //'ifscCode': 'ICIC0000001',        //Pass this if ifscCode is captured at merchant side for eSign only
            'debitStartDate': data.debit_start_date,//'10-01-2019',
            'debitEndDate': data.debit_end_date,//'01-03-2047',
            'maxAmount': data.max_amount,//,'100',
            'amountType': data.amount_type,//'M',
            'frequency': data.frequency, //  Available options DAIL, Week, MNTH, QURT, MIAN, YEAR, BIMN and ADHO
            'accountHolderName': values.account_holder_name,
            'ifscCode': values.ifsc_code

        }
    }
}

