import React from "react"
import clsx from "clsx"
import Search from "../../../Images/search.svg"
import "./searchbar.scss"
import {Button} from "react-bootstrap"
function SearchBar(props) {
  return (
    <>
      <div className="search_bar">
        <input
          type={props.type}
          value={props.value}
          className={clsx(props.className, "search")}
          placeholder={"Search"}
          onChange={(e) => props.onChange(e.target.value)}
          disabled={props.disabled}
          maxLength={props.maxLength ? props.maxLength : 255}
          id={props.id}
        />

        <Button className="searchButton" onClick={() => props.onSearchClick()}>
          <span>SEARCH</span>
        </Button>
      </div>
    </>
  )
}

export default SearchBar
