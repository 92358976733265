import * as React from "react"
import "./Sidebar.scss"
import {Nav} from "react-bootstrap"
import {useNavigate, Link} from "react-router-dom"
import Routes from "./Routes"

const drawerWidth = 240

const Sidebar = () => {
  const navigate = useNavigate()
  return (
    <div className="sidebar">
      <Nav className="flex-column m-0 p-0">
        <Routes />
      </Nav>
    </div>
  )
}

export default Sidebar
