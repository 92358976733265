import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	showSlideBar: false,
};

const showSlideSlice = createSlice({
	name: 'slideBar',
	initialState,
	reducers: {
		showBar: (state, action) => {
			state.showSlideBar = action.payload;
		},
	},
});

export const { showBar } = showSlideSlice.actions;

export const SlideBarReducer = {
	showSlide: showSlideSlice.reducer,
};
