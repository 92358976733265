import React from 'react'
import Loader from "react-js-loader";

const SystemXLoader = () => {
  return (
    <Loader type="spinner-circle" bgColor={"#2D5794"} color={'#2D5794'} size={75} />
          
  )
}

export default SystemXLoader