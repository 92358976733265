import React, { useEffect, useState } from 'react';
import { showBar } from '../../redux/reducer/sideBarStatus';
import { useDispatch, useSelector } from 'react-redux';

import './sidebar.scss';
const SideBar = (props) => {
	const dispatch = useDispatch();

	let sidebarShow = useSelector((state) => state.slideBar);
	const setNav = () => {
		dispatch(showBar(false));
	};

	return (
		<div className={sidebarShow?.showSlideBar ? 'sideNavOpen' : 'sideNavClose'}>
			{/* <a
				href="javascript:void(0)"
				class="closebtn"
				onClick={() => {
					setNav();
				}}
			>
				&times;
			</a> */}
			<div>{props.data}</div>
		</div>
	);
};

export default SideBar;
