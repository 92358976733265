import React, { useEffect, useState } from "react";
import "./CaseDetails.scss";
import downloadIcon from "../../Images/downloadIcon.svg";
import uploadIcon from "../../Images/upload-icon.svg";
import refreshIcon from "../../Images/refresh-icon.svg";
import CustomTable from "../../component/customtable/CustomTable";
import {
  CaseDetailUrl,
  caseDetailsSampleFileKey,
  commonVariables,
  dummyList,
  exceptionSampleFileKey,
} from "../../constants/Common/commonConstants";
import { chain, difference, filter as _filter } from "lodash";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import { errorMessages } from "../../constants/Common/errorConstants";
import apiCall from "../../utils/apiFunction/apiCall";
import SystemXLoader from "../../component/common/Loader/SystemXLoader";
import {
  apiConstants,
  errMessage,
  manualMarkingStates,
  markAsFailedApi,
  singleAccountVerificationAPIMessage,
  toastIds,
} from "../../constants/Common/apiConstants";
import uploadPresigned from "../../utils/presignedUpload";
import ManualMarkingInputBox from "../../component/ManualMarkingInputBox/ManualMarkingInputBox";
import moment from "moment";

const CaseDetails = () => {
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [listdata, setListdata] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [isApiCall, setIsApiCall] = useState(true);
  const [checkedData, setCheckedData] = useState([]);
  const [isCaseDetails, setIsCaseDetails] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [fileKey, setFileKey] = useState("");
  const [clear, setClear] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);

  const onDrop = (file) => {
    if (file.length > 0) {
      getPresigned(file);
    }
  };

  const uploadLoder = (data) => {
    setUploadPercent(data);
  };
  const uploadStatus = () => {
    if (fileKey) {
      let bodyData = {
        key: fileKey,
      };
      apiCall
        .post(
          apiConstants[
          isCaseDetails
            ? "manualMarkingUploadFile"
            : "manualMarkingUploadUPFile"
          ],
          bodyData
        )
        .then(async (res) => {
          if (res?.data?.status === 200) {
            uploadList(res?.data?.response, isCaseDetails);
            toast.success(singleAccountVerificationAPIMessage?.fileUploaded, {
              toastId: toastIds?.FileUploadSuccess,
            });
          } else {
            toast.error(res?.data?.message, {
              toastId: toastIds?.FileUploaderr,
            });
          }
        })
        .catch((err) => {
          toast.error(err?.message || errorMessages.SOMETHING_WRONG, {
            toastId: toastIds?.FileUploaderr,
          });
        });
    } else {
      toast.error(errMessage?.uploadFile, {
        toastId: "uploaderrorupd",
      });
    }
  };
  const uploadList = (data, type) => {
    if (data.length > 0) {
      let batchIds = data.map((value) => value[0].batch_id);
      let apibody;
      if (type) {
        apibody = {
          tablename: "account_number_validation",
          whereQuery: {
            batch_id: batchIds,
          },
        };
      } else {
        apibody = {
          tablename: "account_number_validation",
          whereQuery: {
            loan_number: data.map((value) => value?.map((innerValues) => innerValues.loan_number)),
            fund_reference_id: data.map((value) => value?.map((innerValues) => innerValues.fund_reference_id)),
          },
        };
      }
      if (apibody) {
        apiCall
          .post(apiConstants?.findData, apibody)
          .then(async (res) => {
            setIsApiCall(true);
            if (res?.data?.status === 200) {
              let result = res?.data?.response;
              result = result.map((item) => {
                let newItem = {
                  batch_id: item?.batch_id || "-",
                  id: item?.id || "-",
                  loan_number: item?.loan_number || "-",
                  payment_date: moment(item?.createdAt).format('DD-MM-YYYY') || "-",
                  ref_number: item?.ibl_ref_number || "-",
                  unique_combo_field: item?.unique_combo_field || "-",
                  account_number: item?.account_number_as_per_mandate || "-",
                  holder_name: item?.account_holder_name || "-",
                  status: item?.txn_status_desc || "-",
                  transactionTypeStatus: "-",
                };
                return newItem;
              });
              setListdata([...result]);
            } else {
              setListdata([]);
              toast.error(res?.data?.message, {
                toastId: toastIds?.FileUploaderr,
              });
            }
          })
          .catch((err) => {
            setListdata([]);
            toast.error(errorMessages.SOMETHING_WRONG, {
              toastId: toastIds?.FileUploaderr,
            });
          });
      }
    }
  };
  const downloadSampleFile = async (type) => {
    // setDownloadFlag(true);
    const key = type ? caseDetailsSampleFileKey : exceptionSampleFileKey;

    apiCall
      .get(`${apiConstants?.downloadPresignedUrl}?key=${key}`)
      .then((response) => {
        if (response.status === 200) {
          const link = document.createElement("a");
          link.href = response?.data;
          link.click();
          toast.success(commonVariables?.FileDownloadSuccess, { toastId: toastIds?.FileDownloadSucess })

        }
      })
      .catch((error) => {
        // setDownloadFlag(false);
        return toast.error(errMessage?.InternalServerError, {
          toastId: toastIds?.InternalServerError,
        });
      })
      .finally(() => {
        // setDownloadFlag(false);
      });
  };
  const getPresigned = (value) => {
    setLoading(true);
    apiCall
      .get(
        `${apiConstants?.presignedS3upload}?key=${isCaseDetails ? moment().format('YYYY/MM/DD').toString() + '/payment/upload/disbursement-file-log-case' : moment().format('YYYY/MM/DD').toString() + "/payment/upload/disbursement-file-log-up-case"
        }/${value[0].name}`
      )
      .then(async (res) => {
        if (res.status === 200) {
          let result = res?.data;
          if (result) {
            uploadPresigned(value[0], result, uploadLoder)
              .then((res) => {
                if (res.status == 200) {
                  setFileData(value);
                  setFileKey(
                    isCaseDetails
                      ? `${moment().format('YYYY/MM/DD').toString()}/payment/upload/disbursement-file-log-case/${value[0].name}`
                      : `${moment().format('YYYY/MM/DD').toString()}/payment/upload/disbursement-file-log-up-case/${value[0].name}`
                  );
                }
              })
              .catch((e) => {
                setFileKey("");
                setFileData([]);
                toast.error(errMessage?.fileUploadFailed, {
                  toastId: toastIds?.FileUploaderr,
                });
              });
          }
        }
      })
      .catch((er) => {
        setFileKey("");
        setFileData([]);
        toast.error(errMessage?.fileUploadFailed, {
          toastId: toastIds?.FileUploaderr,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (window.location?.pathname === CaseDetailUrl) {
      setIsCaseDetails(true);
      clearValues();
    } else {
      setIsCaseDetails(false);
      clearValues();
    }
  }, [window.location?.pathname]);

  const clearValues = () => {
    setClear(true);
    setFileData([]);
    setFileKey("");
    setListdata([]);
    setCheckedData([]);
  };

  const handleCheckAll = (checkAll) => {
    if (checkAll) {
      setCheckedData(
        chain(listdata)
          ?.map((e) => (e.disabled ? undefined : e || e))
          ?.filter()
          ?.value()
      );
    } else {
      setCheckedData([]);
    }
  };

  const checkboxFormatter = (row, idx) => (
    <div className="checkBoxForm">
      <input
        class="form-check-input"
        type="checkbox"
        className="checkboxTablerow"
        disabled={row.disabled}
        onChange={() => {
          if (checkedData.includes(row || row)) {
            setCheckedData(checkedData.filter((e) => e !== (row || row)));
          } else {
            setCheckedData([...checkedData, row || row]);
          }
        }}
        checked={checkedData.includes(row || row)}
        id="flexCheckDefault"
      ></input>
    </div>
  );

  const updateStatus = (state) => {
    if (checkedData?.length > 0) {
      let manualMarkingChangeBody = {
        id: checkedData?.map((data) => data?.id),
        // loan_number: loanNumber,
        mark_as: state,
      };
      setUpdateLoading(true);
      apiCall
        .post("api/payment/manualMaking/view_approval", manualMarkingChangeBody)
        .then(async (res) => {
          if (state == manualMarkingStates?.failed && res?.data?.status) {
            toast.success("Successfully Marked as Failed ", {
              toastId: "successfailed",
            });
            clearValues();
          }
          if (
            state == manualMarkingStates?.moveToException &&
            res?.data?.status
          ) {
            toast.success("Successfully Moved to Exception ", {
              toastId: "successfailed",
            });
            clearValues();
          }
        })
        .catch((err) => {
          toast.error(errorMessages.SOMETHING_WRONG, {
            toastId: "errordata",
          });
        })
        .finally(() => {
          setUpdateLoading(false);
        });
    } else {
      toast.error(errMessage?.SelectAValue, { toastId: toastIds?.selectVal });
    }
  };

  const listApi = (value) => {
    let temp = value ? (value - 1) * 10 : (pageNumber - 1) * 10;
    // setLoading(true);
  };
  const changePage = (value) => {
    listApi(value);
    setPageNumber(value - 1);
  };

  const filterFunction = () => {
    const batchId = localStorage.getItem("batchId")
      ? localStorage.getItem("batchId")
      : "";
    const loanNumber = localStorage.getItem("loanNumber")
      ? localStorage.getItem("loanNumber")
      : "";
    const journalNo = localStorage.getItem("journalNo")
      ? localStorage.getItem("journalNo")
      : "";
    if (isCaseDetails) {
      if (batchId && loanNumber) {
        setLoading(true);
        apiCall
          .get(
            `${apiConstants?.manualMarkingfindCase}?batch_id=${batchId}&loan_number=${loanNumber}`
          )
          .then(async (res) => {
            setIsApiCall(true);
            const resultLength = res?.data?.response?.length;
            if (res?.data?.status === 200 && resultLength > 0) {
              let result = res?.data?.response;
              result = result?.map((item) => {
                let newItem = {
                  batch_id: item?.batch_id || "-",
                  id: item?.id || "-",
                  loan_number: item?.loan_number || "-",
                  payment_date: item?.txn_payment_date || "-",
                  ref_number: item?.ibl_ref_number || "-",
                  unique_combo_field: item?.unique_combo_field || "-",
                  account_number: item?.account_number_as_per_mandate || "-",
                  holder_name: item?.account_holder_name || "-",
                  status: item?.txn_status_desc || "-",
                };
                return newItem;
              });
              setListdata([...result]);
            } else {
              toast.error(res?.data?.message, {
                toastId: toastIds?.DataError,
              });
            }
          })
          .catch((er) => {
            let resp = er?.message || "";
            toast.error(resp || errorMessages.SOMETHING_WRONG, {
              toastId: "errornodata",
            });
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        toast.error(errMessage?.EnterBatchIdAndLoanNumber, {
          toastId: toastIds?.DataError,
        });
      }
    } else {
      if (journalNo && loanNumber) {
        setLoading(true);
        apiCall
          .get(
            `${apiConstants?.manualMarkingfindUpCase}?loan_number=${loanNumber}&fund_reference_no=${journalNo}`
          )
          .then(async (res) => {
            setIsApiCall(true);
            const resultLength = res?.data?.response?.length;
            if (res?.data?.status === 200 && resultLength > 0) {
              let result = res?.data?.response;
              result = result?.map((item) => {
                let newItem = {
                  batch_id: item?.batch_id || "-",
                  id: item?.id || "-",
                  loan_number: item?.loan_number || "-",
                  payment_date: item?.txn_payment_date || "-",
                  ref_number: item?.ibl_ref_number || "-",
                  unique_combo_field: item?.unique_combo_field || "-",
                  account_number: item?.account_number_as_per_mandate || "-",
                  holder_name: item?.account_holder_name || "-",
                  status: item?.txn_status_desc || "-",
                  transactionTypeStatus: item?.transaction_type_status || "-",
                };
                return newItem;
              });
              setListdata([...result]);
            } else {
              toast.error(res?.data?.message, {
                toastId: toastIds?.DataError,
              });
            }
          })
          .catch((er) => {
            let resp = er?.message || "";
            toast.error(resp || errorMessages.SOMETHING_WRONG, {
              toastId: toastIds?.DataError,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        toast.error(errMessage?.EnterJournalNoAndLoanNumber, {
          toastId: toastIds?.DataError,
        });
      }
    }
  };
  const DisbursmentCase = () => {
    return (
      <div className="CaseDetails_DisbursmentCaseWrapper mb-3">
        <div className="CaseDetails_DisbursmentCaseWrapper_Table-Head">
          <p>Disbursement Case</p>
        </div>
        <div className="px-3">
          <ManualMarkingInputBox
            isCaseDetails={isCaseDetails}
            clearData={clear}
          ></ManualMarkingInputBox>
          <div className="d-flex mt-3 flex-wrap justify-content-between h-25 align-items-center">
            <div>
              <p className="CaseDetails_DisbursmentCaseWrapper_uploadlabel">
                <b>Upload File</b>
              </p>
            </div>
            <div>
              <button type="button" onClick={() => downloadSampleFile(isCaseDetails)} className="reversalDownloadButton mb-2">
                <img src={downloadIcon} alt="downloadIcon" />
                Download Sample File
              </button>
            </div>
          </div>
          <Dropzone
            onDrop={onDrop}
            multiple={false}
          // accept="video/*"
          // accept={{
          //   "video/mp4": [".mp4", ".mov", ".avi", ".wmv", ".3gpp"],
          // }}
          // disabled={disabled}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps({
                  className:
                    "CaseDetails_DisbursmentCaseWrapper_UploadWrapper d-flex align-items-center justify-content-center",
                })}
              >
                <input {...getInputProps()} />
                <div className="d-flex justify-content-center flex-wrap">
                  {/* <UploadIcon /> */}
                  <div className="d-flex gap-2 uploadContent">
                    {fileData[0]?.name && (
                      <img
                        src={uploadIcon}
                        className="CaseDetails_DisbursmentCaseWrapper_UploadWrapper_uploadIcon mb-2"
                        alt="upload icon"
                      ></img>
                    )}
                    {fileData[0]?.name ? (
                      <p className="mt-2">{fileData[0]?.name}</p>
                    ) : (
                      <>
                        <img
                          src={uploadIcon}
                          className="CaseDetails_DisbursmentCaseWrapper_UploadWrapper_uploadIcon mb-2"
                          alt="upload icon"
                        ></img>
                        <p className="CaseDetails_DisbursmentCaseWrapper_UploadWrapper_dragTxt CaseDetails_DisbursmentCaseWrapper_label mt-2">
                          <b>
                            Drag your File here Or &nbsp;
                            <span className="CaseDetails_DisbursmentCaseWrapper_UploadWrapper_browseTxt">
                              Browse
                            </span>
                          </b>
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Dropzone>

          <div className="CaseDetails_DisbursmentCaseWrapper_bottomButtons">
            <div>
              <button
                onClick={() => filterFunction()}
                className="CaseDetails_DisbursmentCaseWrapper_bottom-btn "
              >
                Filter
              </button>
            </div>
            <div>
              <button
                onClick={() => uploadStatus()}
                className="CaseDetails_DisbursmentCaseWrapper_bottom-btn"
              >
                Upload
              </button>
            </div>
            <div className="CaseDetails_DisbursmentCaseWrapper_bottomButtons_outlineBtn">
              <button onClick={() => clearValues()}>
                <img src={refreshIcon} alt="refreshIcon"></img>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const updateisApiCallState = (newState) => {
    setIsApiCall(newState);
  };
  const CaseDetailsTable = () => {
    return (
      <div
        className="CaseDetails_tableAlignment"
        style={
          listdata?.length <= 0
            ? {
              display: "none",
            }
            : {}
        }
      >
        <CustomTable
          cols={[
            {
              id: "id",
              checkbox: true,
              allChecked: listdata?.length
                ? difference(
                  listdata.map((e) => e),
                  checkedData
                ).length === 0
                : false,
              handleCheckboxAction: () =>
                handleCheckAll(
                  difference(
                    listdata.map((e) => e),
                    checkedData
                  ).length !== 0
                ),
              formatter: checkboxFormatter,
            },
            {
              id: "batch_id",
              title: "Batch ID",
            },
            {
              id: "loan_number",
              title: "Loan Number",
            },
            {
              id: "payment_date",
              title: "Payment Date",
            },
            {
              id: "ref_number",
              title: "IBL Ref Number",
            },
            {
              id: "unique_combo_field",
              title: "Unique Combo Field",
            },
            {
              id: "account_number",
              title: "Account Number",
            },
            {
              id: "holder_name",
              title: "Account Holder Name",
            },
            {
              id: "status",
              title: "Status",
            },
          ]}
          isCaseDetails="CaseDetails"
          data={listdata}
          // count={500}
          onFailed={() => updateStatus(manualMarkingStates?.failed)}
          isDisplayNone
          setPagination={(page) => changePage(page)}
          activePage={pageNumber}
          loading={loading}
          updateLoading={updateLoading}
          isApiCall={isApiCall}
          updateisApiCallState={updateisApiCallState}
        />
      </div>
    );
  };

  const MovingToExceptionTable = () => {
    return (
      <div
        className="CaseDetails_tableAlignment"
        style={
          listdata?.length <= 0
            ? {
              display: "none",
            }
            : {}
        }
      >
        <CustomTable
          cols={[
            {
              id: "id",
              checkbox: true,
              allChecked: listdata?.length
                ? difference(
                  listdata?.map((e) => e),
                  checkedData
                ).length === 0
                : false,
              handleCheckboxAction: () =>
                handleCheckAll(
                  difference(
                    listdata?.map((e) => e),
                    checkedData
                  ).length !== 0
                ),
              formatter: checkboxFormatter,
            },
            {
              id: "batch_id",
              title: "Batch ID",
            },
            {
              id: "loan_number",
              title: "Loan Number",
            },
            {
              id: "payment_date",
              title: "Payment Date",
            },
            {
              id: "ref_number",
              title: "IBL Ref Number",
            },
            {
              id: "unique_combo_field",
              title: "Unique Combo Field",
            },
            {
              id: "account_number",
              title: "Account Number",
            },
            {
              id: "holder_name",
              title: "Account Holder Name",
            },
            {
              id: "status",
              title: "Status",
            },
            {
              id: "transactionTypeStatus",
              title: "Transaction Type Status",
            },
          ]}
          isCaseDetails="MoveToException"
          data={listdata}
          // count={500}
          isDisplayNone
          onMoveToException={() =>
            updateStatus(manualMarkingStates?.moveToException)
          }
          setPagination={(page) => changePage(page)}
          activePage={pageNumber}
          loading={loading}
          updateLoading={updateLoading}
          isApiCall={isApiCall}
          updateisApiCallState={updateisApiCallState}
        />
      </div>
    );
  };
  return (
    <div className="mt-4 pt-2 mx-3">
      <p className="CaseDetails_CaseDetailsTxt ">
        {isCaseDetails ? <b>Case Details</b> : <b>Move To Exception</b>}
      </p>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-3 p-0">
            <DisbursmentCase />
          </div>
          {loading ? (
            <div className="col-12 col-sm-12 col-md-12 col-lg-9 p-0 pt-4 text-center">
              <SystemXLoader />
              <p>
                <b>Loading...</b>
              </p>
            </div>
          ) : (
            <div className="col-12 col-sm-12 col-md-12 col-lg-9 p-0 CaseDetails_tableHeight">
              {isCaseDetails ? (
                <CaseDetailsTable />
              ) : (
                <MovingToExceptionTable />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CaseDetails;
