import axios from 'axios';

import jwt_decode from "jwt-decode";
import { toast } from 'react-toastify';


const mode = (type) => {
  switch (type) {
    case 'development':
      return process.env.REACT_APP_API;
    case 'production':
      return process.env.REACT_APP_API;
    default:
      //return development api
      return process.env.REACT_APP_API;
  }
};
const defaultOptions = {
  // baseURL: base.url,
  baseURL: mode(process.env.REACT_APP_ENVIRONMENT),

  method: 'get',
  headers: {
    'Content-Type': 'application/json',
    // "Access-Control-Allow-Origin": "*",
  },
};

// Create instance
let instance = axios.create(defaultOptions);
instance.interceptors.request.use(
  function (config) {
    let tokenData = localStorage.getItem("loginRoleData")  
    let userToken = tokenData ? JSON.parse(tokenData) : null  
    if (userToken?.token) {
    config.headers['x-access-token'] = userToken.token
    config.headers['token-type'] = 1
    } else {
      delete config.headers["x-access-token"];
      delete config.headers['token-type']
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  
	(response) => {
		return Promise.resolve(response);
	},
	(error) => {
		if (error?.response?.status == 401) {
      window.location.href = '/'
      toast.info("Your session has expired. Please login again", {
        toastId: "sessionExpired",
      });
		}

		if (error) {
			return Promise.reject(error);
		}
	}
);

// export const getToken = () => {
// 	const { token } = store.getState().global;
// 	if (token) {
// 		try {
// 			const JWT = jwt_decode(token);
// 			const currentDate = Math.round(new Date().getTime() / 1000);
// 			if (JWT && JWT.exp > currentDate) {
// 				return token;
// 			}
// 			return null;
// 		} catch (error) {
// 			return null;
// 		}
// 	}
// 	return null;
// };

export const checkToken = (token) => {
	try {
		const JWT = jwt_decode(token);
		const currentDate = Math.round(new Date().getTime() / 1000);
		if (JWT && JWT.exp > currentDate) {
      console.log("JWT valid--------------",token);
			return JWT;
		}
    console.log("JWT expired--------------");
		return null;
	} catch (error) {
		return null;
	}
};

export default instance;
