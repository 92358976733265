import React, { useEffect, useState } from "react";
import SystemDetails from "../../../component/singlemandate/SystemDetails/SystemDetails";
import CustomerDetails from "../../../component/singlemandate/CustomerDetails/CustomerDetails";
import MandateDetails from "../../../component/singlemandate/MandateDetails/MandateDetails";
import "./singleMandate.scss";
import { FOMRS } from "../../../constants/Common/apiConstants";
import { useLocation } from "react-router-dom";

function SingleMandate() {
  const [activeStep, setActiveStep] = useState(0);
  const { state } = useLocation();

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  useEffect(() => {
    if (state?.step === 0) {
      activeStep !== 0 && setActiveStep(0);
    }
  }, [state?.step]);
  
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); 
      if (activeStep === 0 || activeStep === 1) {
        return; 
      }
    }
  };

  return (
    <>
      <div className="singleMandate d-flex flex-wrap gap-2 align-items-center justify-content-between  mt-4 mb-3 mx-3" onKeyDown={handleKeyPress}>
      <div className="mt-2">
                <label className="texsint-nowrap pl-0 common-header">
                  Mandate Registration 
                </label>
              </div>
             <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
            <h6 className="create_manual_mandate">
              {state?.type === FOMRS.EDIT
                ? "View Manual Mandate"
                : "Create Manual Mandate"}
            </h6>
          </div>
          <div className="row col-xs-12 col-sm-12 col-md-12 col-lg-12 ml-0 single_mandate_form">
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 single_mandate_step">
              <h6
                className={activeStep === 0 ? "activeStep" : "non-activeStep"}
              >
                System Details
              </h6>
              <h6
                className={activeStep === 1 ? "activeStep" : "non-activeStep"}
              >
                Customer Details
              </h6>
              <h6
                className={activeStep === 2 ? "activeStep" : "non-activeStep"}
              >
                Mandate Details
              </h6>
            </div>
            <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
              {activeStep === 0 ? (
                <SystemDetails
                  handleNext={handleNext}
                  handleBack={handleBack}
                />
              ) : activeStep === 1 ? (
                <CustomerDetails
                  handleNext={handleNext}
                  handleBack={handleBack}
                />
              ) : (
                <MandateDetails
                  handleNext={handleNext}
                  handleBack={handleBack}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SingleMandate;
