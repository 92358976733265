import React, { useEffect, useState } from "react";
import "../enach/Enach.scss";
import Dropdown from "../../component/common/Dropdown/dropdown";
import Button from "../../component/common/Button/button";
import apiCall from "../../utils/apiFunction/apiCall";
import {
  reportType,
  mandateType,
  YupMessages,
  componentRole
} from "../../constants/Common/commonConstants.js";
import FormLabel from "../../component/common/formLabel/formLabel";
import FormikSelect from "../../component/common/formikSelect/formikSelect";
import FormikDatepicker from "../../component/common/FormikDatepicker/FormikDatepicker";
import { toast } from "react-toastify";
import { Formik, Form, ErrorMessage } from "formik";
import "../../pages/payment/datepicker.scss";
import SystemXLoader from "../../component/common/Loader/SystemXLoader";
import { object, string } from "yup";
import { useSelector } from "react-redux";
import {
  apiConstants,
  companyAttributes,
  errMessage,
  reportConstants,
  tableNamePaymentConstants,
  toastIds,
} from "../../constants/Common/apiConstants";

const Report = () => {
  
  const loginState = useSelector((state) => state.login);

  const handleRefresh = () => {
    window.location.reload();
  };



  const [companycode, setCompanycode] = useState([]);

  const [sourcesystem, setSourcesystem] = useState([]);
  const [selectedReporttype, setSelectedReporttype] = useState("");
  const [downloadFlag, setdownloadFlag] = useState(false);
  const [accessData, setAccessData] = useState("");


  const listCompanyCode = () => {
    apiCall
      .post(apiConstants?.findData, {
        tablename: tableNamePaymentConstants?.Company,
        attributes: companyAttributes,
      })
      .then((result) => {
        if (result.status === 200) {
          result = result?.data?.response.map((item) => {
            const new_item = {
              key: item?.company_name,
              value: item?.company_code,
            };
            return new_item;
          });
          result.splice(0, 0, {
            key: " Please Select Company Code",
            value: "",
          });
          setCompanycode([...result]);
        }
      })
      .catch((error) => {
        console.log(errMessage?.ErrorinCompanyCodeAPI, error);
      });
  };

  const listSourceSystem = () => {
    apiCall
      .post(apiConstants?.findData, {
        tablename: "source_system_master",
        attributes: ["source_system_name"],
        order: [["id", "DESC"]],
      })
      .then((result) => {
        if (result.status === 200) {
          result = result?.data?.response.map((item) => {
            const new_item = {
              key: item?.source_system_name,
              value: item?.source_system_name,
            };
            return new_item;
          });
          result.splice(0, 0, {
            key: "Please Select Source System",
            value: "",
          });
          result.splice(1, 1, { key: "ALL_SOURCE", value: "ALL_SOURCE" });
          setSourcesystem([...result]);
        }
      })
      .catch((error) => {
        console.log(errMessage?.ErrorinCompanyCodeAPI, error);
      });
  };

  const validSchema = object({
    fromdate: string().required(YupMessages?.FromDateRequired),
    todate: string().required(YupMessages?.ToDateRequired),
    company_code :( selectedReporttype?.value === 'E-Mandate Audit Report' || selectedReporttype?.value === 'ENACH Adoption Reports' || selectedReporttype?.value === 'ENACH Adoption Daily Reports' || selectedReporttype?.value === 'SWAP ENACH Reports') && string().required(YupMessages?.CompanyCodeRequired),
    source_system : (selectedReporttype?.value === 'ENACH Adoption Reports' || selectedReporttype?.value === 'ENACH Adoption Daily Reports' || selectedReporttype?.value === 'SWAP ENACH Reports') && string().required(YupMessages?.SourceSystemRequired)
  });

  const onSubmit = (reportType, values) => {
    downloadFile(reportType, values);
  };

  const downloadFile = (flag, values) => {
    values.username  = loginState?.decodedData?.user_name;
    setdownloadFlag(true);

    let api_url;

    switch (flag) {
      case "E-Mandate Audit Report":
        api_url = reportConstants?.eMandateAuditReport;
        break;

      case "ENACH Adoption Reports":
        api_url = reportConstants?.enachAdoptionReport;
        break;

      case "ENACH Adoption Daily Reports":
        api_url = reportConstants?.enachAdoptionDailyReport;
        break;

      case "SWAP ENACH Reports":
        api_url = reportConstants?.swapEnachReports;
        break;


      case "ENACH Not Banked Customer Reports":
        api_url = reportConstants?.enachNotBankedReports;
        break;

      case "ENACH Feedback Reports":
        api_url = reportConstants?.enachFeedBackReports;
        break;

      case "Enach UPI Reports":
        api_url = reportConstants?.enachUPIReports;
        break;

      default:
        window.location.reload();
    }

    apiCall
      .post(api_url, values, flag)
      .then((response) => {
        if (response?.status === 200) {
          if (response?.data?.status === 500)
            return toast.error(errMessage?.fileDownloadFailed, {
              toastId: toastIds?.FileUploaderr,
            });
          if (response?.data?.status === 400)
            return toast.error(errMessage?.fileDownloadFailed, {
              toastId: toastIds?.FileUploaderr,
            });
          if (response?.data?.status === 404)
            return toast.info(errMessage?.NoRecordPresentWithinSelectedDate, {
              toastId: toastIds?.DataError,
            });

          window.location.href = response?.data?.response;
        }
      })
      .catch((error) => {
        toast.error(errMessage?.fileDownloadFailed, {
          toastId: toastIds?.FileUploaderr,
        });
      })
      .finally(() => {
        setdownloadFlag(false);
      });
  };

  useEffect(() => {
    listCompanyCode();
    listSourceSystem(selectedReporttype?.value);
  }, []);

  return (
    <>
      {downloadFlag && (
        <div className="className='row justify-content-center d-flex loaderDiv">
          <SystemXLoader />
        </div>
      )}
      <Formik
        initialValues={{
          fromdate: "",
          todate: "",
          mandate_type: "",
          company_code: "",
          source_system: selectedReporttype?.value === 'SWAP ENACH Reports' ? 'TKP_ENACH_SWAP_PAGE' : (selectedReporttype?.value === 'ENACH Adoption Reports' || selectedReporttype?.value === 'ENACH Adoption Daily Reports' ? 'ALL_SOURCE' : '') ,
        }}
        validationSchema={validSchema}
        enableReinitialize={true}
        onSubmit={(values) => onSubmit(selectedReporttype?.value, values)}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="row justify-content-center d-flex mx-3 mt-4 ">
              <div className="row pl-0 mb-3">
                <div className=" p-0">
                  <label className="  pt-2 common-header">Report</label>
                </div>
              </div>
              <div className="  mycontainerstyle">
                <div className="pt-4 ms-3 ">
                  <div>
                    <div className="row ">
                      <div className="col-md-5 ">
                        <FormLabel
                          value="Select Report Type"
                          className="required"
                        />
                        <div>
                          <Dropdown
                            placeholder={"Please Select a Value"}
                            options={reportType}
                            id={"reptype"}
                            value={selectedReporttype}
                            onChange={(data) => {
                              setSelectedReporttype(data);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    {selectedReporttype?.value !== undefined &&
                    selectedReporttype?.value !== "Select Report Type" ? (
                      <div className="row mt-4 gap-5">
                        <div className="col-md-5">
                          <FormLabel value="From Date" className="required" />
                          <FormikDatepicker
                            placeholder={" Please Select Date"}
                            name="fromdate"
                          />
                          <div className="errorMessage">
                            <ErrorMessage name="fromdate" />
                          </div>
                        </div>
                        <div className="col-md-5">
                          <FormLabel value="To Date" className="required" />
                          <FormikDatepicker
                            placeholder={"Please Select Date"}
                            name="todate"
                          />
                          <div className="errorMessage">
                            <ErrorMessage name="todate" />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  {selectedReporttype?.value === "E-Mandate Audit Report" ? (
                    <div className="row mt-3 gap-5">
                      <div className="col-md-5">
                        <FormLabel value="Company Code" className="required" />
                        <FormikSelect
                          label="Select Company Code"
                          name="company_code"
                          className="form-group form-control-lg pt-1 w-100 h-25"
                          options={companycode}
                        />
                        <div className="errorMessage">
                          <ErrorMessage name="company_code" />
                        </div>
                      </div>

                    </div>
                  ) : null}

                  {selectedReporttype?.value === "ENACH Adoption Reports" ||
                  selectedReporttype?.value ===
                    "ENACH Adoption Daily Reports" ||
                  selectedReporttype?.value === "SWAP ENACH Reports" ? (
                    <div className="row mt-3 gap-5">
                      <div className="col-md-5">
                        <FormLabel value="Company Code" className="required" />
                        <FormikSelect
                          label="Select Company Code"
                          name="company_code"
                          className="form-group form-control-lg pt-1 w-100 h-25"
                          options={companycode}
                        />
                        <div className="errorMessage">
                          <ErrorMessage name="company_code" />
                        </div>
                      </div>

                      <div className="col-md-5">
                        <FormLabel value="Source System" className="required" />
                        <FormikSelect
                          label="Select Source System"
                          name="source_system"
                          className="form-group form-control-lg pt-1 w-100 h-25"
                          options={sourcesystem}
                          disabled = {selectedReporttype?.value === 'SWAP ENACH Reports' && true}
                        />
                          <div className="errorMessage">
                          <ErrorMessage name="source_system" />
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {selectedReporttype?.value !== undefined &&
                  selectedReporttype?.value !== "Select Report Type" ? (
                    <div>
                      <div className="d-flex pt-5 mb-5 gap-3">
                        <div className=" ">
                          <Button
                            value="Reset"
                            onClick={handleRefresh}
                            role={componentRole.PRIMARY}
                            className="cancel"
                          />
                        </div>
                        <div className="">
                          <Button
                            type="submit"
                            onClick={() => {}}
                            value="Download"
                            role={componentRole.PRIMARY}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default Report;
