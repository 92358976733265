import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormLabel from "../../../../../common/formLabel/formLabel";
import { ROUTE_PATH } from "../../../../../../constants/Routes";
import FormikSelect from "../../../../../common/formikSelect/formikSelect";
import Button from "../../../../../common/Button/button";
import { useNavigate, useLocation } from "react-router-dom";
import "../createReceiptType/createReceiptType.scss";
import apiCall from "../../../../../../utils/apiFunction/apiCall";
import {
  apiConstants,
  FOMRS,
  STATUS, tableNamePaymentConstants, errMessage, successMsg, toastIds, isActiveDropdowns
} from "../../../../../../constants/Common/apiConstants";
import { YupMessages } from "../../../../../../constants/Common/commonConstants";
import { toast } from "react-toastify";

const validationSchema = Yup.object({
  receipt_type_name: Yup.string().required(YupMessages?.ReceiptTypeNameRequired)
    .matches(/^(?!.*<script>).*$/, "Receipt type name cannot contain scripts or HTML tags")
    .matches(/^(?!.*<\/?[a-z][\s\S]*>).*$/, "Receipt type name cannot contain scripts or HTML tags")
    .matches(/^[A-Za-z]/, "Receipt type name can only contain alphabets")
    .matches(/^[^@#]+$/, "Receipt type name cannot contain symbols like @ and #")
    .test('no-spaces', 'Receipt type name cannot be only spaces', (value) => value.trim() !== ''),

  receipt_type_code: Yup.string().required(YupMessages?.ReceiptTypeCodeRequired)
    .matches(/^(?!.*<script>).*$/, "Receipt type name cannot contain scripts or HTML tags")
    .matches(/^(?!.*<\/?[a-z][\s\S]*>).*$/, "Receipt type code cannot contain scripts or HTML tags")
    .matches(/^[^@#]+$/, "Receipt type code cannot contain symbols like @ and #")
    .test('no-spaces', 'Receipt type code cannot be only spaces', (value) => value.trim() !== ''),

  isActive: Yup.string().required(YupMessages?.isActiveRequired),
});

const CreateReceiptType = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const formType = state?.type || FOMRS.ADD;
  const headingText =
    formType === FOMRS.ADD ? "Create Receipt Type" : "Update Receipt Type";

  const [formData, setFormData] = useState({
    receipt_type_name: "",
    receipt_type_code: "",
    isActive: "",
  });

  const [loading, setLoading] = useState(false);



  const FormSubmit = (value) => {
    let query = {
      body: {
        name: value.receipt_type_name ? value.receipt_type_name : '-',
        code: value.receipt_type_code ? value.receipt_type_code : '-',
        isActive: value.isActive ? value.isActive : '-',
      },
      tablename: tableNamePaymentConstants?.recieptTypeMaster,
    };


    if (formType === FOMRS.EDIT) {
      query.whereQuery = {
        id: state?.user_type?.id,
      };
      apiCall.post(apiConstants?.updateUser, query).then((res) => {
        if (res?.status === STATUS.SUCCESS) {
          navigate(ROUTE_PATH.RECEIPT_TYPE);
          toast.success(successMsg?.ReceiptUpdatedSuccessfully, toastIds?.FileUploadSuccess)
        }
      });
    }

    if (formType === FOMRS.ADD) {
      apiCall.post(apiConstants?.createUser, query).then((res) => {
        if (res?.status === STATUS.SUCCESS) {
          navigate(ROUTE_PATH.RECEIPT_TYPE);
          toast.success(successMsg?.ReceiptCreatedSuccessfully, toastIds?.FileUploadSuccess)
        }
      });
    }
  };

  const getFormData = async (id) => {
    let bodyParams = {
      tablename: tableNamePaymentConstants?.recieptTypeMaster,
      whereQuery: {
        id,
      },
    };
  };

  useEffect(() => {
    if (formType === FOMRS.EDIT) {
      setFormData({
        receipt_type_name: state?.user_type?.name,
        receipt_type_code: state?.user_type?.code,
        isActive: state?.user_type?.isActive,
      });
    }
  }, [loading]);

  return (
    <Formik
      initialValues={{
        receipt_type_name: formData.receipt_type_name,
        receipt_type_code: formData.receipt_type_code,
        isActive: formData.isActive,
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={(values) => {
        FormSubmit(values);
      }}
    >
      {({ values, setFieldValue }) => (
        <>
          <div id="createCompany">
            <div
              className="col blueFonts large_f"
              onClick={() => navigate(ROUTE_PATH.RECEIPT_TYPE)}
            >
              {`Receipt Type >> `}
              <span className="blackFonts">{headingText}</span>
            </div>
            <div className="main-div">
              <Form className=".labler-wrapper">
                <div id="RegularForm">
                  <div className="createroletext">
                    <p id="pText">{headingText}</p>
                  </div>

                  <div className="row paddingTop ml-4 mr-4">
                    <div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
                      <div>
                        <FormLabel
                          value="Receipt Type Name"
                          className="required"
                        />
                      </div>
                      <Field
                        type="text"
                        className="InputField"
                        name="receipt_type_name"
                        id="receipt_type_name"
                        placeholder="Receipt Type Name"
                      ></Field>
                      <div className="errorMessage">
                        <ErrorMessage name="receipt_type_name" />
                      </div>
                    </div>
                  </div>

                  <div className="row paddingTop ml-4 mr-4">
                    <div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
                      <FormLabel
                        value="Receipt Type Code"
                        className="required"
                      />
                      <Field
                        type="text"
                        className="InputField"
                        name="receipt_type_code"
                        id="receipt_type_code"
                        placeholder="Receipt Type Code"
                      ></Field>
                      <div className="errorMessage">
                        <ErrorMessage name="receipt_type_code" />
                      </div>
                    </div>
                  </div>

                  <div className="row paddingTop ml-4 mr-4">
                    <div className="col-md-12 col-lg-12 medium_f_bold directionColumn ">
                      <div className="formState">
                        <FormLabel value="Is Active" className="required" />
                        <FormikSelect
                          label="Please select a value"
                          name="isActive"
                          options={isActiveDropdowns}
                        />
                      </div>
                      <div className="errorMessage">
                        <ErrorMessage name="isActive" />
                      </div>
                    </div>
                  </div>

                  <div className=" d-flex py-4">
                    <div className="cancel_btn">
                      <Button
                        role="secondary"
                        value="Cancel"
                        type="button"
                        onClick={() => navigate(ROUTE_PATH.RECEIPT_TYPE)}
                      />
                    </div>

                    <div className="submitButton">
                      <Button
                        role="primary"
                        value="Submit"
                        type="submit"
                        onClick={() => { }}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

export default CreateReceiptType;
