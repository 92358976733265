import CustomTable from '../../../../customtable/CustomTable';
import Button from '../../../../common/Button/button';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import './BusinessId.scss';
import { ROUTE_PATH } from '../../../../../constants/Routes';
import apiCall from '../../../../../utils/apiFunction/apiCall';
import {
	apiConstants,
	FOMRS, successMsg, tableNamePaymentConstants
} from '../../../../../constants/Common/apiConstants';
import { commonVariables, tableID, } from '../../../../../constants/Common/commonConstants';
import SearchBar from '../../../../common/Searchbar/searchbar';
import Pencil from "../../../../../Images/assests/images/pencil.svg"
import Trash from "../../../../../Images/assests/images/trash-bin.svg"
import { toast } from 'react-toastify';
import DeleteConfirmation from '../../../../deleteModal/deleteModal';

function BusinessId() {
	const navigate = useNavigate();
	const [pageNumber, setPageNumber] = useState(1);
	const [listdata, setListdata] = useState([]);
	const [loading, setLoading] = useState(false);
	const [datacount, setDatacount] = useState(0)
	const [searchValue, setSearchValue] = useState('');
	const [isSearch, setIsSearch] = useState(false);
	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
	const [deleteItemId, setDeleteItemId] = useState({});

	const searchValueChange = (value) => {
		setSearchValue(value);
	}



	useEffect(() => {
		listApi();
	}, []);

	const deleteFunc = () => {
		const query = {
			tablename: tableNamePaymentConstants?.BusinessIdMaster,
			whereQuery: deleteItemId
		}
		apiCall.post(apiConstants?.deleteRecord, query).then((res) => {
			if (res?.data?.status === 200) {
				toast.success(successMsg.Deleted)
				setShowDeleteConfirmationModal(false);
				listApi(pageNumber)
			} else {
				toast.error(res?.data?.message)
			}
		})
	}

	const listApi = (value) => {
		let temp = 0
		if ((value || pageNumber) > 0) {
			temp = value ? (value - 1) * 10 : (pageNumber - 1) * 10;
		} setLoading(true);
		apiCall
			.post(apiConstants?.fetchAllData, {
				offset: temp,
				limit: 10,
				tablename: tableNamePaymentConstants?.BusinessIdMaster,
				month: 0
			})
			.then(async (res) => {
				if (res?.status === 200) {
					let result = res?.data?.response;
					setDatacount(res?.data?.count || 0)
					result = result.map((item) => {
						let newItem = {
							no: item?.id,
							business_id: item?.business_id ? item?.business_id : '-',
							customer_type: item?.type ? item?.type : '-',
							action: (
								<div>
									<img
										className="icon"
										src={Pencil}
										alt=""
										onClick={() => {
											navigate(ROUTE_PATH.CREATE_BUSINESS_ID, {
												state: {
													type: FOMRS.EDIT,
													user_type: item,
												},
											});
										}}
									/>
									<img
										className="delete_icon"
										src={Trash}
										onClick={() => {
											setDeleteItemId({
												id: item?.id,
												business_id: item?.business_id,
												type: item?.type,
											})
											setShowDeleteConfirmationModal(true)
										}}
										alt=""
									/>
								</div>
							),
						};
						return newItem;
					});
					if (result?.length > 0) {
						setListdata([...result]);
					}
				}
			})
			.catch((er) => {
				console.log('err', er);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleSearch = (value) => {
		let temp = 0

		if ((value || pageNumber) > 0) {
			temp = value ? (value - 1) * 10 : (pageNumber - 1) * 10;
		}
		setIsSearch(true);
		setLoading(true);
		const params = {
			tableName: tableNamePaymentConstants?.BusinessIdMaster,
			columns: ['business_id', 'type'],
			offset: temp,
			order: [['id', 'DESC']]
		};

		apiCall
			.post(apiConstants?.commonSearch + searchValue, params)
			.then((res) => {
				if (res.status === 200) {
					let result = res?.data?.response.rows;
					setDatacount(res.data.count || 0)
					result = result.map((item) => {
						let newItem = {
							no: item?.id,
							business_id: item?.business_id ? item?.business_id : '-',
							customer_type: item?.type ? item?.type : '-',
							action: (
								<div>
									<img
										className="icon"
										src={Pencil}
										alt=""
										onClick={() => {
											navigate(ROUTE_PATH.CREATE_BUSINESS_ID, {
												state: {
													type: FOMRS.EDIT,
													user_type: item,
												},
											});
										}}
									/>
									<img
										className="delete_icon"
										src={Trash}
										onClick={() => {
											setDeleteItemId({
												id: item?.id,
												business_id: item?.business_id,
												type: item?.type,
											})
											setShowDeleteConfirmationModal(true)
										}}
										alt=""
									/>
								</div>
							),
						};
						return newItem;
					});
					if (result?.length > 0) {
						setListdata([...result]);
					} else {
						setListdata([...result]);
					}
				}

			}).catch((err) => {
				console.log("some error", err);
			}).finally(() => {
				setLoading(false);
			});
	}

	const changePage = (value) => {
		isSearch ? handleSearch(value) : listApi(value);
		setPageNumber(value - 1);
	};
	useEffect(() => {
		isSearch ? handleSearch() : listApi();
	}, []);
	useEffect(() => {
		if (searchValue === '') {
			setIsSearch(false);
			listApi();
		}
	}, [searchValue]);
	return (
		<>
			<div id="BusinessId">
				<div className="alignHeader">
					<div className="larger_f_bold blueFont setMargin">
						Business ID Master
					</div>
					<div className="setMargin d-flex">
						<div className='mx-3'>
							<SearchBar value={searchValue} onChange={searchValueChange} onSearchClick={handleSearch} />
						</div>
						{' '}
						<DeleteConfirmation
							showModal={showDeleteConfirmationModal}
							hideModal={() => setShowDeleteConfirmationModal(false)}
							confirmModal={deleteFunc}
							id={deleteItemId}
							type={FOMRS.DELETE}
							message="Are you sure you want to delete this item?"
						/>
						<Button
							value={'Create New Business ID'}
							role="primary"
							onClick={() => {
								navigate(ROUTE_PATH?.CREATE_BUSINESS_ID, {
									state: {
										type: FOMRS.ADD,
									},
								});
							}}
						/>
					</div>
				</div>
				<div className="col dtMergeTable">
					<CustomTable
						cols={[
							{
								id: tableID?.NO || "",
								title: commonVariables?.NO || "",
							},
							{
								id: tableID?.BUSINESS_ID || "",
								title: commonVariables?.BUSINESS_ID || "",
							},
							{
								id: tableID?.CUSTOMER_TYPE || "",
								title: commonVariables?.CUSTOMER_TYPE || "",
							},

							{
								id: tableID?.ACTION || "",
								title: commonVariables?.ACTION || "",
							},
						]}
						data={listdata}
						count={datacount}
						setPagination={(page) => changePage(page)}
						activePage={pageNumber}
						loading={loading}
					/>
				</div>
			</div>
		</>
	);
}

export default BusinessId;
