import { Formik, Form, Field, ErrorMessage } from 'formik';
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import './createRole.scss';
import Button from '.././common/Button/button';
import { useNavigate, useLocation } from 'react-router-dom';
import FormLabel from '../common/formLabel/formLabel';
import apiCall from '../../utils/apiFunction/apiCall';
import { apiConstants, FOMRS, STATUS, tableNamePaymentConstants, errMessage, successMsg, toastIds } from '../../constants/Common/apiConstants';
import { ROUTE_PATH } from '../../constants/Routes';
import { toast } from 'react-toastify';
import { YupMessages } from '../../constants/Common/commonConstants';

function CreateRole() {
	const validationSchema = Yup.object({
		user_role: Yup.string().required(YupMessages?.RoleRequired)
			.matches(/^(?!.*<script>).*$/, "User Role cannot contain scripts or HTML tags")
			.matches(/^(?!.*<\/?[a-z][\s\S]*>).*$/, "User Role cannot contain scripts or HTML tags")
			.matches(/^[^@#<>]+$/, "User Role cannot contain symbols like @ and #")
			.test('no-spaces', 'User Role cannot be only spaces', (value) => value.trim() !== ''),

		role_name: Yup.string().required(YupMessages?.NameRequired)
			.matches(/^(?!.*<script>).*$/, "Role name cannot contain scripts or HTML tags")
			.matches(/^(?!.*<\/?[a-z][\s\S]*>).*$/, "Role name cannot contain scripts or HTML tags")
			.matches(/^[A-Za-z]/, "Role name can only contain alphabets")
			.matches(/^[^@#<>$%^&*]+$/, "Role name cannot contain symbols like @ and #")
			.test('no-spaces', 'Role name cannot be only spaces', (value) => value.trim() !== ''),
	});

	const [loading, setLoading] = useState(false);

	const [formData, setFormData] = useState({
		user_role: '',
		role_name: '',

	});


	const navigate = useNavigate();
	const { state } = useLocation();
	const formType = state?.type || FOMRS.ADD;
	const headingText = formType === FOMRS.ADD ? "Create Role" : "Update Role";
	const buttonText = formType === FOMRS.ADD ? "Submit" : "Update";



	const FormSubmit = (data) => {
		let query = {
			body: {
				user_role: data?.user_role ? data?.user_role : '-',
				role_name: data?.role_name ? data?.role_name : '-',
			},
			tablename: tableNamePaymentConstants?.Role,
		};


		if (formType === FOMRS.EDIT) {
			query.whereQuery = {
				user_role: state?.data?.user_role
			};
			apiCall
				.post(apiConstants?.updateUser, query)
				.then((res) => {
					if (res?.status === STATUS.SUCCESS) {
						navigate(ROUTE_PATH.ROLE);
						toast.success(successMsg?.RoleUpdatedSuccessfully, toastIds?.FileUploadSuccess)
					}
				})

		}

		if (formType === FOMRS.ADD) {
			apiCall
				.post(apiConstants?.createUser, query)
				.then((res) => {
					if (res?.status === STATUS.SUCCESS) {
						navigate(ROUTE_PATH.ROLE);
						toast.success(successMsg?.RoleCreatedSuccessfully, toastIds?.FileUploadSuccess)
					}
				})

		}
	};


	useEffect(() => {
		if (formType === FOMRS.EDIT) {
			setFormData({
				user_role: state?.data?.user_role,
				role_name: state?.data?.role_name,

			});
		}
	}, [loading]);

	return (
		<Formik
			initialValues={{
				user_role: formData.user_role,
				role_name: formData.role_name,
			}}
			validationSchema={validationSchema}
			enableReinitialize={true}
			onSubmit={(values) => {
				FormSubmit(values);
			}}
		>
			{({ values, setFieldValue }) => (
				<>
					<div id="createRole">
						<div className="main-div">
							<Form>
								<div className="mt-5 ">
									<div className="normal-createrole-text mx-3">
										<p
											className="rolecreatelabel"
											onClick={() => navigate(ROUTE_PATH?.ROLE)}
										>
											Role &gt;&gt;
											<span className="labelcolor">{headingText}</span>
										</p>
									</div>
								</div>

								<div id="RegularUserForm" className='mx-3'>
									<div className="createroletext">
										<p id="pText">{headingText}</p>
									</div>

									<div className="row paddingTop ml-2 mr-4 ">
										<div className="col-md-12 col-lg-6 medium_f_bold directionColumn">
											<div>
												<FormLabel value="Role" className="required" />
											</div>
											<Field
												type="text"
												className="InputField"
												name="user_role"
												id="user_role"
												placeholder="Role"
											></Field>
											<div className="errorMessage">
												<ErrorMessage name="user_role" />
											</div>
										</div>
										<div className="col-md-12 col-lg-6 medium_f_bold directionColumn">
											<FormLabel value="Name" className="required" />
											<Field
												type="text"
												className="InputField"
												name="role_name"
												id="role_name"
												placeholder="Name"
											></Field>
											<div className="errorMessage">
												<ErrorMessage name="role_name" />
											</div>
										</div>
									</div>


									<div className="submitButton d-flex flex-wrap gap-2">
											<Button
												role="secondary"
												value="Cancel"
												onClick={() => navigate(ROUTE_PATH.ROLE)}
											/>
											<Button
												className="buttonMargin"
												role="primary"
												value={buttonText}
												onClick={() => { }}
												type="submit"
											/>
									</div>
								</div>
							</Form>
						</div>
					</div>
				</>
			)}
		</Formik>
	);
}

export default CreateRole;
