import React, { useEffect, useState } from "react";
import Header from "./header";
import Sidebar from "./sidebar";
import "./Layout.scss";
import { useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { ReactComponent as HamburgerIcon } from "../Images/sidebar/list.svg";

function Layout({ children }) {
  const [showSidebar, setShowSidebar] = useState(true);
  const [dimension, setDimension] = useState();
  const { pathname } = useLocation();
  useEffect(() => {
    const deviceHeight = (val) => {
      setDimension(val.target.innerWidth);
      if (val.target.innerWidth >= 1000) {
        setShowSidebar(true);
      }
    };
    window.addEventListener("resize", deviceHeight);
    return () => {
      window.removeEventListener("resize", deviceHeight);
    };
  });
  return (
    <div className="container-fluid p-0 m-0">
      <div>
        {/*{pathname === '/' || pathname==='/registermandate' ? (*/}
        {pathname === "/" ||
        pathname.includes("/responsestatus") ||
        pathname.includes("/dtdemergesystemcount") ||
        pathname.includes("/dtdemergefinnonedownload") ||
        pathname.includes("/dtsourceManualDownload") ||
        pathname.includes("/enach/linkexpired") ||
        pathname.includes("/tech_process_v2/e_mandate/upiTransaction") ||
        pathname.includes("/upiTransactionPollingPage") ||
        pathname.includes("/tech_process_v2/e_mandate/auth/find") ? (
          <></>
        ) : (
          <Header />
        )}
      </div>
      <div
        className={`${
          pathname === "/changePassword" ? "pass" : ""
        }grid-container${showSidebar ? "" : "-hide"}`}
      >
        {pathname === "/" ||
        pathname === "/changePassword" ||
        pathname.includes("/dtdemergesystemcount") ||
        pathname.includes("/dtdemergefinnonedownload") ||
        pathname.includes("/dtsourceManualDownload") ||
        pathname.includes("/responsestatus") ||
        pathname.includes("/enach/linkexpired") ||
        pathname.includes("/tech_process_v2/e_mandate/upiTransaction") ||
        pathname.includes("/upiTransactionPollingPage") ||
        pathname.includes("/tech_process_v2/e_mandate/auth/find") ? (
          <></>
        ) : (
          <div className="left-column">
            <Sidebar />
          </div>
        )}
        <div
          className={
            pathname === "/" ||
            pathname === "/changePassword" ||
            pathname.includes("/responsestatus") ||
            pathname.includes("/dtdemergesystemcount") ||
            pathname.includes("/dtdemergefinnonedownload") ||
            pathname.includes("/dtsourceManualDownload") ||
            pathname.includes("/enach/linkexpired") ||
            pathname.includes("/tech_process_v2/e_mandate/upiTransaction") ||
            pathname.includes("/upiTransactionPollingPage") ||
            pathname.includes("/tech_process_v2/e_mandate/auth/find")
              ? ""
              : "right-column"
          }
        >
          {pathname === "/" ||
          pathname === "/changePassword" ||
          pathname.includes("/responsestatus") ||
          pathname.includes("/dtdemergesystemcount") ||
          pathname.includes("/dtdemergefinnonedownload") ||
          pathname.includes("/dtsourceManualDownload") ||
          pathname.includes("/enach/linkexpired") ||
          pathname.includes("/tech_process_v2/e_mandate/upiTransaction") ||
          pathname.includes("/upiTransactionPollingPage") ||
          pathname.includes("/tech_process_v2/e_mandate/auth/find") ? (
            ""
          ) : dimension < 1000 ? (
            <div className="HamburgerIcon cursor-pointer">
              <HamburgerIcon
                className="toggleSwitch"
                onClick={() => setShowSidebar(!showSidebar)}
              />
            </div>
          ) : (
            ""
          )}
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
}

export default Layout;
