export const ROUTE_PATH = {
  ROOT: "/",
  USER: "/user",
  ROLE: "/role",
  USERTYPE: "/userType",
  API_USER_REGISTRATION: "/apiUserRegistration",
  BAD_REQUEST_ERROR: "/BadGatewayError",
  UNAUTHORIZED_ERROR: "/UnauthorizedError",
  CREATE_ROLE: "/createRole",
  ACCOUNT_VALIDATION_STATUS: '/accountValidationStatus',
  CASE_DETAILS_APPROVAL: "/caseDetailsApproval",
  CREATE_USER_TYPE: "/createUserType",
  CREATE_API_USER: "/createApiUser",
  ASSIGN_ROLE: "/assignRole",
  ENACH: "/enach",
  DEBIT_TRANSACTION: "/transaction",
  PAYEMENT: "/payment",
  CaseDetails: "/caseDetails",
  HOME: "/index",
  SINGLE_VERIFY_STATUS: '/singleVerifyStatus',
  REGISTER_MANDATE: "/tech_process_v2/e_mandate/auth/find/:key",
  MANDATE: "/mandate",
  MANDATE_UPDATE: "/mandateUpdate",
  STATUS: "/status",
  BULK_MANDATE: "/bulk-mandate",
  REPORT: "/report",
  REPORTS: "/reports",
  PAYMENT_API_STATUS_BULK: "/api-status-bulk",
  PAYMENT_SPI_STATUS_BATCH: "/api-status-batch",
  REVERSAL_API_BASED: "/reversal-apibased",
  REVERSAL_File_BASED_Bulk: "/reversal-filebased-bulk",
  REPORT_DISBURSMENT_REPORT: "/report-disbursment-report",
  REPORT_DISBURSMENT_TABLE: "/report-disbursment-table",

  DEBIT_TRANSACTION_PRESENTATION: "/dtpresentation",
  DEBIT_TRANSACTION_DEMERGE_STATUS: "/dtdemergestatus",

  DEBIT_TRANSACTION_REPRESENTATION_STATUS: "/dtrepresentationstatus",
  DEBIT_TRANSACTION_PRESENTATION: "/dtpresentation",
  DEBIT_TRANSACTION_DEMERGE_STATUS: "/dtdemergestatus",
  DEBIT_TRANSACTION_REPRESENTATION_STATUS: "/dtrepresentationstatus",
  RPT_OTU: "/rptReversalUpload",
  DEBIT_TRANSACTION_DMERGE: "/dtmerge",
  SINGLE_VERIFY: "/singleVerify",
  REGULAR_PAYMENT: "/regularpayment",
  HOLD_PAYMENT: "/holdpayment",
  UNHOLD_PAYMENT: "/unholdpayment",
  EXCEPTION_PAYMENT: "/exceptionpayment",
  //autopayment 
  REGULAR_AUTO_PAYMENT: 'regularautopayment',
  AUTO_PAYMENT_BANK_MASTEER: 'autopaymentbankmaster',
  DEBIT_TRANSACTION_MERGE: "/merge",
  DEBIT_TRANSACTION_BILLUPLOAD_STATUS: "/billUploadStatus",
  DEBIT_TRANSACTION_BILLUPLOAD_STATUS_UPLOAD: "/billupload", //api integration
  DEBIT_TRANSACTION_REPORTS_MANDATE_REGISTER_HDFC_REPORT:
    "/mandateRegRevReport/HDFC",
  DEBIT_TRANSACTION_REPORTS_MANDATE_REGISTER_INDUSLAND_REPORT:
    "/mandateRegRevReport/INDUSLAND",
  DEBIT_TRANSACTION_REPORTS_REGISTRATION_BANKING: "/registrationBanking", //dropdown api
  PAYMENT_INDUSHDFC: "/payment/indushdfc", //api integration  and formik
  PAYMENT_REVERSAL: "/payment/reversal", //api integration  and formik
  PAYMENT_REVERSAL_STATUS_FILE_BASED_BATCH:
    "/payment/reversalstatus/filebasedbatch", //api integration
  CREATE_USER: "/createuser",
  ACCOUNT_VALIDATION_ACCOUNTS: "/accountvalidtion/accounts", //api integration
  ACCOUNT_VALIDATION_VERIFYBULK: "/verifyAccount/bulk", //api integration
  ACCOUNT_VALIDATION_REPORTS: "/avReports", //api integration formik
  DYNAMIC_FORM: "/dynamicForm/:type",
  DEBIT_TRANSACTION_DEMERGE_SYSTEM_COUNT: "dtdemergesystemcount/:id",
  DEBIT_TRANSACTION_DEMERGE_SOURCE_SYSTEM_DOWNLOAD: "dtdemergefinnonedownload",
  DEBIT_TRANSACTION_PRESENTATION_CREATE: "/dtpresentationcreate",
  ENACH_REPORT: "/enach/report",
  SINGLE_MANDATE: "/enach/singlemandate",
  VERIFY_ACCOUNT: "/verifyaccount",
  SYSTEM: "/system",
  SOURCE_SYSTEM_MASTER: "/source_system_master",
  ENACH_VENDER: "/enach_vender",
  ACCOUNT_TYPE: "/account_type",
  VENDOR: "/vendor",
  REJECTION: "/rejection",
  SCHEME: "/scheme",
  CREATE_SYSTEM: "/create_system",
  CREATE_SOURCE_SYSTEM_MASTER: "/create_source_system_master",
  CREATE_ENACH_VENDOR: "/create_enach_vendor",
  CREATE_ACCOUNT_VALIDATION_VENDOR: "/create_account_validation_vendor",
  CREATE_DISBURSMENT_VENDOR: "/create_disbursment_vendor",
  CREATE_ACCOUNT: "/create_account",
  CREATE_VENDOR: "/create_vendor",
  CREATE_REJ: "/create_rejection",
  CREATE_SCHEME: "/create_scheme",
  DEBIT_TRANSACTION_MERGE: "/merge",
  DEBIT_TRANSACTION_BILLUPLOAD_STATUS: "/billUploadStatus",
  DEBIT_TRANSACTION_BILLUPLOAD_STATUS_UPLOAD: "/billupload", //api integration
  DEBIT_TRANSACTION_REPORTS_MANDATE_REGISTER_HDFC_REPORT:
    "/mandateRegRevReport/HDFC",
  DEBIT_TRANSACTION_REPORTS_MANDATE_REGISTER_INDUSLAND_REPORT:
    "/mandateRegRevReport/INDUSLAND",
  DEBIT_TRANSACTION_REPORTS_REGISTRATION_BANKING: "/registrationBanking", //dropdown api
  PAYMENT_INDUSHDFC: "/payment/indushdfc", //api integration  and formik
  PAYMENT_REVERSAL: "/payment/reversal", //api integration  and formik
  PAYMENT_REVERSAL_STATUS_FILE_BASED_BATCH:
    "/payment/reversalstatus/filebasedbatch", //api integration
  ACCOUNT_VALIDATION_ACCOUNTS: "/accountvalidtion/accounts", //api integration
  ACCOUNT_VALIDATION_VERIFYBULK: "/verifyAccount/bulk", //api integration
  ACCOUNT_VALIDATION_REPORTS: "/avReports", //api integration formik
  DYNAMIC_FORM: "/dynamicForm/:type",
  DEBIT_TRANSACTION_DEMERGE_SYSTEM_COUNT: "dtdemergesystemcount/:id",
  DEBIT_TRANSACTION_DEMERGE_SOURCE_SYSTEM_DOWNLOAD: "dtdemergefinnonedownload",
  DEBIT_TRANSACTION_PRESENTATION_CREATE: "/dtpresentationcreate",

  COMPANY: "/company",
  CREATE_COMPANY: "/company/create",

  E_MANDATE_TYPE: "/mandate_type",
  CREATE_MANDATE_TYPE: "/mandate_type/create",

  FREQUENCY: "/frequency",
  CREATE_FREQUENCY: "/frequency/create",

  COLLECTION_SPONSOR_BANK: "/collection_sponsor_bank",
  CREATE_SPONSOR: "/collection_sponsor_bank/create",

  BANCS_SPONSOR_BANK: "/bancs_sponsor_bank",
  CREATE_BANCS_SPONSOR_BANK: "/bancs_sponsor_bank/edit",
  PAYMENT_TYPE: "/payment_type",
  CREATE_SPONSOR_PAYMENT: "/payment_type/create",
  SPONSOR_PAYMENT_BANK: "/sponsor_payment_bank",
  CREATE_NEW_SPONSOR_PAYMENT_BANK: "/sponsor_payment_bank/create",
  BUSINESS_ID: "/business_id",
  CREATE_BUSINESS_ID: "/business_id/create",
  RECEIPT_TYPE: "/receipt_type/list",
  CREATE_RECEIPT_TYPE: "/receipt_type/update",
  NUPAY_BANK_REPORTS: "/system_master/nupay_bank_reports",
  ROOT: "/",
  /*ENACH PATHS*/
  MANDATE: "/mandate",
  STATUS: "/status",
  BULK_MANDATE: "/bulk-mandate",
  SWAP_UPLOAD: "/swap_upload",
  SWAP_LIST: "/enach/mandateBlockCaseSwap",
  SWAP_REVERSAL_LIST: "/enach/swapReversalList",
  REPORT: "/report",
  REGISTER_MANDATE: "/tech_process_v2/e_mandate/auth/find/:key",
  ENACH: "/enach",
  ENACH_REPORT: "/enach/report",
  SINGLE_MANDATE: "/enach/singlemandate",
  RESPONSE_STATUS: '/responsestatus/:key',
  EACH_ENACH_ADOPTION_REPORT: '/reports/EnachAdoptionReport',
  ENACH_MONTHS: '/enachMonths',

  //  (Add New Routes Above)
  /* ----END----*/

  /*PAYMENT PATHS*/
  REGULAR_PAYMENT: "/regularpayment",
  HOLD_PAYMENT: "/holdpayment",
  UNHOLD_PAYMENT: "/unholdpayment",
  EXCEPTION_PAYMENT: "/exceptionpayment",
  PAYMENT_API_STATUS_BULK: "/api-status-bulk",
  PAYMENT_SPI_STATUS_BATCH: "/api-status-batch",
  REVERSAL_API_BASED: "/reversal-apibased",
  REVERSAL_File_BASED_Bulk: "/reversal-filebased-bulk",
  REPORT_DISBURSMENT_REPORT: "/report-disbursment-report",
  REPORT_DISBURSMENT_TABLE: "/report-disbursment-table",
  PAYMENT_INDUSHDFC: "/payment/indushdfc", //api integration  and formik
  PAYMENT_REVERSAL: "/payment/reversal", //api integration  and formik
  PAYMENT_REVERSAL_STATUS_FILE_BASED_BATCH:
    "/payment/reversalstatus/filebasedbatch",
  PAYEMENT: "/payment",

  //  (Add New Routes Above)
  /* ----END----*/

  /*DebitTransaction PATHS*/
  DEBIT_TRANSACTION_PRESENTATION: "/dtpresentation",
  DEBIT_TRANSACTION_DEMERGE_STATUS: "/dtdemergestatus",

  DEBIT_TRANSACTION_REPRESENTATION_STATUS: "/dtrepresentationstatus",
  DEBIT_TRANSACTION_PRESENTATION: "/dtpresentation",
  DEBIT_TRANSACTION_DMERGE: "/dtmerge",
  DEBIT_TRANSACTION_MERGE: "/merge",
  DEBIT_TRANSACTION_BILLUPLOAD_STATUS: "/billUploadStatus",
  DEBIT_TRANSACTION_BILLUPLOAD_STATUS_UPLOAD: "/billupload", //api integration
  DEBIT_TRANSACTION_REPORTS_MANDATE_REGISTER_HDFC_REPORT: "/mandateRegRevReport/HDFC",
  DEBIT_TRANSACTION_REPORTS_MANDATE_REGISTER_INDUSLAND_REPORT:
    "/mandateRegRevReport/INDUSLAND",
  DEBIT_TRANSACTION_REPORTS_REGISTRATION_BANKING: "/registrationBanking",
  DEBIT_TRANSACTION_DEMERGE_SYSTEM_COUNT: "dtdemergesystemcount/:id",
  DEBIT_TRANSACTION_DEMERGE_SOURCE_SYSTEM_DOWNLOAD: "dtdemergefinnonedownload",
  DEBIT_TRANSACTION_PRESENTATION_CREATE: "/dtpresentationcreate",
  DEBIT_TRANSACTION: "/transaction",
  UPI_EXECUTION_UPDATE: "/upiExecutionUpdate",


  //  (Add New Routes Above)
  /* ----END----*/

  /*AccountNumberValidation PATHS*/

  ACCOUNT_VALIDATION_ACCOUNTS: "/accountvalidtion/accounts", //api integration
  ACCOUNT_VALIDATION_VERIFYBULK: "/verifyAccount/bulk", //api integration
  ACCOUNT_VALIDATION_REPORTS: "/avReports", //api integration formik
  VERIFY_ACCOUNT: "/verifyaccount",

  //  (Add New Routes Above)
  /* ----END----*/

  /*MASTERS PATHS*/
  API_USER_REGISTRATION: "/apiUserRegistration",
  CREATE_USER_TYPE: "/createUserType",
  CREATE_API_USER: "/createApiUser",
  ASSIGN_ROLE: "/assignRole",
  CREATE_ROLE: "/createRole",
  HEALTH_REJECTION: "/health_check_rejection",
  CREATE_HEALTH_REJECTION: "/create-rejection",
  CREATE_USER: "/createuser",
  USER: "/user",
  ROLE: "/role",
  USERTYPE: "/userType",
  REGISTRATION_REJECTION: "/registration-rejection",
  CREATE_REGISTRATION_REJECTION: "/create-registration-rejection",
  TRANSACTION_REJECTION: "/transaction-rejection",
  CREATE_TRANSACTION_REJECTION: "/create-transaction-rejection",

  //  (Add New Routes Above)
  /* ----END----*/

  /*COMMON PATHS*/
  BAD_REQUEST_ERROR: "/BadGatewayError",
  UNAUTHORIZED_ERROR: "/UnauthorizedError",

  CaseDetails: "/caseDetails",
  HOME: "/index",
  REPORTS: "/reports",
  //api integration
  DYNAMIC_FORM: "/dynamicForm/:type",
  ROOT: "/",
  CHANGEPASSWORD: "/changePassword",
  /*ENACH PATHS*/
  MANDATE: "/mandate",
  STATUS: "/status",
  BULK_MANDATE: "/bulk-mandate",
  REPORT: "/report",
  REGISTER_MANDATE: "/tech_process_v2/e_mandate/auth/find/:key",
  UPI_TRANSACTION_PAGE: "/tech_process_v2/e_mandate/upiTransaction/:key",
  UPI_POLLING_PAGE: "/tech_process_v2/e_mandate/upiTransactionPollingPage/:key",
  ENACH: "/enach",
  ENACH_REPORT: "/enach/report",
  SINGLE_MANDATE: "/enach/singlemandate",
  MANDATE_DETAILS: "/enach/mandateDetails",

  //  (Add New Routes Above)
  /* ----END----*/

  /*PAYMENT PATHS*/
  REGULAR_PAYMENT: "/regularpayment",
  HOLD_PAYMENT: "/holdpayment",
  UNHOLD_PAYMENT: "/unholdpayment",
  EXCEPTION_PAYMENT: "/exceptionpayment",
  PAYMENT_API_STATUS_BULK: "/api-status-bulk",
  PAYMENT_SPI_STATUS_BATCH: "/api-status-batch",
  REVERSAL_API_BASED: "/reversal-apibased",
  REVERSAL_File_BASED_Bulk: "/reversal-filebased-bulk",
  REPORT_DISBURSMENT_REPORT: "/report-disbursment-report",
  REPORT_DISBURSMENT_TABLE: "/report-disbursment-table",
  PAYMENT_INDUSHDFC: "/payment/indushdfc", //api integration  and formik
  PAYMENT_REVERSAL: "/payment/reversal", //api integration  and formik
  PAYMENT_REVERSAL_STATUS_FILE_BASED_BATCH:
    "/payment/reversalstatus/filebasedbatch",
  PAYEMENT: "/payment",

  //  (Add New Routes Above)
  /* ----END----*/

  /*DebitTransaction PATHS*/
  DEBIT_TRANSACTION_PRESENTATION: "/dtpresentation",
  DEBIT_TRANSACTION_DEMERGE_STATUS: "/dtdemergestatus",

  DEBIT_TRANSACTION_REPRESENTATION_STATUS: "/dtrepresentationstatus",
  DEBIT_TRANSACTION_PRESENTATION: "/dtpresentation",
  DEBIT_TRANSACTION_DEMERGE_STATUS: "/dtdemergestatus",
  DEBIT_TRANSACTION_REPRESENTATION_STATUS: "/dtrepresentationstatus",
  DEBIT_TRANSACTION_DMERGE: "/dtmerge",
  DEBIT_TRANSACTION_MERGE: "/merge",
  DEBIT_TRANSACTION_BILLUPLOAD_STATUS: "/billUploadStatus",
  DEBIT_TRANSACTION_BILLUPLOAD_STATUS_UPLOAD: "/billupload", //api integration
  DEBIT_TRANSACTION_REPORTS_MANDATE_REGISTER_HDFC_REPORT:
    "/mandateRegRevReport/HDFC",
  DEBIT_TRANSACTION_REPORTS_MANDATE_REGISTER_INDUSLAND_REPORT:
    "/mandateRegRevReport/INDUSLAND",
  DEBIT_TRANSACTION_REPORTS_REGISTRATION_BANKING: "/registrationBanking",
  DEBIT_TRANSACTION_DEMERGE_SYSTEM_COUNT: "dtdemergesystemcount/:id",
  DEBIT_TRANSACTION_DEMERGE_SOURCE_SYSTEM_DOWNLOAD: "dtdemergefinnonedownload",
  DEBIT_TRANSACTION_PRESENTATION_CREATE: "/dtpresentationcreate",
  DEBIT_TRANSACTION: "/transaction",

  //  (Add New Routes Above)
  /* ----END----*/

  /*AccountNumberValidation PATHS*/

  ACCOUNT_VALIDATION_ACCOUNTS: "/accountvalidtion/accounts", //api integration
  ACCOUNT_VALIDATION_VERIFYBULK: "/verifyAccount/bulk", //api integration
  ACCOUNT_VALIDATION_REPORTS: "/avReports", //api integration formik
  VERIFY_ACCOUNT: "/verifyaccount",

  //  (Add New Routes Above)
  /* ----END----*/

  /*MASTERS PATHS*/
  API_USER_REGISTRATION: "/apiUserRegistration",
  CREATE_USER_TYPE: "/createUserType",
  CREATE_API_USER: "/createApiUser",
  ASSIGN_ROLE: "/assignRole",
  CREATE_ROLE: "/createRole",
  HEALTH_REJECTION: "/health_check_rejection",
  CREATE_HEALTH_REJECTION: "/create-rejection",
  CREATE_USER: "/createuser",
  USER: "/user",
  ROLE: "/role",
  USERTYPE: "/userType",
  REGISTRATION_REJECTION: "/registration-rejection",
  CREATE_REGISTRATION_REJECTION: "/create-registration-rejection",
  TRANSACTION_REJECTION: "/transaction-rejection",
  CREATE_TRANSACTION_REJECTION: "/create-transaction-rejection",
  CATEGORY: "/category",
  CREATE_CATEGORY: "/create-category",
  PRODUCT: "/product",
  CREATE_PRODUCT: "/create-product",
  SUB_PRODUCT: "/product/sub-product",
  CREATE_SUB_PRODUCT: "/product/create-sub-product",
  MICR: "/micr",
  CREATE_MICR: "/micr/create-micr",
  ACCOUNT_VALIDATION_VENDOR: "/account_validation_vendor",
  DISBURSMENT_VENDOR: "/disbursment_vendor",
  //  (Add New Routes Above)
  /* ----END----*/

  /*COMMON PATHS*/
  BAD_REQUEST_ERROR: "/BadGatewayError",
  UNAUTHORIZED_ERROR: "/UnauthorizedError",

  CaseDetails: "/caseDetails",
  HOME: "/index",
  REPORTS: "/reports",
  //api integration
  DYNAMIC_FORM: "/dynamicForm/:type",
  ROOT: "/",
  /*ENACH PATHS*/
  MANDATE: "/mandate",
  STATUS: "/status",
  BULK_MANDATE: "/bulk-mandate",
  REPORT: "/report",
  REGISTER_MANDATE: "/tech_process_v2/e_mandate/auth/find/:key",
  ENACH: "/enach",
  ENACH_REPORT: "/enach/report",
  SINGLE_MANDATE: "/enach/singlemandate",
  VIEW_MANDATE: "/enach/viewemandate",
  LINK_EXPIRED: '/enach/linkexpired',

  //  (Add New Routes Above)
  /* ----END----*/

  /*PAYMENT PATHS*/
  REGULAR_PAYMENT: "/regularpayment",
  HOLD_PAYMENT: "/holdpayment",
  UNHOLD_PAYMENT: "/unholdpayment",
  EXCEPTION_PAYMENT: "/exceptionpayment",
  PAYMENT_API_STATUS_BULK: "/api-status-bulk",
  PAYMENT_SPI_STATUS_BATCH: "/api-status-batch",
  REVERSAL_API_BASED: "/reversal-apibased",
  REVERSAL_File_BASED_Bulk: "/reversal-filebased-bulk",
  REPORT_DISBURSMENT_REPORT: "/report-disbursment-report",
  REPORT_DISBURSMENT_TABLE: "/report-disbursment-table",
  PAYMENT_REPORT: "/payment-report",
  PAYMENT_INDUSHDFC: "/payment/indushdfc", //api integration  and formik
  PAYMENT_REVERSAL: "/payment/reversal", //api integration  and formik
  REVERSAL_PAYTM_BASED: 'reversal-paytmbased',
  PAYMENT_REVERSAL_STATUS_FILE_BASED_BATCH:
    "/payment/reversalstatus/filebasedbatch",
  PAYEMENT: "/payment",

  //  (Add New Routes Above)
  /* ----END----*/

  /*DebitTransaction PATHS*/
  DEBIT_TRANSACTION_PRESENTATION: "/dtpresentation",
  DEBIT_TRANSACTION_DEMERGE_STATUS: "/dtdemergestatus",

  DEBIT_TRANSACTION_REPRESENTATION_STATUS: "/dtrepresentationstatus",
  DEBIT_TRANSACTION_PRESENTATION: "/dtpresentation",
  DEBIT_TRANSACTION_DEMERGE_STATUS: "/dtdemergestatus",
  DEBIT_TRANSACTION_REPRESENTATION_STATUS: "/dtrepresentationstatus",
  DEBIT_TRANSACTION_DMERGE: "/dtmerge",
  DEBIT_TRANSACTION_MERGE: "/merge",
  DEBIT_TRANSACTION_BILLUPLOAD_STATUS: "/billUploadStatus",
  DEBIT_TRANSACTION_BILLUPLOAD_STATUS_UPLOAD: "/billupload", //api integration
  DEBIT_TRANSACTION_REPORTS_MANDATE_REGISTER_HDFC_REPORT:
    "/mandateRegRevReport/HDFC",
  DEBIT_TRANSACTION_REPORTS_MANDATE_REGISTER_INDUSLAND_REPORT:
    "/mandateRegRevReport/INDUSLAND",
  DEBIT_TRANSACTION_REPORTS_REGISTRATION_BANKING: "/registrationBanking",
  DEBIT_TRANSACTION_DEMERGE_SYSTEM_COUNT: "dtdemergesystemcount/:id",
  DEBIT_TRANSACTION_DEMERGE_SOURCE_SYSTEM_DOWNLOAD: "dtdemergefinnonedownload",
  DEBIT_TRANSACTION_PRESENTATION_CREATE: "/dtpresentationcreate",
  DEBIT_TRANSACTION: "/transaction",
  SFTPUPLOAD: "/reports/sftpUpload",
  SFTPTABLE: "reports/sftpTable",
  MANDATE_REGISTRATION_DEMERGE: "/mandateregistrationdemerge",
  PHYSICAL_DEMERGE_STATUS: "/physicaldemergestatus",
  SCAN_DEMERGE_STATUS: "/scandemergestatus",

  //  (Add New Routes Above)
  /* ----END----*/

  /*AccountNumberValidation PATHS*/

  ACCOUNT_VALIDATION_ACCOUNTS: "/accountvalidtion/accounts", //api integration
  ACCOUNT_VALIDATION_VERIFYBULK: "/verifyAccount/bulk", //api integration
  ACCOUNT_VALIDATION_REPORTS: "/avReports", //api integration formik
  VERIFY_ACCOUNT: "/verifyaccount",

  //  (Add New Routes Above)
  /* ----END----*/

  /*MASTERS PATHS*/
  API_USER_REGISTRATION: "/apiUserRegistration",
  CREATE_USER_TYPE: "/createUserType",
  CREATE_API_USER: "/createApiUser",
  ASSIGN_ROLE: "/assignRole",
  CREATE_ROLE: "/createRole",
  HEALTH_REJECTION: "/health_check_rejection",
  CREATE_HEALTH_REJECTION: "/create-rejection",
  CREATE_USER: "/createuser",
  USER: "/user",
  ROLE: "/role",
  USERTYPE: "/userType",
  REGISTRATION_REJECTION: "/registration-rejection",
  CREATE_REGISTRATION_REJECTION: "/create-registration-rejection",
  TRANSACTION_REJECTION: "/transaction-rejection",
  CREATE_TRANSACTION_REJECTION: "/create-transaction-rejection",

  //  (Add New Routes Above)
  /* ----END----*/

  /*COMMON PATHS*/
  BAD_REQUEST_ERROR: "/BadGatewayError",
  UNAUTHORIZED_ERROR: "/UnauthorizedError",

  MoveToException: "/moveToException",
  CaseDetails: "/caseDetails",
  HOME: "/index",
  REPORTS: "/reports",
  //api integration
  DYNAMIC_FORM: "/dynamicForm/:type",
  Disbursement_Table: "/disbursementTable",
  Disbursement_Report: "/disbursementReport",
  Payment_Report: "/paymentReport",

  //REPORTS
  PROVISION_REPORTS: "/reports/provisionReports",
  PROVISION_REPORT_ANALYSIS: "/reports/provisionReportAnalysis",
  SCAN_MERGE_STATUS: "/reports/scanMergeStatus",
  SCAN_SFTP_MERGE_STATUS: "/reports/scanSftpMergeStatus",
  SCAN_SFTP_MERGE_VIEW: "/reports/scanSftpMergeView",
  SCAN_MERGE_STATUS_VIEW: "/reports/scanMergeView",
  PAYMENT_LOGS: '/reports/paymentLogs',
  SCAN_SFTP_MERGE_LOG: "/reports/scanSftpMergeLog",
  OPEN_MANDATE_REPORT: "/reports/OpenMandateReports"
};
