import React, { useEffect, useState } from "react";

const ManualMarkingInputBox = (props) => {
  const [batchIdChild, setBatchIdChild] = useState("");
  const [loanNumber, setLoanNumber] = useState("");
  const [journalNo, setJournalNo] = useState("");
 
  const handleBatchId = (event) => {
    setBatchIdChild(event?.target?.value);
    localStorage.setItem("batchId", event?.target?.value);
  };

  const handleLoanNumber = (event) => {
    setLoanNumber(event?.target?.value);
    localStorage.setItem("loanNumber", event?.target?.value);
  };

  const handleJournalNumber = (event) => {
    setJournalNo(event?.target?.value);
    localStorage.setItem("journalNo", event?.target?.value);
  };

  useEffect(() => {
    setBatchIdChild("");
    localStorage.removeItem("journalNo");
    setLoanNumber("");
    localStorage.removeItem("loanNumber");
    setJournalNo("");
    localStorage.removeItem("batchId");
  }, [props?.clearData]);


  return (
    <>
      {props?.isCaseDetails ? (
        <>
          <p className="mb-0 mt-3 CaseDetails_DisbursmentCaseWrapper_label">
            <b>
              Enter Batch ID
              <span className="CaseDetails_DisbursmentCaseWrapper_required">
                *
              </span>
            </b>
          </p>
          <input
            type="text"
            className="CaseDetails_inputBox"
            value={batchIdChild}
            required
            onChange={(e) => handleBatchId(e)}
          />
          <p className="mb-0 mt-3 CaseDetails_DisbursmentCaseWrapper_label">
            <b>Enter Loan Number</b>
          </p>
          <input
            type="text"
            className="CaseDetails_inputBox"
            name="loanNumber"
            onChange={(e) => handleLoanNumber(e)}
            value={loanNumber}
          />
        </>
      ) : (
        <>
          <p className="mb-0 mt-3 CaseDetails_DisbursmentCaseWrapper_label">
            <b>
              Enter Loan Number
              <span className="CaseDetails_DisbursmentCaseWrapper_required">
                *
              </span>
            </b>
          </p>
          <input
            type="text"
            className="CaseDetails_inputBox"
            value={loanNumber}
            required
            onChange={(e) => handleLoanNumber(e)}
          />
          <p className="mb-0 mt-3 CaseDetails_DisbursmentCaseWrapper_label">
            <b>Enter journal No / Payment Ref No</b>
          </p>
          <input
            type="text"
            className="CaseDetails_inputBox"
            onChange={(e) => handleJournalNumber(e)}
            value={journalNo}
          />
        </>
      )}
    </>
  );
};

export default ManualMarkingInputBox;
