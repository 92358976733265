import React, { Component, useEffect, useState } from "react";
import CustomTable from "../../../component/customtable/CustomTable";
import Button from "../../../component/common/Button/button";
import apiCall from "../../../utils/apiFunction/apiCall";
import InputField from "../../../component/common/Inputfield/inputfield";
import Dropdown from "../../../component/common/Dropdown/dropdown";
import {
  selectoptions,
  componentRole,
} from "../../../constants/Common/commonConstants";
import "./accountlist.scss";
import {
  apiConstants,
  tableNamePaymentConstants,
} from "../../../constants/Common/apiConstants";

const AccountList = () => {
  const [selectedMonth, setSelectedMonth] = useState("");
  const [listdata, setListdata] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [loading, setLoading] = useState(false);

  const listApi = (value) => {
    let temp = value ? (value - 1) * 10 : (pageNumber - 1) * 10;
    setLoading(true);
    const viewData = (viewdata) => {
      return (
        <div className="d-flex align-items-center justify-content-between">
          <div className="right-div-header">{viewdata}</div>
          <div className="d-flex left-div-header">
            <Button value={"View"} role="secondary" className="view me-3" />
          </div>
        </div>
      );
    };
    const sms_Not = (smsdata) => {
      return (
        <div className="d-flex align-items-center justify-content-between">
          <div className="right-div-header">{smsdata}</div>
          <div className="d-flex left-div-header">
            <Button value={"Resend"} role="secondary" className="view me-3" />
          </div>
        </div>
      );
    };

    apiCall
      .post(apiConstants?.fetchAllData, {
        offset: temp,
        limit: 10,
        tablename: tableNamePaymentConstants?.BulkMandateReg,
      })
      .then(async (res) => {
        if (res.status === 200) {
          let result = res?.data?.response;
          result = result.map((item) => {
            let newItem = {
              no: item.id,
              batch_id: item.batch_id,
              file_name: item.file_name,
              uploder_name: item.user_name,
              date_time: item.upload_date_time,
              fupload_status: item.upload_status,
              total_records: item.total_count,
              record_success: addDownloadIcon(
                item?.success_count,
                item?.batch_id,
                1
              ),
              record_reject: addDownloadIcon(
                item?.rejected_count,
                item?.batch_id,
                0
              ),
              sms_no: sms_Not(item.rejected_count),
            };
            return newItem;
          });
          if (result?.length > 0) {
            setListdata([...result]);
          }
        }
      })
      .catch((er) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const changePage = (value) => {
    listApi(value);
    setPageNumber(value - 1);
  };

  const addDownloadIcon = (rejected_data, batch_id, flag) => {
    return (
      <>
        <div className="d-flex justify-content-between">
          <span className="text-primary">{rejected_data}</span>
          <Button
            onClick={() => {
              flag === 0
                ? downloadRejectedFile(batch_id)
                : downloadSuccessFile(batch_id);
            }}
            role={componentRole?.TRANSPARENT_DOWNLOAD || ""}
          />
        </div>
      </>
    );
  };

  const downloadSuccessFile = (batch_id) => {
    apiCall
      .get(
        `${apiConstants.downloadPicklistReject + batch_id}`
      )
      .then(async (res) => {
        if (res.status === 200) {
        }
      })
      .catch((er) => {})
      .finally(() => {});
  };

  const downloadRejectedFile = (batch_id) => {
    apiCall
      .get(`${apiConstants.downloadPicklistReject + batch_id}`)
      .then(async (res) => {
        if (res.status === 200) {
        }
      })
      .catch((er) => {})
      .finally(() => {});
  };

  useEffect(() => {
    listApi();
  }, []);

  return (
    <div className="container account_list">
      <div className="row mt-5 mb-3">
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <label className=" text-nowrap common-header">Accounts</label>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <InputField className="SearchButton" placeholder={"Search"} />
        </div>

        <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <Dropdown
            options={selectoptions}
            value={selectedMonth}
            placeholder={"Month"}
            defaultValue={{ value: "1 Month", label: "1 Month" }}
            onChange={(e) => setSelectedMonth(e)}
          />
        </div>
        <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <Button value={"Export"} role="export" className="export_btn" />
        </div>
      </div>
      <div className="mandateTableWrapper">
        <CustomTable
          cols={[
            {
              id: "batch_id",
              title: "Batch ID",
              classStyle: {},
            },
            {
              id: "file_name",
              title: "File Name",
              classStyle: {},
            },
            {
              id: "uploder_name",
              title: "Uploader Name",
              classStyle: {},
            },
            {
              id: "date_time",
              title: "Date & Time",
              classStyle: {},
            },
            {
              id: "fupload_status",
              title: "File Upload Status",
              classStyle: {},
            },
            {
              id: "total_records",
              title: "Total Records",
              classStyle: {},
            },
            {
              id: "record_success",
              title: "Record Successful",
              classStyle: {},
            },
            {
              id: "record_reject",
              title: "Record Rejected",

              classStyle: {},
            },
            {
              id: "sms_yes",
              title: "SMS Delivered",
              classStyle: {},
            },
            {
              id: "sms_no",
              title: "SMS Not Delivered",
              classStyle: {},
            },
          ]}
          data={listdata}
          count={50}
          setPagination={(page) => changePage(page)}
          activePage={pageNumber}
          loading={loading}
        />
      </div>
    </div>
  );
};
export default AccountList;
