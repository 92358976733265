import React, { useEffect, useState } from "react";
import Button from "../../common/Button/button";
import FormLabel from "../../common/formLabel/formLabel";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { number, object, string } from "yup";
import FormikSelect from "../../common/formikSelect/formikSelect";
import "./MandateDetails.scss";
import moment from "moment/moment";
import {
  mandateDetails,
  systemDetails,
  customerDetails,
} from "../../../redux/reducer/SingleMandateReducer";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import { useDispatch, useSelector } from "react-redux";
import FormikDatepicker from "../../common/FormikDatepicker/FormikDatepicker";
import apiCall from "../../../utils/apiFunction/apiCall";
import {
  apiConstants,
  enachApiConstants,
  tokenConstants,
  FOMRS,
  errMessage,
  toastIds,
  tableNamePaymentConstants,
  attributePaymentConstants,
  fetchBankDetailsAttributes,
  flags
} from "../../../constants/Common/apiConstants";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { YupMessages } from "../../../constants/Common/commonConstants";
import DropdownWithTooltip from "../../common/DropdownWithTooltip/DropdownWithTooltip";
import debounce from 'lodash/debounce';

function MandateDetails(props) {
  const { state } = useLocation();

  const navigate = useNavigate();

  const formType = state?.type || FOMRS.ADD;
  const formRef = React.useRef(null);

  const fetchDataById = (id) => {
    const params = {
      tablename: "manualMandate",
      whereQuery: { id: id },
      attributes: [
        "account_holder_name",
        "account_number",
        "account_type",
        "mandate_maximum_amount",
        "micr_code",
        "ifsc_code",
        "contribution",
        "mandate_date",
        "mandate_start_date",
        "mandate_end_date",
        "cycle_date",
        "category_code",
      ],
    };
    apiCall
      .post(apiConstants?.findData, params)
      .then((response) => {
        if (response.status === 200) {
          const result = response?.data?.response[0];
          setFormData({
            account_holder_name: result?.account_holder_name,
            account_number: result?.account_number,
            account_type: result?.account_type,
            mandate_maximum_amount: result?.mandate_maximum_amount,
            ifsc_code: result?.ifsc_code,
            contribution: result?.contribution,
            mandate_date: result?.mandate_date,
            mandate_start_date: result?.mandate_start_date,
            mandate_end_date: result?.mandate_end_date,
            category_code: result?.category_code,
            cycle_date: result?.cycle_date,
          });
        }
      })
      .catch((error) => {
        toast.error("Internal Server Error", { toastId: "FetchError" });
      });
  };

  useEffect(() => {
    if (formType === FOMRS.EDIT) {
      fetchDataById(state?.id);
    }
  }, []);

  const dispatch = useDispatch();

  const ReduxValue = useSelector((state) => state.SingleMandate);

  // const {systemDetails : {systemValues : {bank_upi_flag , product_upi_flag}}} = ReduxValue


  const { handleBack } = props;
  const [accountType, setAccountType] = useState([]);
  const [categoryCode, setCategoryCode] = useState([]);
  const [branch, setBranch] = useState("");
  const [bankName, setbankName] = useState("");
  const [city, setCity] = useState("");
  const [micrCode, setmicrCode] = useState("");
  const [downloadFlag, setdownloadFlag] = useState(false);

  const [formData, setFormData] = useState({
    account_holder_name: "",
    account_number: "",
    account_type: "",
    mandate_maximum_amount: "",
    micr_code: "",
    ifsc_code: "",
    bank_name: "",
    branch_code: "",
    city: "",
    contribution: "",
    mandate_date: "",
    mandate_start_date: "",
    mandate_end_date: "",
    contribution_percentage: "",
    cycle_date: "",
    category_code: "",
    // upi_flag : "",
    // upi_emi_amount: "",
  });

  const calculateAge = (endDate) => {
    const currentDate = new Date();
    const maxAllowedDate = new Date();
    maxAllowedDate.setFullYear(currentDate.getFullYear() + 30); 
    const parts = endDate.split("-");
    const inputYear = parseInt(parts[0], 10);
    const inputMonth = parseInt(parts[1], 10);
    const inputDay = parseInt(parts[2], 10);

    var userDate = new Date(inputYear, inputMonth - 1, inputDay); 

    return userDate < maxAllowedDate;
  }

  const onContinue = (params, mandateValues) => {
    setdownloadFlag(true);
    if (mandateValues.mandate_start_date < mandateValues.mandate_date) {
      toast.error(
        "Start Date should be greater than or equal to Current Date",
        { toastId: "saveMandateInfo" }
      );
      return setdownloadFlag(false);
    }

    if (
      mandateValues.mandate_end_date <= mandateValues.mandate_start_date
    ) {
      toast.error("End Date should be greater than Start Date", {
        toastId: "saveMandateInfo",
      });
      return setdownloadFlag(false);
    }
    const endDate = moment(mandateValues?.mandate_end_date,"DD-MM-YYYY").format("YYYY-MM-DD")
    const yearDifference = calculateAge(endDate)
    if(!yearDifference) {
      toast.error("End Date should not be over a period of 30 years", {
        toastId: "saveMandateInfo",
      });
      return setdownloadFlag(false);
    }
    dispatch(mandateDetails({ mandateValues }));
    const {
      systemDetails: { systemValues },
      customerDetails: { customerValues },
    } = params;

    const whereQuery = { contract_no: systemValues.loan_number };
    apiCall
      .post(apiConstants?.findData, {
        tablename: "io_disbursement_tbl",
        whereQuery: whereQuery,
        attributes: ["contract_no"],
      })
      .then((response) => {
        if (response.status === 200) {
          if (response?.data?.status === 500)
            return toast.error(errMessage?.InternalServerError, {
              toastId: toastIds?.InternalServerError,
            });
          if (response?.data?.status === 404) {
            setdownloadFlag(false);
            return toast.error(errMessage?.InvalidLoanNumber, {
              toastId: toastIds?.DataError,
            });
          }
          const statusCheck = {
            loan_no: systemValues?.loan_number,
            mandate_status: "0300",
          };
          apiCall
            .post(apiConstants?.findData, {
              tablename: tableNamePaymentConstants?.ManualMandate,
              whereQuery: statusCheck,
              attributes: [attributePaymentConstants?.mandateStatus],
            })
            .then((response) => {
              if (response.status === 200) {
                if (response?.data?.status === 500)
                  return toast.error(errMessage?.InternalServerError, {
                    toastId: toastIds?.InternalServerError,
                  });
                if (response?.data?.status === 200) {
                  setdownloadFlag(false);
                  return toast.info("Mandate Already Registered", {
                    toastId: "saveMandateAPIInfo",
                  });
                }
                const body = {
                  mandate_type: systemValues?.manual_mandate,
                  source_system: systemValues?.source_system,
                  bank_code: systemValues.bank_code,
                  company_code: systemValues?.company_code,
                  product_type: systemValues?.product_type,
                  system_code: systemValues?.loan_booking_system,
                  loan_no: systemValues?.loan_number,
                  web_top: systemValues?.web_top,
                  los_id: systemValues?.los_id,
                  frequency_code: systemValues?.frequency,
                  utility_code:
                    systemValues?.company_code === "7000" ? "TCL" : "TCHFL",
                  customer_name: customerValues?.customer_name,
                  mobile_number: customerValues?.mobile_number,
                  std_code : customerValues?.std_code,
                  boardline_no: 0,
                  personal_email: customerValues?.personal_email,
                  office_email: customerValues?.office_email,
                  account_holder_name: mandateValues?.account_holder_name,
                  account_number: mandateValues?.account_number,
                  account_type: mandateValues?.account_type,
                  mandate_maximum_amount: mandateValues?.mandate_maximum_amount,
                  micr_code: mandateValues?.micr_code,
                  ifsc_code: mandateValues?.ifsc_code,
                  bank_name: mandateValues?.bank_name,
                  branch_code: mandateValues?.branch_code,
                  city: mandateValues?.city,
                  mandate_date: moment(
                    mandateValues?.mandate_date,
                    "DD-MM-YYYY"
                  ).format("YYYY-MM-DD"),
                  contribution: mandateValues?.contribution,
                  mandate_start_date: moment(
                    mandateValues?.mandate_start_date,
                    "DD-MM-YYYY"
                  ).format("YYYY-MM-DD"),
                  mandate_end_date: moment(
                    mandateValues?.mandate_end_date,
                    "DD-MM-YYYY"
                  ).format("YYYY-MM-DD"),
                  cycle_date: mandateValues?.cycle_date,
                  category_code: mandateValues?.category_code,
                  remark: systemValues?.purpose,
                  emandate_send_sms: true,
                  source_system_unique_number: systemValues?.loan_number,
                  enach_type: 1,
                  // upi_flag : mandateValues?.upi_flag,
                  // upi_emi_amount: mandateValues?.upi_emi_amount,
                };

                apiCall
                  .post(
                    enachApiConstants?.tokenGenerate,
                    tokenConstants.enach_token_data
                  )
                  .then((response) => {
                    if (response.status === 200) {
                      const headers = {
                        user_id: tokenConstants.enach_token_data.user_id,
                        enach_token: response?.data?.response,
                      };
                      apiCall
                        .get(
                          `${enachApiConstants?.fetchMaxBatchId}?remark=Not banked`,
                          { headers }
                        )
                        .then((response) => {
                          if (response?.status === 200) {
                            body.batch_id = response?.data?.max_batch_id;
                            apiCall
                              .post(
                                enachApiConstants?.mandateRegistration,
                                body,
                                { headers: headers }
                              )
                              .then((response) => {
                                if (response.status === 200) {
                                  if (response?.data?.status === 200) {
                                    toast.success(response?.data?.message);
                                    return navigate("/enach/viewemandate");
                                  }
                                  if (response?.data?.status === 500)
                                    return toast.error(
                                      response?.data?.message
                                    );
                                  if (response?.data?.status === 404)
                                    return toast.error(
                                      response?.data?.message
                                    );

                                  dispatch(systemDetails({}));
                                  dispatch(mandateDetails({}));
                                  dispatch(customerDetails({}));

                                  return toast.info(response?.data?.response);
                                }
                              })
                              .catch((error) => {
                                toast.error(
                                  errMessage?.InternalServerError,
                                  toastIds?.InternalServerError
                                );
                              })
                              .finally(() => {
                                setdownloadFlag(false);
                              });

                          }
                        });
                    }
                  })
                  .catch((error) => {
                    console.log("Error::", error);
                  });
              }
            })
            .catch((error) => {
              console.log("SaveMandateAPI error", error);
            });
        }
      })
      .catch((error) => {
        setdownloadFlag(false);
        toast.error(
          errMessage?.InternalServerError,
          toastIds?.InternalServerError
        );
      })
      .finally(() => {
        // setdownloadFlag(false)
      });
  };

  const validSchema = object({
    account_holder_name: string()
      .test(
        YupMessages?.isValid,
        YupMessages?.InvalidAccountHolderName,
        (value) => /^[A-Za-z\s]*[A-Za-z][A-Za-z\s]*$/.test(value)
      )

      .required(YupMessages?.AccountHolderRequired)

      .matches(/^(?!.*<script>).*$/, YupMessages?.InvalidAccountHolderName),
    account_number: string()
      .required(YupMessages?.AccountNumberRequired)
      .test(
        YupMessages?.isNumber,
        YupMessages?.InvalidAccountNumber,
        (value) => !isNaN(value)
      )
      .test(YupMessages?.isNumber, YupMessages?.InvalidAccountNumber, (value) =>
        /^\d+$/.test(value)
      )
      .test(
        YupMessages?.ShouldNotAcceptZero,
        YupMessages?.InvalidAccountNumber,
        (value) => value !== "0"
      )
      .matches(/^(?!.*<script>).*$/, YupMessages?.InvalidAccountNumber),
    account_type: string()
      .required(YupMessages?.AccountTypeRequired)
      .matches(/^(?!.*<script>).*$/, YupMessages?.AccountTypeRequired),
    mandate_maximum_amount: string()
      .required(YupMessages?.MandateMaximumAmountRequired)
      .test(
        YupMessages?.isNumber,
        YupMessages?.PleaseEnterAmountInDigits,
        (value) => !isNaN(value)
      )
      .test(
        YupMessages?.isNumber,
        YupMessages?.PleaseEnterAmountInDigits,
        (value) => /^\d+$/.test(value)
      )
      .test(
        YupMessages?.ShouldNotAcceptZero,
        YupMessages?.InvalidAmount,
        (value) => value !== "0"
      )
      .matches(/^(?!.*<script>).*$/, YupMessages?.InvalidAmount),

    ifsc_code: string()
      .test(YupMessages?.isValid, YupMessages?.InvalidIFSCCode, (value) => {
        const trimmedValue = value.trim();
        return trimmedValue !== "";
      })
      .matches(/^(?!.*<script>).*$/, YupMessages?.InvalidIFSCCode)

      .required(YupMessages?.IFSCCodeIsRequired),
    contribution: string()
      .required(YupMessages?.ContributionRequired)
      .test(
        YupMessages?.isNumber,
        YupMessages?.ContributionInDigits,
        (value) => !isNaN(value)
      )
      .test(YupMessages?.isNumber, YupMessages?.ContributionInDigits, (value) =>
        /^(0*(100(\.0*)?|\d{0,2}(\.\d*)?)%?)$/.test(value)
      )
      .test(
        YupMessages?.ShouldNotAcceptZero,
        YupMessages?.ContributionInDigits,
        (value) => value !== "0"
      )
      .matches(/^(?!.*<script>).*$/, YupMessages?.ContributionInDigits),

    mandate_date: string().required(YupMessages?.MandateDateRequired),
    mandate_start_date: string().required(YupMessages?.MandateStartDateRequired),
    mandate_end_date: string().required(YupMessages?.MandateEndDateRequired),
    cycle_date: string()
      .required(YupMessages?.CycleDateRequired)
      .test(
        YupMessages?.isNumber,
        YupMessages?.EnterCycleDateInDigits,
        (value) => !isNaN(value)
      )
      .test(
        YupMessages?.isValid,
        YupMessages?.EnterCycleDateInDigits,
        (value) => /^\d+$/.test(value)
      )
      .test(
        YupMessages?.ShouldNotAcceptZero,
        YupMessages?.InvalidCycleDateValue,
        (value) => value !== "0"
      )
      .matches(/^(?!.*<script>).*$/, YupMessages?.InvalidCycleDateValue),
    category_code: string()
      .required(YupMessages?.CategoryCodeRequired)
      .matches(/^(?!.*<script>).*$/, YupMessages?.InvalidCategoryCode),
  });

  const fetchBankDetails = (ifsc_code) => {
    const whereQuery = { ifsc_code: ifsc_code };
    apiCall
      .post(apiConstants?.findData, {
        tablename: tableNamePaymentConstants?.Micr,
        whereQuery: whereQuery,
        attributes: fetchBankDetailsAttributes,
      })
      .then((result) => {
        if (result.status === 200) {
          if (result?.data?.status === 500)
            return toast.error(errMessage?.InternalServerError, {
              toastId: toastIds?.InternalServerError,
            });
          if (result?.data?.status !== 404) {
            result = result?.data?.response[0];
            setBranch(result?.branch_code);
            setbankName(result?.bank_name);
            setCity(result?.city);
            setmicrCode(result?.micr_code);
          }
        }
      })
      .catch((error) => {
        toast.error(errMessage?.InternalServerError, {
          toastId: toastIds?.InternalServerError,
        });
      });
  };

  const accountTypeApi = () => {
    apiCall
      .post(apiConstants?.findData, {
        tablename: tableNamePaymentConstants?.AccountType,
      })
      .then(async (response) => {
        if (response.status === 200) {
          let data = response?.data?.response.map((item) => {
            return {
              key: item?.account_code,
              value: item?.account_code,
              tooltipTitle: item?.account_name
            };
          });
          setAccountType([...data]);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => { });
  };
  const categoryCodeApi = () => {
    apiCall
      .post(apiConstants?.findData, {
        tablename: tableNamePaymentConstants?.Category,
      })
      .then(async (response) => {
        if (response.status === 200) {
          let data = response?.data?.response.map((item) => {
            return {
              key: item.category_code,
              value: item?.category_code,
              tooltipTitle: item?.category_name
            };
          });
          setCategoryCode([...data]);
        }
      })
      .catch((err) => {
        toast.error(errMessage?.InternalServerError, {
          toastId: toastIds?.InternalServerError,
        });
      })
      .finally(() => { });
  };

  const debouncedFetchBankDetails = debounce(fetchBankDetails, 1000);


  const onBack = (mandateValues) => {
    dispatch(mandateDetails({ mandateValues }));
    return handleBack();
  };

  const MandateFormDetails = useSelector((state) => {
    return state?.SingleMandate?.mandateDetails;
  });
  useEffect(() => {
    setFormData(MandateFormDetails?.mandateValues);
  }, [MandateFormDetails]);
  useEffect(() => { }, [formData]);

  useEffect(() => {
    accountTypeApi();
    categoryCodeApi();
  }, []);

  const handleChildComponentDataAccountType = (updatedValue) => {
    const { setFieldValue } = formRef.current;
    setFieldValue("account_type", updatedValue);
  };

  const handleChildComponentDataCategoryCode = (updatedValue) => {
    const { setFieldValue } = formRef.current;
    setFieldValue("category_code", updatedValue);
  };

  const handleIfscCode = (updatedValue) => {
    const {setFieldValue} = formRef.current;
    setFieldValue("ifsc_code", updatedValue);
    debouncedFetchBankDetails(updatedValue)

  }



  return (
    <>
      {downloadFlag ? (
        <div className="className='row justify-content-center d-flex loaderDiv">
          <SystemXLoader />
        </div>
      ) : (
        <Formik
          innerRef={formRef}
          initialValues={{
            account_holder_name: formData?.account_holder_name
              ? formData?.account_holder_name
              : "",
            account_type: formData?.account_type ? formData?.account_type : "",
            account_number: formData?.account_number
              ? formData?.account_number
              : "",
            mandate_maximum_amount: formData?.mandate_maximum_amount
              ? formData?.mandate_maximum_amount
              : "",
            micr_code: formData?.micr_code ? formData?.micr_code : "",
            ifsc_code: formData?.ifsc_code ? formData?.ifsc_code : "",
            bank_name: formData?.bank_name ? formData?.bank_name : "",
            branch_code: formData?.branch ? formData?.branch : "",
            city: formData?.city ? formData?.city : "",
            contribution: formData?.contribution ? formData?.contribution : "",
            contribution_percentage: formData?.contribution_percentage
              ? formData?.contribution_percentage
              : "",
            mandate_date: formData?.mandate_date ? formData?.mandate_date : "",
            mandate_start_date: formData?.mandate_start_date
              ? formData?.mandate_start_date
              : "",
            mandate_end_date: formData?.mandate_end_date
              ? formData?.mandate_end_date
              : "",
            cycle_date: formData?.cycle_date ? formData?.cycle_date : "",
            category_code: formData?.category_code
              ? formData?.category_code
              : "",
            // upi_flag : formData?.upi_flag ? formData?.upi_flag : "",
            // upi_emi_amount: formData?.upi_emi_amount ? formData?.upi_emi_amount : ""
          }}
          enableReinitialize={true}
          validationSchema={validSchema}
          onSubmit={(values) => onContinue(ReduxValue, values)}
        >
          {(props) => {
            const { values, handleBlur } = props;
            return (
              <Form>
                <div className="row mandate_details">
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="field_name">
                      <FormLabel
                        value="Account Holder Name"
                        className="required"
                      />
                    </div>
                    <Field
                      type="text"
                      className="InputField"
                      name="account_holder_name"
                      id="account_holder_name"
                      disabled={state?.type === FOMRS.EDIT ? true : false}
                      placeholder="Account holder name"
                    ></Field>
                    <div className="errorMessage">
                      <ErrorMessage name="account_holder_name" />
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="field_name">
                      <FormLabel
                        value="Account Number As Per Mandate"
                        className="required"
                      />
                    </div>
                    <Field
                      type="text"
                      className="InputField"
                      name="account_number"
                      id="account_number"
                      disabled={state?.type === FOMRS.EDIT ? true : false}
                      placeholder="Account Number As Per Mandate"
                    ></Field>
                    <div className="errorMessage">
                      <ErrorMessage name="account_number" />
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2">
                    <div className="field_name">
                      <FormLabel value="Account Type" className="required" />
                    </div>

                    <DropdownWithTooltip
                      options={accountType}
                      label="select Account Type"
                      onSelectCallback={(updatedValue) => handleChildComponentDataAccountType(updatedValue)}
                      disabled={state?.type === FOMRS.EDIT ? true : false}
                      defaultValue={formData?.account_type}
                    />

                    <div className="errorMessage">
                      <ErrorMessage name="account_type" />
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2">
                    <div className="field_name">
                      <FormLabel
                        value="Mandate Maximum Amount"
                        className="required"
                      />
                    </div>
                    <Field
                      type="text"
                      className="InputField"
                      name="mandate_maximum_amount"
                      id="mandate_maximum_amount"
                      disabled={state?.type === FOMRS.EDIT ? true : false}
                      placeholder="Mandate Maximum Amount"
                    ></Field>
                    <div className="errorMessage">
                      <ErrorMessage name="mandate_maximum_amount" />
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2">
                    <div className="field_name">
                      <FormLabel value="MICR Code" />
                    </div>
                    <Field
                      type="text"
                      className="InputField"
                      name="micr_code"
                      value={micrCode}
                      disabled={true}
                      id="micr_code"
                      placeholder="MICR Code"
                    ></Field>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2">
                    <div className="field_name">
                      <FormLabel value="IFSC Code"/>
                    </div>
                    <Field
                      type="text"
                      className="InputField"
                      name="ifsc_code"
                      id="ifsc_code"
                      placeholder="IFSC Code"
                      disabled={state?.type === FOMRS.EDIT ? true : false}
                      onChange={(event) => handleIfscCode(event?.target?.value)}
                      defaultValue={formData?.ifsc_code}
                    ></Field>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2">
                    <div className="field_name">
                      <FormLabel value="Bank Name" />
                    </div>
                    <Field
                      type="text"
                      className="InputField"
                      name="bank_name"
                      id="bank_name"
                      placeholder="Bank Name"
                      value={bankName}
                      disabled={true}
                    ></Field>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2">
                    <div className="field_name">
                      <FormLabel value="Branch Code" />
                    </div>
                    <Field
                      type="text"
                      className="InputField"
                      name="branch_code"
                      value={branch}
                      disabled={true}
                      id="branch_code"
                      placeholder="Branch Code"
                    ></Field>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2">
                    <div className="field_name">
                      <FormLabel value="City" />
                    </div>
                    <Field
                      type="text"
                      className="InputField"
                      name="city"
                      value={city}
                      disabled={true}
                      id="city"
                      placeholder="City"
                    ></Field>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2">
                    <div className="field_name">
                      <FormLabel value="Contribution" className="required" />
                    </div>
                    <Field
                      type="text"
                      className="InputField"
                      name="contribution"
                      id="contribution"
                      disabled={state?.type === FOMRS.EDIT ? true : false}
                      placeholder="Contribution"
                    ></Field>
                    <div className="errorMessage">
                      <ErrorMessage name="contribution" />
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2">
                    <div className="field_name">
                      <FormLabel value="Mandate Date" className="required" />
                    </div>
                    <FormikDatepicker
                      name="mandate_date"
                      disabled={state?.type === FOMRS.EDIT ? true : false}
                      placeholder={"Please Select Date"}
                    />
                    <div className="errorMessage">
                      <ErrorMessage name="mandate_date" />
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2">
                    <div className="field_name">
                      <FormLabel value="Contribution(In percentage)" />
                    </div>
                    <Field
                      type="text"
                      className="InputField"
                      name="contribution_percentage"
                      id="contribution_percentage"
                      disabled={state?.type === FOMRS.EDIT ? true : false}
                      placeholder="Contribution"
                    ></Field>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2">
                    <div className="field_name">
                      <FormLabel
                        value="Mandate Start Date"
                        className="required"
                      />
                    </div>
                    <FormikDatepicker
                      name="mandate_start_date"
                      disabled={state?.type === FOMRS.EDIT ? true : false}
                      placeholder={"Please Select Date"}
                    />
                    <div className="errorMessage">
                      <ErrorMessage name="mandate_start_date" />
                    </div>
                  </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2">
                      <div className="field_name">
                        <FormLabel value="Mandate End Date" className="required"  />
                      </div>
                      <FormikDatepicker
                        name="mandate_end_date"
                        disabled={state?.type === FOMRS.EDIT ? true : false}
                        placeholder={"Please Select Date"}
                      />
                       <div className="errorMessage">
                      <ErrorMessage name="mandate_end_date" />
                    </div>
                    </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2">
                    <div className="field_name">
                      <FormLabel value="Cycle Date" className="required" />
                    </div>
                    <Field
                      type="text"
                      className="InputField"
                      name="cycle_date"
                      id="cycle_date"
                      disabled={state?.type === FOMRS.EDIT ? true : false}
                      placeholder="Cycle Date"
                    ></Field>
                    <div className="errorMessage">
                      <ErrorMessage name="cycle_date" />
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2">
                    <div className="field_name">
                      <FormLabel value="Category Code" className="required" />
                    </div>
                  
                    <DropdownWithTooltip
                      options={categoryCode}
                      label="select Category Code"
                      onSelectCallback={(updatedValue) => handleChildComponentDataCategoryCode(updatedValue)}
                      disabled={state?.type === FOMRS.EDIT ? true : false}
                      defaultValue={formData?.category_code}
                    />

                    <div className="errorMessage">
                      <ErrorMessage name="category_code" />
                    </div>
                  </div>
                  {/* {
                   (bank_upi_flag && product_upi_flag) ?
                   <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2">
                   <div className="field_name">
                       <FormLabel value="UPI Flag"  />
                     </div>
                     <FormikSelect
                     label="Please select a value"
                     name="upi_flag"
                     options={flags}
                     disabled={state?.type === FOMRS.EDIT ? true : false}
                   />
                   </div>
                   <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2">
                  <div className="field_name">
                      <FormLabel value="UPI EMI Amount"  />
                    </div>
                    <Field
                      type="text"
                      className="InputField"
                      name="upi_emi_amount"
                      id="upi_emi_amount"
                      disabled={state?.type === FOMRS.EDIT ? true : false}
                      placeholder="EMI amount"
                    ></Field>
                  </div> 
                   </div> : ''
                  } */}
                  <div className="d-flex flex-wrap gap-3">
                    <div>
                      <button
                        className="mandate_details-backBtn"
                        type="submit"
                        onClick={() => onBack(values)}
                      >
                        Back
                      </button>
                    </div>
                    {formType === FOMRS.ADD && (
                      <div>
                        <button
                          className="mandate_details-submitBtn"
                          type="submit"
                          onClick={() => onContinue}
                        >
                          Submit
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
}
export default MandateDetails;
