import React, { useEffect, useState } from "react";
import "./scansftpmergelog.scss";
import CustomTable from '../../../../component/customtable/CustomTable';
import Button from "../../../../component/common/Button/button";
import apiCall from "../../../../utils/apiFunction/apiCall";
import {useNavigate } from "react-router-dom";
import {
  commonVariables,
  componentRole,
  tableID,
  selectoptions,
  route
} from '../../../../constants/Common/commonConstants';
import { useDispatch } from "react-redux";
import SystemXLoader from '../../../../component/common/Loader/SystemXLoader'
import { apiConstants, errMessage, toastIds  , tableNamePaymentConstants} from '../../../../constants/Common/apiConstants';
import { toast } from "react-toastify";


const ScanSftpMergeLog = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [count, setCount] = useState(0);
  const [listdata, setListdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [downloadFlag ,setDownloadFlag] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const listApi = (value) => {
    let temp = 0
    if((value || pageNumber) > 0){
      temp = value ? (value - 1) * 10 : (pageNumber - 1) * 10;
    }
    setDownloadFlag(true);
    setLoading(true);
    const params = { 
      "tablename" : tableNamePaymentConstants?.scanSftpMergeLog,
      "month" : 0,
      "offset" : temp,
      "order":[['id','desc']],
      "limit" : 10,
      "dataFetch": [
          {
              "column": "scan_reg_cron_id",
              "operator": "ne",
              "value": "null"
          }
        ],   
        } 
    apiCall.post(apiConstants?.fetchAllData, params)
      .then(async (res) => {
        if (res.status === 200) {
          let result = res?.data?.response;
          setCount(res?.data?.count || 0)
          result = result.map((item,i) => {
            let newItem = {
              no: temp + i + 1,
              batch_id: item?.scan_reg_cron_id ? item?.scan_reg_cron_id : '-',
              mandate: item?.mandate_type ? item?.mandate_type : '-',
              bank: item?.bank_code ? item?.bank_code : '-',
              file_name: item?.file_name ? item?.file_name : '-',
              companyCode: item?.company_code ? item?.company_code : '-',
              total_count: item?.total_records ? item?.total_records : '-',
              upload_date_time: item?.date_time ? item?.date_time : '-',
              sftp_push_flag: item?.sftp_push_flag ? (item?.sftp_push_flag === 1 ? "Success":"Fail") : '-',
              view_record: item?.scan_reg_cron_id ?(addLink(item?.scan_reg_cron_id,item?.bank_code,item?.company_code,item?.mandate_type)): '-',
              download:item?.file_name ? (downloadButton(item?.file_name)) : '-',
            };
            return newItem;
          });
          if (result?.length > 0) {
            setListdata([...result]);
          }
        }
      })
      .catch((er) => {
        console.log("err", er);
      })
      .finally(() => {
        setDownloadFlag(false);
        setLoading(false);
      });
  };

  const addLink=(batch_id,bank_code,company_code,mandate_type)=>{
    return (
      <>
        <Button
          role={componentRole?.TRANSPARENT || ""}
          value={'Verify Data'}
          onClick={() => {
            navigateToNextPage(batch_id,bank_code,company_code,mandate_type);
          }}
        />
      </>
    );
}

const downloadButton=(batch_id,bank_code,company_code,mandate_type)=>{
  return (
    <>
      <Button
        role={componentRole?.TRANSPARENT || ""}
        value={'Download'}
        onClick={() => {
          downloadFile(batch_id,bank_code,company_code,mandate_type);
        }}
      />
    </>
  );
}


const downloadFile = (file_name)=>{
    setDownloadFlag(true);
    apiCall
      .get(
        apiConstants?.scanBaseDownloadFile+file_name
      )
      .then(async (res) => {
        if (res?.data?.status === 200) {
          const presignUrl = res.data.response;
          if (res?.data?.response) {
            const link = document.createElement('a');
            link.href = presignUrl;
            link.click();
            toast.success(commonVariables?.FileDownloadSuccess, { toastId: toastIds?.FileDownloadSucess })
     
          }
        }
        else{
          toast.error((res?.data?.message || errMessage?.somethingWentWrong), {
            toastId: toastIds?.SomethingWentWrong,
          });
        }
      })
      .catch((er) => {
        console.log("err", er);
        toast.error((errMessage?.somethingWentWrong), {
          toastId: toastIds?.SomethingWentWrong,
        });
      })
      .finally(() => {
        setDownloadFlag(false);
      });
}
  const navigateToNextPage = (batch_id,bank_code,company_code,mandate_type)=>{
    navigate(route?.SCAN_SFTP_MERGE_VIEW || "", {
      state: {
        batch_id:batch_id,
        bank_code:bank_code,
        mandate_type:mandate_type,
        company_code:company_code
      },
    });
  }
  const changePage = (value) => {
    listApi(value);
    setPageNumber(value - 1);
  };

  useEffect(() => {
    listApi();
  }, []);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mt-5 mb-4 mx-3">
        <div className="right-div-header">
          <div>
            <label className="labelPresentation">
              {commonVariables?.SFTP_MERGE_LOG || ""}
            </label>
          </div>
        </div>
       </div>
      <div className="col dtMergeTable">
      {downloadFlag && (
        <div className="loaderDiv">
          <SystemXLoader />
        </div>
      )}
        <CustomTable
          cols={[
            {
              id: tableID?.NO || "",
              title: commonVariables?.NO || "",
            },
            {
              id: tableID?.BATCH_ID || "",
              title: commonVariables?.BATCH_ID || "",
            },
            {
              id: tableID?.BANK || "",
              title: commonVariables?.BANK || "",
            },
            {
              id: tableID?.MANDATE || "",
              title: commonVariables?.MANDATE || "",
            },
            {
              id: tableID?.TOTAL_COUNT || "",
              title: commonVariables?.TOTAL_RECORDS || "",
            },
            {
              id: tableID?.FILE_NAME || "",
              title: commonVariables?.FILE_NAME || "",
            },
            {
              id: tableID?.UPLOAD_DATE_TIME || "",
              title: commonVariables?.DATE_TIME || "",
            },
          ]}
          data={listdata}
          count={count}
          setPagination={(page) => changePage(page)}
          activePage={pageNumber}
          loading={loading}
        />
      </div>
    {/* </div> */}
    </>
  );
};

export default ScanSftpMergeLog;
