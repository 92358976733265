import * as React from "react"
import {Provider} from "react-redux"
import {PersistGate} from "redux-persist/integration/react"
import store from "./redux/store"
import { persistStore } from 'redux-persist';
import {HelmetProvider} from "react-helmet-async"

import "react-toastify/dist/ReactToastify.css";
import "./App.scss"
// import { createRoot } from "react-dom/client";
import {BrowserRouter} from "react-router-dom"
import Paths from "./router"
import Layout from "./layout"
import { toast } from "react-toastify";

let persistor = persistStore(store);

export default function App() {
  return (
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<React.Suspense>
					<BrowserRouter>
						<Layout>
							<Paths />
						</Layout>
					</BrowserRouter>
				</React.Suspense>
			</PersistGate>
		</Provider>
	);

  // return <Dropdown />
}
