import CustomTable from '../../../../customtable/CustomTable';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Button from '../../../../common/Button/button';
import React, { useState } from 'react';
import '../sponsorPaymentBank/sponsorPaymentBank.scss';
import { ROUTE_PATH } from '../../../../../constants/Routes';
import apiCall from '../../../../../utils/apiFunction/apiCall';
import {
	apiConstants,
	FOMRS, successMsg, tableNamePaymentConstants
} from '../../../../../constants/Common/apiConstants';
import { commonVariables, tableID, } from '../../../../../constants/Common/commonConstants';
import SearchBar from '../../../../common/Searchbar/searchbar';
import Pencil from "../../../../../Images/assests/images/pencil.svg"
import Trash from "../../../../../Images/assests/images/trash-bin.svg"
import { toast } from 'react-toastify';

function SponsorPaymentBank() {
	const navigate = useNavigate();
	const [pageNumber, setPageNumber] = useState(0);
	const [listdata, setListdata] = useState([]);
	const [loading, setLoading] = useState(false);
	const [datacount, setDatacount] = useState(0)
	const [searchValue, setSearchValue] = useState('');
	const [isSearch, setIsSearch] = useState(false);




	const searchValueChange = (value) => {
		setSearchValue(value);
	}

	const deleteFunc = (item) => {
		const query = {
			tablename: tableNamePaymentConstants?.sponsorBankPaymentTypeMaster,
			whereQuery: {
				id: item?.id,
				bank_code: item?.bank_code,
				bank_name: item?.bank_name,
			}
		}
		apiCall.post(apiConstants?.deleteRecord, query).then((res) => {
			if (res?.data?.status === 200) {
				toast.success(successMsg.Deleted)
				navigate(ROUTE_PATH?.SPONSOR_PAYMENT_BANK)
			} else {
				toast.error(res?.data?.message)
			}
		})
	}

	const listApi = (value) => {
		let temp = 0

		if ((value || pageNumber) > 0) {
			temp = value ? (value - 1) * 10 : (pageNumber - 1) * 10;
		}
		setLoading(true);
		apiCall
			.post(apiConstants?.fetchAllData, {
				offset: temp,
				limit: 10,
				tablename: tableNamePaymentConstants?.sponsorBankPaymentTypeMaster,
				month: 0
			})
			.then(async (res) => {
				if (res?.status === 200) {
					let result = res?.data?.response;
					setDatacount(res?.data?.count || 0)
					result = result.map((item, index) => {
						let newItem = {
							no: temp + index + 1,
							client_code: item?.bank_code ? item?.bank_code : '-',
							bank_name: item?.bank_name ? item?.bank_name : '-',
							bank_gl_code: item?.bank_GL_code ? item?.bank_GL_code : '-',
							account_no: item?.bank_account_number ? item?.bank_account_number : '-',
							payment_max_cap: item?.payment_max_cap ? item?.payment_max_cap : '-',
							payment_type_mode: item?.payment_type ? item?.payment_type : '-',
							action: (
								<div>
									<img
										className="icon"
										src={Pencil}
										alt=""
										onClick={() => {
											navigate(ROUTE_PATH.CREATE_NEW_SPONSOR_PAYMENT_BANK, {
												state: {
													type: FOMRS.EDIT,
													user_type: item,
												},
											});
										}}
									/>
									<img
										className="delete_icon"
										src={Trash}
										onClick={() => deleteFunc(item)}
										alt=""
									/>
								</div>
							),
						};
						return newItem;
					});
					if (result?.length > 0) {
						setListdata([...result]);
					}
				}
			})
			.catch((er) => {
				console.log('err', er);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleSearch = (value) => {
		let temp = 0

		if ((value || pageNumber) > 0) {
			temp = value ? (value - 1) * 10 : (pageNumber - 1) * 10;
		}
		setIsSearch(true);
		setLoading(true);
		const params = {
			tableName: tableNamePaymentConstants?.sponsorBankPaymentTypeMaster,
			columns: ['bank_code', 'bank_name', 'bank_account_number'],
			offset: temp,
			order: [['id', 'DESC']]
		};

		apiCall
			.post(apiConstants?.commonSearch + searchValue, params)
			.then((res) => {
				if (res.status === 200) {
					let result = res?.data?.response.rows;
					setDatacount(res.data.count || 0)
					result = result.map((item, index) => {
						let newItem = {
							no: temp + index + 1,
							client_code: item?.bank_code ? item?.bank_code : '-',
							bank_name: item?.bank_name ? item?.bank_name : '-',
							bank_gl_code: item?.bank_GL_code ? item?.bank_GL_code : '-',
							account_no: item?.bank_account_number ? item?.bank_account_number : '-',
							payment_max_cap: item?.payment_max_cap ? item?.payment_max_cap : '-',
							payment_type_mode: item?.payment_type ? item?.payment_type : '-',
							action: (
								<div>
									<img
										className="icon"
										src={Pencil}
										alt=""
										onClick={() => {
											navigate(ROUTE_PATH.CREATE_NEW_SPONSOR_PAYMENT_BANK, {
												state: {
													type: FOMRS.EDIT,
													user_type: item,
												},
											});
										}}
									/>
									<img
										className="delete_icon"
										src={Trash}
										onClick={() => deleteFunc(item)}
										alt=""
									/>
								</div>
							),
						};
						return newItem;
					});
					if (result?.length > 0) {
						setListdata([...result]);
					} else {
						setListdata([...result]);
					}
				}

			}).catch((err) => {
				console.log("some error", err);
			}).finally(() => {
				setLoading(false);
			});
	}

	const changePage = (value) => {
		isSearch ? handleSearch(value) : listApi(value);
		setPageNumber(value - 1);
	};

	useEffect(() => {
		isSearch ? handleSearch() : listApi();
	}, []);
	useEffect(() => {
		if (searchValue === '') {
			setIsSearch(false);
			listApi();
		}
	}, [searchValue]);
	return (
		<>
			<div id="sponsorPaymentBank">
				<div className="alignHeader">
					<div className="larger_f_bold blueFont setMargin">
						Sponsor Payment Bank Master
					</div>
					<div className="setMargin d-flex">
						<div className='mx-3'>
							<SearchBar value={searchValue} onChange={searchValueChange} onSearchClick={handleSearch} />
						</div>
						{' '}
						<Button
							value={'Create New Sponsor Payment Bank'}
							role="primary"
							onClick={() => {
								navigate(ROUTE_PATH?.CREATE_NEW_SPONSOR_PAYMENT_BANK, {
									state: {
										type: FOMRS.ADD,
									},
								});
							}}
						/>
					</div>
				</div>

				<div className="col dtMergeTable">
					<CustomTable
						cols={[
							{
								id: tableID?.NO || "",
								title: commonVariables?.NO || "",
							},
							{
								id: tableID?.CLIENT_CODE || "",
								title: commonVariables?.CLIENT_CODE || "",
							},

							{
								id: tableID?.BANK_NAME || "",
								title: commonVariables?.BANK_NAME || "",
							},

							{
								id: tableID?.BANK_GL_CODE || "",
								title: commonVariables?.BANK_GL_CODE || "",
							},
							{
								id: tableID?.ACCOUNT_NUMBER || "",
								title: commonVariables?.ACCOUNT_NUMBER || "",
							},
							{
								id: tableID?.PAYMENT_MAX_CAP || "",
								title: commonVariables?.PAYMENT_MAX_CAP || "",
							},

							{
								id: tableID?.PAYMENT_TYPE_MODE || "",
								title: commonVariables?.PAYMENT_TYPE_MODE || "",
							},

							{
								id: tableID?.ACTION || "",
								title: commonVariables?.ACTION || "",
							},
						]}
						data={listdata}
						count={datacount}
						setPagination={(page) => changePage(page)}
						activePage={pageNumber}
						loading={loading}
					/>
				</div>
			</div>
		</>
	);
}

export default SponsorPaymentBank;
