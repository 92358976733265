import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { YupMessages, commonVariables, componentRole } from '../../constants/Common/commonConstants';
import Button from '../common/Button/button';
import FormLabel from '../common/formLabel/formLabel';
import './assignRole.scss';
// import Form from 'react-bootstrap/Form';
import FormikSelect from '../common/formikSelect/formikSelect';

import { useDispatch } from 'react-redux';
import { apiConstants, toastIds, userMasterAPIMessage } from '../../constants/Common/apiConstants';
import apiCall from '../../utils/apiFunction/apiCall';
import { showBar } from '../../redux/reducer/sideBarStatus';
import { ROUTE_PATH } from '../../constants/Routes';
export default function AssignRole(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [assignedRole, setAssignedRole] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [roleError, setRoleError] = useState(false);

  const validationSchema = Yup.object({
    user: Yup.string(),
    // assign_role: Yup.string().required(YupMessages?.AssignRoleRequired),
    select_role: Yup.string().required(YupMessages?.SelectRoleRequired),
  });

  useEffect(() => {
    getUserRoles();
  }, []);

  const getUserRoles = () => {
    let temp = 0;
    Promise.all([
      apiCall.post(apiConstants?.fetchAllData, {
        // request body
        offset: temp || 0,
        limit: 10,
        tablename: 'userRole',
        whereQuery: { user_code: props?.selectedUser?.user_code },
        order: [['id', 'DESC']],
        month: 0
      }),
      apiCall.post(apiConstants?.fetchAllData, {
        // request body
        tablename: 'role',
        month: 0,
      }),
    ])
      .then((response) => {
        const [userRoleResponse, roleResponse] = response;

        if (userRoleResponse?.data?.status === 200) {
          console.log(userRoleResponse?.data?.response.length);
          setAssignedRole(userRoleResponse?.data?.response);
          if (userRoleResponse?.data?.response.length > 0) { setRoleError(true) }
        }

        if (roleResponse?.data?.status === 200) {
          const userList = roleResponse?.data?.response.map((role) => ({ key: role.user_role, value: role.user_role, ...role }));
          setRoleList([
            {
              key: 'Please Select Role',
              value: '',
            },
            ...userList,
          ]);
        }
      })
      .catch((error) => {
        console.log('error', error.message);
      });
  };
  const handleSubmit = (value) => {
    // handle form submission logic here
    if (!roleError) {
      apiCall
        .post(apiConstants?.createUser, {
          // request body
          tablename: 'userRole',
          body: { user_role: value.select_role, user_code: value?.user, isActive: 1, case_approve_flag: 0 },
        })
        .then((response) => {
          if (response.data.status === 200) {
            props.onClose();
            navigate(ROUTE_PATH.USER)
            toast.success(userMasterAPIMessage?.assignedRoleSuccess, {
              toastId: toastIds?.assignedRoleSuccess,
            });
          }
        })
        .catch((error) => {
          toast.success(userMasterAPIMessage?.assignedRoleSuccess, {
            toastId: toastIds?.assignedRoleSuccess,
          });
        });
    } else {
      apiCall
        .post(apiConstants?.updateUser, {
          // request body
          tablename: 'userRole',
          body: { user_role: value.select_role },
          whereQuery: { user_code: props?.selectedUser?.user_code },
        })
        .then((response) => {
          if (response.data.status === 200) {
            props.onClose();
            toast.success(userMasterAPIMessage?.assignedRoleSuccess, {
              toastId: toastIds?.assignedRoleSuccess,
            });
          }
        })
        .catch((error) => {
          toast.success(userMasterAPIMessage?.assignedRoleSuccess, {
            toastId: toastIds?.assignedRoleSuccess,
          });
        });

      // if (roleList.length > 0) {
      //   setRoleError(false);
      // } else {
      //   setRoleError(true);
      // }
    }

  };



  const onSelect = (value) => {
    // console.log('value', value);
    // setRoleList(value);
  };

  return (
    <Formik
      initialValues={{
        user: props?.selectedUser?.user_code,
        select_role: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(value) => {
        handleSubmit(value);
      }}
    >
      <div id="assignRole">
        <Form className=".labler-wrapper">
          <div className="d-flex justify-content-end mr-3">
            <Button role={componentRole?.TRANSPARENT_CANCEL || ''} onClick={() => {
              props.onClose();
              navigate(ROUTE_PATH.USER);
            }} />
          </div>
          <div className="large_f px-4">
            <div className="d-flex justify-content-start demergeLabel f-5">
              <FormLabel value={commonVariables?.ASSIGN_ROLE || ''} />
            </div>
            {/* <p className="Userlabel" onClick={() => navigate(ROUTE_PATH.USER)}>
                  <span className="labelcolor">Assign Role</span>
                </p> */}

            <div>
              <div className="select-container medium_f_bold mt-4">
                <FormLabel value="User" />

                <Field type="text" className="InputField" name="user" id="user" value={props.selectedUser?.user_name} disabled></Field>
                <div className="errorMessage">
                  <ErrorMessage name="user" />
                </div>

                {/* <Form.Label htmlFor="userName">user</Form.Label>
                <Form.Control type="text" id="userName" aria-describedby="User Name" value={props.selectedUser?.user_name} disabled /> */}
              </div>
              {assignedRole.length > 0 && (
                <div className=" medium_f_bold">
                  <FormLabel value="Assigned Role" />
                  {/* <Form.Label htmlFor="assignedRole">Assigned Role</Form.Label> */}

                  {/* <Field type="text" className="InputField" name="assign_role" id="assign_role" placeholder="Assign Role"></Field> */}

                  <ol type="1">
                    {assignedRole.map((role) => (
                      <li>{role.user_role}</li>
                    ))}
                  </ol>
                </div>
              )}

              <div className="select-container medium_f_bold mt-3">
                {/* <FormLabel value=" Select Role" className="required usertext" /> */}
                {/* <Form.Label htmlFor="inputPassword5" className="required"></Form.Label> */}
                {/* <MultiSelect options={roleList} onChange={onSelect} name={'select_role'} id="select_role" /> */}
                <FormLabel value="Select Role" className="required" />

                <FormikSelect label="select Role " name="select_role" id="select_role" options={roleList} />

                <div className="errorMessage">
                  <ErrorMessage name="select_role" />
                </div>

                {/* <FormikSelect label="Select Role" name="mandate_type" className="form-group form-control-lg pt-1 w-100 h-25" options={roleList} />

                <ErrorMessage name="role" /> */}
              </div>

              <div className="d-flex mt-3">
                <div className="cancel_btn mr-2">
                  <Button
                    role="secondary"
                    value="Cancel"
                    // disabled={isSubmitting}
                    onClick={() => {
                      dispatch(showBar(false))
                      props.onClose();
                    }}
                  />
                </div>

                <div className="submitButton">
                  <Button
                    role="primary"
                    value="Submit"
                    onClick={() => {
                      //   handleSubmit();
                    }}
                    type="submit"
                  />
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </Formik>
  );
}
