/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./Payment.scss";
import Dropdown from "../common/Dropdown/dropdown";
import {
  PresentationOptionsNew,
  paymentDropdownValues,
} from "../../constants/Common/commonConstants";

import apiCall from "../../utils/apiFunction/apiCall";
import {
  apiConstants,
  attributePaymentConstants,
  tableNamePaymentConstants,
} from "../../constants/Common/apiConstants";
import SystemXLoader from "../common/Loader/SystemXLoader";
const DynamicForm = React.lazy(() =>
  import("../common/DynamicForm/dynamicForm")
);
const Payment = (props) => {
  const [formValues, setFormValues] = useState();
  const [companyCode, setCompanyCode] = useState([]);
  const [paymentMode, setPaymentMode] = useState([]);
  const [businessID, setBusinessID] = useState([]);
  const [sponsorPay, setSponsorPay] = useState([]);
  const [recieptType, setRecieptType] = useState([]);
  const [productCode, setProductCode] = useState([]);
  const [nonVendor, setNonVendor] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCompanyCode();
    getPaymentMode();
    getBusinessID();
    getSponsorPayment();
    getRecieptType();
    getProductCode();
    getNonVendor();
    localStorage.removeItem(paymentDropdownValues);
  }, []);

  useEffect(() => {
    settingFormValues();
  }, [
    companyCode,
    paymentMode,
    businessID,
    sponsorPay,
    recieptType,
    productCode,
    nonVendor,
  ]);

  const settingFormValues = () => {
    if (props?.isRegularPayment) {
      setFormValues([
        {
          type: "dropdown",
          value: "",
          label: "Company",
          placeholder: "Company",
          required: true,
          width: 12,
          options: companyCode,
        },
        {
          type: "dropdown",
          value: "",
          label: "Payment Mode",
          placeholder: "Payment Mode",
          required: false,
          width: 12,
          options: paymentMode,
        },
        {
          type: "dropdown",
          value: "",
          label: "Business ID",
          placeholder: "Business ID",
          required: false,
          width: 12,
          options: businessID,
        },
        {
          type: "dropdown",
          value: "",
          label: "Sponsor Payment Bank",
          placeholder: "Sponsor Payment Bank",
          required: true,
          width: 12,
          options: sponsorPay,
        },
        {
          type: "dropdown",
          value: "",
          label: "Reciept Type",
          placeholder: "Reciept Type",
          required: false,
          width: 12,
          options: recieptType,
        },
        {
          type: "dropdown",
          value: "",
          label: "Product Code",
          placeholder: "Product Code",
          required: false,
          width: 12,
          options: productCode,
        },
        {
          type: "dropdown",
          value: "",
          label: "Vendor Type",
          placeholder: "Vendor Type",
          required: true,
          width: 12,
          options: nonVendor,
        },
      ]);
    } else if (props?.isRegularAutoPayment) {
      setFormValues([
        {
          type: "dropdown",
          value: "",
          label: "Company",
          placeholder: "Company",
          required: true,
          width: 12,
          options: companyCode,
        },
        {
          type: "dropdown",
          value: "",
          label: "Payment Mode",
          placeholder: "Payment Mode",
          required: false,
          width: 12,
          options: paymentMode,
        },
        {
          type: "dropdown",
          value: "",
          label: "Business ID",
          placeholder: "Business ID",
          required: false,
          width: 12,
          options: businessID,
        },
        {
          type: "dropdown",
          value: "",
          label: "Sponsor Payment Bank",
          placeholder: "Sponsor Payment Bank",
          required: true,
          width: 12,
          options: sponsorPay,
        },
        {
          type: "dropdown",
          value: "",
          label: "Reciept Type",
          placeholder: "Reciept Type",
          required: false,
          width: 12,
          options: recieptType,
        },
        {
          type: "dropdown",
          value: "",
          label: "Product Code",
          placeholder: "Product Code",
          required: false,
          width: 12,
          options: productCode,
        },
        {
          type: "dropdown",
          value: "",
          label: "Vendor Type",
          placeholder: "Vendor Type",
          required: true,
          width: 12,
          options: [{
            "value": "Vendor",
            "label": "Vendor"
        }],
        },
      ]);
    } else {
      setFormValues([
        {
          type: "dropdown",
          value: "",
          label: "Company",
          placeholder: "Company",
          required: true,
          width: 12,
          options: companyCode,
        },
        {
          type: "dropdown",
          value: "",
          label: "Sponsor Payment Bank",
          placeholder: "Sponsor Payment Bank",
          required: true,
          width: 12,
          options: sponsorPay,
        },
        {
          type: "dropdown",
          value: "",
          label: "Branch Code",
          placeholder: "Branch Code",
          required: false,
          width: 12,
          options: [],
        },
        {
          type: "dropdown",
          value: "",
          label: "LMS ID",
          placeholder: "LMS ID",
          required: false,
          width: 12,
          options: [],
        },

        {
          type: "dropdown",
          value: "",
          label: "LOS ID",
          placeholder: "LOS ID",
          required: false,
          width: 12,
          options: [],
        },

        {
          type: "dropdown",
          value: "",
          label: "Vendor Type",
          placeholder: "Vendor Type",
          required: true,
          width: 12,
          options: nonVendor,
        },
      ]);
    }
  };

  const getCompanyCode = () => {
    setLoading(true);
    let paramObj = {
      // offset: 0,
      // limit: 10,
      tablename: tableNamePaymentConstants?.Company,
      attributes: [attributePaymentConstants?.Company],
      where: {
        isActive: 1,
      },
    };
    apiCall
      .post(apiConstants?.findData, paramObj)
      .then(async (res) => {
        let result = res?.data?.response;
        result = result.map((item) => {
          let newItem = {
            value: item.company_name,
            label: item.company_name,
          };
          return newItem;
        });
        setCompanyCode(result);
      })
      .catch((er) => {
        console.log("errr =>>>>", er);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getPaymentMode = () => {
    if (props?.isRegularPayment) {
      setLoading(true);
      let paramObj = {
        // offset: 0,
        // limit: 10,
        tablename: tableNamePaymentConstants?.PaymentType,
        attributes: [attributePaymentConstants?.PaymentType],
      };
      apiCall
        .post(apiConstants?.findData, paramObj)
        .then(async (res) => {
          let result = res?.data?.response;
          result = result.map((item) => {
            let newItem = {
              value: item.payment_mode_name,
              label: item.payment_mode_name,
            };
            return newItem;
          });
          setPaymentMode(result);
        })
        .catch((er) => {
          console.log("errr =>>>>", er);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getBusinessID = () => {
    if (props?.isRegularPayment) {
      setLoading(true);
      let paramObj = {
        // offset: 0,
        // limit: 10,
        tablename: tableNamePaymentConstants?.BusinessIdMaster,
        attributes: [attributePaymentConstants?.BusinessIdMaster],
      };
      apiCall
        .post(apiConstants?.findData, paramObj)
        .then(async (res) => {
          let result = res?.data?.response;
          result = result.map((item) => {
            let newItem = {
              value: item.business_id,
              label: item.business_id,
            };
            return newItem;
          });
          setBusinessID(result);
        })
        .catch((er) => {
          console.log("errr =>>>>", er);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getSponsorPayment = () => {
    setLoading(true);
    let paramObj = {
      // offset: 0,
      // limit: 10,
      tablename: tableNamePaymentConstants?.sponsorBankPaymentTypeMaster,
      attributes: [attributePaymentConstants?.sponsorBankPaymentTypeMaster],
    };
    apiCall
      .post(apiConstants?.findData, paramObj)
      .then(async (res) => {
        let result = res?.data?.response;
        result = result.map((item) => {
          let newItem = {
            value: item.bank_name,
            label: item.bank_name,
          };
          return newItem;
        });
        setSponsorPay(result);
      })
      .catch((er) => {
        console.log("errr =>>>>", er);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getRecieptType = () => {
    if (props?.isRegularPayment) {
      setLoading(true);
      let paramObj = {
        // offset: 0,
        // limit: 10,
        tablename: tableNamePaymentConstants?.recieptTypeMaster,
        attributes: [attributePaymentConstants?.recieptTypeMaster],
      };
      apiCall
        .post(apiConstants?.findData, paramObj)
        .then(async (res) => {
          let result = res?.data?.response;
          result = result.map((item) => {
            let newItem = {
              value: item.name,
              label: item.name,
            };
            return newItem;
          });
          setRecieptType(result);
        })
        .catch((er) => {
          console.log("errr =>>>>", er);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getProductCode = () => {
    if (props?.isRegularPayment) {
      setLoading(true);
      let paramObj = {
        // offset: 0,
        // limit: 10,
        tablename: tableNamePaymentConstants?.Product,
        attributes: [attributePaymentConstants?.Product],
      };
      apiCall
        .post(apiConstants?.findData, paramObj)
        .then(async (res) => {
          let result = res?.data?.response;
          result = result.map((item) => {
            let newItem = {
              value: item.product_code,
              label: item.product_code,
            };
            return newItem;
          });
          setProductCode(result);
        })
        .catch((er) => {
          console.log("errr =>>>>", er);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getNonVendor = () => {
      setLoading(true);
      let paramObj = {
        // offset: 0,
        // limit: 10,
        tablename: tableNamePaymentConstants?.VenderName,
        order:[
          [
            "updatedAt","asc"
        ]
      ]
        // attributes: ["vender_name"],
      };
      apiCall
        .post(apiConstants?.findData, paramObj)
        .then(async (res) => {
          let result = res?.data?.response;
          console.log("errr =>>>eres non vendor>", result);
          result = result
            .filter((value) => value?.isActive)
            .map((item) => {
              let newItem = {
                value:
                  item?.vender_name === "Non-Vendor"
                    ? "allcases"
                    : item?.vender_name,
                label: item?.vender_name,
              };
              return newItem;
            });
          setNonVendor([...result]);
        })
        .catch((er) => {
          console.log("errr =>>>>", er);
        })
        .finally(() => {
          setLoading(false);
        });
  };

  const onView = (data) => {
    localStorage.setItem(paymentDropdownValues, JSON.stringify(data));
    props.setView();
  };

  return (
    <>
      {loading ? (
        <div className="text-center">
          <SystemXLoader />
          <p>
            <b>Loading...</b>
          </p>
        </div>
      ) : (
        <div className="Payment_DisbursmentCaseWrapper">
          {/* Dropdowns */}
          <div>
            <div className="Payment_DisbursmentCaseWrapper_Table-Head">
              <p className="m-0">Select Type</p>
            </div>
            <div className="m-4">
              {formValues && (
                <DynamicForm
                  onSubmit={onView}
                  isPayment
                  formValues={formValues}
                />
              )}
            </div>
          </div>
          {/* Bottom Buttons */}
        </div>
      )}
    </>
  );
};

export default Payment;
