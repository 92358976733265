import React, { useEffect, useState } from "react";
import "./scanDemergeStatus.scss";
import CustomTable from "../../../../component/customtable/CustomTable";
import Button from "../../../../component/common/Button/button";
import apiCall from "../../../../utils/apiFunction/apiCall";
import {
  commonVariables,
  componentRole,
  tableID,
  selectoptions,
  dateFormats,
  route
} from "../../../../constants/Common/commonConstants";
import {useSelector } from "react-redux";
import SystemXLoader from "../../../../component/common/Loader/SystemXLoader";
import { apiConstants, toastIds } from "../../../../constants/Common/apiConstants";
import { toast } from "react-toastify";
import moment from "moment";
import {useNavigate } from "react-router-dom";

const ScanDemergeStatus = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [count, setCount] = useState(0);
  const [selectMonth, setSelectMonth] = useState(1);
  const [listdata, setListdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [downloadFlag, setDownloadFlag] = useState(false);
  const navigate = useNavigate();


  const listApi = (value) => {
    let temp = 0;
    if ((value || pageNumber) > 0) {
      temp = value ? (value - 1) * 10 : (pageNumber - 1) * 10;
    }
    setDownloadFlag(true);
    setLoading(true);
    const params = {
      tablename:"registrationReversalStatus" ,
      month: selectMonth,
      offset: temp,
      order: [["id", "desc"]],
      limit: 10,
      dataFetch: [
        {
          column: "batch_id",
          operator: "ne",
          value: "null",
        },
        {
          column: "document_type",
          operator: "eq",
          value: "1",
        }
      ],
    };
    apiCall
      .post(apiConstants?.fetchAllData, params)
      .then(async (res) => {
        if (res.status === 200) {
          let result = res?.data?.response;
          setCount(res?.data?.count || 0);
          result = result.map((item) => {
            let newItem = {
              batch_id: item?.batch_id ? item?.batch_id : "-",
              user_name: item?.user_name ? item?.user_name : "-",
              file_name: item?.file_name ? item?.file_name : "-",
              upload_status: item?.upload_status ? item?.upload_status : "-",
              total_count: item?.total_count ? item?.total_count : "-",
              upload_date_time: item?.upload_date_time
                ? moment(item?.upload_date_time).format(dateFormats.dateFormatInMDYWithTimeAndAm)
                : "-",
              succuss_count: item?.succuss_count ? item?.succuss_count : "-",
              rejected_count:
                item?.upload_status === "Success" &&
                  item?.rejected_count !== "0"
                  ? addDownloadIcon(item?.rejected_count, item?.batch_id)
                  : item?.rejected_count
                    ? item.rejected_count
                    : "-",
              pending_count: item?.pending_count ? item?.pending_count : "-",
            };
            return newItem;
          });
          if (result?.length > 0) {
            setListdata([...result]);
          }
        }
      })
      .catch((er) => {
        console.log("err", er);
      })
      .finally(() => {
        setDownloadFlag(false);
        setLoading(false);
      });
  };

  const changePage = (value) => {
    listApi(value);
    setPageNumber(value - 1);
  };

  const addDownloadIcon = (rejected_data, batch_id) => {
    return (
      <>
        <div className="d-flex justify-content-between">
          <span className="text-primary">{rejected_data}</span>
          <Button
            onClick={() => {
              downloadRejectedFile(batch_id);
            }}
            role={componentRole?.TRANSPARENT_DOWNLOAD || ""}
          />
        </div>
      </>
    );
  };

  const downloadRejectedFile = (batch_id) => {
    setDownloadFlag(true);
    apiCall
      .get(
        `${apiConstants.DebitTransactionDeMergeMandateReg}?reverasl_batch_id=${batch_id}`
      )
      .then(async (res) => {
        if (res.status === 200) {
          const presignUrl = res.data.response;
          if (res?.data?.response) {
            const link = document.createElement("a");
            link.href = presignUrl;
            link.click();
            toast.success(commonVariables?.FileDownloadSuccess, { toastId: toastIds?.FileDownloadSucess })
          }
        }
      })
      .catch((er) => {
        console.log("err", er);
      })
      .finally(() => {
        setDownloadFlag(false);
      });
  };

const navigateMandateRegistration = () => {
    navigate(`${route?.MANDATE_REGISTRATION}` || "");
  };


  useEffect(() => {
    listApi();
  }, [selectMonth]);

  return (
    <>
      <div className="d-flex flex-wrap gap-2 align-items-center justify-content-between mt-4 mb-3 mx-3">
        <div className="right-div-header">
          <div className="mt-1">
            <label 
                    onClick={() => {
                      navigateMandateRegistration();
                    }}
                    className="text-nowrap pl-0 common-header"
                  >
                    {commonVariables?.MANDATE_REGISTRATION_DEMERGE || ""} &gt; &gt;
            </label>
            <span className="text-nowrap pl-0">
              {commonVariables?.SCAN_DEMERGE_STATUS || ""}
            </span>
          </div>
        </div>
        <div className="d-flex flex-wrap gap-2 left-div-header">
          <div>
            <select
              value={selectMonth}
              disabled={loading || downloadFlag}
              onChange={(e) => {
                setSelectMonth(e?.target?.value);
              }}
              className="form-select selectInput cursor-pointer"
            >
              <option key="Select Month" hidden value>
                Select Month
              </option>
              {selectoptions?.length &&
                selectoptions.map((value) => {
                  return <option value={value.value}>{value.label}</option>;
                })}
            </select>
          </div>
        </div>
      </div>
      <div className="col dtMergeTable">
        {downloadFlag && (
          <div className="loaderDiv">
            <SystemXLoader />
          </div>
        )}
        <CustomTable
          cols={[
            {
              id: tableID?.BATCH_ID || "",
              title: commonVariables?.BATCH_ID || "",
            },
            {
              id: tableID?.UPLOAD_DATE_TIME || "",
              title: commonVariables?.DATE_TIME || "",
            },
            {
              id: tableID?.USER_NAME || "",
              title: commonVariables?.USER_NAME || "",
            },
            {
              id: tableID?.FILE_NAME || "",
              title: commonVariables?.FILE_NAME || "",
            },
            {
              id: tableID?.UPLOAD_STATUS || "",
              title: commonVariables?.FILE_UPLOAD_STATUS || "",
            },
            {
              id: tableID?.TOTAL_COUNT || "",
              title: commonVariables?.TOTAL_RECORDS || "",
            },
            {
              id: tableID?.SUCCESS_COUNT || "",
              title: commonVariables?.SUCCESSFUL || "",
            },
            {
              id: tableID?.REJECTED_COUNT || "",
              title: commonVariables?.REJECTED || "",
            },
            {
              id: tableID?.PENDING_COUNT || "",
              title: commonVariables?.PENDING || "",
            },
          ]}
          data={listdata}
          count={count}
          setPagination={(page) => changePage(page)}
          activePage={pageNumber}
          loading={loading}
        />
      </div>
    </>
  );
};

export default ScanDemergeStatus;
