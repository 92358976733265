import React, { useEffect, useState } from "react";
import CustomTable from "../../../../../component/customtable/CustomTable";
import Button from "../../../../../component/common/Button/button";
import apiCall from "../../../../../utils/apiFunction/apiCall";
import { useLocation, useNavigate } from "react-router-dom";
import {
  commonVariables,
  componentRole,
  tableID,
  selectoptions,
  route,
} from "../../../../../constants/Common/commonConstants";
import { useDispatch } from "react-redux";
import SystemXLoader from "../../../../../component/common/Loader/SystemXLoader";
import { apiConstants } from "../../../../../constants/Common/apiConstants";

const VerifyData = () => {
  const { state } = useLocation();
  const [pageNumber, setPageNumber] = useState(0);
  const [count, setCount] = useState(0);
  const [listdata, setListdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [downloadFlag, setDownloadFlag] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { batch_id, bank_code, mandate_type, company_code } = state;

  const listApi = (value) => {
    let temp = 0;
    if ((value || pageNumber) > 0) {
      temp = value ? (value - 1) * 10 : (pageNumber - 1) * 10;
    }
    setDownloadFlag(true);
    setLoading(true);
    const params = {
      tablename: "manualMandate",
      month: 0,
      offset: temp,
      order: [["updatedAt", "desc"]],
      limit: 10,
      dataFetch: [
        {
          column: "scan_reg_cron_id",
          operator: "eq",
          value: batch_id,
        },
        {
          column: "bank_code",
          operator: "eq",
          value: bank_code,
        },
        {
          column: "mandate_type",
          operator: "eq",
          value: mandate_type,
        },
        {
          column: "company_code",
          operator: "eq",
          value: company_code,
        },
        {
          column: "document_type",
          operator: "eq",
          value: 1,
        },
      ],
    };
    apiCall
      .post(apiConstants?.fetchAllData, params)
      .then(async (res) => {
        if (res.status === 200) {
          let result = res?.data?.response;
          setCount(res?.data?.count || 0);
          result = result.map((item, i) => {
            let newItem = {
              no: temp + i + 1,
              bank: item?.bank_code ? item?.bank_code : "-",
              source_system: item?.source_system ? item?.source_system : "-",
              loan_no: item?.loan_no ? item?.loan_no : "-",
              customer_name: item?.customer_name ? item?.customer_name : "-",
              mandate_maximum_amount: item?.mandate_maximum_amount
                ? item?.mandate_maximum_amount
                : "-",
              mandate_start_date: item?.mandate_start_date
                ? item?.mandate_start_date
                : "-",
              mandate_end_date: item?.mandate_end_date
                ? item?.mandate_end_date
                : "-",
            };
            return newItem;
          });
          if (result?.length > 0) {
            setListdata([...result]);
          }
        }
      })
      .catch((er) => {
        console.log("err", er);
      })
      .finally(() => {
        setDownloadFlag(false);
        setLoading(false);
      });
  };

  const addLink = (batch_id) => {
    return (
      <>
        <Button
          role={componentRole?.TRANSPARENT || ""}
          value={"Verify Data"}
          onClick={() => {
            navigateToNextPage(batch_id);
          }}
        />
      </>
    );
  };

  const navigateToNextPage = (batch_id) => {
    navigate(route?.DT_DEMERGE_STATUS || "", {
      state: {
        batch_id: batch_id,
      },
    });
  };
  const changePage = (value) => {
    listApi(value);
    setPageNumber(value - 1);
  };

  useEffect(() => {
    listApi();
  }, []);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mt-5 mb-4 mx-3">
        <div className="right-div-header">
          <div>
            <label className="labelPresentation">
              {commonVariables?.SFTP_MERGE_STATUS || ""}
            </label>
          </div>
        </div>
      </div>
      <div className="col dtMergeTable">
        {downloadFlag && (
          <div className="loaderDiv">
            <SystemXLoader />
          </div>
        )}
        <CustomTable
          cols={[
            {
              id: tableID?.NO || "",
              title: commonVariables?.NO || "",
            },
            {
              id: tableID?.BANK || "",
              title: commonVariables?.BANK || "",
            },
            {
              id: tableID?.SOURCE_SYSTEM || "",
              title: commonVariables?.SOURCE_SYSTEM || "",
            },
            {
              id: tableID?.LOAN_NO || "",
              title: commonVariables?.LOAN_NO || "",
            },
            {
              id: tableID?.CUSTOMER_NAME || "",
              title: commonVariables?.CUSTOMER_NAME || "",
            },
            {
              id: tableID?.MANDATE_MAX_AMT || "",
              title: commonVariables?.AMOUNT || "",
            },
            {
              id: tableID?.MANDATE_START_DATE || "",
              title: commonVariables?.MANDATE_DATE || "",
            },
            {
              id: tableID?.MANDATE_END_DATE || "",
              title: commonVariables?.SETTLEMENT_DATE || "",
            },
          ]}
          data={listdata}
          count={count}
          setPagination={(page) => changePage(page)}
          activePage={pageNumber}
          loading={loading}
        />
      </div>
    </>
  );
};

export default VerifyData;
