import React, { useEffect, useLayoutEffect, useState } from "react";
import "./DtRepresentationStatus.scss";
import CustomTable from "../../../component/customtable/CustomTable";
import Button from "../../../component/common/Button/button";
import apiCall from "../../../utils/apiFunction/apiCall";
import {
  commonVariables,
  componentRole,
  tableID,
  selectoptions,
  dateFormats
} from "../../../constants/Common/commonConstants";
import DebitTransactionRepresentaionForm from "../DtRepresentationForm/DtRepresentationForm";
import { useDispatch } from "react-redux";
import { showBar } from "../../../redux/reducer/sideBarStatus";
import SideBar from "../../../component/sideBar";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import { toast } from "react-toastify";
import { DtRepresentationTableOrder, ModuleName, apiConstants, errMessage, successMsg, tableNamePaymentConstants, toastIds } from "../../../constants/Common/apiConstants";
import moment from "moment";

const DebitTransactionRepresentationStatus = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [count, setCount] = useState(0);
  const [selectMonth,setSelectMonth] = useState(1)
  const [listdata, setListdata] = useState([]);
  const [downloadFlag, setDownloadFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const listApi = (value) => {
    let temp = 0
    if((value || pageNumber) > 0){
      temp = value ? (value - 1) * 10 : (pageNumber - 1) * 10;
    }
    setDownloadFlag(true);
    setLoading(true);
    const params = {
      tablename: tableNamePaymentConstants?.debitTranRegFileUploadStatus,
      month : selectMonth,
      offset: temp,
      limit: 10,
      order:DtRepresentationTableOrder,
      whereQuery: {
        module_name: ModuleName?.RPTProvisionUpload,
      },
    };
    apiCall
      .post(apiConstants?.fetchAllData, params)
      .then(async (res) => {
        if (res.status === 200) {
          let result = res?.data?.response;
          setCount(res?.data?.count || 0)
          result = result.map((item, i) => {
            let newItem = {
              no: temp + i + 1,
              batch_id: item?.batch_id,
              user_name: item?.user_name,
              file_name: item?.file_name,
              upload_status: item?.upload_status,
              total_count: item?.total_count,
              upload_date_time:moment(item?.upload_date_time).format(dateFormats.dateFormatInMDYWithTimeAndAm),
              valid:
                item?.succuss_count === "0"
                  ? item?.succuss_count
                  : downloadValid(item?.succuss_count, item?.batch_id),
              invalid:
                item?.rejected_count === "0"
                  ? item?.rejected_count
                  : downloadInvalid(item?.rejected_count, item?.batch_id),
              action:item?.succuss_count !== "0" ? (item?.isApprove !== 1 ? (item?.isReject !== 1  ? addButton(item?.batch_id): commonVariables?.DEMERGE_REJECT_MESSAGE) : commonVariables?.DEMERGE_SUCCESS_MESSAGE):"-"
            };
            return newItem;
          });
          if (result?.length > 0) {
            setListdata([...result]);
          }
        }
      })
      .catch((er) => {
        console.log("err", er);
      })
      .finally(() => {
        setDownloadFlag(false);
        setLoading(false);
      });
  };

  const changePage = (value) => {
    listApi(value);
    setPageNumber(value - 1);
  };
  // useEffect(() => {
    
  // }, []);
  // useLayoutEffect(() => {
  
  // }, []);
  const downloadValid = (succuss_count, batch_id) => {
    return (
      <>
        <Button
          value={succuss_count}
          role={componentRole.TRANSPARENT}
          onClick={() => {
            downloadSuccessFile(batch_id);
          }}
        />
      </>
    );
  };

  const downloadInvalid = (rejected_count, batch_id) => {
    return (
      <>
        <Button
          value={rejected_count}
          role={componentRole.TRANSPARENT}
          onClick={() => {
            downloadRejectedFile(batch_id);
          }}
        />
      </>
    );
  };

  const addButton = (param) => {
    return (
      <>
        <div className="d-flex justify-content-between gap-2 rptTableButton">
          <div>
            <button
              className="approveBtn"
              onClick={() => {
                approveMerge(param);
              }}
            >
              {commonVariables.APPROVE}
            </button>
          </div>
          <div>
            <button
              className="rejectBtn"
              onClick={() => {
                rejectMerge(param);
              }}
            >
              {commonVariables.REJECT}
            </button>
          </div>
        </div>
      </>
    );
  };

  const approveMerge = (batch_id) => {
    setDownloadFlag(true);
    apiCall
      .get(`${apiConstants.DebitTransactionRptApproveMergeFlag}?batch_id=${batch_id}`)
      .then(async (res) => {
        if (res?.data?.status === 200) {
          toast.success(successMsg?.RepresentationApprove, toastIds?.rptbillUpload);
        }
      })
      .catch((er) => {
        console.log("err", er);
        toast.error(er?.message||errMessage?.somethingWentWrong,toastIds?.SomethingWentWrong);
      })
      .finally(() => {
        setDownloadFlag(false);
        listApi();
      });
  };

  const rejectMerge = (batch_id) => {
    setDownloadFlag(true);
    apiCall
      .get(`${apiConstants.DebitTransactionRptRejectMergeFlag}?batch_id=${batch_id}`)
      .then(async (res) => {
        if (res?.data?.status === 200) {
          toast.success(successMsg?.RepresentationRejected, toastIds?.rptbillUpload);
        }
      })
      .catch((er) => {
        console.log("err", er);
        toast.error(er?.message||errMessage?.somethingWentWrong,toastIds?.SomethingWentWrong);
      })
      .finally(() => {
        setDownloadFlag(false);
        listApi();
      });
  };

  const downloadSuccessFile = (batch_id) => {
    setDownloadFlag(true);
    apiCall
      .get(`${apiConstants.debitTransactionRptDownloadRptSuccess}?batch_id=${batch_id}`)
      .then(async (res) => {
        if (res.status === 200) {
          const presignUrl = res.data.response;
          if (res?.data?.status === 200) {
            const link = document.createElement('a');
            link.href = presignUrl;
            link.click();
            toast.success(commonVariables?.FileDownloadSuccess, { toastId: toastIds?.FileDownloadSucess })
     
          }
        }
        if (res?.data?.status === 404) {
          toast.error(res?.data?.message||errMessage?.somethingWentWrong, { toastId: toastIds?.SomethingWentWrong });
        }
      })
      .catch((er) => {
        console.log("err", er);
      })
      .finally(() => {
        setDownloadFlag(false);
      });
  };

  const downloadRejectedFile = (batch_id) => {
    setDownloadFlag(true);
    apiCall
      .get(`${apiConstants.DebitTransactionRptDownloadRptReject}?batch_id=${batch_id}`)
      .then(async (res) => {
        if (res?.data?.status === 200) {
          const presignUrl = res?.data?.response;
          if (res?.data?.status === 200) {
            const link = document.createElement('a');
            link.href = presignUrl;
            link.click();
            toast.success(commonVariables?.FileDownloadSuccess, { toastId: toastIds?.FileDownloadSucess })
     
          }
        } else { 
          toast.error(res?.data?.message||errMessage?.somethingWentWrong, { toastId: toastIds?.SomethingWentWrong });
        }
      })
      .catch((er) => {
        console.log("err", er);
      })
      .finally(() => {
        setDownloadFlag(false);
      });
  };
  useEffect(() => {
    listApi();
  }, [selectMonth]);

  return (
    <>
      <div className="d-flex flex-wrap gap-2 align-items-center justify-content-between mt-4 mb-3 mx-3">
        <div className="right-div-header">
          <div className="mt-1">
            <label className="text-nowrap pl-0 common-header">
              {commonVariables.PROVISION_UPLOAD}
            </label>
          </div>
        </div>
        <div className="d-flex flex-wrap gap-2 left-div-header">
        <div>
            <select
            disabled={loading || downloadFlag}
            value={selectMonth}
              onChange={(e) => {setSelectMonth(e?.target?.value)}}
              className="form-select selectInput cursor-pointer"
            >
              <option key="Select Month" hidden value>
                Select Month
              </option>
              {selectoptions?.length &&
                selectoptions.map((value) => {
                  return <option value={value.value}>{value.label}</option>;
                })}
            </select>
            {/* <Dropdown placeholder={"1 Month"} options={selectoptions}/> */}
          </div>
          <div>
            <button 
            disabled={loading || downloadFlag}
            onClick={() => {
                dispatch(showBar(true));
              }}
              className="uploadBtn"
            >{commonVariables.UPLOAD}</button>
            <button
              onClick={() => {
                listApi()
              }}
              className="ml-2 presentationStatus_uploadBtn"
            >
              Refresh
            </button>
            <div>
              <SideBar data={<DebitTransactionRepresentaionForm onCancel={()=>listApi()} />} />
            </div>
          </div>
        </div>
      </div>
      <div className="col dtRepresentationTable">
        {downloadFlag && (
          <div className="loaderDiv">
            <SystemXLoader />
          </div>
        )}
        <CustomTable
          cols={[
            {
              id: tableID.NO,
              title: commonVariables.NO,
            },
            {
              id: tableID.BATCH_ID,
              title: commonVariables.BATCH_ID,
            },
            {
              id: tableID.UPLOAD_DATE_TIME,
              title: commonVariables.DATE_TIME,
            },
            {
              id: tableID.USER_NAME,
              title: commonVariables.USER_NAME,
            },
            {
              id: tableID.FILE_NAME,
              title: commonVariables.FILE_NAME,
            },
            {
              id: tableID.UPLOAD_STATUS,
              title: commonVariables.FILE_UPLOAD_STATUS,
            },
            {
              id: tableID.TOTAL_COUNT,
              title: commonVariables.TOTAL_RECORDS,
            },
            {
              id: tableID.VALID,
              title: commonVariables.VALID,
            },
            {
              id: tableID.INVALID,
              title: commonVariables.INVALID,
            },
            {
              id: tableID.ACTION,
              title: commonVariables.ACTION,
            },
          ]}
          data={listdata}
          count={count}
          setPagination={(page) => changePage(page)}
          activePage={pageNumber}
          loading={loading}
        />
      </div>
    </>
  );
};

export default DebitTransactionRepresentationStatus;
