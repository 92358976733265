import { createSlice } from "@reduxjs/toolkit";

const SingleMandateReducer = createSlice({
  name: "CreateSession/reducer",

  initialState: {
    activeStep: 0,
  },

  reducers: {
    systemDetails: (state, action) => {
      state.systemDetails = action.payload;
    },
    customerDetails: (state, action) => {
      state.customerDetails = action.payload;
    },
    mandateDetails: (state, action) => {
      state.mandateDetails = action.payload;
    },
  },
});
export const { systemDetails, customerDetails, mandateDetails } =
  SingleMandateReducer.actions;
export const SingleMandate = SingleMandateReducer.reducer;
