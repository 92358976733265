import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormLabel from "../../../../../common/formLabel/formLabel";
import { ROUTE_PATH } from "../../../../../../constants/Routes";
import FormikSelect from "../../../../../common/formikSelect/formikSelect";
import Button from "../../../../../common/Button/button";
import "../createPaymentType/createPaymentType.scss";
import apiCall from "../../../../../../utils/apiFunction/apiCall";
import {
  apiConstants,
  FOMRS,
  STATUS,tableNamePaymentConstants,isActiveDropdowns ,errMessage, successMsg, toastIds
} from "../../../../../../constants/Common/apiConstants";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { YupMessages } from "../../../../../../constants/Common/commonConstants";
import { toast } from "react-toastify";

const validationSchema = Yup.object({
  payment_mode_name: Yup.string().required(YupMessages?.PaymentModeRequired)
  .matches(/^(?!.*<script>).*$/, "Payment mode contain scripts or HTML tags")
  .matches(/^(?!.*<\/?[a-z][\s\S]*>).*$/, "Payment mode cannot contain scripts or HTML tags")
  .matches(/^[A-Za-z]/, "Payment mode can only contain alphabets")
  .test('no-spaces', 'Payment mode cannot be only spaces', (value) => value.trim() !== ''),

  payment_mode_code: Yup.string().required(
    YupMessages?.paymentModeCodeRequired)
	.matches(/^(?!.*<script>).*$/, "Payment mode code contain scripts or HTML tags")
  .matches(/^(?!.*<\/?[a-z][\s\S]*>).*$/, "Payment mode code cannot contain scripts or HTML tags")
  
  .test('no-spaces', 'Payment mode code cannot be only spaces', (value) => value.trim() !== ''),

  isActive: Yup.string().required(YupMessages?.isActiveRequired),
});

const CreatePaymentType = () => {
  const navigate = useNavigate();

  const { state } = useLocation();

  const formType = state?.type || FOMRS.ADD;

  const headingText =
    formType === FOMRS.ADD ? "Create New Payment Type" : "Update Payment Type";

  const [formData, setFormData] = useState({
    payment_mode_name: "",
    payment_mode_code: "",
    isActive: "",
  });

  const [loading, setLoading] = useState(false);

 

  const FormSubmit = (value) => {
    let query = {
      body: {
        payment_mode_name: value.payment_mode_name,
        payment_mode_code: value.payment_mode_code,
        isActive: value.isActive,
      },
      tablename: tableNamePaymentConstants?.PaymentType,
    };
   

    if (formType === FOMRS.EDIT) {
      query.whereQuery = {
        id: state?.user_type?.id,
      };
      apiCall.post(apiConstants?.updateUser, query).then((res) => {
        if (res?.status === STATUS.SUCCESS) {
          navigate(ROUTE_PATH.PAYMENT_TYPE);
          toast.success(successMsg?.PaymentUpdatedSuccessfully, toastIds?.FileUploadSuccess)         
        }
      });
    }

    if (formType === FOMRS.ADD) {
      apiCall.post(apiConstants?.createUser, query).then((res) => {
        if (res?.status === STATUS.SUCCESS) {
          navigate(ROUTE_PATH.PAYMENT_TYPE);
          toast.success(successMsg?.PaymentCreatedSuccessfully, toastIds?.FileUploadSuccess)
        }
      });
    }
  };

  const getFormData = async (id) => {
    let bodyParams = {
      tablename: tableNamePaymentConstants?.PaymentType,
      whereQuery: {
        id,
      },
    };
  };

  useEffect(() => {
    if (formType === FOMRS.EDIT) {
      setFormData({
        payment_mode_name: state.user_type?.payment_mode_name,
        payment_mode_code: state?.user_type?.payment_mode_code,
        isActive: state?.user_type?.isActive,
      });
    }
  }, [loading]);

  return (
    <Formik
      initialValues={{
        payment_mode_name: formData.payment_mode_name,
        payment_mode_code: formData.payment_mode_code,
        isActive: formData.isActive,
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={(values) => {
        FormSubmit(values);
      }}
    >
      {({ values, setFieldValue }) => (
        <>
          <div id="createPayment">
            <div
              className="col blueFonts large_f"
              onClick={() => navigate(ROUTE_PATH.PAYMENT_TYPE)}
            >
              {`Payment Type Master >> `}
              <span className="blackFonts">{headingText}</span>
            </div>
            <div className="main-div">
              <Form className=".labler-wrapper">
                <div id="RegularForm">
                  <div className="createroletext">
                    <p id="pText">{headingText}</p>
                  </div>

                  <div className="row paddingTop ml-4 mr-4">
                    <div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
                      <div>
                        <FormLabel
                          value="Payment Mode Name"
                          className="required"
                        />
                      </div>
                      <Field
                        type="text"
                        className="InputField"
                        name="payment_mode_name"
                        id="payment_mode_name"
                        placeholder="Payment Mode Name"
                      ></Field>
                      <div className="errorMessage">
                        <ErrorMessage name="payment_mode_name" />
                      </div>
                    </div>
                  </div>

                  <div className="row paddingTop ml-4 mr-4">
                    <div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
                      <FormLabel
                        value="Payment Mode Code"
                        className="required"
                      />
                      {/* <Input /> */}
                      <Field
                        type="text"
                        className="InputField"
                        name="payment_mode_code"
                        id="payment_mode_code"
                        placeholder="Payment Mode Code"
                      ></Field>
                      <div className="errorMessage">
                        <ErrorMessage name="payment_mode_code" />
                      </div>
                    </div>
                  </div>

                  <div className="row paddingTop ml-4 mr-4">
                    <div className="col-md-12 col-lg-12 medium_f_bold directionColumn ">
                      <div className="formState">
                        <FormLabel value="Is Active" className="required" />
                        <FormikSelect
                          label="Please select a value"
                          name="isActive"
                          options={isActiveDropdowns}
                        />
                      </div>
                      <div className="errorMessage">
                        <ErrorMessage name="isActive" />
                      </div>
                    </div>
                  </div>

                  <div className=" d-flex py-4">
                    <div className="cancel_btn">
                      <Button
                        role="secondary"
                        value="Cancel"
                        type="button"
                        onClick={() => navigate(ROUTE_PATH.PAYMENT_TYPE)}
                      />
                    </div>

                    <div className="submitButton">
                      <Button
                        role="primary"
                        value="Submit"
                        type="submit"
                        onClick={() => {}}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

export default CreatePaymentType;
