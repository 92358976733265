import React from 'react'
import { Modal, Button } from "react-bootstrap";
import "./deleteModal.scss"
const DeleteConfirmation = ({ showModal, hideModal, confirmModal, id, type, message }) => {
    return (
        <Modal centered show={showModal} onHide={hideModal}>
        <Modal.Header className='custom-modal-header' closeButton>
          <Modal.Title className='custom-modal-title' >Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body><div className="alert alert-danger">{message}</div></Modal.Body>
        <Modal.Footer>
          <Button className='custom-modal-button-danger' onClick={() => confirmModal(type, id) }>
            Delete
          </Button>
          <div className='bufferMargin'></div>
          <Button variant="default" onClick={hideModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default DeleteConfirmation;