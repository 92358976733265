import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  enachTable: '',
};

const AccountValidationReducer = createSlice({
  name: "accountValidation",
  initialState,
  reducers: {
    setEnachTable: (state, action) => {
      state.enachTable=action.payload;
    },
  },
});
export const { setEnachTable } =
  AccountValidationReducer.actions;
export const selectArray = (state) => state.accountValidation.enachTable;
export const AccountValidation = AccountValidationReducer.reducer;
