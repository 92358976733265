import React, { useEffect, useState } from "react";
import CustomTable from "../../../component/customtable/CustomTable";
import { useLocation } from "react-router-dom";
import {useNavigate } from "react-router-dom";
import apiCall from "../../../utils/apiFunction/apiCall";
import "./DtSourceManualDownload.scss";

import {
  commonVariables,
  route,
} from "../../../constants/Common/commonConstants";
import { toast } from "react-toastify";
import {
  apiConstants,
  errMessage,
  toastIds,
} from "../../../constants/Common/apiConstants";

const DtSourceManualDownload = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [listdata, setListdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const { batchId } = state;

  const navigate = useNavigate();

  useEffect(() => {
    listApi();
  }, []);

  const tableLink = (val, sourceSystem, batchID) => {
    return (
      <>
        <p
          className="tableLink"
          onClick={() => downloadData(sourceSystem, batchID)}
        >
          {val}
        </p>
      </>
    );
  };

  const previousNavigation = (databatchId) => {
    navigate(`${route?.DT_DEMERGE_SYSTEM}/${databatchId}` || "");
  };
  const downloadData = (sourceSystem, batchID) => {
    let postData = {
      batchId: batchID,
      sourceSystem: sourceSystem,
      mandateType: commonVariables?.NACH?.toUpperCase(),
    };
    apiCall
      .post(apiConstants?.downloadManualDT, postData)
      .then(async (res) => {
        if (res?.data?.status === 200) {
          const link = document.createElement("a");
          link.href = res?.data?.response;
          link.click();
          toast.success(commonVariables?.FileDownloadSuccess, { toastId: toastIds?.FileDownloadSucess })
     
        } else {
          toast.error(res?.message || errMessage?.somethingWentWrong, {
            toastId: toastIds?.FileUploaderr,
          });
        }
      })
      .catch((err) => {
        toast.error(err?.message || errMessage?.somethingWentWrong, {
          toastId: toastIds?.DataError,
        });
      });
  };

  const listApi = () => {
    setLoading(true);
    apiCall
      .get(
        `${apiConstants.DebitTransactionDeMergeFetchManualCount}?batch_Id=${
          batchId || ""
        }&source_System=${commonVariables?.FINNONE.toUpperCase()}&mandate_Type=${
          commonVariables?.NACH?.toUpperCase() || ""
        }`
      )
      .then(async (res) => {
        if (res?.data?.status === 200) {
          setListdata([
            {
              batchId: (
                <p className="tableLink">{res?.data?.response?.batchId}</p>
              ),
              finnoneCount:
                res?.data?.response?.finnoneCount === 0
                  ? res?.data?.response?.finnoneCount
                  : tableLink(
                      res?.data?.response?.finnoneCount,
                      commonVariables?.F1_Manual_Upload_File,
                      res?.data?.response?.batchId
                    ),
              bancsCount:
                res?.data?.response?.bancsCount === 0
                  ? res?.data?.response?.bancsCount
                  : tableLink(
                      res?.data?.response?.bancsCount,
                      commonVariables?.BANCS_MANUAL_UPLOAD_FILE,
                      res?.data?.response?.batchId
                    ),
              otherCount:
                res?.data?.response?.otherCount === 0
                  ? res?.data?.response?.otherCount
                  : tableLink(
                      res?.data?.response?.otherCount,
                      commonVariables?.OTHER_MANUAL_Upload_File,
                      res?.data?.response?.batchId
                    ),
            },
          ]);
        } else {
          toast.error(res?.data?.response?.message, {
            toastId: toastIds?.DataError,
          });
        }
      })
      .catch((err) => {
        toast.error(err?.message, { toastId: toastIds?.DataError });
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div className="demergeManualCountWrapper">
        <div className="d-flex align-items-center justify-content-between mt-5">
          <div className="right-div-header">
            <label className="common-header cursor-pointer ps-4">
                {commonVariables?.DEMERGE || ""} &gt; &gt;
              </label>
              <label
                onClick={() => {
                  previousNavigation(batchId);
                }}
                className="common-header cursor-pointer ps-2"
              >
                {commonVariables?.SYSTEM_COUNT_DATA || ""} &gt; &gt;
              </label>
              <span className="text-nowrap ps-2">
                {commonVariables?.MANUAL || ""}
              </span>
          </div>
        </div>
        <div className="col">
          <CustomTable
            cols={[
              {
                id: "batchId" || "",
                title: commonVariables?.BATCH_ID || "",
              },
              {
                id: "finnoneCount" || "",
                title: commonVariables?.FINNONE_MANUAL_REVERSAL || "",
              },
              {
                id: "bancsCount" || "",
                title: commonVariables?.BANCS_MANUAL_REVERSAL || "",
              },
              {
                id: "otherCount" || "",
                title: commonVariables?.OTHER_SOURCE_SYSTEM || "",
              },
            ]}
            data={listdata}
            activePage={pageNumber}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
};

export default DtSourceManualDownload;
