import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import FormLabel from '../../../../../common/formLabel/formLabel';
import { ROUTE_PATH } from '../../../../../../constants/Routes';
import FormikSelect from '../../../../../common/formikSelect/formikSelect';
import Button from '../../../../../common/Button/button';
import { useNavigate, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import apiCall from '../../../../../../utils/apiFunction/apiCall';
import {
	apiConstants,
	FOMRS,
	STATUS, tableNamePaymentConstants, errMessage, successMsg, toastIds
} from '../../../../../../constants/Common/apiConstants';
import './CreateBusinessId.scss';
import { YupMessages } from '../../../../../../constants/Common/commonConstants';
import { toast } from 'react-toastify';


const validationSchema = Yup.object({
	type: Yup.string().required(YupMessages?.CustomerTypeRequired),
	business_id: Yup.string().required(YupMessages?.BusinessIDRequired)
		.matches(/^[^@#]+$/, "Business ID cannot contain symbols like @ and #")
		.test('no-spaces', 'Business ID cannot be only spaces', (value) => value.trim() !== ''),
});

const CreateBusinessId = () => {
	const navigate = useNavigate();
	const { state } = useLocation();

	const formType = state?.type || FOMRS.ADD;
	const headingText = formType === FOMRS.ADD ? "Create Business ID" : "Update  Business ID";


	const [formData, setFormData] = useState({
		type: '',
		business_id: '',
	});

	const [loading, setLoading] = useState(false);

	const dropdownOptions = [
		{ key: 'Please Select a option', value: '' },
		{ key: 'Customer', value: 'customer' },
		{ key: 'Non Customer', value: 'non-customer' },
		{ key: state?.user_type?.type, value: state?.user_type?.type },
	];

	const FormSubmit = (value) => {
		let query = {
			body: {
				type: value.type,
				business_id: value.business_id,
			},
			tablename: tableNamePaymentConstants?.BusinessIdMaster,
		};


		if (formType === FOMRS.EDIT) {
			query.whereQuery = {
				id: state?.user_type?.id,
			};
			apiCall
				.post(apiConstants?.updateUser, query)
				.then((res) => {
					if (res?.status === STATUS.SUCCESS) {
						navigate(ROUTE_PATH.BUSINESS_ID);
						toast.success(successMsg?.BusinessIdMasterUpdatedSuccessfully, toastIds?.FileUploadSuccess)
					}
				})

		}

		if (formType === FOMRS.ADD) {
			apiCall
				.post(apiConstants?.createUser, query)
				.then((res) => {
					if (res?.status === STATUS.SUCCESS) {
						navigate(ROUTE_PATH.BUSINESS_ID);
						toast.success(successMsg?.BusinessIdMasterCreatedSuccessfully, toastIds?.FileUploadSuccess)
					}
				})

		}
	};

	const getFormData = async (id) => {
		let bodyParams = {
			tablename: tableNamePaymentConstants?.BusinessIdMaster,
			whereQuery: {
				id,
			},
		};
	};

	useEffect(() => {
		if (formType === FOMRS.EDIT) {
			setFormData({
				type: state?.user_type?.type,
				business_id: state?.user_type?.business_id,
			});
		}
	}, [loading]);

	return (
		<Formik
			initialValues={{
				type: formData.type,
				business_id: formData.business_id,
			}}
			validationSchema={validationSchema}
			enableReinitialize={true}
			onSubmit={(values) => {
				FormSubmit(values);
			}}
		>
			{({ values, setFieldValue }) => (
				<>
					<div id="createBusinessId">
						<div
							className="col blueFonts large_f"
							onClick={() => navigate(ROUTE_PATH.BUSINESS_ID)}
						>
							{`Business ID >> `}
							<span className="blackFonts">{headingText}</span>
						</div>
						<div className="main-div">
							<Form className=".labler-wrapper">

								<div id="RegularForm">
									<div className="createroletext">
										<p id="pText">{headingText}</p>
									</div>

									<div className="row paddingTop ml-4 mr-4">
										<div className="col-md-12 col-lg-12 medium_f_bold directionColumn ">
											<div className="formState">
												<FormLabel value="Customer Type" className="required" />
												<FormikSelect
													label="Please select a value"
													name="type"
													options={dropdownOptions}
												/>
											</div>
											<div className="errorMessage">
												<ErrorMessage name="type" />
											</div>
										</div>
									</div>

									<div className="row paddingTop ml-4 mr-4">
										<div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
											<FormLabel value="Business ID" className="required" />
											<Field
												type="text"
												className="InputField"
												name="business_id"
												id="business_id"
												placeholder="Business Id"
											></Field>
											<div className="errorMessage">
												<ErrorMessage name="business_id" />
											</div>
										</div>
									</div>

									<div className=" d-flex py-4">
										<div className="cancel_btn">
											<Button
												role="secondary"
												value="Cancel"
												type="button"
												onClick={() => navigate(ROUTE_PATH.BUSINESS_ID)}
											/>
										</div>

										<div className="submitButton">
											<Button
												role="primary"
												value="Submit"
												type="submit"
												onClick={() => { }}
											/>
										</div>
									</div>
								</div>
							</Form>
						</div>
					</div>
				</>
			)}
		</Formik>
	);
};

export default CreateBusinessId;
