import axios from "axios";

const uploadPresigned = async (file = {}, url, setPercent = () => { },source) => {
    let returnObject = ""
    await axios.put(url, file, {
        headers: {
            'Content-Type': file.type,
        },
        onUploadProgress: (e) => {
            setPercent(Math.round((e.loaded * 100) / e.total))
        },
        cancelToken: source?.token,
    })
        .then((res) => {
            returnObject = res
        })
        .catch((e) => {
            returnObject = e;
          });
    return returnObject
};

export const uploadPresignedMulti = (file = [], url, setPercent = () => { }) => {
    let array = [];
    let temp = {}
    if (Array.isArray(file)) {
        file.map((item,index) => {
            return array.push(
                new Promise((resolve, reject) => {
                    axios.put(url, item, {
                        headers: {
                            "Content-Type": item.type,
                        },
                        onUploadProgress: (e) => {
                            temp["updPercent"+index] = Math.round((e.loaded * 100) / e.total)
                            setPercent(temp)
                        },
                    })
                        .then((res) => {
                            resolve(res)
                        })
                        .catch((e) => {
                            reject(e)
                        });
                })
            );
        });
    }
    return Promise.all(array);
};

export default uploadPresigned;