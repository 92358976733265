import React, { useEffect } from "react";
import { Navigate, useNavigate, useOutlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkToken } from "../../utils/apiFunction/apiCall";
import { USER_LOGOUT } from "../../redux/reducer/login";
import { toast } from "react-toastify";

const ProtectedRoute = () => {
  const outlet = useOutlet();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let loginState = useSelector((state) => state.login);
  let RoleAccess = loginState?.userToken;
  useEffect(() => {
    if (!checkToken(RoleAccess)) {
      dispatch(USER_LOGOUT());
      navigate("/");
      toast.info("Your session has expired. Please login again", {
        toastId: "sessionExpired",
      });
    }
    console.log("JWT checkTokencheckToken");
  }, []);
  return <>{RoleAccess ? [outlet] : <Navigate to="/" />}</>;
};

export default ProtectedRoute;
