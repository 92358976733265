import React, { useEffect, useState } from "react";
import "./RegistrationAndBanking.scss";
import apiCall from "../../../../utils/apiFunction/apiCall";
import {
  apiConstants,
  errMessage,
  tableNamePaymentConstants,
  toastIds,
  reportStatusAttributes,
  reportConstants
} from "../../../../constants/Common/apiConstants";
import DynamicForm from "../../../../component/common/DynamicForm/dynamicForm";
import { toast } from "react-toastify";
import moment from "moment";
import SystemXLoader from "../../../../component/common/Loader/SystemXLoader";
import {
  commonVariables,
  repaymentModeOptions,
  reportTypeOptions,
  FILE_FORMAT,
  QUERY_PARAM,
  S3FILEPATH
} from "../../../../constants/Common/commonConstants";
import { useSelector } from "react-redux";
import downloadIcon from '../../../../Images/download.svg'
import CustomTable from "../../../../component/customtable/CustomTable";
import { OneWeekLaterDate } from "../../../../utils/utils";

const RegistrationBanking = () => {
  const [companyCode, setCompanyCode] = useState([]);
  const [formValues, setFormValues] = useState();
  const loginState = useSelector((state) => state.login);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [listData, setListData] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);

  const onSubmit = async(data) => {
    if (data.length) {
      let tempData = [...data];
      tempData = Object.assign({}, ...tempData);
      let userData = loginState?.decodedData;
      if (tempData["To Date"] >= tempData["From Date"]) {
        const report_type = tempData["Report Type"] || "";
        const reportData = await fileformat(report_type)
        const company_code = tempData["Company Code"] || "";
        const FromDate = tempData["From Date"]
          ? moment(tempData["From Date"]).format("DD-MM-YYYY")
          : "";
        const ToDate = tempData["To Date"]
          ? moment(tempData["To Date"]).format("DD-MM-YYYY")
          : "";
        const repayment_mode = (tempData["Repayment Mode"] === '1' ? 'NACH' : tempData["Repayment Mode"] === '2' ? "AD" : tempData["Repayment Mode"] === '3' ? "UPI Mandate" : tempData["Repayment Mode"] === "LEG" ? "LEG" : "ALL") || "";
        let apiBody = {
          module_type: 'DT', 
          sub_module_type: reportData?.reportName ,
          created_by:userData?.user_name || "",
          status:QUERY_PARAM.PROCESSING,
          date_time:moment(new Date()).format('YYYY-MM-DD hh:mm:ss A'),
          from_date:FromDate,
          to_date:ToDate,
          expires_at:OneWeekLaterDate()
        };
        setApiLoading(true);
        apiCall
          .post(apiConstants?.createUser, {
            body: apiBody,
            tablename: "reportStatus",
          })
          .then(async (res) => {
            if (res?.data?.status === 200) {
              if (res?.data?.response?.data?.id) {
                onUpdate(res.data.response.data,apiBody,reportData,repayment_mode,company_code);
              } else {
                toast.error(
                  res?.data?.message || errMessage?.somethingWentWrong,
                  {
                    toastId: toastIds?.FileUploaderr,
                  }
                );
              }
            } else {
              toast.error(
                res?.data?.message || errMessage?.somethingWentWrong,
                {
                  toastId: toastIds?.FileUploaderr,
                }
              );
            }
          })
          .catch((er) => {
            setApiLoading(false);
            let resp = er?.message || "";
            toast.error(resp, { toastId: toastIds?.FileUploaderr });
          })
          .finally(() => {
          });
       
      } else {
        toast.error(errMessage?.ToDateMustBeGreaterThanFromDate, {
          toastId: "saveMandateInfo",
        });
      }
    }
  };

  const onUpdate = async(data,apiBody,reportData,repayment_mode,company_code) => {
      const key = `${loginState?.year}/${loginState?.month}/${loginState?.day}/${S3FILEPATH?.DT?.DT}/${S3FILEPATH?.COMMON?.DOWNLOADS}/${S3FILEPATH?.COMMON?.REPORTS}/${data?.id}`    
      let apiBodyUpdate = {
        metadata:{
            report_name: reportData?.reportName,
            s3_output_path:key,
            batch_id: parseInt(data?.id),
            start_date: `${apiBody?.from_date} 00:00:00`,
            end_date: `${apiBody?.to_date} 23:59:59`,
            file_format: reportData?.extension,
            mandate_type: repayment_mode,
            company_code:parseInt(company_code)
        }
      };
      apiCall
        .post(apiConstants?.updateUser, {
          body: apiBodyUpdate,
          tablename: "reportStatus",
          whereQuery: {
            id: data.id || "",
          },
        })
        .then(async (res) => {
          if (res?.data?.status === 200) {
            if (res.data.response[0] === 1) {
              reportApiCall(apiBodyUpdate?.metadata);
            } else {
              toast.error(
                res?.data?.message || errMessage?.somethingWentWrong,
                {
                  toastId: toastIds?.FileUploaderr,
                }
              );
            }
          } else {
            toast.error(res?.data?.message || errMessage?.somethingWentWrong, {
              toastId: toastIds?.FileUploaderr,
            });
          }
        })
        .catch((er) => {
          setApiLoading(false);
          let resp = er?.message || "";
          toast.error(resp, { toastId: toastIds?.FileUploaderr });
        })
        .finally(() => {
        });
  };
  const reportApiCall = async (data) =>{
    setApiLoading(true);
     apiCall
          .post(`${reportConstants?.paymentReports}`,data)
          .then(async (res) => {
              if (res?.data?.status === 200) {
                toast.success( res?.data?.message || commonVariables?.FileDownloadSuccess , { toastId: toastIds?.FileDownloadSucess });
                setFormValues([
                  {
                    type: "dropdown",
                    value: "",
                    label: "Report Type",
                    placeholder: "Please select value",
                    required: true,
                    width: 6,
                    options: reportTypeOptions,
                  },
                  {
                    type: "dropdown",
                    value: "",
                    label: "Company Code",
                    placeholder: "Please select value",
                    required: true,
                    width: 6,
                    options: companyCode,
                  },
                  {
                    type: "dateinput",
                    value: "",
                    label: "From Date",
                    placeholder: "Please select Date",
                    required: true,
                    width: 6,
                  },
                  {
                    type: "dateinput",
                    value: "",
                    label: "To Date",
                    placeholder: "Please select Date",
                    required: true,
                    width: 6,
                  },
                  {
                    type: "dropdown",
                    value: "",
                    label: "Repayment Mode",
                    placeholder: "Please select a value",
                    required: false,
                    width: 6,
                    options: repaymentModeOptions,
                  },
                ]);
                tableAPI();
              } else {
                toast.error(
                  res?.data?.message || errMessage?.somethingWentWrong,
                  { toastId: toastIds?.SomethingWentWrong }
                );
              }
          })
          .catch((er) => {
            let resp = er?.message || "";
            toast.error(resp, { toastId: toastIds?.FileUploaderr });
          })
          .finally(() => {
            setApiLoading(false);
          });
  }

  const fileformat = async(reportType)=> {
    let reportName;
    let extension;
  
    switch (reportType) {
        case QUERY_PARAM.REPRESENTATION_BOUNCE_CASES:
            reportName = QUERY_PARAM.REPRESENTATION_BOUNCE_REPORT1;
            extension = FILE_FORMAT.csv;
            break;
        case QUERY_PARAM.REPRESENTATION_POST_REPORTS:
            reportName = QUERY_PARAM.DT_REPRESENTATION_RESPONSE_REPORT1;
            extension = FILE_FORMAT.csv;
            break;
        case QUERY_PARAM.REG_PRE_REPORTS:
            reportName = QUERY_PARAM.REG_SENT_BANK_REPORT;
            extension = FILE_FORMAT.csv;
            break;
        case QUERY_PARAM.REG_POST_REPORTS:
            reportName = QUERY_PARAM.REG_BANK_RESPONSE_REPORT;
            extension = FILE_FORMAT.csv;
            break;
        case QUERY_PARAM.TRAN_PRE_REPORTS:
            reportName = QUERY_PARAM.DT_SENT_BANK_REPORTS;
            extension = FILE_FORMAT.csv;
            break;
        case QUERY_PARAM.TRAN_POST_REPORTS:
            reportName = QUERY_PARAM.DT_BANK_RESPONSE_REPORTS;
            extension = FILE_FORMAT.csv;
            break;
        case QUERY_PARAM.REPRESENTATION_PRE_REPORTS:
            reportName = QUERY_PARAM.DT_REPRESENTATION_BANK_REPORTS;
            extension = FILE_FORMAT.csv;
            break;
        case QUERY_PARAM.UPI_PDN_REPORTS:
            reportName = QUERY_PARAM.DT_UPI_PDN_REPORT;
            extension = FILE_FORMAT.csv;
            break;
        case QUERY_PARAM.UPI_EMI_Banking_REPORTS:
            reportName = QUERY_PARAM.DT_UPI_EMI_BANKING_REPORT;
            extension = FILE_FORMAT.csv;
            break;
        default:
            break;
    }
    try{    
    let response = { reportName: reportName,extension:extension };
    return response;
    }catch(ex){
        console.log('error', ex);
    }
    }
  const CompanyCode = () => {
    let paramObj = {
      offset: 0,
      limit: 10,
      tablename: tableNamePaymentConstants?.Company,
    };
    apiCall
      .post(apiConstants?.findData, paramObj)
      .then(async (res) => {
        let result = res?.data?.response;
        result = result.map((item) => {
          let newItem = {
            value: item?.company_code,
            label: item?.company_name,
          };
          return newItem;
        });
        setCompanyCode([...result]);
      })
      .catch((er) => {
        console.log("errr =>>>>", er);
      });
  };

  const downloadbtn = (data) => {
    return (
      <div>
        <button
          disabled={!data}
          onClick={() => fetchBlockFile(data)}
          className="downloadbtn pt-1 pe-2 pe-2 d-flex align-items-center justify-content-center"
        >
          <img src={downloadIcon} alt="download" />
          Download
        </button>
      </div>
    );
  };

  const fetchBlockFile = (key) => {
    apiCall
      .get(`${apiConstants?.downloadPresignedUrl}?key=${key}`)
      .then((response) => {
        if (response.status === 200) {
          const link = document.createElement("a");
          link.href = response?.data;
          link.click();
          toast.success(commonVariables?.FileDownloadSuccess, {
            toastId: toastIds?.FileDownloadSucess,
          });
        }
      })
      .catch((error) => {
        return toast.error(errMessage?.InternalServerError, {
          toastId: toastIds?.InternalServerError,
        });
      });
  };

  const tableAPI = () => {
    setAutoRefresh(true)
    apiCall
      .post(apiConstants?.fetchAllData, {
        tablename: tableNamePaymentConstants?.reportStatus,
        whereQuery: {
          created_by: loginState?.decodedData?.user_name || "",
          module_type: commonVariables?.DT,
        },
        attributes: reportStatusAttributes,
        order: [["id", "desc"]],
      })
      .then(async (res) => {
        if (res?.data?.status == 200) {
          let result = res?.data?.response;
          result = result.map((item, index) => {
            let newItem = {
              idData: index + 1,
              date: `${
                item?.metadata?.start_date.split(' ')[0] || ""
              } to ${
                item?.metadata?.end_date.split(' ')[0] || ""
              }`,
              moduletype: item?.module_type || "",
              submoduletype: item?.sub_module_type || "",
              rpname: item?.metadata?.report_name || "-",
              createdby: item?.created_by || "-",
              status: item?.status || "",
              action: downloadbtn(item?.s3_path),
            };
            return newItem;
          });
          let slice20 = result.slice(0,20)
          if (slice20?.length > 0) {
            setListData([...slice20]);
          }
        } else {
          setListData([]);
          toast.error(res?.data?.message, {
            toastId: toastIds?.DataError,
          });
        }
      })
      .catch((er) => {
        setListData([]);
        toast.error(er?.message, { toastId: toastIds?.DataError });
      }).finally(() => {
        setAutoRefresh(false)
      })
  };

  useEffect(() => {
    CompanyCode();
    tableAPI();
  }, []);

  useEffect(() => {
    setFormValues([
      {
        type: "dropdown",
        value: "",
        label: "Report Type",
        placeholder: "Please select value",
        required: true,
        width: 6,
        options: reportTypeOptions,
      },
      {
        type: "dropdown",
        value: "",
        label: "Company Code",
        placeholder: "Please select value",
        required: true,
        width: 6,
        options: companyCode,
      },
      {
        type: "dateinput",
        value: "",
        label: "From Date",
        placeholder: "Please select Date",
        required: true,
        width: 6,
      },
      {
        type: "dateinput",
        value: "",
        label: "To Date",
        placeholder: "Please select Date",
        required: true,
        width: 6,
      },
      {
        type: "dropdown",
        value: "",
        label: "Repayment Mode",
        placeholder: "Please select a value",
        required: false,
        width: 6,
        options: repaymentModeOptions,
      },
    ]);
  }, [companyCode]);

  return (
    <>
    <div>
      {apiLoading ? (
        <div className="loaderDiv">
          <SystemXLoader />
        </div>
      ) : (
        <div className="main-container pt-3">
          <div className="d-flex flex-wrap gap-2 align-items-center justify-content-between  mt-3 mb-3 ">
            <div className="right-div-header">
              <div>
                <label className="common-header cursor-pointer ps-4">
                  Reports &gt; &gt;
                </label>
                <span className="text-nowrap">Registration And Banking </span>

              </div>
            </div>
          </div>
          <div className="container shadow-sm border border-1 rounded-2 ml-4 mt-2">
            <div className="m-4">
              <DynamicForm
                onSubmit={onSubmit}
                isDownload
                formValues={formValues}
              />
            </div>
          </div>
        </div>)}
            <div className="mx-4 pb-4">
            {listData.length > 0 && (
              <>
                <div className="pt-2 toggle-wrapper">
                <button
                    onClick={() => tableAPI()}
                    disabled={autoRefresh}
                    className="customRefreshButton mt-1"
                  >
                    Refresh
                  </button>
                </div>
                <CustomTable
                  cols={[
                    {
                      id: "idData",
                      title: "ID",
                      classStyle: {},
                    },
                    {
                      id: "date",
                      title: "Date Range",
                      classStyle: {},
                    },
                    {
                      id: "rpname",
                      title: "Report Name",
                      classStyle: {},
                    },
                    {
                      id: "createdby",
                      title: "Created By",
                      classStyle: {},
                    },
                    {
                      id: "status",
                      title: "Status",
                      classStyle: {},
                    },
                    {
                      id: "action",
                      title: "Action",
                      classStyle: {},
                    },
                  ]}
                  data={listData}
                  loading={apiLoading}
                />
              </>
            )}
          </div>
        </div>
    </>
  );
};
export default RegistrationBanking;
