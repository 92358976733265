import React, { useState, useEffect } from "react";
import "./Merge.scss";
import "../../../styles/layout/common.scss";
import CustomTable from "../../../component/customtable/CustomTable";
import InputField from "../../../component/common/Inputfield/inputfield";
import Button from "../../../component/common/Button/button";
import apiCall from "../../../utils/apiFunction/apiCall";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { showBar } from "../../../redux/reducer/sideBarStatus";
import SideBar from "../../../component/sideBar";
import CreateMerge from "./CreateMerge/createmerge";
import Newdropdown from "../../../component/common/Dropdown/newDropdown";

import { commonVariables, dateFormats, selectoptions } from "../../../constants/Common/commonConstants";
import { apiConstants } from "../../../constants/Common/apiConstants";
import moment from "moment/moment";

const Merge = () => {

  const [pageNumber, setPageNumber] = useState(0);
  const [listdata, setListdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const listApi = (value) => {
    let temp = value ? (value - 1) * 10 : (pageNumber - 1) * 10;

    setLoading(true);

    apiCall
      .get(
        `${apiConstants.DebitTransactionMergeFetchMergeOffsetStatus}?offset=${
          temp || 0
        }&limit=${apiConstants?.limit}`
      )
      .then(async (res) => {
        if (res.status === 200) {
          let result = res?.data?.response;
          result = result.map((item) => {
            let newItem = {
              no: item.user_code,
              batch_id: item.batch_id,
              mandate: item.mandate_type,
              bank: item.bank_code,
              records: item.total_count,
              date_time: moment(item?.date_time)?.format(dateFormats?.dmyHms),
              name: item.user_name,
              sftp_push: item.sftp_push_flag,
              download: (
                <Form.Select>
                  <option key="Download" hidden value>
                    Download
                  </option>
                  <option >Excel</option>
                  <option>TXT</option>
                </Form.Select>
              ),
            };

            return newItem;
          });
          if (result?.length > 0) {
            setListdata([...result]);
            
            
          }
        }
      })
      .catch((er) => {
        console.log("err", er);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const TextFileDownload=(batch_id,mandate_type,bank_code)=>{
      apiCall.get(`${apiConstants.DebitTransactionMergeDownloadMergeData}?batchId=${batch_id}&mandateType=${mandate_type}&bankCode=${bank_code}`)
      .then(async(res)=>{
        if(res.status===200){

        }
      })
  }
  const changePage = (value) => {
    listApi(value);
    setPageNumber(value - 1);
  };
  useEffect(() => {
    listApi();
  }, []);

  return (
    <div className="container-fluid mt-4">
      <div className="container MergeTable">
        <div className="d-flex  justify-content-between mt-5 mb-4 ">
          <div>
            <label className="common-header">{commonVariables.MERGE}</label>
          </div>

          <div className="d-flex gap-3">
            <div>
            <InputField placeholder={"Search"} className="merge-searchBox"  />
            </div>
            <div>
              <Newdropdown placeholder="1 Month" options={selectoptions}  className="merge-dropdown"/>
            </div>
            <div>
              <Button value="Export" role="export" />
            </div>
            <div className="merge-mergeBtn">
              <Button
                value="Merge"
                role="primary"
                onClick={() => dispatch(showBar(true))}
              />
              <SideBar data={<CreateMerge />} />
            </div>
          </div>
        </div>

        <CustomTable
          cols={[
            {
              id: "no",
              title: "No.",
            },
            {
              id: "batch_id",
              title: "Batch ID",
            },
            {
              id: "mandate",
              title: "Mandate",
            },
            {
              id: "bank",
              title: "Bank",
            },
            {
              id: "records",
              title: "Total Records",
            },
            {
              id: "date_time",
              title: "Date & Time",
            },
            {
              id: "name",
              title: "User Name",
            },
            {
              id: "sftp_push",
              title: "SFTP Push",
            },
            {
              id: "download",
              title: "Download",
            },
          ]}
          data={listdata}
          count={500}
          setPagination={(page) =>changePage(page)}
          activePage={pageNumber}
        />
      </div>
    </div>
  );
};
export default Merge;
