import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DateRangePicker from '../../../component/common/Daterangepicker/daterangepicker';
import Button from '../../../component/common/Button/button';
import CustomTable from '../../../component/customtable/CustomTable';
import './Reports.scss'
import { apiConstants, tableNamePaymentConstants } from '../../../constants/Common/apiConstants';
const Reports = () => {

    const [pageNumber, setPageNumber] = useState(0)
    const [listdata, setListdata] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [loading, setLoading] = useState(false);
    const handleDateChange = (sDate, eDate) => {
        setStartDate(sDate);
        setEndDate(eDate);
    };

    const downloadbtn = () => {
        return (
            <div>
                <button className=' downloadbtn pt-1 pe-2 pe-2 '><img src={""} className='pe-2 pb-1' />Download</button>
            </div>
        )
    }

    const Status = (status) => {
        return (
            <div>
                {status === 'NULL' ?
                    (
                        <div className=''>
                            <p style={{ margin: '2px' }}>Inprogress</p>
                            <div className="progress">
                                <div className='progress-bar ' role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="75"></div>
                            </div>
                        </div>
                    )
                    : (<p>Completed</p>)
                }
            </div>
        )
    }

    const listApi = (value) => {
        let temp = value ? (value - 1) * 10 : (pageNumber - 1) * 10;
        let data = JSON.stringify({
            "offset": temp,
            "limit": apiConstants?.limit,
            "tablename": tableNamePaymentConstants?.ManualMandate
        });
        setLoading(true);
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: apiConstants?.fetchAllData,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then(async (res) => {
                if (res.status === 200) {
                    let result = res?.data?.response;
                    result = result.map((item) => {
                        let newItem = {
                            sr_no: item?.id,
                            company_code: item?.company_code,
                            source_system: item?.source_system,
                            from_to_date: item?.date,
                            status: Status(item?.success),
                            action: downloadbtn(),
                        };
                        return newItem;
                    });
                    if (result?.length > 0) {
                        setListdata([...result]);
                    }

                }
            })
            .catch((er) => {
                console.log("err", er);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const changePage = (value) => {
        listApi(value);
        setPageNumber(value - 1);
    };
    useEffect(() => {
        listApi();
    }, []);


    return (
        <div>

            <div className="container">
                <div className="d-flex align-items-center justify-content-between mt-5 mb-4 mx-3">
                    <div className="right-div-header">
                        <div>
                            <label className="labelPresentation">Reports</label>
                        </div>
                    </div>
                    <div className="d-flex gap-3 left-div-header">

                        <DateRangePicker
                            handleDateChange={handleDateChange}
                            startDate={startDate}
                            endDate={endDate}
                            isClear={true}
                            monthsShown={"2"}
                            placeholderText="start date-end date "
                        />
                        <div className='text-nowrap reportsui'>
                        <Button value={"Clear Data"} role="secondary" />
                        </div>
                        
                    </div>
                </div>

                <CustomTable
                    cols={[
                        {
                            id: "sr_no",
                            title: "Sr.No.",
                            classStyle: {},
                        },
                        {
                            id: "company_code",
                            title: "Company Code",
                            classStyle: {},
                        },
                        {
                            id: "source_system",
                            title: "Source System",
                            classStyle: {},
                        },
                        {
                            id: "from_to_date",
                            title: "From Date - To Date",
                            classStyle: {},
                        },
                        {
                            id: "status",
                            title: "Status",
                            classStyle: {},
                        },
                        {
                            id: "action",
                            title: "Action",
                            classStyle: {},
                        },
                    ]}
                    data={listdata}
                    count={500}
                    setPagination={(page) => changePage(page)}
                    activePage={pageNumber}
                // loading={client.clientListLoading}
                />
            </div>
        </div>
    )

}
export default Reports;