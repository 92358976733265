import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  commonVariables,
  componentRole,
} from "../../../constants/Common/commonConstants";
import { Button as ButtonReact } from "react-bootstrap";
import Button from "../../common/Button/button";
import FormLabel from "../../common/formLabel/formLabel";
import "./changePassword.scss";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import apiCall from "../../../utils/apiFunction/apiCall";
import {
  apiConstants,
  tableNamePaymentConstants,
  toastIds,
} from "../../../constants/Common/apiConstants";
import { genSaltSync, hashSync } from "bcryptjs-react";
import { errorMessages } from "../../../constants/Common/errorConstants";
import { toast } from "react-toastify";
import { showBar } from "../../../redux/reducer/sideBarStatus";
import { useState } from "react";
import SystemXLoader from "../../common/Loader/SystemXLoader";

const FormSchema = Yup.object().shape({
  pass: Yup.string()
    .min(8, "Password must be 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a symbol"),
  confirm: Yup.string().oneOf(
    [Yup.ref("pass"), null],
    'Must match "password" field value'
  ),
});
const ChangePasswordSidebar = (props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmit = (value) => {
    var salt = genSaltSync(10);
    if (value?.pass && value?.confirm && props?.selectedUser?.user_code) {
      console.log("value", value);
      let query = {
        body: {
          change_password: 1,
          password: hashSync(value?.pass, salt),
        },
        tablename: tableNamePaymentConstants?.User,
      };
      query.whereQuery = {
        user_code: props?.selectedUser?.user_code,
      };
      setLoading(true);
      apiCall
        .post(apiConstants?.updateUser, query)
        .then(async (res) => {
          if (res?.data?.status === 200) {
            toast.success("Password changed successfully", {
              toastId: toastIds?.FileUploadSuccess,
            });
            dispatch(showBar(false))
          } else {
            toast.error(res.data.message || errorMessages.SOMETHING_WRONG, {
              toastId: toastIds?.DataError,
            });
          }
        })
        .catch((err) => {
          toast.error(err || errorMessages.SOMETHING_WRONG, {
            toastId: toastIds?.DataError,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      toast.error("Enter all values", {
        toastId: toastIds?.DataError,
      });
    }
  };
  return (
    <>
      {loading ? (
        <div className="loaderDiv">
          <SystemXLoader />
        </div>
      ) : (
        <div className="changepwdwrappersdb">
          <div className="password-card">
            <Formik
              initialValues={{
                pass: "",
                confirm: "",
              }}
              validationSchema={FormSchema}
              onSubmit={(value) => {
                handleSubmit(value);
              }}
            >
              {/* <div id="assignRole"> */}
              {({ errors }) => (
                <Form>
                  <div className="large_f px-4">
                    <div className="d-flex justify-content-end mr-3">
                      <Button
                        role={componentRole?.TRANSPARENT_CANCEL || ""}
                        onClick={() => dispatch(showBar(false))}
                      />
                    </div>
                    <div className="d-flex justify-content-start demergeLabel f-5 mb-4">
                      <FormLabel
                        value={commonVariables?.CHANGE_PASSWORD || ""}
                      />
                    </div>

                    <div className="select-container medium_f_bold ">
                      <FormLabel value="Password" className="usertext" />

                      <Field
                        type="password"
                        name="pass"
                        className="InputField"
                        id="password"
                      ></Field>
                      <div className="errorMessage">
                        {errors.pass && <p>{errors.pass}</p>}
                      </div>
                    </div>

                    <div className="select-container medium_f_bold ">
                      <FormLabel
                        value="Confirm Password"
                        className="usertext"
                      />
                      <Field
                        type="password"
                        name="confirm"
                        className="InputField"
                        id="confirmpassword"
                      ></Field>
                      <div className="errorMessage">
                        {errors.confirm && <p>{errors.confirm}</p>}
                      </div>
                    </div>
                    {/* <div>
                    <label>
                      Password Confirm
                      <Field type="password" name="confirm" />
                    </label>
                    {errors.confirm && <p>{errors.confirm}</p>}
                  </div> */}
                    <div className="d-flex gap-4 mt-3">
                      <ButtonReact
                        className="customButtonGreenInverted"
                        disabled={loading}
                        onClick={() => dispatch(showBar(false))}
                      >
                        Cancel
                      </ButtonReact>
                      <ButtonReact
                        disabled={loading}
                        className="customButtonGreen"
                        type="submit"
                      >
                        Submit
                      </ButtonReact>
                    </div>
                  </div>
                </Form>
              )}
              {/* </div> */}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};

export default ChangePasswordSidebar;
