import React, { useEffect, useState } from "react";
import "./exportCollectionSponsor.scss";
import {
    WhereQuery,
    commonVariables,
    componentRole,
} from "../../../../../constants/Common/commonConstants";
import { useDispatch, useSelector } from "react-redux";
import { showBar } from "../../../../../redux/reducer/sideBarStatus";
import Button from "../../../../common/Button/button";
import FormLabel from "../../../../common/formLabel/formLabel";
import { toast } from "react-toastify";
import apiCall from "../../../../../utils/apiFunction/apiCall";
import { apiConstants, errMessage, successMsg, tableNamePaymentConstants, toastIds } from "../../../../../constants/Common/apiConstants";
import SystemXLoader from "../../../../common/Loader/SystemXLoader";
import moment from "moment";
import { Col, Row, Button as ButtonReact } from "react-bootstrap";

const ExportCollectionSponsor = () => {
    const dispatch = useDispatch();
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [formValues, setFormValues] = useState({
        manual_mandate: {
            value: "",
            error: "",
            required: true,
        },
        company_code: {
            value: "",
            error: "",
            required: true,
        },
    });
    const [loading, setLoading] = useState(false);
    const [companyOptions, setCompanyOptions] = useState([]);
    const [mandateOptions, setMandateOptions] = useState([]);
    const [whereQuery, setWhereQuery] = useState({
        company_code: '',
        mandate_type: ''
    })
    let LoginState = useSelector((state) => state.login);
    const getMandateList = () => {
        setLoading(true);
        apiCall
            .post(apiConstants?.findData, {
                tablename: tableNamePaymentConstants?.mandateType,
            })
            .then((res) => {
                let tempData = [...res?.data?.response];
                tempData = tempData.map((item) => {
                    return {
                        value: item.mandate_type,
                        label: item.mandate_name,
                    };
                });
                setMandateOptions([...tempData]);
            })
            .catch((err) => {
                setMandateOptions([]);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const getCompanyList = () => {
        setLoading(true);
        apiCall
            .post(apiConstants?.findData, {
                tablename: tableNamePaymentConstants?.Company,
                whereQuery: WhereQuery,
            })
            .then((res) => {
                let tempData = [...res?.data?.response];
                tempData = tempData.map((item) => {
                    return {
                        value: item.company_code,
                        label: item.company_name,
                    };
                });
                setCompanyOptions([...tempData]);
            })
            .catch((err) => {
                setCompanyOptions([]);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onChange = (e) => {
        let tempData = { ...formValues };
        tempData[e.target.name].value = e.target.value;
        e.target.value
            ? (tempData[e.target.name].error = false)
            : (tempData[e.target.name].error = true);
        setFormValues({ ...tempData });
    };

    // useEffect(() => {
    //   console.log(settlementDate,"<<settlement Date");
    // }, [settlementDate])

    const onSubmit = () => {
        let params = {
            tablename: tableNamePaymentConstants?.SponsorBank,
            attributes: ["company_code", "mandate_type", "bank_code", "bank_name"],
            month: "0",
            where: whereQuery
        }

        apiCall.post(apiConstants?.getXLSXFile, params).then((response) => {
            if (response.data.status == 200) {
                const link = document.createElement("a");
                link.href = response?.data?.response;
                link.click();      
                toast.success(
                    successMsg?.FileDownloadSuccessfully,
                    toastIds?.FileDownloadSucess
                );
            } else {
                toast.error(errMessage?.NoRecordPresent, {
                    toastId: toastIds?.DataError,
                });
            }
        });
    };



    const handleCancel = () => {
        setMandateOptions([]);
        setCompanyOptions([]);
        setLoading(false);
        setFormValues({
            manual_mandate: {
                value: "",
                error: "",
                required: true,
            },
            company_code: {
                value: "",
                error: "",
                required: true,
            },
        });
        setToDate();
        setFromDate();
    };
    useEffect(() => {
        getMandateList();
        getCompanyList();
    }, [formValues]);

    return (
        <>
            {loading ? (
                <div className="loaderDiv">
                    <SystemXLoader />
                </div>
            ) : (
                <>
                    <div className="d-flex justify-content-end mr-3">
                        <Button
                            role={componentRole?.TRANSPARENT_CANCEL || ""}
                            onClick={() => dispatch(showBar(false), handleCancel())}
                        />
                    </div>
                    <div className="d-flex justify-content-start demergeLabel f-5 ms-4">
                        <FormLabel value={"EXPORT"} />
                    </div>
                    <div className="m-4">
                        <div className="dynamicFormWrapper">
                            <Row className="g-3 gx-5 mb-5">
                                <Col lg={12} md={12} sm={12}>
                                    <div className="inputDiv mt-1">
                                        <p>
                                            Mandate Type<span className="textLabelRed">*</span>
                                        </p>
                                        <select
                                            name="manual_mandate"
                                            onChange={(e) => {
                                                setWhereQuery({ ...whereQuery, mandate_type: e.target.value })
                                                onChange(e)
                                            }}
                                            value={formValues.manual_mandate.value}
                                            className="form-select selectInput"
                                        >
                                            <option key="Please Select a Value" hidden value>
                                                Please Select a Value
                                            </option>
                                            {mandateOptions &&
                                                mandateOptions.map((value) => {
                                                    return (
                                                        <option value={value.value}>{value.label}</option>
                                                    );
                                                })}
                                        </select>
                                        {formValues.manual_mandate.error &&
                                            formValues.manual_mandate.required && (
                                                <p className="textLabelRed m-0 mt-2">
                                                    Mandate Type is required
                                                </p>
                                            )}
                                    </div>
                                </Col>
                                <Col lg={12} md={12} sm={12}>
                                    <div className="inputDiv mt-1">
                                        <p>
                                            Company Code <span className="textLabelRed">*</span>
                                        </p>
                                        <select
                                            name="company_code"
                                            onChange={(e) => {
                                                setWhereQuery({
                                                    ...whereQuery,
                                                    company_code: e.target.value
                                                })
                                                onChange(e)
                                            }}
                                            value={formValues.company_code.value}
                                            className="form-select selectInput"
                                        >
                                            <option key="Please Select a Value" hidden value>
                                                Please Select a Value
                                            </option>
                                            {companyOptions &&
                                                companyOptions.map((value) => {
                                                    return (
                                                        <option value={value.value}>{value.label}</option>
                                                    );
                                                })}
                                        </select>
                                        {formValues.company_code.error &&
                                            formValues.company_code.required && (
                                                <p className="textLabelRed m-0 mt-2">
                                                    Company Code is required
                                                </p>
                                            )}
                                    </div>
                                </Col>

                                <div className="d-flex gap-4">
                                    <ButtonReact
                                        className="customButtonGreenInverted"
                                        onClick={() => dispatch(showBar(false), handleCancel())}
                                    >
                                        Cancel
                                    </ButtonReact>
                                    <ButtonReact
                                        className="customButtonGreen"
                                        onClick={() => onSubmit()}
                                    >
                                        Export
                                    </ButtonReact>
                                </div>
                            </Row>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default ExportCollectionSponsor;
