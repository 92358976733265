import {PDFDownloadLink} from "@react-pdf/renderer"
import {ErrorMessage, Field, Form, Formik} from "formik"
import React, {useEffect, useRef, useState} from "react"
import DatePicker from "react-datepicker"
import {toast} from "react-toastify"
import Download from "../../../Images/download.svg"
import {object, string} from "yup"
import {
  apiConstants,
  attributePaymentConstants,
  getBankDetailsSingleAttributesMicr,
  getBankDetailsSingleAttributesIfsc,
  tableNamePaymentConstants,
  toastIds,
} from "../../../constants/Common/apiConstants"
import {YupMessages, commonVariables} from "../../../constants/Common/commonConstants"
import apiCall from "../../../utils/apiFunction/apiCall"
import FormLabel from "../../common/formLabel/formLabel"
import FormikSelect from "../../common/formikSelect/formikSelect"
import "./BankDetails.scss"
import GeneratePDF from "./PDFDocument/PDFDocument"
function sanitizeSpecialCharacters(value) {
  const sanitizedValue = value
    .replace(/(https?:\/\/[^\s]+)/, "")
    .replace(/[!@#$%^&*(),;:'.?"{}",|<>_+=\]\-]/g, "")
  return sanitizedValue
}

function sanitizeLettersAndSpecialCharacters(input) {
  return input
    .replace(/(https?:\/\/[^\s]+)/, "")
    .replace(/[a-zA-\]\Z!@#$%^&*(),;:'.?"{}|<>_+=\/-]/, "")
}

function sanitizeNumberAndSpecialCharacters(value) {
  const sanitizedValue = value
    .replace(/(https?:\/\/[^\s]+)/, "")
    .replace(/[^a-zA-Z/\s/g]/g, "")
    .replace(/\s\s+/g, " ")
  return sanitizedValue
}

function BankDetails(props) {
  const isSubmitted = useRef(false)
  const {handleBack, handleNext, counter, pdfResponse} = props
  const [accountTypeValues, setAccountTypeValues] = useState([])
  const [disableValues, setDisableValues] = useState(false)

  const DatePickerField = ({name, value, onChange}) => {
    return (
      <DatePicker
        selected={(value && new Date(value)) || null}
        onChange={(val) => {
          onChange(name, val)
        }}
      />
    )
  }

  const formRef = React.useRef(null)
  useEffect(() => {
    getAccountTypeValues()
  }, [])

  const handleInputChangeForSpecialCharacters = (event, field) => {
    const sanitizedValue = sanitizeSpecialCharacters(event.target.value)

    field.onChange({
      target: {
        name: field.name,
        value: sanitizedValue,
      },
    })
  }

  const getBankValuesForIfsc = (val) => {
    const {setFieldValue, validateForm, setTouched, setFieldError} = formRef.current
    if (val?.length >= 11) {
      apiCall
        .post(apiConstants?.findData, {
          tablename: tableNamePaymentConstants?.micr,
          whereQuery: {
            ifsc_code: val,
          },
          attributes: getBankDetailsSingleAttributesIfsc,
        })
        .then(async (res) => {
          if (res?.data?.status == 200) {
            const response = res?.data?.response[0]
            setFieldValue("micr_code", response?.micr_code)
            setFieldValue("bank_name", response?.bank_name)
            setFieldValue("branch_code", response?.branch_code)
            setFieldValue("city", response?.city)
            setFieldError("bank_name", "")
            setFieldError("branch_code", "")
            setFieldError("city", "")
            setTouched({})
            validateForm()
          } else {
            setFieldValue("micr_code", "")
            setFieldValue("bank_name", "")
            setFieldValue("branch_code", "")
            setFieldValue("city", "")
            toast.error(res?.data?.message, {
              toastId: toastIds?.DataError,
            })
          }
        })
        .catch((er) => {
          setFieldValue("micr_code", "")
          setFieldValue("bank_name", "")
          setFieldValue("branch_code", "")
          setFieldValue("city", "")
          toast.error(er?.message, {toastId: toastIds?.DataError})
        })
    } else {
      setFieldValue("micr_code", "")
      setFieldValue("bank_name", "")
      setFieldValue("branch_code", "")
      setFieldValue("city", "")
    }
  }

  const handleInputChangeForSpecialCharactersAndNumbers = (event, field) => {
    const sanitizedValue = sanitizeNumberAndSpecialCharacters(event.target.value)

    field.onChange({
      target: {
        name: field.name,
        value: sanitizedValue,
      },
    })
  }

  const handleInputChangeForSpecialCharactersAndLetters = (event, field, type) => {
    const sanitizedValue = sanitizeLettersAndSpecialCharacters(event.target.value)
    field.onChange({
      target: {
        name: field.name,
        value: sanitizedValue.trim(),
      },
    })
    // if (type == commonVariables?.Micr) {
    // }
  }

  const getBankValuesForMicr = (val) => {
    const {setFieldValue, validateForm, setTouched, setFieldError} = formRef.current
    if (val?.length >= 9) {
      apiCall
        .post(apiConstants?.findData, {
          tablename: tableNamePaymentConstants?.micr,
          whereQuery: {
            micr_code: val,
          },
          attributes: getBankDetailsSingleAttributesMicr,
        })
        .then(async (res) => {
          if (res?.data?.status == 200) {
            const response = res?.data?.response[0]
            setFieldValue("ifsc_code", response?.ifsc_code)
            setFieldValue("bank_name", response?.bank_name)
            setFieldValue("branch_code", response?.branch_code)
            setFieldValue("city", response?.city)
            setFieldError("ifsc_code", "")
            setFieldError("bank_name", "")
            setFieldError("branch_code", "")
            setFieldError("city", "")
            setTouched({})
            validateForm()
          } else {
            setFieldValue("ifsc_code", "")
            setFieldValue("bank_name", "")
            setFieldValue("branch_code", "")
            setFieldValue("city", "")
            toast.error(res?.data?.message, {
              toastId: toastIds?.DataError,
            })
          }
        })
        .catch((er) => {
          setFieldValue("ifsc_code", "")
          setFieldValue("bank_name", "")
          setFieldValue("branch_code", "")
          setFieldValue("city", "")
          toast.error(er?.message, {toastId: toastIds?.DataError})
        })
    } else {
      setFieldValue("ifsc_code", "")
      setFieldValue("bank_name", "")
      setFieldValue("branch_code", "")
      setFieldValue("city", "")
    }
  }

  const getAccountTypeValues = () => {
    let paramObj = {
      tablename: tableNamePaymentConstants?.AccountType,
      attributes: [attributePaymentConstants?.AccountName],
      where: {
        isActive: 1,
      },
    }
    apiCall
      .post(apiConstants?.findData, paramObj)
      .then(async (res) => {
        let result = res?.data?.response
        // let formatedData = result?.filter(
        //   (data) => data.company_code == 7000 || data.company_code == 9000
        // );
        result = result.map((item) => {
          let newItem = {
            key: item?.account_name,
            value: item?.account_name,
          }
          return newItem
        })
        setAccountTypeValues(result)
      })
      .catch((er) => {
        console.log("errr =>>>>", er)
      })
  }

  let validSchema = object({
    account_type: string().required(YupMessages?.AccountTypeRequired),
  })
  const onContinue = (values) => {
    setDisableValues(true)
    return handleNext(JSON.stringify(values))
    // return handleNext();
  }

  const validateAccountNumber = (val) => {
    let error
    if (!val) {
      error = YupMessages?.AccountNumberRequired
    } else if (/^\s*$/.test(val)) {
      error = YupMessages?.invalidAccountNumber
    }
    return error
  }

  const validateAccountHolderName = (val) => {
    let error
    if (!val) {
      error = YupMessages?.AccountHolderRequired
    } else if (/^\s*$/.test(val)) {
      error = YupMessages?.invalidAccountHolderName
    }
    return error
  }

  const validateMicrNo = (val) => {
    let error
    if (val && /^\s*$/.test(val)) {
      error = YupMessages?.invalidMicrCode
    }
    return error
  }

  const validateIFSCCode = (val) => {
    let error
    if (!val) {
      error = YupMessages?.IFSCCodeIsRequired
    } else if (/^\s*$/.test(val)) {
      error = YupMessages?.invalidIfscCode
    }
    return error
  }

  const validateBankName = (val) => {
    let error
    if (val && /^\s*$/.test(val)) {
      error = YupMessages?.invalidBankName
    }
    return error
  }

  const validateBranchCode = (val) => {
    let error
    if (val && /^\s*$/.test(val)) {
      error = YupMessages?.invalidBranchCode
    }
    return error
  }

  const validateCity = (val) => {
    let error
    if (val && /^\s*$/.test(val)) {
      error = YupMessages?.invalidCity
    }
    return error
  }

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={{
          account_holder_name: "",
          account_number: "",
          account_type: "",
          micr_code: "",
          ifsc_code: "",
          bank_name: "",
          branch_code: "",
          city: "",
        }}
        validationSchema={validSchema}
        onSubmit={onContinue}>
        <Form>
          <div className="row bank_details">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2 pt-0">
              <div className="field_name">
                <FormLabel value="Account Number As Per Mandate" className="required" />
              </div>
              <Field name="account_number" validate={validateAccountNumber}>
                {({field}) => (
                  <div>
                    <input
                      type="text"
                      id="account_number"
                      name={field.name}
                      value={field.value}
                      className="InputField"
                      onChange={(e) => {
                        field.onChange(e)
                        handleInputChangeForSpecialCharactersAndLetters(
                          e,
                          field,
                          commonVariables?.ACCOUNT_NUMBER,
                        )
                      }}
                      placeholder="Account Number As Per Mandate"
                      disabled={disableValues}
                      // onChange={sanitizeSpecialCharacters}
                    />
                    <div className="errorMessage">
                      <ErrorMessage name="account_number" />
                    </div>
                  </div>
                )}
              </Field>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2 pt-0">
              <div className="field_name">
                <FormLabel value="Account holder name" className="required" />
              </div>
              <Field name="account_holder_name" validate={validateAccountHolderName}>
                {({field}) => (
                  <div>
                    <input
                      type="text"
                      id="account_holder_name"
                      name={field.name}
                      value={field.value}
                      className="InputField"
                      disabled={disableValues}
                      onChange={(e) => {
                        field.onChange(e)
                        handleInputChangeForSpecialCharactersAndNumbers(e, field)
                      }}
                      placeholder="Account holder name"
                      // onChange={sanitizeSpecialCharacters}
                    />
                    <div className="errorMessage">
                      <ErrorMessage name="account_holder_name" />
                    </div>
                  </div>
                )}
              </Field>
            </div>
            <div className="singleValidationDropdown col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2">
              <div className="field_name">
                <FormLabel value="Account Type" className="required" />
              </div>
              <FormikSelect
                label="select account Type"
                name="account_type"
                options={accountTypeValues}
                showPlaceHolder
                disabled={disableValues}
                placeholder="Please select Account Type"
              />
              <div className="errorMessage">
                <ErrorMessage name="account_type" />
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2">
              <div className="field_name">
                <FormLabel value="MICR Code" />
              </div>
              <Field name="micr_code" validate={validateMicrNo}>
                {({field}) => (
                  <div>
                    <input
                      type="text"
                      id="micr_code"
                      name={field.name}
                      value={field.value}
                      className="InputField"
                      onChange={(e) => {
                        field.onChange(e)
                        handleInputChangeForSpecialCharactersAndLetters(
                          e,
                          field,
                          commonVariables?.Micr,
                        )
                        getBankValuesForMicr(e.target.value)
                      }}
                      disabled={disableValues}
                      placeholder="MICR Code"
                    />
                    <div className="errorMessage">
                      <ErrorMessage name="micr_code" />
                    </div>
                  </div>
                )}
              </Field>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2">
              <div className="field_name">
                <FormLabel value="IFSC Code" className="required" />
              </div>
              <Field name="ifsc_code" validate={validateIFSCCode}>
                {({field}) => (
                  <div>
                    <input
                      type="text"
                      id="ifsc_code"
                      name={field.name}
                      value={field.value}
                      className="InputField"
                      disabled={disableValues}
                      onChange={(e) => {
                        field.onChange(e)
                        handleInputChangeForSpecialCharacters(e, field)
                        getBankValuesForIfsc(e.target.value)
                      }}
                      placeholder="IFSC Code"
                    />
                    <div className="errorMessage">
                      <ErrorMessage name="ifsc_code" />
                    </div>
                  </div>
                )}
              </Field>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2">
              <div className="field_name">
                <FormLabel value="Bank Name" />
              </div>
              <Field name="bank_name" validate={validateBankName}>
                {({field}) => (
                  <div>
                    <input
                      type="text"
                      id="bank_name"
                      name={field.name}
                      value={field.value}
                      className="InputField"
                      onChange={(e) => {
                        field.onChange(e)
                        handleInputChangeForSpecialCharacters(e, field)
                      }}
                      placeholder="Bank Name"
                      disabled
                    />
                    <div className="errorMessage">
                      <ErrorMessage name="bank_name" />
                    </div>
                  </div>
                )}
              </Field>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2">
              <div className="field_name">
                <FormLabel value="Branch Code" />
              </div>
              <Field name="branch_code" validate={validateBranchCode}>
                {({field}) => (
                  <div>
                    <input
                      type="text"
                      id="branch_code"
                      name={field.name}
                      value={field.value}
                      className="InputField"
                      onChange={(e) => {
                        field.onChange(e)
                        handleInputChangeForSpecialCharacters(e, field)
                      }}
                      placeholder="Branch Code"
                      disabled
                    />
                    <div className="errorMessage">
                      <ErrorMessage name="branch_code" />
                    </div>
                  </div>
                )}
              </Field>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2">
              <div className="field_name">
                <FormLabel value="City" />
              </div>
              <Field name="city" validate={validateCity}>
                {({field}) => (
                  <div>
                    <input
                      type="text"
                      id="city"
                      name={field.name}
                      value={field.value}
                      className="InputField"
                      onChange={(e) => {
                        field.onChange(e)
                        handleInputChangeForSpecialCharacters(e, field)
                      }}
                      disabled
                      placeholder="City"
                    />
                    <div className="errorMessage">
                      <ErrorMessage name="city" />
                    </div>
                  </div>
                )}
              </Field>
            </div>
            {!disableValues && (
              <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 p-2 ">
                <button type="button" className="bottombtn_backBtn" onClick={() => handleBack()}>
                  Back
                </button>
              </div>
            )}
            {props?.isSingleVerify ? (
              <>
                {!disableValues && (
                  <div className=" col-xs-12 col-sm-12 col-md-2 col-lg-2 p-2">
                    <button type="submit" className="bottombtn_submitBtn">
                      Validate
                    </button>
                  </div>
                )}
              </>
            ) : (
              <div className=" col-xs-12 col-sm-12 col-md-2 col-lg-2 p-2">
                <button className="bottombtn_submitBtn" value="Next" type="submit">
                  submit
                </button>
              </div>
            )}
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 p-2">
              {counter !== null ? (
                <>
                  {counter > 0 ? (
                    <button className="bank_details-btnCounter" disabled>
                      {counter > 0 ? `Please wait... ${counter}` : "Download"}
                    </button>
                  ) : (
                    <PDFDownloadLink
                      document={<GeneratePDF response={pdfResponse} />}
                      fileName="Customer receipt"
                      className="bank_details-btn-pdfLink">
                      <img src={Download} alt="download" className="deleteIcon" />
                      Download
                    </PDFDownloadLink>
                  )}
                </>
              ) : null}
            </div>{" "}
          </div>
        </Form>
      </Formik>
    </>
  )
}
export default BankDetails
