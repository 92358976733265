import React, { useEffect, useState } from "react";
import CustomTable from "../../../component/customtable/CustomTable";
import Button from "../../../component/common/Button/button";
import apiCall from "../../../utils/apiFunction/apiCall";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../../constants/Routes";
import info from '../../../Images/info.svg'
import {
  apiConstants,
  DtRepresentationTableOrder,
  FOMRS,
  tableNamePaymentConstants,
  viewMandateAttributes,
} from "../../../constants/Common/apiConstants";
import {
  EnachStatusCode,
  commonVariables,
  selectoptions,
  componentRole,
  toolTipMessages
} from "../../../constants/Common/commonConstants";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import { toast } from "react-toastify";
import "./viewMandate.scss";
import CustomTooltip from "../../../component/Tooltip/Tooltip";

const ViewMandate = () => {
  const [selectMonth, setSelectMonth] = useState(1);
  const [count, setCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [listdata, setListdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [downloadFlag, setdownloadFlag] = useState(false);

  const navigate = useNavigate();

  const listApi = (value) => {
    setdownloadFlag(true);
    let temp = 0;
    if ((value || pageNumber) > 0) {
      temp = value ? (value - 1) * 10 : (pageNumber - 1) * 10;
    }
    setLoading(true);
    const params = {
      tablename: tableNamePaymentConstants?.ManualMandate,
      month: selectMonth,
      offset: temp || 0,
      attributes: viewMandateAttributes,
      limit: apiConstants?.limit,
      whereQuery: { remark: "Not banked", enach_type: 1 },
      order: DtRepresentationTableOrder,
    };

    apiCall
      .post(apiConstants?.fetchAllData, params)
      .then((res) => {
        if (res?.status === 200) {
          let result = res?.data?.response;
          setCount(res?.data?.count || 0)
          result = result.map((item, index) => {
            let newItem = {
              id: temp + index + 1,
              batch_id: item?.batch_id,
              loan_no: item?.loan_no,
              product_type: item?.product_type,
              mandate_status:
                item?.mandate_status === EnachStatusCode.FAIL_STATUS_CODE
                  ? commonVariables?.Failed
                  : item?.mandate_status === EnachStatusCode.SUCCESS_STATUS_CODE
                    ? commonVariables?.Success
                    : commonVariables?.Pending,
              action: (
                <div>
                  <Button
                    onClick={() => {
                      navigate(ROUTE_PATH.SINGLE_MANDATE, {
                        state: {
                          type: FOMRS.EDIT,
                          id: item.id,
                        },
                      });
                    }}
                    value='View'
                    role={componentRole.CANCEL}
                  />
                </div>
              ),
            };
            return newItem;
          });
          if (result?.length > 0) {
            setListdata([...result]);
          }
        }
      })
      .catch((er) => { })
      .finally(() => {
        setdownloadFlag(false);
        setLoading(false);
      });
  };

  const changePage = (value) => {
    listApi(value);
    setPageNumber(value - 1);
  };
  useEffect(() => {
    listApi();
  }, [selectMonth]);

  return (
    <>
      <div className="mx-3 mt-4">
        {downloadFlag && (
          <div className="d-flex justify-content-center d-flex align-items-center loaderDiv">
            <SystemXLoader />
          </div>
        )}
        <div className="d-flex align-items-center justify-content-between flex-wrap mb-3 ">
          <div className="right-div-header ">
            <div className="mt-1">
              <label className="text-nowrap pl-0 common-header">View Mandate</label>
            </div>
          </div>
          <div className="d-flex flex-wrap gap-3 left-div-header">
            <div>
              <select
                value={selectMonth}
                onChange={(e) => {
                  setSelectMonth(e?.target?.value);
                }}
                className="form-select selectInput cursor-pointer"
              >
                <option key="Select Month" hidden value>
                  Select Month
                </option>
                {selectoptions?.length &&
                  selectoptions.map((value) => {
                    return (
                      <option value={value.value}>{value.label}</option>
                    );
                  })}
              </select>
            </div>
            <div>
            </div>
          </div>
        </div>
        <div className="dtMergeTable">
          <CustomTable
            cols={[
              {
                id: "id",
                title: "Sr.No",
              },
              {
                id: "batch_id",
                title: "Batch Id",
              },
              {
                id: "loan_no",
                title: "Loan Number",
              },
              {
                id: "product_type",
                title: "Product Type",
              },
              {
                id: "mandate_status",
                title: (
                  <>
                    Mandate Status
                    <CustomTooltip
                      title={toolTipMessages?.mandateStatus}
                      position="bottom"
                      id='info'
                    >
                      <img src={info} alt="info" className="infoIcon mt-0"></img>
                    </CustomTooltip>
                  </>
                ),
              },
              {
                id: "action",
                title: "Action",
              },
            ]}
            data={listdata}
            count={count}
            setPagination={(page) => changePage(page)}
            activePage={pageNumber}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
};

export default ViewMandate;
