import React, { useState, useEffect } from "react";
import "./DtMergeForm.scss";

import {
  Button as ButtonReact,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import {
  commonVariables,
  componentRole,
  bankWiseDate,
  DSD,
  PresentationOptions,
  consolidateBank,
  autoDebit,
  rePresentation,
  presentation,
  representaionBankDateError,
  dateFormats,
  WhereQuery,
} from "../../../../constants/Common/commonConstants";
import { useDispatch, useSelector } from "react-redux";
import { showBar } from "../../../../redux/reducer/sideBarStatus";
import Button from "../../../../component/common/Button/button";
import SystemXLoader from "../../../../component/common/Loader/SystemXLoader";
import FormLabel from "../../../../component/common/formLabel/formLabel";
import DynamicForm from "../../../../component/common/DynamicForm/dynamicForm";
import { toast } from "react-toastify";
import moment from "moment";
import apiCall from "../../../../utils/apiFunction/apiCall";
import ReactDatePicker from "react-datepicker";
import {
  apiConstants,
  errMessage,
  successMsg,
  tableNamePaymentConstants,
  toastIds,
} from "../../../../constants/Common/apiConstants";

const DebitTransactionMergeForm = (props) => {
  const dispatch = useDispatch();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [formValues, setFormValues] = useState({
    sponsor_bank: {
      value: "",
      error: "",
      required: true,
    },
    manual_mandate: {
      value: "",
      error: "",
      required: true,
    },
    presentation_type: {
      value: "",
      error: "",
      required: true,
    },
    company_code: {
      value: "",
      error: "",
      required: true,
    },
  });
  const [triggerRadioBtn, setTriggerRadioBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const [settlementDate, setSettlementDate] = useState();
  const [companyOptions, setCompanyOptions] = useState([]);
  const [mandateOptions, setMandateOptions] = useState([]);
  const [sponsorOptions, setSponsorOptions] = useState([]);
  const [isFixedChoice, setIsFixedChoice] = useState(true);
  const [bankDates, setBankDates] = useState();
  let LoginState = useSelector((state) => state.login);
  const [buttonClick, setButtonClick] = useState(false);

  const getMandateList = () => {
    setLoading(true);
    apiCall
      .post(apiConstants?.findData, {
        tablename: tableNamePaymentConstants?.mandateType,
      })
      .then((res) => {
        let tempData = [...res?.data?.response];
        tempData = tempData.map((item) => {
          return {
            value: item.mandate_type,
            label: item.mandate_name,
          };
        });
        setMandateOptions([...tempData]);
      })
      .catch((err) => {
        setMandateOptions([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getCompanyList = () => {
    setLoading(true);
    apiCall
      .post(apiConstants?.findData, {
        tablename: tableNamePaymentConstants?.Company,
        whereQuery: WhereQuery,
      })
      .then((res) => {
        let tempData = [...res?.data?.response];
        tempData = tempData.map((item) => {
          return {
            value: item.company_code,
            label: item.company_name,
          };
        });
        setCompanyOptions([...tempData]);
      })
      .catch((err) => {
        setCompanyOptions([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getSponsorBank = (value) => {
    if (value) {
      setLoading(true);
      apiCall
        .post(apiConstants?.findData, {
          tablename: tableNamePaymentConstants?.SponsorBank,
          whereQuery: {
            mandate_type: value,
          },
        })
        .then((res) => {
          let tempData = [];
          if (res.data.status === 404) {
            toast.error(errMessage?.noDataForSelectedMandateType, {
              toastId: toastIds?.DataError,
            });
          } else {
            tempData = [...res?.data?.response];
            tempData = tempData.map((item) => {
              return {
                value: `${item.bank_code}--${item.bank_name}`,
                label: item.bank_name,
              };
            });
          }
          setSponsorOptions([...tempData]);
        })
        .catch((err) => {
          setSponsorOptions([]);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setSponsorOptions([]);
    }
  };

  const onChange = (e) => {
    let tempData = { ...formValues };
    tempData[e.target.name].value = e.target.value;
    e.target.value
      ? (tempData[e.target.name].error = false)
      : (tempData[e.target.name].error = true);
    setFormValues({ ...tempData });
  };

  // useEffect(() => {
  //   console.log(settlementDate,"<<settlement Date");
  // }, [settlementDate])

  const onSubmit = () => {
    let userData = LoginState?.decodedData;
    let tempDates = bankDates.filter(data => data.date) // Filter out entries with empty or falsy dates
    .map(data => ({
    bankName: data.bankName,
    date: moment(data.date).format(dateFormats?.dmy),
    }));

    let splitData = formValues.sponsor_bank.value.split('--')
    let apiBody = {
      mandate_type: formValues.manual_mandate.value || "",
      bank_code: splitData[0] || "",
      createdAtFrom: fromDate ? moment(fromDate).format(dateFormats?.dmy) : "",
      createdAtTo: toDate ? moment(toDate).format(dateFormats?.dmy) : "",
      presentation_type: formValues.presentation_type.value || "",
      company_code: formValues.company_code.value || "",
      typeOfDateSelection: isFixedChoice
        ? commonVariables?.fsd
        : commonVariables?.dsd || "",
      settlement_date: settlementDate
        ? moment(settlementDate).format(dateFormats?.dmy)
        : "",
      bankWiseDate:isFixedChoice === false ? tempDates || "" : "",
      user_name: userData?.user_name || "",
      user_code: userData?.user_code || "",
    };
    setApiLoading(true);
    apiCall
      .post(apiConstants?.DtMerge, apiBody)
      .then(async (res) => {
        if (res?.data?.status === 200) {
          toast.success(
            res?.data?.response?.message,
            toastIds?.FileUploadSuccess
          );
          handleCancel();
          dispatch(showBar(false));
        }
        else if (res?.data?.status === 400) {
          toast.error(
            res?.data?.response?.message,
            toastIds?.FileUploaderr
          );
          handleCancel();
          dispatch(showBar(false));
        }
        else{
          toast.error(
            res?.data?.response?.message
            || errMessage?.somethingWentWrong,
            toastIds?.FileUploaderr
          );
          setButtonClick(false);
        }
      })
      .catch((er) => {
        let resp = er?.message || errMessage?.somethingWentWrong;
        toast.error(resp, toastIds?.FileUploaderr);
        setButtonClick(false);
      })
      .finally(() => {
        setApiLoading(false);
      });
  };

  const submitForm = () => {
    if(isFixedChoice === true) {
      if(settlementDate === undefined || toDate === undefined || fromDate ===undefined){
        toast.error("Fill all required fields", { toastId: "uploaderror" });
      }
     else if (!validate()) {
        setButtonClick(true);
        onSubmit();
      } else {
        toast.error("Fill all required fields", { toastId: "uploaderror" });
      }
    } else {
      if(toDate === undefined || fromDate ===undefined){
        toast.error("Fill all required fields", { toastId: "uploaderror" });
      }
     else if (!validate()) {
        setButtonClick(true);
        onSubmit();
      } else {
        toast.error("Fill all required fields", { toastId: "uploaderror" });
      }
    }
   
  };
  const validate = () => {
    let error = false;
    let tempForm = { ...formValues };
    Object.entries(tempForm).forEach((value) => {
      if (value[1].required) {
        if (value[1].value) {
          tempForm[value[0]].error = false;
        } else {
          tempForm[value[0]].error = true;
          error = true;
        }
      }
      return value;
    });
    setFormValues({ ...tempForm });
    return error;
  };
  const setRadioType = (type) => {
    if (type == commonVariables?.fixed) {
      setIsFixedChoice(true);
    } else {
      setIsFixedChoice(false);
    }
  };

  const setFileValue = () => {
    if (
      formValues.manual_mandate.value.toLowerCase() == autoDebit &&
      formValues.sponsor_bank.value.toLowerCase() == consolidateBank
    ) {
      setTriggerRadioBtn(true);
    } else {
      setTriggerRadioBtn(false);
      setIsFixedChoice(true);
    }
  };
  const handleCancel = () => {
    setButtonClick(false);
    props?.onCancel();
    setMandateOptions([]);
    setCompanyOptions([]);
    setSponsorOptions([]);
    setSettlementDate();
    setIsFixedChoice(true);
    setLoading(false);
    setBankDates();
    setTriggerRadioBtn(false);
    setFormValues({
      sponsor_bank: {
        value: "",
        error: "",
        required: true,
      },
      manual_mandate: {
        value: "",
        error: "",
        required: true,
      },
      presentation_type: {
        value: "",
        error: "",
        required: true,
      },
      company_code: {
        value: "",
        error: "",
        required: true,
      },
    });
    setToDate();
    setFromDate();
  };
  useEffect(() => {
    getMandateList();
    getCompanyList();
    setDateValues();
    setFileValue();
  }, [formValues]);

  const setDateValues = () => {
    let tempForm = bankWiseDate.map((data, index) => ({
      bankName: data.bankName,
      date: '',
    }));
    setBankDates(tempForm);
  };

  // useEffect(() => {

  // }, [
  //   formValues.manual_mandate.value,
  //   formValues.sponsor_bank.value,
  //   formValues.presentation_type.value,
  // ]);

  return (
    <>
      {(loading || apiLoading) ? (
        <div className="loaderDiv">
          <SystemXLoader />
        </div>) : 
        (<>
          <div className="d-flex justify-content-end mr-3">
            <Button
              role={componentRole?.TRANSPARENT_CANCEL || ""}
              onClick={() => dispatch(showBar(false), handleCancel())}
            />
          </div>
          <div className="d-flex justify-content-start demergeLabel f-5 ms-4">
            <FormLabel value={commonVariables?.MERGE || ""} />
          </div>
          <div className="m-4">
            <div className="dynamicFormWrapper">
              <Row className="g-3 gx-5 mb-5">
                <Col lg={12} md={12} sm={12}>
                  <div className="inputDiv mt-1">
                    <p>
                      Manual Mandate <span className="textLabelRed">*</span>
                    </p>
                    <select
                      name="manual_mandate"
                      onChange={(e) => {
                        getSponsorBank(e.target.value);
                        onChange(e);
                      }}
                      value={formValues.manual_mandate.value}
                      className="form-select selectInput"
                    >
                      <option key="Please Select a Value" hidden value>
                        Please Select a Value
                      </option>
                      {mandateOptions &&
                        mandateOptions.map((value) => {
                          return (
                            <option value={value.value}>{value.label}</option>
                          );
                        })}
                    </select>
                    {formValues.manual_mandate.error &&
                      formValues.manual_mandate.required && (
                        <p className="textLabelRed m-0 mt-2">
                          Mandate Type is required
                        </p>
                      )}
                  </div>
                </Col>
                <Col lg={12} md={12} sm={12}>
                  <div className="inputDiv mt-1">
                    <p>
                      Sponsor Bank <span className="textLabelRed">*</span>
                    </p>
                    <select
                      name="sponsor_bank"
                      onChange={(e) => onChange(e)}
                      onClick={() => {
                        if (!sponsorOptions?.length) {
                          if (!formValues.manual_mandate.value) {
                            toast.error("First select a mandate type ", {
                              toastId: "mandTypeselect",
                            });
                          } else {
                            toast.error(
                              "No data is present for selected mandate type",
                              { toastId: "noData" }
                            );
                          }
                        }
                      }}
                      value={formValues.sponsor_bank.value}
                      className="form-select selectInput"
                    >
                      <option key="Please Select a Value" hidden value>
                        Please Select a Value
                      </option>
                      {sponsorOptions &&
                        sponsorOptions.map((value) => {
                          return (
                            <option value={value.value}>{value.label}</option>
                          );
                        })}
                    </select>
                    {formValues.sponsor_bank.error &&
                      formValues.sponsor_bank.required && (
                        <p className="textLabelRed m-0 mt-2">
                          Sponsor Bank is required
                        </p>
                      )}
                  </div>
                </Col>
                <Col lg={12} md={12} sm={12}>
                  <div className="inputDiv mt-1">
                    <p>
                      From Date <span className="textLabelRed">*</span>
                    </p>
                    <ReactDatePicker
                      className="customDatepicker"
                      selected={fromDate}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => {
                        setFromDate(date);
                      }}
                    />
                  </div>
                </Col>
                <Col lg={12} md={12} sm={12}>
                  <div className="inputDiv mt-1">
                    <p>
                      To Date <span className="textLabelRed">*</span>
                    </p>
                    <ReactDatePicker
                      className="customDatepicker"
                      selected={toDate}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => {
                        setToDate(date);
                      }}
                    />
                  </div>
                </Col>
                <Col lg={12} md={12} sm={12}>
                  <div className="inputDiv mt-1">
                    <p>
                      Presentation Type <span className="textLabelRed">*</span>
                    </p>
                    <select
                      name="presentation_type"
                      onChange={(e) => onChange(e)}
                      value={formValues.presentation_type.value}
                      className="form-select selectInput"
                    >
                      <option key="Please Select a Value" hidden value>
                        Please Select a Value
                      </option>
                      {PresentationOptions &&
                        PresentationOptions.map((value) => {
                          return <option value={value.key}>{value.key}</option>;
                        })}
                    </select>
                    {formValues.presentation_type.error &&
                      formValues.presentation_type.required && (
                        <p className="textLabelRed m-0 mt-2">
                          Presentation Type is required
                        </p>
                      )}
                  </div>
                </Col>
                <Col lg={12} md={12} sm={12}>
                  <div className="inputDiv mt-1">
                    <p>
                      Company Code <span className="textLabelRed">*</span>
                    </p>
                    <select
                      name="company_code"
                      onChange={(e) => onChange(e)}
                      value={formValues.company_code.value}
                      className="form-select selectInput"
                    >
                      <option key="Please Select a Value" hidden value>
                        Please Select a Value
                      </option>
                      {companyOptions &&
                        companyOptions.map((value) => {
                          return (
                            <option value={value.value}>{value.label}</option>
                          );
                        })}
                    </select>
                    {formValues.company_code.error &&
                      formValues.company_code.required && (
                        <p className="textLabelRed m-0 mt-2">
                          Company Code is required
                        </p>
                      )}
                  </div>
                </Col>
                <Col lg={12} md={12} sm={12}>
                    {isFixedChoice&&
                  <div className="inputDiv mt-1">
                    <p>
                      Settlement Date <span className="textLabelRed">*</span>
                    </p>
                    <ReactDatePicker
                      className="customDatepicker"
                      selected={settlementDate}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => {
                        setSettlementDate(date);
                      }}
                      />
                  </div>
                  }
                </Col>
                {triggerRadioBtn && (
                  <Col lg={12} md={12} sm={12}>
                    <div className="inputDiv mt-1">
                      <p>
                        Select Choice <span className="textLabelRed">*</span>
                      </p>

                      <Form>
                        <Form.Check
                          inline
                          label="Fixed Settlement Date"
                          name="group1"
                          type="radio"
                          checked={isFixedChoice}
                          onChange={() => setRadioType("fixed")}
                          id={`inline-radio-1`}
                        />
                        <Form.Check
                          inline
                          label="Different Settlement Date"
                          name="group1"
                          checked={!isFixedChoice}
                          type="radio"
                          onChange={() => setRadioType("different")}
                          id={`inline-radio-2`}
                        />
                      </Form>
                    </div>
                  </Col>
                )}
                {!isFixedChoice && (
                  <>
                    <div className="inputDivDemerge mt-1">
                      <p> Bank List</p>
                      <div className="bankList_bankWrapper w-100 pr-0">
                        <div className="bankList d-flex flex-wrap">
                          {bankDates.map((data, index) => (
                            <div className="bankList_banks d-flex align-items-center">
                              <p className="px-2 bankList_bank-txt">
                                {data.bankName}
                              </p>
                              <ReactDatePicker
                                name={data.bankName}
                                className="customDatepicker m-2"
                                dateFormat="dd/MM/yyyy"
                                selected={data.date}
                                onChange={(date) => {
                                  let tempForm = [...bankDates];
                                  tempForm[index].date = date;
                                  setBankDates([...tempForm]);
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="d-flex gap-4">
                  <ButtonReact
                    className="customButtonGreenInverted"
                    onClick={() => dispatch(showBar(false), handleCancel())}
                  >
                    Cancel
                  </ButtonReact>
                  <ButtonReact
                    className="customButtonGreen"
                    disabled={buttonClick}
                    onClick={() => submitForm()}
                  >
                    Submit
                  </ButtonReact>
                </div>
              </Row>
            </div>
          </div>
        </>)}
    </>
  );
};

export default DebitTransactionMergeForm;
