import React, { useEffect, useState } from "react";
import FormLabel from "../../common/formLabel/formLabel";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { number, object, string } from "yup";
import FormikSelect from "../../common/formikSelect/formikSelect";
import "./CustomerDetails.scss";
import {
  apiConstants,
  tableNamePaymentConstants,
} from "../../../constants/Common/apiConstants";
import apiCall from "../../../utils/apiFunction/apiCall";
import { YupMessages } from "../../../constants/Common/commonConstants";

function sanitizeSpecialCharacters(value) {
  const sanitizedValue = value
    .replace(/(https?:\/\/[^\s]+)/, "")
    .replace(/[!@#$%^&*(),;:'.?"{}|<>_+=/\-]/g, "");
  return sanitizedValue;
}

function sanitizeSpecialCharactersAndSpace(value) {
  const sanitizedValue = value
    .replace(/(https?:\/\/[^\s]+)/, "")
    .replace(/\s+/g, "")
    .replace(/[!@#$%^&*(),;:'.?"{}|<>_+=/\-]/g, "");
  return sanitizedValue;
}

function sanitizeSpaceAndSpecialCharactersForEmail(value) {
  const sanitizedValue = value
    .replace(/(https?:\/\/[^\s]+)/, "")
    .replace(/\s+/g, "")
    .replace(/[!#$%^&*(),;:'?"{}|<>/\=]/g, "")
    .trim();
  return sanitizedValue;
}

function sanitizeLettersAndSpecialCharacters(input) {
  return input
    .replace(/(https?:\/\/[^\s]+)/, "")
    .replace(/[a-zA-\]\Z!@#$%^&*(),;:'.?"{}|<>_+=\/-]/, "");
}

function sanitizeNumberAndSpecialCharacters(value) {
  const sanitizedValue = value
    .replace(/(https?:\/\/[^\s]+)/, "")
    .replace(/[^a-zA-Z/\s+/g,]/g, "")
    .replace(/[!@#$%^&*(),;:'.?"{}|<>_+=/\-]/g, "")
    .replace(/\s\s+/g, " ");
  return sanitizedValue;
}
function CustomerDetails(props) {
  const { handleNext, handleBack } = props;
  const [companyCode, setCompanyCode] = useState([]);
  const [productType, setProductType] = useState([]);
  // const [prefilledValues, setPrefilledValues] = useState({});
  const onContinue = (values) => {
    localStorage.setItem("PrefilledValues", JSON.stringify(values));
    return handleNext(JSON.stringify(values));
  };
  let initalNullValues = {
    application_id: "",
    board_line_no: "",
    company_code: "",
    customer_name: "",
    lease_id: "",
    loan_no: "",
    mobile_number: "",
    office_email: "",
    personal_email: "",
    product_type: "",
    std_code: "",
  };
  const initialValues = window.location.search
    ? JSON.parse(localStorage.getItem("PrefilledValues")) || initalNullValues
    : initalNullValues;
  let validSchema = object({
    application_id: string(),
    board_line_no: string(),
    lease_id: string(),
    loan_no: string(),
    std_code: string(),
    product_type: string().required(YupMessages?.ProductTypeRequired),
    company_code: string().required(YupMessages?.CompanyCodeRequired),
    customer_name: string().required(YupMessages?.CustomerNameIsRequired),
    mobile_number: number().typeError(
      YupMessages?.PleaseRnterValidMobileNumber
    ),
    office_email: string().email(),
    personal_email: string()
      .email(YupMessages.PleaseEnterValidEmail),
  });

  useEffect(() => {
    getCompanyCode();
    getProductCode();
  }, []);

  const getCompanyCode = () => {
    let paramObj = {
      // offset: 0,
      // limit: 10,
      tablename: tableNamePaymentConstants?.Company,
      where: {
        isActive: 1,
      },
    };
    apiCall
      .post(apiConstants?.findData, paramObj)
      .then(async (res) => {
        let result = res?.data?.response;
        let formatedData = result?.filter(
          (data) => data?.company_code == 7000 || data?.company_code == 9000
        );
        result = formatedData.map((item) => {
          let newItem = {
            key: `${item?.company_code} (${item?.company_name})`,
            value: item?.company_code,
          };
          return newItem;
        });
        setCompanyCode(result);
      })
      .catch((er) => {
        console.log("errr =>>>>", er);
      });
  };

  const getProductCode = () => {
    let paramObj = {
      tablename: tableNamePaymentConstants?.Product,
    };
    apiCall
      .post(apiConstants?.findData, paramObj)
      .then(async (res) => {
        let result = res?.data?.response;
        result = result?.map((item) => {
          let newItem = {
            key: item?.product_name,
            value: item?.product_code,
          };
          return newItem;
        });
        setProductType(result);
      })
      .catch((er) => {
        console.log("errr =>>>>", er);
      });
  };
  const handleInputChangeForSpecialCharacters = (event, field) => {
    const sanitizedValue = sanitizeSpecialCharacters(event.target.value);

    field.onChange({
      target: {
        name: field.name,
        value: sanitizedValue.trim(),
      },
    });
  };

  const handleInputChangeForSpecialCharactersAndSpace = (event, field) => {
    const sanitizedValue = sanitizeSpecialCharactersAndSpace(
      event.target.value
    );

    field.onChange({
      target: {
        name: field.name,
        value: sanitizedValue,
      },
    });
  };
  const handleInputChangeForSpecialCharactersAndNumbers = (event, field) => {
    const sanitizedValue = sanitizeNumberAndSpecialCharacters(
      event.target.value
    );

    field.onChange({
      target: {
        name: field.name,
        value: sanitizedValue,
      },
    });
  };
  const handleInputChangeForMobileNumber = (event, field) => {
    const sanitizedValue = sanitizeLettersAndSpecialCharacters(event.target.value);
    field.onChange({
      target: {
        name: field.name,
        value: sanitizedValue.slice(0, 10).trim(),
      },
    });
  };
  const handleInputChangeForEmail = (event, field) => {
    const sanitizedValue = sanitizeSpaceAndSpecialCharactersForEmail(
      event.target.value
    );
    field.onChange({
      target: {
        name: field.name,
        value: sanitizedValue.trim(),
      },
    });
  };

  const validateWebTop = (val) => {
    let error;
      if (val && /^\s*$/.test(val)) {
        error = YupMessages?.invalidWebTopId;
      }
    return error;
  };

  const validateLoanNumber = (val) => {
    let error;
    if (val && /^\s*$/.test(val)) {
      error = YupMessages?.invalidLoanNumber;
    }
    return error;
  };
  const validateApplicationId = (val) => {
    let error;
    if (val && /^\s*$/.test(val)) {
      error = YupMessages?.invalidApplicationId;
    }
    return error;
  };

  const validateCustomerName = (val) => {
    let error;
    if (!val) {
      error = YupMessages?.CustomerNameIsRequired;
    } else if (/^\s*$/.test(val)) {
      error = YupMessages?.InvalidCustomerName;
    }
    return error;
  };

  const validateMobileNumber = (val) => {
    let error;
    if (val) {
      if (val?.length != 10) {
        error = YupMessages?.PleaseRnterValidMobileNumber;
      } else if (/^\s*$/.test(val)) {
        error = YupMessages?.MobileNumberRequired;
      }
    }
    return error;
  };

  

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validSchema}
        onSubmit={onContinue}
      >
        <Form>
          <div className="row customer_account_details">
            <div className="singleValidationDropdown col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2 pt-0">
              <div className="field_name">
                <FormLabel value="Product Type" className="required" />
              </div>
              <FormikSelect
                label="select a topic"
                name="product_type"
                showPlaceHolder
                options={productType}
              />
              <div className="errorMessage">
                <ErrorMessage name="product_type" />
              </div>
            </div>
            <div className="singleValidationDropdown col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2 pt-0">
              <div className="field_name">
                <FormLabel value="Company Code" className="required" />
              </div>
              <FormikSelect
                label="select a topic"
                name="company_code"
                options={companyCode}
                showPlaceHolder
              />
              <div className="errorMessage">
                <ErrorMessage name="company_code" />
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2">
              <div className="field_name">
                <FormLabel
                  value="Web Top Id/CIF No/Lease Id" />
              </div>
              <Field name="lease_id" validate={validateWebTop}>
                {({ field }) => (
                  <div>
                    <input
                      type="text"
                      id="lease_id"
                      name={field.name}
                      value={field.value}
                      className="InputField"
                      onChange={(e) => {
                        field.onChange(e);
                        handleInputChangeForSpecialCharacters(e, field);
                      }}
                      placeholder="Web Top Id/CIF No/Lease Id"
                    // onChange={sanitizeSpecialCharacters}
                    />
                    <div className="errorMessage">
                      <ErrorMessage name="lease_id" />
                    </div>
                  </div>
                )}
              </Field>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2">
              <div className="field_name">
                <FormLabel value="Loan No" />
              </div>
              <Field name="loan_no" validate={validateLoanNumber}>
                {({ field }) => (
                  <div>
                    <input
                      type="text"
                      id="loan_no"
                      name={field.name}
                      value={field.value}
                      className="InputField"
                      onChange={(e) => {
                        field.onChange(e);
                        handleInputChangeForSpecialCharacters(e, field);
                      }}
                      placeholder="Loan No"
                    // onChange={sanitizeSpecialCharacters}
                    />
                    <div className="errorMessage">
                      <ErrorMessage name="loan_no" />
                    </div>
                  </div>
                )}
              </Field>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2">
              <div className="field_name">
                <FormLabel value="LOS ID/Application id" />
              </div>
              <Field name="application_id" validate={validateApplicationId}>
                {({ field }) => (
                  <div>
                    <input
                      type="text"
                      id="application_id"
                      name={field.name}
                      value={field.value}
                      className="InputField"
                      onChange={(e) => {
                        field.onChange(e);
                        handleInputChangeForSpecialCharactersAndSpace(e, field);
                      }}
                      placeholder="LOS ID/Application id"
                    />
                    <div className="errorMessage">
                      <ErrorMessage name="application_id" />
                    </div>
                  </div>
                )}
              </Field>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2">
              <div className="field_name">
                <FormLabel value="Customer Name" className="required" />
              </div>
              <Field name="customer_name" validate={validateCustomerName}>
                {({ field }) => (
                  <div>
                    <input
                      type="text"
                      id="customer_name"
                      name={field.name}
                      value={field.value}
                      className="InputField"
                      onChange={(e) => {
                        field.onChange(e);
                        handleInputChangeForSpecialCharactersAndNumbers(
                          e,
                          field
                        );
                      }}
                      placeholder="Customer Name"
                    />
                    <div className="errorMessage">
                      <ErrorMessage name="customer_name" />
                    </div>
                  </div>
                )}
              </Field>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2">
              <div className="field_name">
                <FormLabel value="Mobile Number" />
              </div>
              <Field name="mobile_number" validate={validateMobileNumber}>
                {({ field }) => (
                  <div>
                    <input
                      type="text"
                      id="mobile_number"
                      name={field.name}
                      value={field.value}
                      className="InputField no-spinner"
                      onChange={(e) => {
                        field.onChange(e);
                        handleInputChangeForMobileNumber(e, field);
                      }}
                      placeholder="Mobile Number"
                    />
                    <div className="errorMessage">
                      <ErrorMessage name="mobile_number" />
                    </div>
                  </div>
                )}
              </Field>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 p-2">
              <div className="field_name">
                <FormLabel value="STD Code" />
              </div>
              <Field
                type="number"
                min="7"
                className="InputField"
                name="std_code"
                id="std_code"
                placeholder="STD Code"
              ></Field>
              <div className="errorMessage">
                <ErrorMessage name="std_code" />
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 p-2">
              <div className="field_name">
                <FormLabel value="Board Line No" />
              </div>
              <Field
                type="number"
                min="7"
                className="InputField"
                name="board_line_no"
                id="board_line_no"
                placeholder="Board Line No"
              ></Field>
              <div className="errorMessage">
                <ErrorMessage name="board_line_no" />
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2">
              <div className="field_name">
                <FormLabel value="Personal Email"/>
              </div>
              <Field name="personal_email">
                {({ field }) => (
                  <div>
                    <input
                      type="text"
                      id={field.name}
                      name={field.name}
                      value={field.value}
                      className="InputField no-spinner"
                      onChange={(e) => {
                        field.onChange(e);
                        handleInputChangeForEmail(e, field);
                      }}
                      placeholder="Personal Email"
                    />
                    <div className="errorMessage">
                      <ErrorMessage name="personal_email" />
                    </div>
                  </div>
                )}
              </Field>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-2">
              <div className="field_name">
                <FormLabel value="Office Email" />
              </div>
              <Field name="office_email">
                {({ field }) => (
                  <div>
                    <input
                      type="text"
                      id={field.name}
                      name={field.name}
                      value={field.value}
                      className="InputField no-spinner"
                      onChange={(e) => {
                        field.onChange(e);
                        handleInputChangeForEmail(e, field);
                      }}
                      placeholder="Office Email"
                    />
                    <div className="errorMessage">
                      <ErrorMessage name="office_email" />
                    </div>
                  </div>
                )}
              </Field>
            </div>
            {!props?.isSingleVerify && (
              <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 p-2">
                <button
                  className="bottombtn_backBtn"
                  onClick={() => handleBack()}
                />
                Back
              </div>
            )}
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 p-2 borderBtnNext">
              <button type="submit" className="bottombtn_submitBtn">
                Next
              </button>
            </div>
          </div>
        </Form>
      </Formik>
    </>
  );
}
export default CustomerDetails;
