import React, { useState } from 'react';
import '../apiStatus/bulk.scss'
import Datepicker from '../../../component/common/Daterange/daterange';
import Button from '../../../component/common/Button/button';
const Reportdisburmnetreports = () => {
    const [fromdate, setFromdate]=useState('');
    const [todate, setTodate]=useState('');
    return (
        <div >
            <div className='container '>
                <div className='row justify-content-center d-flex '>
                    <div className="row ps-5 pt-5 pb-3 ">
                        <div className="col-md-6">
                            <label className="labelPresentation text-nowrap">Disbursment Reports</label>
                        </div>
                    </div>
                    <div className=" col-md-11 containerstyle ">
                        <div className=' pt-4'>
                            <div className='row mb-2 gap-5'>
                                <div className='col-md-5 '>
                                    <div className='label'>From Date</div>
                                    <div>
                                        <Datepicker placeholder='Select Date' className='datepickerstyle' onChange={(e) => setFromdate(e)} selected={fromdate} />
                                    </div>
                                </div>
                                <div className='col-md-5 '>
                                <div className='label'>To Date</div>
                                <div>
                                    <Datepicker placeholder='Select Date' className='datepickerstyle' onChange={(e) => setTodate(e)} selected={todate}/>
                                </div>
                                </div>
                            </div>
                           
                        </div>
                        <div className='d-flex gap-3 pt-3 ms-3 mb-3'>
                            <div className=''>
                                <Button value="Cancel" role="secondary" className='cancel' />
                            </div>
                            <div className=''>
                                <Button value="Download" role="primary" className='Download' />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default Reportdisburmnetreports;