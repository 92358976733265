import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './modal.scss'
const CustomModal = ({ heading, data, show, onHide }) => {
  return (
    <>
      <Modal centered show={show} onHide={onHide} backdrop='static'>
        <Modal.Header className='custom-modal-header'>
          <Modal.Title className='custom-modal-title'>{heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {
          <ul>
            {data
              ? typeof data === 'object' && !Array.isArray(data)
                ? Object.keys(data).map((key) => (
                    <li key={key}>{`${key}: ${data[key]}`}</li>
                  ))
                : data.length > 0
                ? data.map((item, index) => <li key={index}>{item}</li>)
                : 'No data available'
              : 'No data available'}
          </ul>
        } 
        </Modal.Body>
        <Modal.Footer>
          <Button className='custom-modal-button' onClick={onHide}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CustomModal;
