import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import FormLabel from '../../../../common/formLabel/formLabel';
import { ROUTE_PATH } from '../../../../../constants/Routes';
import apiCall from '../../../../../utils/apiFunction/apiCall';
import {
	apiConstants,
	FOMRS,
	STATUS, tableNamePaymentConstants, errMessage, successMsg, toastIds
} from '../../../../../constants/Common/apiConstants';
import Button from '../../../../common/Button/button';
import { useNavigate, useLocation } from 'react-router-dom';
// import "../craeteFrequency/createFrequency.scss";
import FormikSelect from '../../../../common/formikSelect/formikSelect';
import Dropdown from '../../../../common/Dropdown/dropdown';
import React, { useState, useEffect } from 'react';
import { YupMessages } from '../../../../../constants/Common/commonConstants';
import { toast } from 'react-toastify';


const validationSchema = Yup.object({
	bank_code: Yup.string().required(YupMessages?.BankCodeRequired),
	bank_name: Yup.string().required(YupMessages?.BankNameIsRequired),
	company_code: Yup.string().required(YupMessages?.CompanyCodeRequired),
	tcl_sponssor_bank_id: Yup.string().required(YupMessages?.TclSponsorBankNameRequired),
});

const CreateBancs = () => {
	const navigate = useNavigate();
	const { state } = useLocation();

	const formType = state?.type || FOMRS.ADD;
	const headingText = formType === FOMRS.ADD ? "Create BaNCS Sponsor Bank" : "Update BaNCS Sponsor Bank";

	const [formData, setFormData] = useState({
		bank_code: '',
		bank_name: '',
		company_code: '',
		bancs_bank_code: '',
		tcl_sponssor_bank_id: '',
	});

	const [loading, setLoading] = useState(false);

	const FormSubmit = (value) => {
		let query = {
			body: {
				bank_code: value.bank_code ? value.bank_code : '-',
				bank_name: value.bank_name ? value.bank_name : '-',
				company_code: value.company_code ? value.company_code : '-',
				bancs_bank_code: value.bancs_bank_code ? value.bancs_bank_code : '-',
				tcl_sponssor_bank_id: value.tcl_sponssor_bank_id ? value.tcl_sponssor_bank_id : '-',
			},
			tablename: tableNamePaymentConstants?.BaNCS,
		};

		if (formType === FOMRS.EDIT) {
			query.whereQuery = {
				bank_code: state?.user_type?.bank_code,
			};
			apiCall
				.post(apiConstants?.updateUser, query)
				.then((res) => {
					if (res?.status === STATUS.SUCCESS) {
						navigate(ROUTE_PATH.BANCS_SPONSOR_BANK);
						toast.success(successMsg?.BaNCSUpdatedSuccessfully, toastIds?.FileUploadSuccess)
					}
				})

		}

		if (formType === FOMRS.ADD) {
			apiCall
				.post(apiConstants?.createUser, query)
				.then((res) => {
					if (res?.status === STATUS.SUCCESS) {
						navigate(ROUTE_PATH.BANCS_SPONSOR_BANK);
						toast.success(successMsg?.BaNCSCreatedSuccessfully, toastIds?.FileUploadSuccess)
					}
				})

		}


	};

	const getFormData = async (id) => {
		let bodyParams = {
			tablename: tableNamePaymentConstants?.BaNCS,
			whereQuery: {
				id,
			},
		};
	};

	useEffect(() => {
		if (formType === FOMRS.EDIT) {
			setFormData({
				bank_code: state?.user_type?.bank_code,
				bank_name: state?.user_type?.bank_name,
				company_code: state?.user_type?.company_code,
				bancs_bank_code: state?.user_type?.bancs_bank_code,
				tcl_sponssor_bank_id: state?.user_type?.tcl_sponssor_bank_id,
			});
		}
	}, [loading]);

	return (
		<Formik
			initialValues={{
				bank_code: formData.bank_code,
				bank_name: formData.bank_name,
				company_code: formData.company_code,
				bancs_bank_code: formData.bancs_bank_code,
				tcl_sponssor_bank_id: formData.tcl_sponssor_bank_id,
			}}
			validationSchema={validationSchema}
			enableReinitialize={true}
			onSubmit={(values) => {
				FormSubmit(values);
			}}
		>
			{({ values, setFieldValue }) => (
				<>
					<div id="createFrequency">
						<div className="col blueFonts large_f"
							onClick={() => navigate(ROUTE_PATH.BANCS_SPONSOR_BANK)}
						>
							{`BaNCS Sponsor Bank Master >> `}
							<span className="blackFonts">{headingText}</span>
						</div>
						<div className="main-div">
							<Form className=".labler-wrapper">
								<div id="RegularForm">
									<div className="createroletext">
										<p id="pText">{headingText}</p>
									</div>

									<div className="row paddingTop ml-4 mr-4">
										<div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
											<div>
												<FormLabel value="Bank Code" className="required" />
											</div>
											<Field
												type="text"
												className="InputField"
												name="bank_code"
												id="bank_code"
												placeholder="Bank Code"
												disabled={formType === FOMRS.EDIT ? true : false}
											></Field>
											<div className="errorMessage">
												<ErrorMessage name="bank_code" />
											</div>
										</div>
									</div>

									<div className="row paddingTop ml-4 mr-4">
										<div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
											<FormLabel value="Bank Name" className="required" />
											<Field
												type="text"
												className="InputField"
												name="bank_name"
												id="bank_name"
												placeholder="Bank Name"
												disabled={formType === FOMRS.EDIT ? true : false}
											></Field>
											<div className="errorMessage">
												<ErrorMessage name="bank_name" />
											</div>
										</div>
									</div>

									<div className="row paddingTop ml-4 mr-4">
										<div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
											<FormLabel value="Company Code" className="required" />
											<Field
												type="text"
												className="InputField"
												name="company_code"
												id="company_code"
												placeholder="Comapny Code"
												disabled={formType === FOMRS.EDIT ? true : false}
											></Field>
											<div className="errorMessage">
												<ErrorMessage name="company_code" />
											</div>
										</div>
									</div>

									<div className="row paddingTop ml-4 mr-4">
										<div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
											<FormLabel
												value="TCL Sponsor Bank Id"
												className="required"
											/>
											<Field
												type="text"
												className="InputField"
												name="tcl_sponssor_bank_id"
												id="bancs_bank_code"
												placeholder="TCL Sponsor Bank Id"
											></Field>
											<div className="errorMessage">
												<ErrorMessage name="tcl_sponssor_bank_id" />
											</div>
										</div>
									</div>

									<div className=" d-flex py-4">
										<div className="cancel_btn">
											<Button
												role="secondary"
												value="Cancel"
												type="button"
												onClick={() => navigate(ROUTE_PATH.BANCS_SPONSOR_BANK)}
											/>
										</div>

										<div className="submitButton">
											<Button
												role="primary"
												value="Submit"
												type="submit"
												onClick={() => { }}
											/>
										</div>
									</div>
								</div>
							</Form>
						</div>
					</div>
				</>
			)}
		</Formik>
	);
};

export default CreateBancs;
