import React, { useEffect, useState } from "react";
import "./scanMergeStatus.scss";
import CustomTable from "../../../../component/customtable/CustomTable";
import Button from "../../../../component/common/Button/button";
import apiCall from "../../../../utils/apiFunction/apiCall";
import { useNavigate } from "react-router-dom";

import {
  commonVariables,
  componentRole,
  tableID,
  route,
} from "../../../../constants/Common/commonConstants";
import SystemXLoader from "../../../../component/common/Loader/SystemXLoader";
import { apiConstants, toastIds, errMessage } from "../../../../constants/Common/apiConstants";
import { toast } from "react-toastify";

const ScanMergeStatus = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [count, setCount] = useState(0);
  const [selectMonth] = useState(1);
  const [listdata, setListdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [downloadFlag, setDownloadFlag] = useState(false);
  const navigate = useNavigate();
  const onDownload = (paramObj) => {
    // console.log(response?.data)
    apiCall.post(apiConstants?.ScanMergeStatusDownloadMergeStatusReport, paramObj)
      .then((res) => {
        if (res?.status === 200) {
          // setLoading(false);
          let result = res?.data
          if (result) {
            if (typeof res?.data?.response == "string") {
              const link = document.createElement("a")
              link.href = res?.data?.response
              link.click()
              toast.success(commonVariables?.FileDownloadSuccess, {
                toastId: toastIds?.FileDownloadSucess,
              })
            } else {
              toast.error(res?.data?.message || errMessage?.somethingWentWrong, {
                toastId: toastIds?.DataError,
              })
            }
          }
        } else {
          // setLoading(false);
          toast.error(res?.data?.message || errMessage?.somethingWentWrong, {
            toastId: toastIds?.DataError,
          })
        }
      }).catch((err) => {
        setLoading(false)
        toast.error(err?.message || errMessage?.somethingWentWrong, {
          toastId: toastIds?.DataError,
        })
      }).finally(() => {
        setLoading(false)
      })
  }

  const listApi = (value) => {
    let temp = 0;
    if ((value || pageNumber) > 0) {
      temp = value ? (value - 1) * 10 : (pageNumber - 1) * 10;
    }
    setDownloadFlag(true);
    setLoading(true);
    const params = {
      tablename: "mergeStatus",
      month: 0,
      offset: temp,
      order: [["id", "desc"]],
      limit: 10,
      dataFetch: [
        {
          column: "bank_code",
          operator: "ne",
          value: "null",
        },
        {
          column: "totalcount",
          operator: "ne",
          value: "0",
        },
      ],
    };
    apiCall
      .post(apiConstants?.fetchAllData, params)
      .then(async (res) => {
        if (res.status === 200) {
          let result = res?.data?.response;
          setCount(res?.data?.count || 0);
          result = result.map((item, i) => {
            let newItem = {
              no: temp + i + 1,
              batch_id: item?.batch_id ? item?.batch_id : "-",
              total_count: item?.totalcount ? item?.totalcount : "-",
              mandate: item?.mandate_type ? item?.mandate_type : "-",
              bank: item?.bank_code ? item?.bank_code : "-",
              date_time: item?.date_time ? item?.date_time : "-",
              user_name: item?.user_name ? item?.user_name : "-",

              view_record: item?.batch_id
                ? addLink(
                  item?.batch_id,
                  item?.bank_code,
                  item?.company_code,
                  item?.mandate_type
                )
                : "-",

              download: (
                <Button
                  value={commonVariables?.DOWNLOAD || ""}
                  role={componentRole?.TRANSPARENT || ""}
                  onClick={() => {
                    onDownload({
                      "merge_batch_id": item?.batch_id,
                      "mandate_type": item?.mandate_type,
                      "bank_code": item?.bank_code
                    })
                  }}
                />
              ),
            };
            return newItem;
          });
          if (result?.length > 0) {
            setListdata([...result]);
          }
        }
      })
      .catch((er) => {
        console.log("err", er);
      })
      .finally(() => {
        setDownloadFlag(false);
        setLoading(false);
      });
  };

  const changePage = (value) => {
    listApi(value);
    setPageNumber(value - 1);
  };
  const addLink = (batch_id) => {
    return (
      <>
        <Button
          role={componentRole?.TRANSPARENT || ""}
          value={"Verify Data"}
          onClick={() => {
            navigateToNextPage(batch_id);
          }}
        />
      </>
    );
  };
  const navigateToNextPage = (batch_id) => {
    navigate(route?.SCAN_MERGE_STATUS_VIEW || "", {
      state: {
        batch_id: batch_id,
      },
    });
  };


  useEffect(() => {
    listApi();
  }, [selectMonth]);

  return (
    <>
      { }
      <div className="d-flex align-items-center justify-content-between mt-5 mb-4 mx-3">
        <div className="right-div-header"> <label className="labelPresentation">
          {commonVariables?.MERGE_STATUS || ""}
        </label>
          <div></div>
        </div>

      </div>
      <div className="col dtMergeTable">
        {downloadFlag && (
          <div className="loaderDiv">
            <SystemXLoader />
          </div>
        )}
        <CustomTable
          cols={[
            {
              id: tableID?.NO || "",
              title: commonVariables?.NO || "",
            },
            {
              id: tableID?.BATCH_ID || "",
              title: commonVariables?.BATCH_ID || "",
            },
            {
              id: tableID?.TOTAL_COUNT || "",
              title: commonVariables?.TOTAL_RECORDS || "",
            },
            {
              id: tableID?.MANDATE || "",
              title: commonVariables?.MANDATE || "",
            },
            {
              id: tableID?.BANK || "",
              title: commonVariables.BANK || "",
            },
            {
              id: tableID?.DATE_TIME || "",
              title: commonVariables?.DATE_TIME || "",
            },
            {
              id: tableID?.USER_NAME || "",
              title: commonVariables?.USER_NAME || "",
            },
            {
              id: tableID?.VIEW_RECORD || "",
              title: commonVariables?.VIEW_RECORD || "",
            },
            {
              id: tableID?.DOWNLOAD || "",
              title: commonVariables?.DOWNLOAD || "",
            },
          ]}
          data={listdata}
          count={count}
          setPagination={(page) => changePage(page)}
          activePage={pageNumber}
          loading={loading}
        />
      </div>
      {/* </div> */}
    </>
  );
};

export default ScanMergeStatus;
