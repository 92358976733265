import React, { useEffect, useState } from "react";
import "../enach/Enach.scss"
import CustomTable from "../../component/customtable/CustomTable"
import Button from "../../component/common/Button/button";
import apiCall from "../../utils/apiFunction/apiCall"
import { commonVariables, componentRole, dateFormats, selectoptions } from '../../constants/Common/commonConstants';
import '../../styles/layout/common.scss'
import SystemXLoader from "../../component/common/Loader/SystemXLoader";
import { toast } from "react-toastify";
import { apiConstants , errMessage , toastIds} from "../../constants/Common/apiConstants";
import moment from "moment";


const SwapList = () => {
    const [selectMonth, setSelectMonth] = useState(1);
    const [count, setCount] = useState(0);
    const [listdata, setListdata] = useState([]);
    const [pageNumber, setPageNumber] = useState(0)
    const [loading, setLoading] = useState(false);
    const [downloadFlag, setdownloadFlag] = useState(false)


    const fetchBlockFile = (key) => {
        setdownloadFlag(true);    
        apiCall
          .get(`${apiConstants?.downloadPresignedUrl}?key=${key}`)
          .then((response) => {
            if (response.status === 200) {
              const link = document.createElement("a");
              link.href = response?.data;
              link.click();  
              toast.success(commonVariables?.FileDownloadSuccess, { toastId: toastIds?.FileDownloadSucess })
     
            }
          })
          .catch((error) => {
            console.log('Error' , error);
            setdownloadFlag(false);
            return toast.error(errMessage?.InternalServerError, {
              toastId: toastIds?.InternalServerError,
            });
          })
          .finally(() => {
            setdownloadFlag(false);
          });
    }


    const listDataByMonthApi = (value) => {
        setdownloadFlag(true)
        let temp = 0
        if ((value || pageNumber) > 0) {
            temp = value ? (value - 1) * 10 : (pageNumber - 1) * 10;
        }
        setLoading(true);
        const params = {
            "tablename": "swap_mnd_blk_files_log",
            "month": 0,
            "offset": temp || 0,
            "attributes": ['id', 'file_name', 'record_count', 'createdAt' , 's3_key', 'logs_value'],
            "limit": apiConstants?.limit,
            "order": [['id', 'DESC']]

        }
        apiCall.post(apiConstants?.fetchAllData, params).then((response) => {
            if (response.status === 200) {
                if (response?.data?.status === 500) return toast.error('Internal Server Error', { toastId: 'BulkEnachListError' })
                if (response?.data?.status === 404) {
                    setLoading(false)
                    return setListdata([])
                }
                else {
                    setCount(response?.data?.count || 0)
                    let data = response?.data?.response.map((item , index) => {
                        return {
                            no: temp + index +1,                
                            file_name: item?.file_name,
                            record_count: item?.record_count,
                            file_received_date: moment(item?.createdAt).format(dateFormats?.dmyHms),
                            date_time: moment(item?.upload_date_time).format(dateFormats?.dmyHms),
                            action: (
                                <div>
                                    <Button
                                 onClick={() => {fetchBlockFile(item?.s3_key)
                                }}
                              value='Download'
                              role={componentRole.CANCEL}
                            />
                                </div>
                              ),
                            // Download add

                        };

                    })
                    if (data?.length > 0) {
                        setListdata([...data])
                    }
                }

            }
        }).catch((error) => {
            setLoading(false)
            return setListdata([])
        }).finally(() => {
            setdownloadFlag(false)
            setLoading(false);
        })


    }


    const changePage = (value) => {
        listDataByMonthApi(value)
        setPageNumber(value - 1);
    };



 







    useEffect(() => {
        listDataByMonthApi();
    }, [selectMonth]);

    return (

        <div className="mx-3 mt-4">
            {downloadFlag && (
                <div className='d-flex justify-content-center d-flex align-items-center loaderDiv'>
                    <SystemXLoader />
                </div>
            )}

            <div>
                <div>
                    <div className="d-flex align-items-center justify-content-between mt-4 mb-3">
                        <div className="right-div-header">
                            <div className="mt-1">
                                <label className=" text-nowrap common-header">MandateBlockCaseSwap</label>

                            </div>
                        </div>
                        <div className="d-flex gap-4 right-div-header  mandateui">
                            <div>
                                <select
                                    value={selectMonth}
                                    onChange={(e) => {
                                        setSelectMonth(e?.target?.value);
                                    }}
                                    className="form-select selectInput"
                                >
                                    <option key="Select Month" hidden value>
                                        Select Month
                                    </option>
                                    {selectoptions?.length &&
                                        selectoptions.map((value) => {
                                            return <option value={value.value}>{value.label}</option>;
                                        })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="mandateTableWrapper">

                        <CustomTable
                            cols={[
                                {
                                    id: "no",
                                    title: "Sr No.",
                                    classStyle: {},
                                },
                                {
                                    id: "file_name",
                                    title: "File Name",
                                    classStyle: {},
                                },
                                {
                                    id: "record_count",
                                    title: "Record count",
                                    classStyle: {},
                                },
                                {
                                    id: "file_received_date",
                                    title: "File Received Date",
                                    classStyle: {},
                                },
                                {
                                    id: "action",
                                    title: "Download",

                                    classStyle: {},
                                }
                            ]}
                            data={listdata}
                            count={count}
                            setPagination={(page) => changePage(page)}
                            activePage={pageNumber}
                            loading={loading}

                        />
                    </div>
                </div>
            </div>

        </div>

    )
}
  export default SwapList;