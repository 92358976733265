import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./Tooltip.scss";
const CustomTooltip = ({ id, children, title, position }) => (
  <OverlayTrigger
    placement={position}
    containerPadding={2}  
    trigger={['hover', 'focus']}
    id={id}
    key={id}
    delay={{ show: 500, hide: 250 }}
    overlay={<Tooltip id={id}>{title}</Tooltip>}
  >
    <a href="#">{children}</a>
  </OverlayTrigger>
);

export default CustomTooltip;
